import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CardHeader from '../UsersManagement/CardHeader';
import BrandForm from './BrandForm'
import initData from '../../../utils/initData';
import LoadingLayer from '../../widgets/Shared/LoadingLayer';
import ApiData from '../../../utils/Api';

export default class AddBrandComponent extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        showMessage: PropTypes.func.isRequired,
    }

    state = {
        inProgress: false,
        errMessage: false,
    }

    addBrand = (brand) => {
        const { types } = initData.getStatusMessages();
        const { history, showMessage , errorMessage} = this.props;
        this.setState({ inProgress: true });
        
        ApiData.createBrand(brand)
            .then(result => {
                this.setState({errMessage:false});
                typeof showMessage === 'function' && showMessage(types.createBrand, brand.displayName ? brand.displayName : brand.name);
                typeof errorMessage === 'function' && errorMessage(this.state.errMessage)
                history.push('/brands');
            })
            .catch(error => {
                this.setState({errMessage:true});
                if(Number(error.data.statusCode) === 400){
                    this.setState({sessionTimeoutErr:true});
                    typeof showMessage === 'function' && showMessage(types.existingBrand, error.data.error);
                }
                this.setState({
                    inProgress: false,
                    error: error.data.statusCode === 400 ? error.data.error : error.error,
                },typeof errorMessage === 'function' && errorMessage(this.state.errMessage));
                
            })
    }

    cancelAddingBrand = () => {
        this.props.history.push('/brands');
    }

    render() {
        const addBrandData = initData.getBrandData();
        const { inProgress } = this.state;
        // const { match } = this.props;

        return (
            <div>
                <CardHeader 
                    primaryTitle={addBrandData.title(addBrandData.modes.create)}
                    secondaryTitle={addBrandData.subtitle(addBrandData.modes.create)} 
                />
                <BrandForm
                    cancelForm={this.cancelAddingBrand}
                    createBrand={this.addBrand}
                    editMode={addBrandData.modes.create}
                />
                {inProgress && <LoadingLayer />}
            </div>
        )
    }
}
