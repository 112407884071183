import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './LineChart.scss';
import cx from 'classnames';
import * as d3 from "d3";
import initData from '../../../utils/initData';
import ItemList from '../Shared/ItemList';
import SimpleSelect from '../Shared/SimpleDropDownSelect';
import NoDataBox from '../Shared/NoDataBox';
import { isEmpty, isEqual } from 'lodash';
import { DateTime } from 'luxon';
import lineChartStatTestingDateService from '../../../utils/lineChartStatTestingDateService';
import {
    enableBrand,
    disableBrand,
    enableSegment,
    disableSegment,
} from '../../../reducers/Trends/actionCreators';
import { filterHSSMarketTrendsLabels } from '../../../utils/common';

const trendsTabOptions = [
    {id: 0, name: "Equity"},
    {id: 1, name: "Conversion"},
    {id: 2, name: "Emotional"},
    {id: 3, name: "Advertising"},
    {id: 4, name: "Irreplaceability"},
];

const getRenamedKey = (key) => {
  let result = key

  if (key === 'total') result = 'equity'
  if (key === 'momentum') result = 'netMomentum'
  if (key === 'quality') result = 'highQuality'
  if (key === 'recommend') result = 'recommend'

  return result
}

class LineChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      yAxis: null,
      optionOpen: '',
      lineData: [],
      scoreDecomposition:false,
     
    };
    this.svgRef = React.createRef()
    this.getDataSetting(this.props.data);
    this.onSelectStab = this.onSelectStab.bind(this);
    this.onSelectScoreTab = this.onSelectScoreTab.bind(this);
    this.onClickScoreDecomposition = this.onClickScoreDecomposition.bind(this)
    this.onSelectOption = this.onSelectOption.bind(this);
    this.onResize = this.onResize.bind(this);
    this.selectedMovingAverage = initData.getMovingAverageIntervals().some(o => o.id === this.props.interval);
    this.scoreDecompositionData = this.props.selectedOption ==='consideration'?initData.getTrendsOptionCategories()[5]:initData.getTrendsOptionCategories()[4];
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize )
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.statTestingEnabled !== nextProps.statTestingEnabled) {
      this.onResize();
    }

    if (!isEqual(this.props.data, nextProps.data)) {
      this.onResize();
      this.getDataSetting(nextProps.data);
    }
  }

  onResize() {
    const { noEditable } = this.props;
    const lineChartContainerHeight = (noEditable || window.innerHeight < 730) ? 420 : window.innerHeight - 310;

    if (this.lineChartCardContainer) {
      const width = (noEditable || window.innerWidth < 813) ?
        this.lineChartCardContainer.clientWidth :
        this.lineChartCardContainer.clientWidth - 420;
      const lineChartContainerWidth = width

      this.setState({ lineChartContainerWidth, lineChartContainerHeight }, this.buildLineData);
    }
  }

  getDataSetting = (data) => {
    let {selectedMarket} = this.props;
    this.optionsData={};
    this.availableDates = [];
    this.trendsOptionCategories = initData.getTrendsOptionCategories();
    trendsTabOptions.forEach((stab) => {
        this.optionsData[stab.id]= filterHSSMarketTrendsLabels(selectedMarket?.countryCode,this.trendsOptionCategories[stab.id]);
    });
    this.getAvailableDates(data);
  };

  getAvailableDates = (lineData) => {
    if(this.selectedMovingAverage && lineData.length> 0){
      let allDates = lineData.flatMap(obj => obj.data.map(d=>d.formatedDate));
      let validDates = allDates.filter(date => date !== null && date !== undefined && date !== 'Invalid date');
      let uniqueDates = [...new Set(validDates)];
      this.availableDates = uniqueDates.sort((a,b)=>{ 
        const dateA = DateTime.fromFormat(a, 'M/d/yy'); 
        const dateB = DateTime.fromFormat(b, 'M/d/yy');
        return dateA - dateB;
      });
    }else if(lineData.length> 0){
        let data = lineData.filter(obj => obj.data.every(d => d.date !== null));
        this.availableDates = data.slice().sort((a, b) => b.data.length - a.data.length)[0]?.data.map(d => d.formatedDate);
        this.availableDates = this.availableDates?.filter(el=>el !== 'Invalid date');
        this.availableDates = Array.isArray(this.availableDates) ? this.availableDates : [];
    }
  }

  buildLineData() {
    const{ lineChartContainerWidth, lineChartContainerHeight } = this.state;
    const{ noEditable, data, stab, selectedOption, chartsIdPrefix, isExportComponent } = this.props;
    const height = noEditable ? lineChartContainerHeight - 50 : (lineChartContainerHeight - 160);
    this.availableDates = [];
    this.getAvailableDates(data);
    let lineData = data.filter(dataObj =>  dataObj.stab === stab && dataObj?.segment?.active !==  false )
    const list = lineData.map(dataObj => dataObj.data).filter(el => el.length);

    let maxValueOfYAxis = parseInt(Math.max.apply(0,
      list.map(IntervalData=> (Math.max.apply(0,
        IntervalData.map(d=> d[selectedOption])))))/10+1)*10;
    let minV = parseInt(Math.min.apply(maxValueOfYAxis,
      list.map(data=>parseInt(Math.min.apply(maxValueOfYAxis,
        data.map(d=>d[selectedOption])))))/10);

    if (maxValueOfYAxis > 100) {
      maxValueOfYAxis = 100;
    }
    let negativeZero = -0
    if (minV < 0 || Object.is(negativeZero, minV)) {
      minV = (minV - 1) * 10
    }
    else {
      minV = minV * 10
    }


    if(!isExportComponent) {
      d3.select(`.${chartsIdPrefix}lineChartSvg`).selectAll("circle").remove();
      d3.select(`.${chartsIdPrefix}lineChartSvg`).selectAll("path").remove();
    }

    this.x = d3.scaleBand().domain(this.availableDates).range([50, lineChartContainerWidth]).padding(1);
    this.y = d3.scaleLinear().domain([minV, maxValueOfYAxis]).nice().range([height, 0]);
    this.setState({
      lineData: lineData
    });
  }

  onSelectStab(index) {
    const defaultValue = this.optionsData[index].length > 0 ? this.optionsData[index][0].id : 0;
    if (typeof this.props.onChangeLineOption === 'function') {
      this.props.onChangeLineOption({
        stab: index,
        selectedOption: defaultValue,
        selectedScoreOption: this.props.selectedScoreOption
      });
    }
    this.setState({
      optionOpen: '',
    });
      this.props.getTendsOptionsData(defaultValue)
  }

  onClickScoreDecomposition() {
    this.setState((prevState => ({ scoreDecomposition: !prevState.scoreDecomposition })));
  }

  onSelectScoreTab(index) {
    if (typeof this.props.onChangeLineOption === 'function') {
      this.props.onChangeLineOption({
        stab: this.props.stab,
        selectedOption: this.props.selectedOption,
        selectedScoreOption: index,
      });
    }
    this.props.getTendsOptionsData(index)
  }

  onSelectOption(index) {
    if (typeof this.props.onChangeLineOption === 'function') {
      let scoreDecompositionValues = index ==='consideration'?initData.getTrendsOptionCategories()[5]:initData.getTrendsOptionCategories()[4]
      this.props.onChangeLineOption({
        stab: this.props.stab,
        selectedOption: index, 
        selectedScoreOption: scoreDecompositionValues.some(obj=>this.props.selectedScoreOption == obj.id)?this.props.selectedScoreOption:'positive'
      });
    }
    this.setState({
      optionOpen: '',
    });
    this.props.getTendsOptionsData(index)
  }

  onShowOrHideClick = (id, loading) => {
    const {
      enableBrand,
      disableBrand,
      enableSegment,
      disableSegment,
      isDetailed,
      hiddenList,
      emulateLoading,
      page
    } = this.props;
    const enableAction = isDetailed ? enableSegment : enableBrand;
    const disableAction = isDetailed ? disableSegment : disableBrand;
    const action = hiddenList.includes(id) ? enableAction : disableAction;
    action(id);
    if(loading){
      emulateLoading(true, false);
      setTimeout(() => emulateLoading(false, true), 2000);
    }
  };

  customYAxis = (node) => {
    const {lineChartContainerWidth } = this.state;
    let x2 = (lineChartContainerWidth).toString();
    node.append('g').call(d3.axisRight(this.y).tickSize(lineChartContainerWidth).ticks(8));
    node.select(".domain").remove();
    node.selectAll(".tick line").attr("stroke", "#DFE2EA").attr("x1", "30").attr("x2", x2);
    node.selectAll(".tick text").attr("x", 4).style("font-size", '0.8rem').style("color", "#264653");
  }

  customXAxis = (node) => {
    let { lineChartContainerHeight, lineChartContainerWidth } = this.state;
    let { noEditable, interval } = this.props;
    let tickValuesArr = (this.orderLabels(this.x.domain(),10))
    const height = noEditable ? lineChartContainerHeight - 50 : (lineChartContainerHeight - 160);
    let xScale = d3.scaleBand().domain(this.availableDates).range([50, lineChartContainerWidth]).padding(1);
    const lastTwoXCoordinates = [xScale(tickValuesArr[tickValuesArr.length - 2]), xScale(tickValuesArr[tickValuesArr.length - 1])];
    const distance = Math.abs(lastTwoXCoordinates[1] - lastTwoXCoordinates[0]);
    if(distance <= 12){
      tickValuesArr.splice(tickValuesArr.length-2,1);
    }
    const { x } = this;
    let xAxis = d3.axisBottom(x).tickSizeInner(0).tickPadding(1).tickSizeOuter(0).tickValues(tickValuesArr)

    node.append('g')
      .attr('class', 'x-axis')      
      .attr('transform', `translate(0, ${height})`)
      .call(xAxis);   
    node.selectAll(".domain").remove();
    node.selectAll("text")
    .style("font-size", '0.8rem')
    .style("color", "#264653")
    .attr("y", "30")
    .style('transform','rotate(-60deg) translate(-15px, -12px)')
    .style("text-anchor", "end");
    node.selectAll("line").style("stroke", "#FFFFFF");
    if (interval === 'WEEKS') {
      node.selectAll("text")
      .call(this.wrap,100)
    }
  };

  wrap = (text, width) => {
    text.each(function () {
      var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1, 
          y = 10,
          dy = parseFloat(text.attr("dy")),
          tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text.append("tspan").attr("x", 0).attr("y", y + 2).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
        }
      }
    });
  }

  orderLabels(data, count) {
    if (!this.selectedMovingAverage)
      return data;

    let elements = [data[0]];
    let total = data.length - 1;
    let interval = Math.floor(total/(count - 1));

    for (let i = 1; i < count; i++) {
      elements.push(data[i * interval]);
    }

    elements.push(data[data.length - 1]);
  //   let daysDifference = Math.ceil((moment(elements[elements?.length-1])?.diff(moment(elements[elements?.length-2]))  + 1)/(1000 * 3600 * 24))
  //   if(daysDifference < 7){
  //     elements.splice(elements.length-2,1);
  //   }
    return elements;
  }

  getStatTestingInfo(data, field) {
    const { statTestingEnabled, selectedOption, interval,selectedScoreOption } = this.props;
    // stat testing is turned off or no data
    let scoreDecompositionEnabled = (selectedOption === 'momentum' || selectedOption === 'quality' || selectedOption === 'recommend' || selectedOption ==='consideration')
    if (!statTestingEnabled || isEmpty(data[field]) || !data[field][scoreDecompositionEnabled? selectedScoreOption+(getRenamedKey(selectedOption)[0].toUpperCase() + getRenamedKey(selectedOption).slice(1)):getRenamedKey(selectedOption)]) {
      return null;
    }

    const statTestingDateIsValid = lineChartStatTestingDateService.validateStatTestingDate(
        data.date,
        data[field][scoreDecompositionEnabled? selectedScoreOption+(getRenamedKey(selectedOption)[0].toUpperCase() + getRenamedKey(selectedOption).slice(1)):getRenamedKey(selectedOption)][0],
        interval,
    );

    // tooltip and circle outline should appear only for prior period comparison
    return statTestingDateIsValid ? data[field][scoreDecompositionEnabled? selectedScoreOption+(getRenamedKey(selectedOption)[0].toUpperCase() + getRenamedKey(selectedOption).slice(1)):getRenamedKey(selectedOption)] : null;
  }

  getStatTestingTooltip(data) {
    const { interval } = this.props;
    const higherThan = this.getStatTestingInfo(data, 'higherThan');
    const lowerThan = this.getStatTestingInfo(data, 'lowerThan');
    const base = `<span style="color: #000">${data.formatedDate}</span> is statistically significantly`;
    let htmlStr = '';

    if (higherThan) {
      const date = lineChartStatTestingDateService.formatStatTestingDate(higherThan[0], interval);
      htmlStr += `${base} higher than <span style="color: #000">${date}</span> `;
    }

    if (lowerThan) {
      const date = lineChartStatTestingDateService.formatStatTestingDate(lowerThan[0], interval);
      htmlStr += `${higherThan ? '</br>' : ''}${base} lower than <span style="color: #000">${date}</span> `;
    }

    return htmlStr;
  }

  renderLine = (node, data, brandName) => {
    const { interval, selectedOption } = this.props;
    const { x, y } = this;
    const lineGenerator = d3.line()
      .x(d => x(d.formatedDate))
      .y(d => {return  y(d[selectedOption]) });

    if(interval === "WEEKS" || interval === "MONTHS" || interval === "QUARTERS"){
     node.append("path")
      .datum(data.data)
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", data.color)
      .attr('stroke-width', '2')
      .attr("d",(d)=>lineGenerator(d)) 

      var dots = node.selectAll(".dot").data(data.data);
      // Outer circle
      dots.enter()
        .append("circle")
        .attr("cx", (d) => x(d.formatedDate))
        .attr("cy", (d) => y(d[selectedOption]))
        .attr('stroke', () => '#FFFFFF')
        .attr('fill', () => data.color)
        .attr("r", (d) => this.getStatTestingInfo(d, 'lowerThan') || this.getStatTestingInfo(d, 'higherThan') ? 10 : 6)
        .attr('stroke-width', (d) => this.getStatTestingInfo(d, 'lowerThan') || this.getStatTestingInfo(d, 'higherThan') ? 0 : 2)
      // Inner circle
      dots.enter()
        .append("circle")
        .attr("cx", (d) => x(d.formatedDate))
        .attr("cy", (d) => y(d[selectedOption]))
        .attr('stroke', () => '#FFFFFF')
        .attr('fill', () => data.color)
        .attr("r", (d) => this.getStatTestingInfo(d, 'lowerThan') || this.getStatTestingInfo(d, 'higherThan') ? 6 : 5)
        .attr('stroke-width', (d) => this.getStatTestingInfo(d, 'lowerThan') || this.getStatTestingInfo(d, 'higherThan') ? 4 : 2)
        .on("mouseover",(event,d)=>this.handleMouseOver(event, data, brandName))
        .on('mouseout', this.handleMouseOut);
      dots.exit().remove();
    }else{
     node.append("path")
      .datum(data.data)
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", data.color)
      .attr('stroke-width', '2')
      .attr("d",(d)=>lineGenerator(d))
      .on("mouseover",(event,d)=>this.handleMouseOver(event, data, brandName))
      .on('mouseout', this.handleMouseOut);
    }
  }

  handleMouseOver = (event, data, brandName) => {
    let {interval, noEditable, selectedOption, stab, selectedScoreOption} = this.props;
    let scoreDecompositionEnabled = (selectedOption === 'momentum' || selectedOption === 'quality' || selectedOption === 'recommend' || selectedOption ==='consideration')

    let d;
    if(interval === "WEEKS" || interval === "MONTHS" || interval === "QUARTERS") {
      d = event;
    } else {
      const [x] = d3.mouse(this.svgRef.current);
      const bisect = d3.bisector(d => this.x(d.formatedDate)).left;
      const index = bisect(data.data, x, 1);
      d = index > (data?.data?.length/2) ? data?.data[index]:data?.data[index-1];
    }
    const tooltip = d3.select("body")
      .append("div")
      .attr("class", "trendsTooltip")
      .style("display", "none")
      .style("max-width", "250px")
      .style("position", noEditable?"absolute":"fixed");

    const brand = tooltip.append("div")
      .attr("class", "brand");

    const label = tooltip.append("div")
      .attr("class", "label");
     
    const scoreLabel = tooltip.append("div")
      .attr("class", "label")
      .style("padding-bottom", "5px")
      .style("padding-top", "1px")

    const ssize = tooltip.append("div")
      .attr("class", "sampleSize");

    const value = tooltip.append("div")
      .attr("class", "value");

    const date = tooltip.append("div")
      .attr("class", "date")
      .style("color", data.color);

    const statTesting = tooltip.append("div")
      .attr("class", "statTesting")
      .style("border-radius", "4px")
      .style("font-family", '"azo-sans-web", sans-serif')

    brand.text(brandName);
    value.text(d.hasOwnProperty(selectedOption)?d[selectedOption].toFixed(1):'0.0');
    label.text(trendsTabOptions.find((s)=>{return s.id === stab}).name+ ' / '+ this.optionsData[stab]?.find((s) => {return s.id === selectedOption}).name) ;
    scoreLabel.text(scoreDecompositionEnabled?  (selectedScoreOption[0]?.toUpperCase() + selectedScoreOption?.slice(1)):'');
    ssize.text('n=' + ( d.pool >= 1 ? Math.round(d.pool): '<1').toLocaleString('en-En'));
    date.text(d.formatedDate);
    statTesting
      .style("background-color", "#fff")
      .html('')
        if (this.getStatTestingTooltip(d)) {
          statTesting
            .style("background-color", "#DFE2EA")
            .style("margin-bottom", "-14px")
            .style("margin-right", "-10px")
            .style("margin-left", "-10px")
            .style("font-size", "11px")
            .style("color", "#71767D")
            .style("padding", "10px")
            .style("width", "100%")
            .html(this.getStatTestingTooltip(d))
        }
    tooltip.style("display", "flex")
    tooltip.style("top", (d3.event.pageY + 5) + "px").style("left", (d3.event.pageX-100) + "px");
  }

  handleMouseOut = () => {
    d3.selectAll('.trendsTooltip').remove()
  }

  renderSvg = () => {
    const { lineData } = this.state;
    const { isDetailed, hiddenList, page, noEditable} = this.props;
    let list = [], node;
    this.svg = d3.select(this.svgRef.current);
    this.svg.selectAll('*').remove();
    if(noEditable){
      node = this.svg.append('g');
    }else{
      node = this.svg.append('g').attr('transform', 'translate(0, 20)');
    }


    lineData.forEach(d => {
      if(page === 'global'){
        d.campaign.id= d.campaign?.marketId;
        d.countryCode = d.countryCode
      }
      if(page === 'segments'){
        d.segment.id= d.segment?.segmentId;
      }
      const listItem = page === 'global'?d.campaign:isDetailed?d.segment:d.brand;
      let listData = d.data.length === 1 ? (d.data[0].date === null || d.data[0].pool === 0) ? [] : d.data:d.data;
      if (hiddenList.indexOf(listItem.id) < 0 && d.data.length > 0 && listData?.length > 0) {
        list.push({
          data: d.data,
          color: page !== 'global'? page === 'segments' ? d.color?d.color:d.segment.color:d.brand.brandUpdation==='yes'?d.brand.color:d.color:d.color,
          brand: d.brand,
          segment: d.segment,
          market: d.campaign?.marketName
        });
      }
    });
    if (list.length > 0) {
       list.forEach((item, i) => {
        let brandName = page !=='global'?isDetailed?item.segment.segmentName:item.brand.displayName?item.brand.displayName:item.brand.name:item.market;
        this.renderLine(node, item, brandName);
      })
      this.customXAxis(node)
      this.customYAxis(node)
    } else {
       return <NoDataBox />;
    }
  }

  render() {
    const { noEditable, isDetailed, hiddenList, page, stab, selectedOption, selectedTab, selectedBrand, selectedMarket,selectedScoreOption,chartsIdPrefix,downloadexport } = this.props;
    const { optionOpen, lineData, lineChartContainerWidth, lineChartContainerHeight, scoreDecomposition } = this.state;
    const label = stab<2? (selectedOption==='total'?'equity':selectedOption): trendsTabOptions[stab]?.name.toLowerCase();
    let scoreDecompositionEnabled = (selectedOption === 'momentum' || selectedOption === 'quality' || selectedOption === 'recommend' || selectedOption ==='consideration')
    return (
      <div className="lineChartContainer" ref={e => this.lineChartCardContainer = e} >
        {!noEditable &&
          <ItemList
            listData={lineData}
            showSsize={true}
            listKey={page!=='global'?isDetailed?"segment":"brand":"campaign"}
            defaultList={isDetailed ? [0] : []}
            hiddenList={hiddenList}
            listClass="trendsLeftContainer"
            page={page}
            onClickShowOrHide={this.onShowOrHideClick}
            movingAverage={this.selectedMovingAverage}
            getList={this.props.getList}
            selectedBrand={selectedBrand}
            selectedTab={selectedTab}
            selectedMarket={selectedMarket?.marketId}
          />
        }
        <div className={cx("trendsRightContainer", {"chartCard": noEditable})}>
        {!noEditable &&
          <div className="trendsChartTopContainer">
            <div className="selectContainer">
              <SimpleSelect
                data={trendsTabOptions}
                selected={stab}
                open={optionOpen==='stab'}
                placeholder="" skey="c_"
                onOpenSelect={()=>this.setState( {optionOpen: optionOpen==='stab'? '' : 'stab' })}
                onSelectFunction = {this.onSelectStab}
                selectedTab = {selectedTab}
              />
              <SimpleSelect
                data={this.optionsData[stab]}
                selected={selectedOption}
                open={optionOpen==='options'}
                placeholder=""
                skey="c_"
                onOpenSelect={()=>this.setState( {optionOpen: optionOpen==='options'? '' : 'options'  })}
                onSelectFunction = {this.onSelectOption}
                selectedTab = {selectedTab}
              />
            {scoreDecompositionEnabled &&  <SimpleSelect
                data={this.scoreDecompositionData}
                selected={selectedScoreOption}
                open={scoreDecomposition}
                placeholder=""
                skey="c_"
                onOpenSelect={this.onClickScoreDecomposition}
                onSelectFunction = {this.onSelectScoreTab}
                selectedTab = {selectedTab}
              />
            }
            </div>
            <div className="withQText">
              <span>{initData.getQText(label, selectedMarket?.countryCode)}</span>
            </div>
          </div>
        }
        {lineData.length > 0 && lineChartContainerWidth && lineChartContainerHeight && this.renderSvg() }
        <svg ref={this.svgRef} className={`${chartsIdPrefix}lineChartSvg`} 
          width={downloadexport ? lineChartContainerWidth+15:''} 
          height={ (noEditable ? (lineChartContainerHeight + 35) : lineChartContainerHeight)} 
          viewBox={ (noEditable?(`0 0  ${lineChartContainerWidth+35} ${lineChartContainerHeight}`):(`0 0  ${lineChartContainerWidth+15} ${lineChartContainerHeight}`))} 
          preserveAspectRatio="xMidYMid meet" 
        /> 
        </div>
      </div>
    );
  }
}

LineChart.defaultProps = {
  chartsIdPrefix: '',
}

const mapStateToProps = ({ trends }) => ({
    data: trends.chartData.filter(({ tab }) => tab === 4),
    hiddenList: trends.hiddenList,
});

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        enableBrand,
        enableSegment,
        disableBrand,
        disableSegment,
    }, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(LineChart);