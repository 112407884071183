import React, { Component } from 'react';
import cx from 'classnames';
import './SimpleDropDownSelect.scss';
import ddIcon from '../../../assets/icons/icon_arrow_down.svg';

export default class SimpleDropDownSelect extends Component {
    constructor(props) {
        super(props);

        this.outsideClickListener = this.outsideClickListener.bind(this);
    }

    onHeaderClick = (e) => {
        if (!this.props.open) {
            document.addEventListener('click', this.outsideClickListener)
            this.props.onOpenSelect(e)

        }else {
            document.removeEventListener('click', this.outsideClickListener)
            this.props.onOpenSelect(e)

        }
    }

    outsideClickListener(e) {
        if (this.props.open) {
            this.props.onOpenSelect(e);
        }
        document.removeEventListener('click', this.outsideClickListener);
    }

    handleSelect = (e, cid) => {
        const { data } = this.props
        let checkSelect = data.map(c=>c?.name )
        if(checkSelect[0] === 'Gen Pop'|| checkSelect[1] === 'Familiar'){
            this.props.onSelectModeChange(true)
        }
        e.preventDefault();
        this.props.onSelectFunction(cid);
    }

    render() {
        const {
            customClass,
            data,
            disabled,
            open,
            openTop,
            placeholder,
            selected,
            skey,
            disabledOption,
            wider = false,
        } = this.props;
        const selectedData = selected !== ''
            ? data && data.find( c => {return c.id === selected })
            : null;

        return (
            <div className={cx('inputBox', customClass, {'open': open, 'disabled': disabled})} onClick = {(e) => this.onHeaderClick(e)}>
                { selectedData ? <span className='inputLabelText'>{selectedData.name}</span> : <label className="placeholder">{placeholder}</label> }
                {!disabled && <span><img className="ddArrow" src={ddIcon} alt="" /></span>}
                {!disabled &&
                <div className={
                    cx( 
                        "selectListContainer",
                        {
                            openTop: openTop,
                            wider: wider,
                        }
                    )}>
                    {data && data.map( c => {
                        return (
                            <div
                                key={skey + c.id}
                                className={
                                    cx(
                                        "selectItem",
                                        {
                                            "selected": c.id === selected,
                                            "maxContent": wider,
                                            "disabled": disabledOption?.includes(c.id)
                                        }
                                    )
                                }
                                onClick = {(c.id !== selected) ? (e) => this.handleSelect(e, c.id) : null}
                            >
                                {c.name}
                            </div>
                        )
                    })}
                </div>
                }
            </div>
        )
    }
}
