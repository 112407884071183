import React, { Component } from 'react'
import Modal from "react-modal"
import {PulseLoader} from 'halogenium';

const customStyles = {
    overlay : {
        position          : 'fixed',
        top               : 0,
        left              : 0,
        right             : 0,
        bottom            : 0,
        backgroundColor   : 'rgba(0, 0, 0, 0.3)',
        zIndex            : 6000,
        display           : 'flex',
        alignItems        : 'center',
        justifyContent    : 'center'
    },
    content : {
        position                   : 'relative',
        width                      : '140px',
        height                     : '140px',
        border                     : 'none',
        background                 : 'rgba(0, 0, 0, 0.0)',
        overflow                   : 'auto',
        overflowX                  : 'hidden',
        WebkitOverflowScrolling    : 'touch',
        borderRadius               : '4px',
        outline                    : 'none',
    }
}


class LoadingComponent extends Component {

    render() {
        const {showLoading} = this.props;
        return (
            <Modal isOpen={showLoading} contentLabel="Loading Modal" style={customStyles} ariaHideApp={false}>
                <PulseLoader color="#5C961A" size="16px" margin="4px"/>
            </Modal>
        )
    }
}

export default LoadingComponent