import {combineReducers} from 'redux';
import loginReducer from './loginReducer';
import twoByTwoChart from './TwoByTwoChart/reducer';
import trends from './Trends/reducer';
import SearchReducer from './AdminSearch/SearchReducer'

const allReducers = combineReducers({
    loginReducer,
    trends,
    twoByTwoChart,
    SearchReducer
});

export default allReducers;
