import React, { Component } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import './Tabs.scss';
import BrandTitle from '../widgets/Shared/BrandTitle';
import compassLogoPng from "../../assets/icons/harris-logo.png";
import initData from '../../utils/initData';

const TabAvatar = styled(Avatar)`
&.MuiAvatar-colorDefault {
    width: 20px;
    height: 20px;
    background-color: #0062FF;
    margin: 8px;
    font-size: 1rem;
}
`;
export default class Tab extends Component {
    constructor(props) {
        super(props)
        this.tabItem=[];
    }

    componentDidMount() {
       this.setHighlight(this.props.currentTab);
    }

    componentDidUpdate() {
        this.setHighlight(this.props.currentTab);
    }

    clickTabItem(index) {
        const { onClickTabItem } = this.props;
        typeof onClickTabItem === 'function' && onClickTabItem(index);
    }

    setHighlight = (index) => {
        if (this.highlightbar && this.tabItem[index]) {
            if(index == 0){
                this.highlightbar.style.width = this.tabItem[index].clientWidth - 20  + 'px';
                this.highlightbar.style.left = this.tabItem[index].offsetLeft + 'px';
            } else {
                this.highlightbar.style.width = this.tabItem[index].clientWidth - 20 + 'px';
                this.highlightbar.style.left = this.tabItem[index].offsetLeft + 10  + 'px';
            }
        }
    }

    getTabElement = (tab) => {
        const { inMenu, isBrand, page, isAdminPanel } = this.props;
        let listItem;
        if (inMenu || isAdminPanel) {
            listItem = <div>{tab.title}</div>
        }else if(isBrand) {
            listItem = <BrandTitle brand={tab}/>
        } else if(!isBrand && !isAdminPanel) {
            listItem = <Link to={`/${page}/${tab.name.replace('/','').toLowerCase()}`}>{tab.name}</Link>
        } else if(isAdminPanel) {
            listItem = <div>{tab.name}</div>
        }
        return (listItem);
    }

    render() {
        const {tabs, currentTab, inMenu, history, isAdminPanel } = this.props;
        const routes = initData.getAppRoutes();
        const pathname = history && history.location.pathname

        return (
            <div className={inMenu? "tabsinMenu" : "tabsContainer"} style = {{height: isAdminPanel? 80: "100%"}}>
                <ul className="tabs-list">
                    {Array.isArray(tabs) && tabs.map( (tab, index )=> {
                        const tabClass = (index === currentTab) ? `tab current tab${index}` : `tab tab${index}`;
                       return (
                            <li ref={e=>this.tabItem[index]=e} className={tabClass} key={index} onClick={this.clickTabItem.bind(this, index)}>
                                {this.getTabElement(tab)}{tab?.count && <TabAvatar variant="rounded" style={{width: 'auto', padding: '0 5px'}}>{`${tab?.count}`}</TabAvatar>}
                            </li>
                       )
                    })}
                    {!inMenu && <div className="highlightBar" ref={e=>this.highlightbar=e}></div>}
                </ul>
                
                <img src={compassLogoPng} className="compassLogoPng" style={{display: 'none'}} alt="compassLogoPng" />
                {(currentTab === 5 || currentTab === 6) && (pathname === routes.segments || pathname === routes.competitive) && (
                    <div className="betaBlock">
                        <div className="betaBtn">Beta</div>
                        <div className="betaTxt">This Dashboard is in <span>Beta</span> Stage</div>
                    </div>
                )}
                {this.props?.countEle}
                
            </div>
        )
    }
}
