import usersIcon from '../assets/icons/icon_nav_users.svg';
import campaignsIconBlue from '../assets/icons/icon_nav_campaigns_blue.svg';
import campaignsIcon from '../assets/icons/icon_nav_campaigns.svg';
import summaryIcon from '../assets/icons/icon_nav_summary.svg';
import detailedIcon from '../assets/icons/icon_nav_detailed.svg';
import competitiveIcon from '../assets/icons/icon_nav_competitive.svg';
import geoIcon from '../assets/icons/icon_geo.svg';
import geoIconBlue from '../assets/icons/icon_geo_blue.svg';
import advancedIcon from '../assets/icons/icon_nav_advanced.svg';
import usersBlue from '../assets/icons/icon_nav_users_blue.svg';
import summaryBlue from '../assets/icons/icon_nav_summary_blue.svg';
import detailedBlue from '../assets/icons/icon_nav_detailed_blue.svg';
import competitiveBlue from '../assets/icons/icon_nav_competitive_blue.svg';
import advancedBlue from '../assets/icons/icon_nav_advanced_blue.svg';
import productInactive from '../assets/icons/product-inactive.svg';
import productActive from '../assets/icons/product-active.svg';
import pdfIcon from '../assets/icons/icon_pdf.svg';
import pptIcon from '../assets/icons/icon_ppt.svg';
import xlsIcon from '../assets/icons/icon_csv.svg';
import pngIcon from '../assets/icons/icon_image.svg';
import { getLocalStorage } from '../../src/utils/storageHelpers';
import filtersTransformations, { filterGroupTypes } from './filterTransformations';
import groupsIcon from '../assets/icons/group.svg'
import groupsBlue from '../assets/icons/groups_blue.svg'
import hasPermission from '../utils/Permissions'


export default class InitData {
    static getExternalUserIDs(){
        return[
            'auth0|5d8e3c54fbfb0b0c4c6d5f49',
            'auth0|628f4392f9c53c0069ba371e',
            'auth0|629648fa5d73c2006f521da4',
            'auth0|605bcbecc89a51006976531f',
            'auth0|619384659792730069099576',
            'auth0|5fe10bff66406700753ce376',
            'auth0|66214d5c8dc87d096948999d',
            'auth0|66214d97a3bc2b794e26d5c6'
        ]
    }
    static getMenu() {
        return {
            users: {
                title: 'User Management',
                menuTitle: 'User Management',
                icon: usersIcon,
                iconCurrent: usersBlue,
                tabs: [
                    {
                        "name": "Users",
                        "title": "Users",
                    },
                ],
                authorizedType: 'ADMIN'
            },
            user: {
                title: 'User Management',
                menuTitle: 'hidden',
                icon: usersIcon,
                iconCurrent: usersBlue,
                tabs: [
                    {
                        "name": "Profile",
                        "title": "Profile",
                    },
                ],
                authorizedType: 'ADMIN'
            },
            groups: {
                title: 'Group Management',
                menuTitle: 'Group Management',
                icon: groupsIcon,
                iconCurrent: groupsBlue,
                tabs: [
                    {
                        "name": "Groups",
                        "title": "Groups",
                    },
                ],
                authorizedType: 'ADMIN'
            },
            group: {
                title: 'Group Management',
                menuTitle: 'hidden',
                icon: groupsIcon,
                iconCurrent: groupsBlue,
                tabs: [
                    {
                        "name": "Overview",
                        "title": "Overview"
                    },
                    {
                        "name": "Contracts",
                        "title": "Contracts",
                    },
                    {
                        "name": "Filters",
                        "title": "Filters",
                    },
                ],
                authorizedType: 'ADMIN'
            },
            brands: {
                title: 'Brand Management',
                menuTitle: 'Brand Management',
                icon: detailedIcon,
                iconCurrent: detailedBlue,
                tabs: [
                    {
                        "name": "Approved",
                        "title": "Approved",
                    },
                    hasPermission()?
                    {
                        "name": "Pending",
                        "title": "Pending",
                    }
                    : {"name": "",
                    "title": ""},
                ],
                authorizedType: 'ADMIN'
            },
            markets: {
                title: 'Geography Management',
                menuTitle: 'Geography Management',
                icon: campaignsIcon,
                iconCurrent: campaignsIconBlue,
                tabs: [
                    {
                        "name": "Active",
                        "title": "Active",
                    },
                    hasPermission() ?
                    {
                        "name": "Inactive",
                        "title": "Inactive",
                    }
                    : {"name": "",
                        "title": ""},
                ],
                authorizedType: 'ADMIN'
            },
            market: {
                title: 'Campaign Management',
                menuTitle: 'hidden',
                icon: campaignsIcon,
                iconCurrent: campaignsIconBlue,
                tabs: [
                    {
                        "name": "Overview",
                        "title": "Overview",
                    },
                    {
                        "name": "Brands",
                        "title": "Brands",
                    }
                ],
                authorizedType: 'ADMIN'
            },
            marketBrands: {
                title: 'Campaign Management',
                menuTitle: 'hidden',
                icon: campaignsIcon,
                iconCurrent: campaignsIconBlue,
                tabs: [
                    {
                        "name": "Overview",
                        "title": "Overview",
                    },
                    {
                        "name": "Brands",
                        "title": "Brands",
                    }
                ],
                authorizedType: 'ADMIN'
            },
            summary: {
                title: 'Brand Summary',
                menuTitle: 'Summary',
                icon: summaryIcon,
                iconCurrent: summaryBlue,
                tabs: [],
                authorizedType: 'USER'
            },
            segments: {
                title: 'Segments',
                menuTitle: 'Segments',
                icon: detailedIcon,
                iconCurrent: detailedBlue,
                tabs: [
                    {
                        "name": "Equity",
                        "title": "Brand Equity"
                    },
                    {
                        "name": "Conversion",
                        "title": "Conversion Funnel",
                    },
                    {
                        "name": "Emotional",
                        "title": "Emotional",
                    },
                    {
                        "name": "Advertising",
                        "title": "Advertising",
                    },
                    {
                        "name": "Trends",
                        "title": "Trends",
                    },
                    {
                        "name": "Profile",
                        "title": "Profile",
                    },
                    // {
                    //     "name": "Discover",
                    //     "title": "Discover",
                    // },
                ],
                authorizedType: 'USER'
            },
            competitive: {
                title: 'Competitive Intelligence',
                menuTitle: 'Competitive',
                icon: competitiveIcon,
                iconCurrent: competitiveBlue,
                tabs: [
                    {
                        "name": "Equity",
                        "title": "Brand Equity"
                    },
                    {
                        "name": "Conversion",
                        "title": "Conversion Funnel",
                    },
                    {
                        "name": "Emotional",
                        "title": "Emotional",
                    },
                    {
                        "name": "Advertising",
                        "title": "Advertising",
                    },
                    // {
                    //     "name": "Touchpoints",
                    //     "title": "Touchpoints",
                    // },
                    {
                        "name": "Trends",
                        "title": "Trends",
                    },
                    {
                        "name": "Profile",
                        "title": "Profile",
                    },
                    // {
                    //     "name": "Discover",
                    //     "title": "Discover",
                    // },
                ],
                authorizedType: 'USER'
            },
            global: {
                title: 'Global',
                menuTitle: 'Global',
                icon: geoIcon,
                iconCurrent: geoIconBlue,
                tabs: [
                    {
                        "name": "Equity",
                        "title": "Brand Equity"
                    },
                    {
                        "name": "Conversion",
                        "title": "Conversion Funnel",
                    },
                    {
                        "name": "Emotional",
                        "title": "Emotional",
                    },
                    {
                        "name": "Advertising",
                        "title": "Advertising",
                    },
                    // {
                    //     "name": "Touchpoints",
                    //     "title": "Touchpoints",
                    // },
                    {
                        "name": "Trends",
                        "title": "Trends",
                    },
                    {
                        "name": "Profile",
                        "title": "Profile",
                    },
                    // {
                    //     "name": "Discover",
                    //     "title": "Discover",
                    // },
                ],
                authorizedType: 'USER'
            },
            advanced: {
                title: 'Advanced Analytics',
                menuTitle: 'Advanced',
                icon: advancedIcon,
                iconCurrent: advancedBlue,
                tabs: [
                    {
                        "name": "Differentiation",
                        "title": "Differentiation",
                        "desc" : "Differentiation mapping helps identify how differentiated brands are relative to a predefine category of brands."
                    },
                    {
                        "name": "Perceptual",
                        "title": "Perceptual",
                        "desc": "A principle components analysis run on a set of brands and their imagery attributes scores."
                    },
                    {
                        "name": "Butterfly",
                        "title": "Butterfly",
                        "desc": "A clustered bar chart that shows the attribute deltas for 2 brands head to head."
                    },
                    {
                        "name": "Pre/Post",
                        "title": "Pre/Post",
                        "desc": "Allows you to see the difference in metrics before and after a date."
                    }
                ],
                authorizedType: 'USER'
            },
            // "quest-suite": {
            //     title: 'Quest Suite',
            //     menuTitle: 'Quest Suite',
            //     icon: productInactive,
            //     iconCurrent: productActive,
            //     tabs: [],
            //     authorizedType: 'USER'
            // }
        }
    }

    static getModes = () => {
        return [
            {id: 0, name: "Gen Pop"},
            {id: 1, name: "Familiar"},
            {id: 2, name: "Ad Recall"},
            {id: 3, name: "User"}
        ]
    }

    static getTabs(menu) {
        let tabs = [];
        let menuItem = InitData.getMenu()[menu];
        if (menuItem) {
            tabs = InitData.getMenu()[menu].tabs;
        }
        return tabs;
    }

    static getStabOptions = () => {
        return [
            {id: 0, name: "Equity"},
            {id: 1, name: "Conversion"},
            {id: 2, name: "Emotional"},
            {id: 3, name: "Touchpoints"},
            {id: 4, name: "Size"},
            {id: 5, name: "Advertising"},
        ]
    }

    static getMenuTitle(menu) {
        let title = '';
        let menuItem = InitData.getMenu()[menu];
        if (menuItem) {
            title = InitData.getMenu()[menu].title;
        }
        return title;
    }

    static getSortingTypes() {
        return {
            asc: 'asc',
            desc: 'desc',
        }
    }

    static getColumns(index) {
        let columns = [
            [
                {key:'firstName', field:'firstName', title:'First Name', width:'160px', sorting: 'active'},
                {key:'lastName', field:'lastName', title:'Last Name', width:'160px', sorting: 'inactive'},
                {key:'userGroups', field:'userGroups', title:'Groups', width:'290px', sorting: null},
                {key:'email', field:'email', title:'Email Address', width:'290px', sorting: null},
                {key:'verified', field:'verified', title:'Email Verified', width:'150px', sorting: 'inactive'},
                {key:'phone', field:'phone', title:'Phone Number', width:'150px', sorting: null},
                {key:'company', field:'company', title:'Company', width:'200px', sorting: 'inactive'},
                {key:'dateCreated', field:'registered', title:'Registered', width:'150px', sorting: 'inactive'},
                {key:'lastLoginDate', field:'lastAccessed', title:'Last Accessed', width:'150px', sorting: 'inactive'},
            ],
            [
                {key:'displayName', field:'displayName', title: 'Group Name', width:'260px', sorting: 'inactive'},
                {key:'company', field:'company', title: 'Client Company', width:'180px', sorting: 'inactive'},
                {key:'internalOwners', field:'groupInternalOwners', title: 'Internal Owner', width:'210px', sorting: 'inactive'},
                {key:'campaignGroups', field:'campaignGroups', title: 'Markets', width:'250px', sorting: null},
                {key:'brands', field:'brands', title:'Brands', width:'140px', sorting: 'inactive'},
                {key:'users', field:'users', title:'Users', width:'130px', sorting: 'inactive'},
                {key:'contractsStatus', field:'contractsStatus', title:'Contracts', width:'170px', sorting: 'inactive'},
                {key:'createdOn', field:'createdOn', title: 'Created', width:'160px', sorting: 'active'},
                {key:'lastContractDate', field:'lastContractDate', title: 'Last Contract Date', width:'180px', sorting: 'inactive'},
            ],
            [
                {key:'brand', field:'brand', title: 'Brand', width:'320px'},
                {key:'startDate', field:'startDate', title:'Added', width:'170px'},
                {key:'endDate', field:'endDate', title:'Expires', width:'170px'}
            ],
            [
                {key:'salesForceId', field:'salesForceId', title: 'Salesforce ID', width:'200px'},
                {key:'amount', field:'amount', title:'Amount', width:'160px'},
                {key:'startDate', field:'startDate', title:'Start Date', width:'170px'},
                {key:'endDate', field:'endDate', title: 'End Date', width:'170px'},
            ],
            [ 
                {key:'brand', field:'name', title: 'Decipher Name', width:'450px', sorting: 'active'},
                {key:'displayName', field:'displayName', title: 'Display Name', width:'300px', sorting: 'inactive'},
                {key:'brandId', field:'id', title:'Brand ID', width:'160px', sorting: 'inactive'},
                {key:'brandGroups', field:'brandGroups', title:'Groups', width:'290px', sorting: null},
                {key:'campaignGroups', field:'campaignGroups', title:'Markets', width:'290px', sorting: null},
                {key:'industry', field:'industry', title:'Industry', width:'160px', sorting: 'inactive'},
                // {key:'subIndustry', field:'subIndustry', title:'Sub-Industry', width:'290px', sorting: 'inactive'},
                {key:'totalCompletes', field:'totalCompletes', title:'Total Completes', width:'160px', sorting: null},
                {key:'contracts', field:'activeContracts', title:'Active Contracts', width:'150px', sorting: 'inactive'},
                {key:'expired', field:'expired', title: 'Expired', width:'150px', sorting: 'inactive'},
                {key:'status', field:'status', title: 'Decipher Status', width:'160px', sorting: 'inactive'}
            ],
            [
                {key:'brand', field:'name', title: 'Decipher Name', width:'450px', sorting: 'active'},
                {key:'displayName', field:'displayName', title: 'Display Name', width:'320px', sorting: 'inactive'},
                {key:'industry', field:'industry', title:'Industry', width:'160px', sorting: null},
                // {key:'subIndustry', field:'subIndustry', title:'Sub-Industry', width:'290px', sorting: null},
                {key:'createdBy', field:'createdBy', title:'Added By', width:'320px', sorting: 'inactive'},
                {key:'createdOn', field:'createdOn', title: 'Added', width:'160px', sorting: 'inactive'},
            ],
            //campaigns admin page - active / inactive tables
            [
                {key:'name', field:'name', title: 'Country', width:'250px', sorting: 'active'},
                {key:'regionState', field:'regionState', title: 'Region/State', width:'200px', sorting: 'inactive'},
                {key:'campaignGroups', field:'groups', title: 'Groups', width:'500px', sorting: null},
                {key:'contractsCount', field:'contractsCount', title: 'Active Contracts', width:'200px', sorting: 'inactive'},
                {key:'modifiedOn', field:'modifiedOn', title: 'Last Modified', width:'150px', sorting: 'inactive'},
                {key:'qCampaign', field:'qCampaign', title: 'QCampaign', width:'150px', sorting: null},
                {key:'weightQuota', field:'weightQuota', title: 'Weight Quota', width:'175px', sorting: null},
                {key:'lastWeightedAt', field:'lastWeightedAt', title: 'Last Weighted', width:'200px', sorting: 'active'},
                {key:'totalCompletes', field:'totalCompletes', title: 'Total Weighted', width:'175px', sorting: 'inactive'},
                {key:'ingestedCount', field:'ingestedCount', title: 'Total Ingested', width:'175px', sorting: 'active'},
                {key:'weightDifference', field:'weightDifference', title: 'Weighted Difference', width:'175px', sorting: null}
            ],
            [
                {key:'brand', field:'name', title: 'Decipher Name', width:'450px', sorting: 'active'},
                {key:'displayName', field:'displayName', title: 'Display Name', width:'300px', sorting: 'inactive'},
                {key:'brandId', field:'brandId', title:'Brand ID', width:'160px', sorting: null},
                {key:'brandGroups', field:'brandGroups', title:'Groups', width:'290px', sorting: null},
                {key:'contracts', field:'activeContracts', title:'Active Contracts', width:'150px', sorting: 'inactive'},
                {key:'expired', field:'expired', title: 'Expired', width:'150px', sorting: 'inactive'},
                {key:'status', field:'status', title: 'Decipher Status', width:'160px', sorting: null},
            ],
        ];

        return columns[index];
    }

    static getStatus(status) {
        let name = "active";
        let label = "Active";
        let priority = 0;

        switch (status) {
            default:
            case "active":
                name = "active";
                label = "Active";
                priority = 3;
                break;
            case "inactive":
                name = "inactive";
                label = "Not Active";
                priority = 0;
                break;
            case "pending":
                name = "pending";
                label = "Not verified";
                priority = 0;
                break;
            case "expired":
                name = "expired";
                label = "Expired";
                priority = 1;
                break;
            case "future":
                name = "future";
                label = "Future";
                priority = 2;
                break;
            case "verified":
                name = "verified";
                label = "Verified";
                priority = 2;
                break;
            case "notVerified":
                name = "notVerified";
                label = "Not Verified";
                priority = 1;
                break;
        }

        return {name, label, priority};
    }

    static getBrandCategory(categoriesList,id) {
        return categoriesList.find(c => {return c.id === id});
    }

    static getColors(index) {
        const colors = [
            ["#73CCEF", "#DCF2FB"],
            ["#747A81", "#D0D4D8"],
            ["#17BCAC", "#C5EEEA"],
            ["#B974D2", "#F7E1FF"],
            ["#6692AD", "#C9E8FC"],
            ["#FFBA03", "#FFEEBF"],
            ["#896D9D", "#E1DAE6"],
            ["#FF7F61", "#FFDFD7"],
            ["#5DD781", "#C9F7D6"],
            ["#5DA5D7", "#C9F4F7"]
        ];
        if (index && index>0) {
            return colors[index-1];
        } else {
            return colors;
        }
    }
    static getDonutColors() {
        return ['#73CCEF', '#50C4CD', '#095355', '#042C3B'];
    }

    static getFilterProfileGroups(){
        return [
            {
                title: "Demographics",
                filters: [
                    {name: "generation", label: "Generation"},
                    {name: "gender", label: "Gender"},
                    {name: "ethnicity", label: "Ethnicity - US", market: 'US'},
                    {name: "region_us", label: "Region - US", market: 'US'},
                    {name: "region_uk", label: "Region - UK", market: 'UK'},
                    {name: "region_au", label: "Region - AU", market: 'AU'},
                    {name: "region_ca", label: "Region - CA", market: 'CA'},
                    {name: "region_de", label: "Region - DE", market: 'DE'},
                    {name: "population_density_us", label: "Population Density - US", market: 'US'},
                    {name: "population_density_nonus", label: "Population Density - NonUS", market: 'NONUS'},
                    {name: "education_us", label: "Education - US", market: 'US'},
                    {name: "education_uk", label: "Education - UK", market: 'UK'},
                    {name: "education_au", label: "Education - AU", market: 'AU'},
                    {name: "education_ca", label: "Education - CA", market: 'CA'},
                    {name: "education_de", label: "Education - DE", market: 'DE'},
                    {name: "employment", label: "Employment"},
                    {name: "household_income_us", label: "Household Income - US", market: 'US'},
                    {name: "household_income_uk", label: "Household Income - UK", market: 'UK'},
                    {name: "household_income_au", label: "Household Income - AU", market: 'AU'},
                    {name: "household_income_ca", label: "Household Income - CA", market: 'CA'},
                    {name: "household_income_de", label: "Household Income - DE", market: 'DE'},
                    // TODO enable filter group back once backend is ready
                    // https://jira.hubub.com/browse/COMPII-313
                    {name: "household_assets_us", label: "Household Assets - US", market: 'US'},
                    {name: "household_assets_uk", label: "Household Assets - UK", market: 'UK'},
                    {name: "household_assets_au", label: "Household Assets - AU", market: 'AU'},
                    {name: "household_assets_ca", label: "Household Assets - CA", market: 'CA'},
                    {name: "household_assets_de", label: "Household Assets - DE", market: 'DE'},
                    {name: "marital_status", label: "Marital Status"},
                    {name: "sexuality", label: "Sexuality"},
                    {name: "household_size", label: "Household Size"},
                    {name: "household_composition", label: "Household Composition", withSubfilters: true},
                    {name: "political_party", label: "Political Party"},
                    {name: "shopping_channel", label: "Shopping Channel"},
                    {name: "media_consumers", label: "Media Consumption"},
                    {name: "tech_status", label: "Tech Status"},
                    {name: "pet_ownership", label: "Pet Ownership", withSubfilters: true},
                    {name: "cat_litter_users", label: "Cat Litter Users"},
                    {name: "ownership_usage", label: "Owner/Subscriber", withSubfilters: true},
                    {name: "traveler_type", label: "Flew last year", withSubfilters: true},
                    {name: "charitable_organizations", label: "Сharitable Donations", withSubfilters: true},
                    {name: "sports_and_gaming", label: "Sports and Gaming", withSubfilters: true},
                ]
            },
            {
                title: "Equity",
                filters: [
                    {name: "equity_familiarity", label: "Familiarity", isCompositeFilter: true},
                    {name: "equity_quality", label: "Quality", isCompositeFilter: true},
                    {name: "equity_momentum", label: "Momentum", isCompositeFilter: true},
                    {name: "equity_consideration", label: "Consideration", isCompositeFilter: true},
                ]
            },
            {
                title: "Conversion Funnel",
                filters: [
                    {name: "conversion_familiarity", label: "Familiarity", isCompositeFilter: true},
                    {name: "conversion_ownership_usage", label: "Ownership/Usage", isCompositeFilter: true},
                    {name: "conversion_recommendation", label: "Recommendation", isCompositeFilter: true},
                ]
            },
            {
                title: "Personification",
                filters: [
                    {name: "personification", label: "personification", withSubfilters: true},
                ]
            },
            {
                title: "Advertising",
                filters: [
                    {name: "advertising", label: "advertising", withSubfilters: true},
                ]
            },
            {
                title: "Customer Touchpoint",
                filters: [
                    {name: "touchpoint", label: "Touchpoint", withSubfilters: true},
                ]
            },
            {
                title: "Other Brand",
                filters: [
                    {name: "other_brand", label: "Willing to Pay a Premium", isCompositeFilter: true},
                    {name: "irreplaceability", label: "Irreplaceability", isCompositeFilter: true},
                ]
            },
        ];
    }

    static getFilterGroups(){
        if(InitData.filterGroups == null) {
            InitData.filterGroups = [];
            // JSON.parse(getLocalStorage('filter_categories')).filterCategories.forEach(category => {
            //     InitData.filterGroups.push(category);
            // });
        }
        return InitData.filterGroups;
    }

    static optionLabelsApi = null;
    static filterGroups = null;

    static getFilterOptionLabel(key, index = 0, defaultValue = 'No name') {
        this.filters = JSON.parse(getLocalStorage("filters"));
        this.filters = this.filters?.filter(obj=> obj.category !== null); 
        InitData.optionLabelsApi = {};
        this.filters?.length>0 && this.filters.map(({filterResponse})=>{
            filterResponse.forEach(filter => {
                if(filter.filterOptions) {
                    filter.filterOptions.forEach(option => {
                        InitData.optionLabelsApi[filtersTransformations.createFilterOptionKey(filter.filterName, option.filterOptionName)] = [option.filterOptionName, option.filterOptionName ? option.filterOptionName : ""];
                    });
                } else if(filter.filterGroupOptions) {
                    filter.filterGroupOptions.forEach(option => {
                        InitData.optionLabelsApi[filtersTransformations.createFilterOptionKey(filter.filterName, option.filterOptionName)] = [option.filterOptionName, option.filterOptionName];
                    });
                }
                return;
            });
            return filterResponse;
        })
       return  InitData.optionLabelsApi[key] ?
            (InitData.optionLabelsApi[key][index] !== "" ?
                InitData.optionLabelsApi[key][index] :
                InitData.optionLabelsApi[key][0]) : defaultValue;
    }

    static getVariableOptionLabel(id, index=0, value='No Name'){
        return InitData.variableOptionLabels[id] ?
            (InitData.variableOptionLabels[id][index] !== "" ?
            InitData.variableOptionLabels[id][index] :
            InitData.variableOptionLabels[id][0]) : value;
    }

    
    static getPeriodOptions(movingAverage = false, exclude = []){

        let periods = [
            {id: 1, name: "Custom Range", key: "CUSTOM"},
            {id: 2, name: "Last 7 days", key: "L7D" },
            {id: 3, name: "Last 30 days", key: "L30D" },
            {id: 4, name: "Last 90 days", key: "L90D" },
            {id: 6, name: "Last 180 days", key: "L180D"},
            {id: 5, name: "Year to Date", key: "YTD"}
        ];

        if (movingAverage) {
            exclude.push(2);
        }
        return periods = periods.filter(p =>  !exclude.includes(p.id)) 
    }

    static gettrendsTabOptions (){
        return[
            {id: 0, name: "Equity"},
            {id: 1, name: "Conversion"},
            {id: 2, name: "Emotional"},
            {id: 3, name: "Advertising"},
            {id: 4, name: "Irreplaceability"},
        ];
    }

    static getIntervals(movingAverage = false, exclude = [], hideLabels = false) {
        if (movingAverage && !exclude.includes('DAYS')) {
            exclude.push('DAYS');
        }

        // TODO: Refactor when possible. [Optimization]
        let intervals = [
            {
                label: "Periodic",
                options: this.getPeriodicIntervals([...exclude])
            }
        ];
        let intervalsWithLabels = [
            {
                label: "Periodic",
                options: this.getPeriodicIntervals([...exclude])
            }
        ];

        if (movingAverage) {
            intervals.push(...this.getMovingAverageIntervals([...exclude]));

            intervalsWithLabels.push({
                label: "Moving Average",
                options: this.getMovingAverageIntervals([...exclude])
            })
        }

        if (hideLabels) {
            return intervals;
        }
        else {
            return intervalsWithLabels;
        }
    }

    static getSurveyPaths(){
        let surveyPaths = [
            {surveyName: "MAIN",path: "/selfserve/53b/221143"},
            {surveyName: "STATE",path: "/selfserve/53b/221042"},
            {surveyName: "INTERNATIONAL",path: "/selfserve/53b/221274"}
        ]
     return surveyPaths
    }

    // HBP-16 | Introduced Moving Average for Trends.
    // Revised standard intervals into periodic intervals.
    // ============================================
    static getPeriodicIntervals(exclude = []) {
        let intervals = [
            {id: "DAYS", name: "Days", movingAverage: false, key: "P-D"},
            {id: "WEEKS", name: "Weeks", movingAverage: false, key: "P-W"},
            {id: "MONTHS", name: "Months", movingAverage: false, key: "P-M"},
            {id: "QUARTERS", name: "Quarters", movingAverage: false, key: "P-Q"},
        ]

        return intervals.filter(i => !exclude.includes(i.id));
    }

    // HBP-16 | Introduced Moving Average for Trends.
    // {Days}-DAYS => {Days} is the duration to calculate the moving average for. (Convert the weeks into days)
    // Moving Average is currently exclusively used for Trends as of Feb 2022.
    // ============================================
    static getMovingAverageIntervals(exclude = []) {
        let intervals = [
            // {id: "7-DAYS", name: "1 Week"},
            // {id: "14-DAYS", name: "2 Week"},
            // {id: "28-DAYS", name: "4 Week"},
            // {id: "56-DAYS", name: "8 Week"},
            // {id: "84-DAYS", name: "12 Week"}
            {id: "ONEWEEKS", name: "1 Week" ,key: "MV-1"},
            {id: "TWOWEEKS", name: "2 Week" ,key: "MV-2"},
            {id: "FOURWEEKS", name: "4 Week" ,key: "MV-4"},
            {id: "EIGHTWEEKS", name: "8 Week" ,key: "MV-8"},
            {id: "TWELVEWEEKS", name: "12 Week" ,key: "MV-12"},
        ]

        return intervals.filter(i => !exclude.includes(i.id));
    }       


    static getFilterLabels(filter) { 

        const getFilter = (filterKey, filterOptionValues) => {
            const jsonFilters = JSON.parse(getLocalStorage('filters'))
            if (!jsonFilters) {
                return []
            }

            const filters = jsonFilters.filters
            const foundFilter = filters?.find(item => item.name === filterKey)

            if(foundFilter && foundFilter.id) {
                return [{
                    id: foundFilter.id,
                    filterOptions: foundFilter.filterOptions.filter(item => filterOptionValues.includes(item.value))
                }]
            } else {
                return []
            }
        }

        const labels = {
            equity: [
                {
                    name: 'total',
                    filters: [
                        ...getFilter('equity_quality', ['8', '9', '10 - Outstanding/Extraordinary quality']),
                        ...getFilter('equity_momentum', ['On its way up']),
                        ...getFilter('equity_consideration', ['Possibly', 'Absolutely']),
                    ]
                },
                { name: 'momentum', filters: getFilter('equity_momentum', ['On its way up']) },
                { name: 'consideration', filters: getFilter('equity_consideration', ['Possibly', 'Absolutely']) },
                { name: 'quality', filters: getFilter('equity_quality', ['8', '9', '10 - Outstanding/Extraordinary quality']) },
                { name: 'familiarity', filters: getFilter('brand_engagement', ['Familiar with']) },
            ],
            conversion: [
                { name: 'awareness', filters: getFilter('brand_engagement', ['Familiar with', 'Just know the name']) },
                { name: 'familiarity', filters: getFilter('brand_engagement', ['Familiar with']) },
                { name: 'trial', filters: getFilter('brand_engagement', ['Regular user', 'Occasional user', 'Tried in the past']) },
                { name: 'usage', filters: getFilter('brand_engagement', ['Regular user', 'Occasional user']) },
                { name: 'recommend', filters: getFilter('conversion_recommendation', ['Promoter']) },
            ],
            emotional: [
                { name: 'corporate', filters: getFilter('personification', ['corporate']) },
                { name: 'customer_centric', filters: getFilter('personification', ['customer_centric']) },
                { name: 'dependable', filters: getFilter('personification', ['dependable']) },
                { name: 'fun', filters: getFilter('personification', ['fun']) },
                { name: 'good_value', filters: getFilter('personification', ['good_value']) },
                { name: 'hip', filters: getFilter('personification', ['hip']) },
                { name: 'innovative', filters: getFilter('personification', ['innovative']) },
                { name: 'intelligent', filters: getFilter('personification', ['intelligent']) },
                { name: 'practical', filters: getFilter('personification', ['practical']) },
                { name: 'premium', filters: getFilter('personification', ['premium']) },
                { name: 'simple', filters: getFilter('personification', ['simple']) },
                { name: 'socially_consciouis', filters: getFilter('personification', ['socially_conscious']) },
                { name: 'smart', filters: getFilter('personification', ['smart']) },
                { name: 'stylish', filters: getFilter('personification', ['stylish']) },
                { name: 'traditional', filters: getFilter('personification', ['traditional']) },
                { name: 'trustworthy', filters: getFilter('personification', ['trustworthy']) },
                { name: 'unconventional', filters: getFilter('personification', ['unconventional']) },
                { name: 'visionary', filters: getFilter('personification', ['visionary']) },
                { name: 'wholesome', filters: getFilter('personification', ['wholesome']) },
                { name: 'classy', filters: getFilter('personification', ['classy']) },
                { name: 'sophisticated', filters: getFilter('personification', ['sophisticated']) },
                { name: 'young', filters: getFilter('personification', ['young']) },
                { name: 'energetic', filters: getFilter('personification', ['energetic']) },
                { name: 'confident', filters: getFilter('personification', ['confident']) },
                { name: 'bold', filters: getFilter('personification', ['bold']) },
            ],

            advertising: [
                { name: 'advertisingRecall', filters: getFilter('advertising', ['advertising_recall']) },
                { name: 'tvCableSatelliteBroadcast', filters: getFilter('advertising', ['tv_(cable_slash_satellite_slash_broadcast)']) },
                { name: 'onlineStreamingVideo', filters: getFilter('advertising', ['online_slash_streaming_video']) },
                { name: 'newsEntertainmentWebsiteOrApp', filters: getFilter('advertising', ['news_slash_entertainment_website_or_app']) },
                { name: 'ecommerceReviewWebsiteOrApp', filters: getFilter('advertising', ['e_hyphen_commerce_slash_review_website_or_app']) },
                { name: 'postPopUponSocialMedia', filters: getFilter('advertising', ['post_slash_pop_hyphen_up_on_social_media']) },
                { name: 'influencerOnSocialMedia', filters: getFilter('advertising', ['influencer_on_social_media']) },
                { name: 'storePhysicalLocation', filters: getFilter('advertising', ['store_slash_physical_location']) },
                { name: 'onlineGaming', filters: getFilter('advertising', ['online_gaming']) },
                { name: 'streamingAudioPodcast', filters: getFilter('advertising', ['streaming_audio_slash_podcast']) },
                { name: 'radio', filters: getFilter('advertising', ['radio']) },
                { name: 'outdoor', filters: getFilter('advertising', ['outdoor']) },
                { name: 'printMedia', filters: getFilter('advertising', ['print_media']) },
                { name: 'email', filters: getFilter('advertising', ['email']) },
                { name: 'other', filters: getFilter('advertising', ['other']) },
            ],
            touchpoints: [
                { name: 'tvAd', filters: getFilter('touchpoint', ['tv_advertising']) },
                { name: 'radioAd', filters: getFilter('touchpoint', ['radio_advertising']) },
                { name: 'directMailAd', filters: getFilter('touchpoint', ['direct_mail_advertising']) },
                { name: 'printAd', filters: getFilter('touchpoint', ['print_advertising']) },
                { name: 'socialMediaAd', filters: getFilter('touchpoint', ['social_media_advertising']) },
                { name: 'digitalAd', filters: getFilter('touchpoint', ['digital_advertising']) },
                { name: 'podcastAd', filters: getFilter('touchpoint', ['podcast_advertising']) },
                { name: 'emailAd', filters: getFilter('touchpoint', ['email_advertising']) },
                { name: 'blogPost', filters: getFilter('touchpoint', ['blog_posts']) },
                { name: 'sponsoredEvent', filters: getFilter('touchpoint', ['sponsored_events']) },
                { name: 'partnership', filters: getFilter('touchpoint', ['partnerships']) },
                { name: 'productPlacement', filters: getFilter('touchpoint', ['product_placement']) },
                { name: 'wordOfMouth', filters: getFilter('touchpoint', ['word_of_mouth']) },
                { name: 'celebrityEndorsment', filters: getFilter('touchpoint', ['celebrity_endorsements']) },
                { name: 'newsMedia', filters: getFilter('touchpoint', ['news_media']) },
                { name: 'other', filters: getFilter('touchpoint', ['other']) },
            ],
            size: [],
        }
        return labels[filter] ? labels[filter] : '';
    }

    static labelText = {
        "tvAd": "TV advertising",
        "podcastAd": "Podcast advertising",
        "blogPost": "Blog posts",
        "celebrityEndorsment": "Celebrity endorsements",
        "digitalAd": "Digital advertising",
        "directMailAd": "Direct mail advertising",
        "emailAd": "Email advertising",
        "newsMedia": "News media",
        "other": "Other",
        "partnership": "Partnerships",
        "printAd": "Print advertising",
        "productPlacement": "Product placement",
        "radioAd": "Radio advertising",
        "socialMediaAd": "Social media advertising",
        "inStore": "In store",
        "outdoorAdvertising": "Outdoor advertising",
        "sponsoredEvent": "Sponsored events",
        "wordOfMouth": "Word of mouth",
        "stylish" : "Stylish",
        "good_value" : "Good Value",
        "goodValue" : "Good Value",
        "socially_conscious" : "Socially Conscious",
        "sociallyConscious" : "Socially Conscious",
        "simple" : "Simple",
        "sophisticated" : "Sophisticated",
        "premium" : "Premium",
        "energetic" : "Energetic",
        "unconventional" : "Unconventional",
        "visionary" : "Visionary",
        "classy" : "Classy",
        "innovative" : "Innovative",
        "customer_centric" : "Customer-centric",
        "customerCentric" : "Customer-centric",
        "young" : "Young",
        "corporate" : "Corporate",
        "intelligent" : "Intelligent",
        "wholesome" : "Wholesome",
        "bold" : "Bold",
        "fun" : "Fun",
        "smart" : "Smart",
        "confident" : "Confident",
        "trustworthy" : "Trustworthy",
        "trustWorthy" : "Trustworthy",
        "dependable" : "Dependable",
        "practical" : "Practical",
        "hip" : "Hip",
        "traditional" : "Traditional",
        "advertisingRecall": "Advertising Recall",
        "tvCableSatelliteBroadcast": "TV (cable/satellite/ broadcast)",
        "onlineStreamingVideo": "Online/Streaming Video",
        "newsEntertainmentWebsiteOrApp": "News/Entertainment Website or App",
        "ecommerceReviewWebsiteOrApp": "E-commerce/Review Website or App",
        "postPopUponSocialMedia": "Post/Pop-up on Social Media",
        "influencerOnSocialMedia": "Influencer on Social Media",
        "storePhysicalLocation": "Store/Physical Location",
        "onlineGaming": "Online Gaming",
        "SearchEngineegGoogleBing": "Search Engine(e.g., Google, Bing)",
        "streamingAudioPodcast": "Streaming Audio/Podcast",
        "radio": "Radio",
        "outdoor": "Outdoor",
        "printMedia": "Print Media",
        "email": "Email",
        "approachable": "Approachable",
        "bestInClass": "Best in Class",
        "knowledgeable": "Knowledgeable",
        "personable": "Personable",
    };

    static initiateLabelText(data) {
        data.map(filter => this.labelText[filter.name] = filter.displayName);
    }

    static getLabelText(key) {
        return this.labelText[key] ? this.labelText[key] : key;
    }

    static getQText(key, market){
        let commonQText = {
            "equity" : "Equity is a composite average of four key indicators: Momentum, Consideration, Quality, and Familiarity",
            "higher": "This value is higher than the comparison period",
            "lower": "This value is lower than the comparison period",
            "emotional": "For each of the following brands, please indicate whether or not you associate each characteristic with a brand. Please select all that apply for each brand.",
            "advertising": "Please indicate where you saw or heard the ads.",
        }
        let marketBasedQText;
        if(market === 'HSS'){
            marketBasedQText = {
                "momentum" : "Based on your personal experience and/or what you have read, seen or heard, how would you describe where each of the following healthcare providers that offer orthopedic care is headed?",
                "consideration" : "If needed for yourself or an immediate family member, how likely are you to seek orthopedic care from the following healthcare providers?",
                "quality" : "Now, we will display a list of healthcare providers that offer orthopedic care and ask you to rate the overall quality of each.",
                "familiarity" : "Please rate your familiarity with each of the following healthcare providers that offer orthopedic care.",
                "awareness": "Please rate your familiarity with each of the following healthcare providers that offer orthopedic care.",
                "trial": "Have you or an immediate family member sought orthopedic care from each of the following healthcare providers?",
                "usage": "Have you or an immediate family member sought orthopedic care from each of the following healthcare providers?",
                "recommend": "How likely are you to recommend the following healthcare providers to a friend or family member for orthopedic care?",
            }
        }else{
            marketBasedQText = {
                "momentum" : "Based on your personal experience and/or what you have read, seen or heard, how would you describe where each of the following brands is headed?",
                "consideration" : "If price were not a consideration, how likely are you to purchase products or services of the following brands in the future?",
                "quality" : "Now, we will display a list of brands and ask you to rate the overall quality of each brand.",
                "familiarity" : "Please rate your familiarity with each of the following brands. Are somewhat or more familiar.",
                "awareness": "Please rate your familiarity with each of the following brands. Know the name or are somewhat or more familiar.",
                "trial": "How often do you purchase/use products or services of the following brands? Usage may mean purchasing, consuming, watching, visiting, supporting, or other form of engagement. Have purchased/used products or services of this brand in the past.",
                "usage": "How often do you purchase/use products or services of the following brands? Usage may mean purchasing, consuming, watching, visiting, supporting, or other form of engagement. Purchase/use the product occasionally or regularly.",
                "recommend": "How likely are you to recommend the following brands to a friend or family member? Are likely to recommend the brand.",
            }
        }
        
        const qText = Object.assign({}, commonQText, marketBasedQText);
        return qText[key] ? qText[key] : key;
    }

    static getWayUpValue(value){
        return value < 0 ? 0 : value;
    }

    static getAppRoutes() {
        return {
            summary: '/summary',
            segments: '/segments',
            competitive: '/competitive',
            advanced: '/advanced',
            global: '/global'
        }
    }

    static getExportFormats() {
        return {
            pdf: 'pdf',
            pptx: 'pptx',
            xlsx: 'xlsx',
            png: 'png',
            csv: 'csv',
        }
    }

    static getExportFormatsOptions(exportType) {
        const exportFormats = InitData.getExportFormats();
        return [
            {
                id: 1,
                name: exportFormats.pdf,
                icon: pdfIcon,
                disabled: exportType === 2 || exportType === 3,
            },
            {
                id: 2,
                name: exportFormats.pptx,
                icon: pptIcon,
                disabled: false,
            },
            {
                id: 3,
                name: exportFormats.xlsx,
                icon: xlsIcon,
                disabled: false,
            },
            {
                id: 4,
                name: exportFormats.png,
                icon: pngIcon,
                disabled: exportType === 2 || exportType === 3,
            },
        ]
    }

    static getExportOptions(currentRoute, selectedTab) {
        const routes = InitData.getAppRoutes();
        let secondOptionLabel = '';

        switch(currentRoute) {
            case routes.summary: {
                secondOptionLabel = 'All Summary Screens';
                break;
            }
            case routes.segments: {
                secondOptionLabel = 'All Segment Screens';
                break;
            }
            case routes.competitive: {
                secondOptionLabel = 'All Competitive Screens';
                break;
            }
            case routes.global: {
                secondOptionLabel = 'All Global Screens';
                break;
            }
            case routes.advanced: {
                secondOptionLabel = 'All Advanced Screens';
                break;
            }
            default: secondOptionLabel = '';
        }

        const options = [
            {
                id: 1,
                name: 'screen',
                label: 'This Screen',
            },
            {
                id: 2,
                name: 'all_screens',
                label: secondOptionLabel,
            },
        ]

        if (currentRoute === routes.advanced) {
            options.forEach((el, idx, o) => {
                if (el.id === 2 && selectedTab === 1) {
                    o.splice(idx, 1);
                }
            });
        }

        return { title: 'download', options };
    }

    static getProgressBarColors() {
        return {
            pathColor: '#1BA379',
            trailColorDefault: '#000',
            trailColorMain: '#DFE2EA',
        }
    }

    static getHorizontalMenuNames() {
        return {
            bar: 'secondary',
            column: 'primary',
        }
    }

    static getModuleType = (type) => {
        let moduleTypes = [
            {id: 1, name:"SUMMARY", displayName: "summary"},
            {id: 2, name:"DETAILED", displayName: "segments"},
            {id: 3, name:"COMPETITIVE", displayName: "competitive"},
            {id: 4, name:"GLOBAL", displayName: "global"},
            {id: 5, name:"ADVANCED", displayName: "advanced"}
        ];
        let selectedType = moduleTypes.find(ele=> ele.displayName === type)
        return selectedType && selectedType?.name
    }


    static getExportFilename(currentRoute, currentTab, currentBrand, optionId) {
        const routes = InitData.getAppRoutes();
        switch(currentRoute) {
            case routes.segments: {
                switch (optionId) {
                    case 1: {
                        switch(currentTab) {
                            case 0: return `Segments-Equity-${currentBrand.name}`;
                            case 1: return `Segments-Conversion-${currentBrand.name}`;
                            case 2: return `Segments-Emotional-${currentBrand.name}`;
                            case 3: return `Segments-Advertising-${currentBrand.name}`;
                            case 4: return `Segments-Trends-${currentBrand.name}`;
                            case 5: return `Segments-Profile-${currentBrand.name}`;
                            default: return `filename`;
                        }
                    }
                    case 2: return `All-Segment-Screens`;
                    default: return `filename`;
                }
            }

            case routes.competitive: {
                switch (optionId) {
                    case 1: {
                        switch(currentTab) {
                            case 0: return `Competitive-Equity`;
                            case 1: return `Competitive-Conversion`;
                            case 2: return `Competitive-Emotional`;
                            case 3: return `Competitive-Advertising`;
                            case 4: return `Competitive-Trends`;
                            case 5: return `Competitive-Profile`;
                            default: return `filename`;
                        }
                    }
                    case 2: return `All-Competitive-Screens`;
                    default: return `filename`;
                }
            }

            case routes.global: {
                switch (optionId) {
                    case 1: {
                        switch (currentTab) {
                            case 0: return `Global-Equity`;
                            case 1: return `Global-Conversion`;
                            case 2: return `Global-Emotional`;
                            case 3: return `Global-Advertising`;
                            case 4: return `Global-Trends`;
                            case 5: return `Global-Profile`;
                            default: return `filename`;
                        }
                    }
                    case 2: return `All-Global-Screens`;
                    default: return `filename`;
                }
            }

            case routes.advanced: {
                switch (optionId) {
                    case 1: {
                        switch(currentTab) {
                            case 0: return `Differentiation-${currentBrand.name}`;
                            case 1: return `Perceptual`;
                            case 2: return `Butterfly`;
                            case 3: return `Pre-Post`;
                            default: return `filename`;
                        }
                    }
                    case 2: return `All-Advanced-Screens`;
                    default: return `filename`;
                }
            }

            default: return 'filename';
        }
    }

    static getExportTemplate(currentRoute, selectedTab, horizontalMenu) {
        const horizontalMenuNames = InitData.getHorizontalMenuNames();
        const ignoreElements = [
            'createCustomContainer',
            'highlightBar',
            'filterIconContainer',
            'horizontalBarBottom',
            'exportBtnContainer',
            'iconContainer',
            'blueTextButton',
            'ddArrow',
            'arrowIcon',
            'filtersContainer',
            'betaBlock',

            (element) => {
                return (element.classList.contains('tab') && element.classList.contains('current')) ?
                    false :
                    element.classList.contains('tab') ?
                    true : false;
            },
        ];

        const prepareHeader = (element, type, selectedTab) => {
            const $header = document.querySelector('.headerContainer')
            let $title = document.createElement('div')
            const $logoBlock = document.createElement('div')
            const $tabsContainer = element.querySelector('.tabsContainer')
            const titleText = type === 'competitive' && selectedTab === 2 ? 'Emotional' :
                (element.querySelector('.tab.current') ? element.querySelector('.tab.current').textContent : 'Summary');

            $title.innerHTML = `
                <div style="font-size: 16px; color: #71767D; font-family: sans-serif;">${type.toUpperCase()}</div>
                <div>${titleText}</div>`

            if (type === 'summary') {
                $title = $header.querySelector('.title').cloneNode(true)
            }

            $title.setAttribute('style', "font-family: 'Silka-Bold', sans-serif; font-size: 24px; line-height: 28px; margin-left: 30px")
            $tabsContainer.setAttribute('style', 'display: flex; justify-content: space-between; color: black; width: 100%; min-height: 100px;')
            $logoBlock.setAttribute('style', 'margin: 5px 45px 0 0;')
            const $logo = $tabsContainer.querySelector('.compassLogoPng')
            $logo.setAttribute('style', 'width: 180px; display: block; margin: 5px 45px 0 0;')
            type !== 'summary' && $tabsContainer.querySelector('.tabs-list').remove()
            // if (type === 'summary') {
            //     const $brand = document.querySelector('.tab.current .brandTitle').cloneNode(true)
            //     $brand.querySelector('.brandName').setAttribute('style', 'color: black;')
            //     $tabsContainer.prepend($brand)
            // }
            const $brandDropDown = element.querySelector('.brandDropDown')
            if ($brandDropDown) {
                const $brand = $brandDropDown.querySelector('.brandTitle')
                $brand.querySelector('.brandName').setAttribute('style', 'color: black;')
                if (type !== 'advanced') $tabsContainer.prepend($brand)
            }
            $tabsContainer.prepend($title)

            const $brandLogoIcon = element.querySelectorAll('.brandLogoIcon')
            if ($brandLogoIcon.length) $brandLogoIcon.forEach(elem => elem.setAttribute('style', 'box-shadow: unset; border: 1px solid rgba(0, 0, 0, 0.15);'))
        }

        const prepareInputBoxes = (element) => {
            const $inputBoxes = element.querySelectorAll('.inputBox')

            if ($inputBoxes.length) {
                $inputBoxes.forEach(elem => {
                    const parentEl = elem.parentElement
                    const classListArr = ['selectContainer', 'mainSelectContainer', 'secondarySelectContainer']
                    const padding = classListArr.includes(parentEl.className) ? '20px 30px' : '14px 20px'
                    parentEl.setAttribute('style', 'display: flex;')
                    elem.setAttribute('style', `
                        min-width: unset;
                        width: unset;
                        box-shadow: unset;
                        padding: ${padding};
                    `)
                })
            }
        }

        const additionalWidth = () => {
            let width = 120
            if (document.querySelector('.mainContent.filterOpen')) width = 320
            if (document.querySelector('.contentContainer.expand')) width = 210

            return width
        }

        const summaryPageTemplate = (chartsDelta, getPdfTemplate) => {
            const { xOffset, yOffset } = chartsDelta;

            const mainContent = {
                element: document.querySelector('.mainContent'),
            }

            const mainContentElement = document.querySelector('.mainContent');
            mainContentElement.style.backgroundColor = '#F5F7FA';
            const equityChartW = document.querySelector('.equityContainer').scrollWidth;
            const lineChartW = document.querySelector('.lineChartSvg').scrollWidth;
            const summaryBottomContainerW = document.querySelector('.summaryBottomContainer').scrollWidth

            const clearDOMCallback = () => {
                if(mainContentElement) {
                    mainContentElement.style.backgroundColor = '#F5F7FA';
                }
            }

            const onCloneHandler = (element) => {
                const $brandEquity = element.querySelector('.chartHeaderContainer.equityTitle')
                const $menuBarContainer = element.querySelector('.menuBarContainer')
                element.querySelector('.chartHeaderContainer.equityTitle').remove()
                $brandEquity.setAttribute('style', 'top: 10px; position: relative;')
                $menuBarContainer.appendChild($brandEquity)
                prepareHeader(element, 'summary')
                prepareInputBoxes(element)
            }
         
            const templateWidth = (summaryBottomContainerW > equityChartW + lineChartW ? summaryBottomContainerW : equityChartW + lineChartW) + xOffset + additionalWidth() ;
            const templateHeight = mainContentElement.scrollHeight + yOffset;

            return {
                img: {
                    templateWidth,
                    templateHeight,
                    element: mainContent,
                    ignoreElements,
                    clearDOMCallback,
                    onCloneHandler,
                },
                pdf: getPdfTemplate(templateWidth, templateHeight),
            };
        }

        const globalPageTemplate = (chartsDelta, selectedTab, getPdfTemplate, horizontalMenu) => {
            const { xOffset, yOffset } = chartsDelta;

            const mainContent = {
                element: document.querySelector('.mainContent'),
            }

            const mainContentElement = document.querySelector('.mainContent');
            const dataItemsContainer = document.querySelector('.dataItemsContainer');
            const leftContainer = document.querySelector('.barChartContainer > div[class*=LeftContainer]');
            const rightContainer = document.querySelector('.barChartContainer > div[class*=RightContainer]');
            const barChartContent = document.querySelector('.barChartContent');
            mainContentElement.style.backgroundColor = '#F5F7FA';

            const clearDOMCallback = () => {
                if(mainContentElement) {
                    mainContentElement.style.backgroundColor = '#F5F7FA';
                }
            }

            const getDataItemsWidth = () => {
                let width = 0
                const minWidth = 800
                dataItemsContainer.querySelectorAll('.cardContainer').forEach(item => {
                    width += item.offsetWidth
                })
                if (selectedTab === 5 || selectedTab === 6) width = dataItemsContainer.scrollWidth + 2 * xOffset
                if (width < minWidth) width = minWidth

                return width += xOffset
            }
            let templateWidth = (leftContainer && rightContainer
                ? leftContainer?.scrollWidth + barChartContent?.scrollWidth + xOffset 
                : getDataItemsWidth()) + additionalWidth();
            let templateHeight = mainContentElement.scrollHeight + yOffset;
            let maxCardHeaderHeight = 0

            const $cardHeaderContainer = document.querySelectorAll('.cardHeaderContainer')
            if ($cardHeaderContainer.length) {
                $cardHeaderContainer.forEach(item => {
                    if (item.querySelector('.filterList')) {
                        item.setAttribute('style', 'height: auto !important;')
                        if (maxCardHeaderHeight < item.offsetHeight) maxCardHeaderHeight = item.offsetHeight
                        item.setAttribute('style', '')
                    }
                })
                templateHeight += maxCardHeaderHeight - 120
            }

            const onCloneHandler = (element) => {
                const scrollWrapper = element.querySelector('.scrollWrapper');
                if (scrollWrapper)
                    scrollWrapper.setAttribute('style', 'overflow-y: hidden;');

                const $filterList = element.querySelector('.filterList')
                const $emotionalContainer = element.querySelectorAll('.cardContainer.emotionalContainer')
                const $cardContainer = element.querySelectorAll('.cardContainer')
                const $chartItems = element.querySelectorAll('.chartItem')
                const $hiddenBrands = element.querySelectorAll('.brandItemRightContainer.brandHidden')
                const type = 'global'

                element.querySelector('.dataItemsContainer').setAttribute('style', 'min-height: unset')
                element.querySelector('.menuBarContainer').setAttribute('style', 'width: 100%;')
                element.querySelector('.periodContainer').setAttribute('style', 'margin-right: 15px; width: auto; border-top: unset;')
                element
                    .querySelectorAll('.cardHeaderContainer')
                    .forEach(elem => elem.setAttribute('style', 'box-shadow: unset; border-bottom: 1px solid rgba(0, 0, 0, 0.05);'))
                element
                    .querySelectorAll('.brandTitle .brandDefaultLogo')
                    .forEach(elem => elem.setAttribute('style', 'box-shadow: unset;'))
                if ($filterList) $filterList.setAttribute('style', 'margin: 0 26px')
                if ($emotionalContainer.length) {
                    $emotionalContainer.forEach(elem => elem.setAttribute('style', 'min-height: unset'))
                    $emotionalContainer.forEach(container => {
                        container.querySelectorAll('g.node').forEach(elem => {
                            const $labelText = elem.querySelector('.label-text')
                            if (navigator.userAgent.includes('Chrome')) {
                                $labelText.style.letterSpacing = '0.5px'
                            }
                            if (navigator.userAgent.includes('Firefox')) {
                                $labelText.style.letterSpacing = '-1px'
                            }
                            $labelText.style.fontSize = $labelText.style.fontSize.replace('px', '') - 1
                            $labelText.style.textAlign = 'center'
                        })
                    })
                }
                if ($cardContainer.length) {
                    $cardContainer.forEach(elem => {
                        if (elem.querySelector('.filterList')) {
                            const $cardHeaderContainer = elem.querySelector('.cardHeaderContainer')
                            const styles = $cardHeaderContainer.getAttribute('style')
                            $cardHeaderContainer.setAttribute('style', `${styles} height: ${maxCardHeaderHeight}px !important; position: relative;`)
                            elem.setAttribute('style', 'min-height: unset; padding-top: 0px;')
                        }
                    })
                }
                if ($chartItems.length) {
                    $chartItems.forEach(item => {
                        const clArr = item.style.backgroundColor.split('')
                        clArr.splice(clArr.length - 1, 0, `, ${item.style.opacity}`)
                        item.style.backgroundColor = clArr.join('').replace('rgb', 'rgba')
                    })
                }
                if ($hiddenBrands.length) {
                    $hiddenBrands.forEach(item => {
                        item.querySelector('.brandName').setAttribute('style', 'color: rgba(0, 0, 0, 0.5);')
                        item.querySelector('.showOrHideIcon').setAttribute('style', 'width: 20px;')
                    })
                }
                if (type === 'global') {
                    const $horizontalBarContainer = element.querySelector('.horizontalBarContainer')
                    const $filterList = $horizontalBarContainer.querySelector('.filterList')
                    $filterList.setAttribute('style', 'position: absolute; left: 10px;')
                    $horizontalBarContainer.querySelector('.periodPickerContainer').prepend($filterList)
                }
                if (type === 'advanced') {
                    const $horizontalBarContainer = element.querySelector('.horizontalBarContainer')
                    const $filterList = $horizontalBarContainer.querySelector('.filterList')
                    $filterList.setAttribute('style', 'position: absolute; left: 10px;')
                    $horizontalBarContainer.querySelector('.periodPickerContainer').prepend($filterList)
                }
                if (selectedTab === 1) element.querySelector('.dataItemsContainer').setAttribute('style', 'width: 102%;')
                // if (selectedTab === 2 || selectedTab === 3) element.querySelectorAll('.bottomContainer').forEach(elem => elem.remove())
                prepareHeader(element, type, selectedTab)
                prepareInputBoxes(element)
            }

            const imgTemplate = {
                templateWidth,
                templateHeight,
                element: mainContent,
                clearDOMCallback,
                ignoreElements,
                onCloneHandler,
            };

            switch(selectedTab) {
                
                case 0:
                case 1:
                case 5:
                case 6: return { img: imgTemplate, pdf: getPdfTemplate(imgTemplate.templateWidth, imgTemplate.templateHeight) };

                case 2:
                case 3: {
                    if(horizontalMenu === horizontalMenuNames.column) {
                        return {
                            img: imgTemplate,
                            pdf: getPdfTemplate(imgTemplate.templateWidth, imgTemplate.templateHeight),
                        }
                    } else {
                        const barChart = document.querySelector('.barChartContainer svg');
                        const equityLeftContainer = document.querySelector('.equityLeftContainer');
                        const templateWidth = equityLeftContainer.scrollWidth + barChart.scrollWidth + xOffset;

                        return {
                            img: {
                                ...imgTemplate,
                                templateWidth,
                            },
                            pdf: getPdfTemplate(templateWidth, imgTemplate.templateHeight)
                        }
                    }
                }
                case 4: {
                    const trendsLeftContainer = document.querySelector('.trendsLeftContainer');
                    const lineChartSvg = document.querySelector('.lineChartSvg');
                    const templateWidth = trendsLeftContainer.scrollWidth + lineChartSvg.scrollWidth + xOffset + additionalWidth() ;
                    return {
                        img: {
                            ...imgTemplate,
                            templateWidth,
                        },
                        pdf: getPdfTemplate(templateWidth, imgTemplate.templateHeight),
                    };
                }

                default: return {};

            }
        }

        const competitiveIntelPageTemplate = (chartsDelta, selectedTab, getPdfTemplate, horizontalMenu) => {
            const { xOffset, yOffset } = chartsDelta;

            const mainContent = {
                element: document.querySelector('.mainContent'),
            }

            const mainContentElement = document.querySelector('.mainContent');
            const dataItemsContainer = document.querySelector('.dataItemsContainer');
            const leftContainer = document.querySelector('.barChartContainer > div[class*=LeftContainer]');
            const rightContainer = document.querySelector('.barChartContainer > div[class*=RightContainer]');
            const barChartContent = document.querySelector('.barChartContent');
            mainContentElement.style.backgroundColor = '#F5F7FA';

            const clearDOMCallback = () => {
                if(mainContentElement) {
                    mainContentElement.style.backgroundColor = '#F5F7FA';
                }
            }

            const getDataItemsWidth = () => {
                let width = 0
                const minWidth = 800
                dataItemsContainer.querySelectorAll('.cardContainer').forEach(item => {
                    width += item.offsetWidth
                })
                if (selectedTab === 5 || selectedTab === 6) width = dataItemsContainer.scrollWidth + 2 * xOffset
                if (width < minWidth) width = minWidth

                return width += xOffset
            }
            
            let templateWidth = (leftContainer && rightContainer
            ? leftContainer?.scrollWidth + barChartContent?.scrollWidth + xOffset + additionalWidth()
            : getDataItemsWidth()) + additionalWidth();
            let templateHeight = mainContentElement.scrollHeight ;
            let maxCardHeaderHeight = 0
            const $cardHeaderContainer = document.querySelectorAll('.cardHeaderContainer')
            if ($cardHeaderContainer.length) {
                $cardHeaderContainer.forEach(item => {
                    if (item.querySelector('.filterList')) {
                        item.setAttribute('style', 'height: auto !important;')
                        if (maxCardHeaderHeight < item.offsetHeight) maxCardHeaderHeight = item.offsetHeight
                        item.setAttribute('style', '')
                    }
                })
                templateHeight += maxCardHeaderHeight - 120
            }

            const onCloneHandler = (element) => {
                const scrollWrapper = element.querySelector('.scrollWrapper');
                if (scrollWrapper)
                    scrollWrapper.setAttribute('style', 'overflow-y: hidden;');

                const $filterList = element.querySelector('.filterList')
                const $emotionalContainer = element.querySelectorAll('.cardContainer.emotionalContainer')
                const $cardContainer = element.querySelectorAll('.cardContainer')
                const $chartItems = element.querySelectorAll('.chartItem')
                const $hiddenBrands = element.querySelectorAll('.brandItemRightContainer.brandHidden')
                const type = element.querySelector('.pageContent.segmentsPage') ? 'segments' : 'competitive'

                element.querySelector('.dataItemsContainer').setAttribute('style', 'min-height: unset')
                element.querySelector('.menuBarContainer').setAttribute('style', 'width: 100%;')
                element.querySelector('.periodContainer').setAttribute('style', 'margin-right: 15px; width: auto; border-top: unset;')
                element
                    .querySelectorAll('.cardHeaderContainer')
                    .forEach(elem => elem.setAttribute('style', 'box-shadow: unset; border-bottom: 1px solid rgba(0, 0, 0, 0.05);'))
                element
                    .querySelectorAll('.brandTitle .brandDefaultLogo')
                    .forEach(elem => elem.setAttribute('style', 'box-shadow: unset;'))
                if ($filterList) $filterList.setAttribute('style', 'margin: 0 26px')
                if ($emotionalContainer.length) {
                    $emotionalContainer.forEach(elem => elem.setAttribute('style', 'min-height: unset'))
                    $emotionalContainer.forEach(container => {
                        container.querySelectorAll('g.node').forEach(elem => {
                            const $labelText = elem.querySelector('.label-text')
                            if (navigator.userAgent.includes('Chrome')) {
                                $labelText.style.letterSpacing = '0.5px'
                            }
                            if (navigator.userAgent.includes('Firefox')) {
                                $labelText.style.letterSpacing = '-1px'
                            }
                            $labelText.style.fontSize = $labelText.style.fontSize.replace('px', '') - 1
                            $labelText.style.textAlign = 'center'
                        })
                    })
                }
                if ($cardContainer.length) {
                    $cardContainer.forEach(elem => {
                        if (elem.querySelector('.filterList')) {
                            const $cardHeaderContainer = elem.querySelector('.cardHeaderContainer')
                            const styles = $cardHeaderContainer.getAttribute('style')
                            $cardHeaderContainer.setAttribute('style', `${styles} height: ${maxCardHeaderHeight}px !important; position: relative;`)
                            elem.setAttribute('style', 'min-height: unset; padding-top: 0px;')
                        }
                    })
                }
                if ($chartItems.length) {
                    $chartItems.forEach(item => {
                        const clArr = item.style.backgroundColor.split('')
                        clArr.splice(clArr.length - 1, 0, `, ${item.style.opacity}`)
                        item.style.backgroundColor = clArr.join('').replace('rgb', 'rgba')
                    })
                }
                if ($hiddenBrands.length) {
                    $hiddenBrands.forEach(item => {
                        item.querySelector('.brandName').setAttribute('style', 'color: rgba(0, 0, 0, 0.5);')
                        item.querySelector('.showOrHideIcon').setAttribute('style', 'width: 20px;')
                    })
                }
                if (type === 'competitive') {
                    const $horizontalBarContainer = element.querySelector('.horizontalBarContainer')
                    const $filterList = $horizontalBarContainer.querySelector('.filterList')
                    $filterList.setAttribute('style', 'position: absolute; left: 10px;')
                    $horizontalBarContainer.querySelector('.periodPickerContainer').prepend($filterList)
                }
                if (selectedTab === 1) element.querySelector('.dataItemsContainer').setAttribute('style', 'width: 102%;')
                // if (selectedTab === 2 || selectedTab === 3) element.querySelectorAll('.bottomContainer').forEach(elem => elem.remove())
                prepareHeader(element, type, selectedTab)
                prepareInputBoxes(element)
            }

            const imgTemplate = {
                templateWidth,
                templateHeight,
                element: mainContent,
                clearDOMCallback,
                ignoreElements,
                onCloneHandler,
            };
            switch(selectedTab) {
                case 0:
                case 1:
                case 5:
                case 6: return { img: imgTemplate, pdf: getPdfTemplate(imgTemplate.templateWidth, imgTemplate.templateHeight) };

                case 2:
                case 3: {
                    if(horizontalMenu === horizontalMenuNames.column) {
                        return {
                            img: imgTemplate,
                            pdf: getPdfTemplate(imgTemplate.templateWidth, imgTemplate.templateHeight),
                        }
                    } else {
                        const barChart = document.querySelector('.barChartContainer svg');
                        const equityLeftContainer = document.querySelector('.equityLeftContainer');
                        const templateWidth = equityLeftContainer.scrollWidth + barChart.scrollWidth + xOffset ;

                        return {
                            img: {
                                ...imgTemplate,
                                templateWidth,
                            },
                            pdf: getPdfTemplate(templateWidth, imgTemplate.templateHeight),
                        }
                    }
                }
                case 4: {
                    const trendsLeftContainer = document.querySelector('.trendsLeftContainer');
                    const lineChartSvg = document.querySelector('.lineChartSvg');
                    const templateWidth = trendsLeftContainer.scrollWidth + lineChartSvg.scrollWidth + xOffset +additionalWidth() ;
                    return {
                        img: {
                            ...imgTemplate,
                            templateWidth,
                            
                        },
                        pdf: getPdfTemplate(templateWidth,imgTemplate.templateHeight ),
                    };
                }

                default: return {};

            }
        }

        const advancedAnalyticsPageTemplate = (chartsDelta, selectedTab, getPdfTemplate) => {
            const { xOffset, yOffset } = chartsDelta;

            const mainContent = {
                element: document.querySelector('.mainContent'),
            }

            const mainContentElement = document.querySelector('.mainContent');
            mainContentElement.style.backgroundColor = '#F5F7FA';

            const clearDOMCallback = () => {
                const cardHeaderContainersFictive = document.querySelectorAll('.cardHeaderContainer.fictive');
                [].slice.call(cardHeaderContainersFictive).forEach(element => element && element.parentNode.removeChild(element));
                if(mainContentElement) {
                    mainContentElement.style.backgroundColor = '#F5F7FA';
                }
            }

            const onCloneHandler = (element) => {
                const $differentiationItems = element.querySelectorAll('.differentiationItem')
                const $logoIcons = element.querySelectorAll('.map .logoIcon')
                const $prePost = element.querySelector('.periodContainer.prePost')
                const $periodContainer = element.querySelector('.periodContainer')
                const $ButterflyMapContainer = element.querySelector('.ButterflyMapContainer')

                if ($periodContainer) $periodContainer.setAttribute('style', 'margin-right: 25px; width: auto; border-top: unset;')
                if ($logoIcons.length) $logoIcons.forEach(elem => elem.setAttribute('style', 'border-radius: 16px; padding: unset'))
                if ($differentiationItems.length) $differentiationItems.forEach(elem => {
                    elem.style.boxShadow = 'unset'
                    if (elem.classList.contains('logoImg')) elem.style.border = '1px solid rgba(0, 0, 0, 0.15)'
                })
                // if ($prePost) {
                //     $prePost.setAttribute('style', 'margin-right: 15px; width: auto; border-top: unset; display: flex; justify-content: flex-end;')
                //     $prePost.querySelectorAll('.sSize').forEach(elem => elem.remove())
                //     $prePost.querySelectorAll('.periodIcon').forEach(elem => elem.remove())
                //     $prePost.querySelectorAll('.customInputDate').forEach(elem => elem.remove())



                //     $prePost.querySelectorAll('.periodPickerContainer').forEach((elem, index) => {
                //         if (!index) elem.setAttribute('style', 'padding-left: 0; border-right: black 1px solid;')
                //         elem.setAttribute('style', 'padding-left: 0;')
                //     })
                // }
                if ($ButterflyMapContainer) {
                    $ButterflyMapContainer.setAttribute('style', 'min-height: unset; height: unset')
                    $ButterflyMapContainer.querySelector('.map').setAttribute('style', 'overflow: unset; height: unset;')
                    $ButterflyMapContainer.querySelectorAll('.map > div').forEach(elem => elem.setAttribute('style', 'position: relative; top: unset; right: unset;'))
                    $ButterflyMapContainer.querySelectorAll('.map > div.title').forEach(elem => elem.setAttribute('style', 'padding: 20px 40px 20px; position: relative; top: unset; right: unset;'))
                }

                prepareHeader(element, 'advanced', selectedTab)
                prepareInputBoxes(element)
            }

            const templateWidth = mainContentElement.scrollWidth + xOffset + (document.querySelector('.contentContainer.expand') && additionalWidth() );
            let templateHeight = mainContentElement.scrollHeight + yOffset;

            const $ButterflyMapContainer = mainContentElement.querySelector('.ButterflyMapContainer')

            // Get height from the real DOM
            if ($ButterflyMapContainer) {
                // Modify
                $ButterflyMapContainer.setAttribute('style', 'min-height: unset; height: unset')
                $ButterflyMapContainer.querySelector('.map').setAttribute('style', 'overflow: unset; height: unset;')
                mainContentElement.querySelector('.advancedAnalyticContainer').setAttribute('style', 'min-height: unset; height: unset')
                const styles = []
                $ButterflyMapContainer.querySelectorAll('.map > div').forEach(elem => {
                    styles.push(elem.style.cssText)
                    return elem.setAttribute('style', 'position: relative; top: unset; right: unset;')
                })
                templateHeight = mainContentElement.scrollHeight + yOffset
                // Restore
                $ButterflyMapContainer.setAttribute('style', '')
                $ButterflyMapContainer.querySelector('.map').setAttribute('style', '')
                mainContentElement.querySelector('.advancedAnalyticContainer').setAttribute('style', '')
                $ButterflyMapContainer.querySelectorAll('.map > div').forEach((elem, index) => elem.setAttribute('style', styles[index]))
            }

            switch(selectedTab) {
                case 0:
                case 1:
                case 2: {
                    return {
                        img: {
                            templateWidth,
                            templateHeight,
                            element: mainContent,
                            ignoreElements,
                            clearDOMCallback,
                            onCloneHandler,
                        },
                        pdf: getPdfTemplate(templateWidth, templateHeight),
                    }
                }
                case 3: {
                    const barChart = document.querySelector('.barChartContainer svg');
                    const templateWidth = barChart.scrollWidth + xOffset +additionalWidth() ;
                    const index = ignoreElements.indexOf('iconContainer')
                    if (index !== -1) {
                        ignoreElements.splice(index, 1)
                    }

                    return {
                        img: {
                            templateWidth,
                            templateHeight,
                            element: mainContent,
                            ignoreElements,
                            clearDOMCallback,
                            onCloneHandler,
                        },
                        pdf: getPdfTemplate(templateWidth, templateHeight),
                    }
                }
                default: {
                    return {};
                }
            }
        }

        const initExportTemplate = (currentRoute, selectedTab, horizontalMenu) => {
            const getPdfTemplate = (imgWidth, imgHeight) => {
                const ratio = imgWidth / imgHeight;

                return {
                    orientation: ratio > 1 ? 'l' : 'p',
                    unit: 'px',
                    format: 'a4',
                    xOffset: 0,
                    yOffset: 0,
                    imageFormat: 'PNG',
                    ratio,
                    width: imgWidth,
                    height: imgHeight,
                }
            };

            const routes = InitData.getAppRoutes();

            let xOffset = 0;
            let yOffset = 0;

            const yCorrection = () => {
                return document.querySelector('.horizontalBarContainer').offsetHeight > 100 ? -80 : 0
            }

            const yScrollCorrection = () => {
                // const containerHeight = document.querySelector('.dataItemsContainer').offsetHeight;
                const scrollWrapperHeight = document.querySelector('.scrollWrapper').offsetHeight;
                // const scrollWrapperTop = document.querySelector('.scrollWrapper').offsetTop;

                return scrollWrapperHeight;
            }

            if (currentRoute === routes.summary) {
                xOffset = 200
                yOffset = -20
            }

            if (currentRoute === routes.segments) {
                yOffset = yCorrection()
                if (selectedTab === 0 || horizontalMenu === horizontalMenuNames.bar) {
                    xOffset = 110
                } else if (selectedTab === 1) {
                    xOffset = 110
                    yOffset = -60 + yCorrection()
                } else if (selectedTab === 2) {
                    xOffset = 40
                } else if (selectedTab === 3) {
                    xOffset = 110
                    // yOffset = -1200 + yCorrection()
                } else if (selectedTab === 4) {
                    xOffset = 200
                } else if (selectedTab === 6) {
                    xOffset = 40
                }
            }

            if (currentRoute === routes.competitive) {
                yOffset = yCorrection()
                if (selectedTab === 0 || selectedTab === 1 || horizontalMenu === horizontalMenuNames.bar) {
                    xOffset = 110
                } else if (selectedTab === 2) {
                    xOffset = 150
                } else if (selectedTab === 3) {
                    xOffset = 160
                    // yOffset = -1100 + yCorrection()                
                } else if (selectedTab === 4) {
                    xOffset = 200
                } else if (selectedTab === 6) {
                    xOffset = 40
                }
            }

           
            if (currentRoute === routes.global) {
                yOffset = yCorrection()
                if (selectedTab === 0 || selectedTab === 1 || horizontalMenu === horizontalMenuNames.bar) {
                    xOffset = 110
                } else if (selectedTab === 2) {
                    xOffset = 40
                } else if (selectedTab === 3) {
                    xOffset = 110
                    // yOffset = -1100 + yCorrection()                
                } else if (selectedTab === 4) {
                    xOffset = 200
                } else if (selectedTab === 6) {
                    xOffset = 40
                }
            }

             if (currentRoute === routes.advanced) {
                xOffset = 100
                yOffset = 80 + yCorrection()
                if (selectedTab === 2) {
                    xOffset = 200
                    yOffset = 110 + yCorrection()
                }
                if (selectedTab === 3) {
                    xOffset = 190
                }
            }

            const chartsDelta = {
                xOffset: xOffset,
                yOffset: yOffset,
            };

            switch(currentRoute) {
                case routes.summary: return summaryPageTemplate(chartsDelta, getPdfTemplate);

                case routes.segments: return competitiveIntelPageTemplate(chartsDelta, selectedTab, getPdfTemplate, horizontalMenu);

                case routes.competitive: return competitiveIntelPageTemplate(chartsDelta, selectedTab, getPdfTemplate, horizontalMenu);

                case routes.global: return globalPageTemplate(chartsDelta, selectedTab, getPdfTemplate, horizontalMenu);

                case routes.advanced: return advancedAnalyticsPageTemplate(chartsDelta, selectedTab, getPdfTemplate);

                default: return {};
            }
        }

        return initExportTemplate(currentRoute, selectedTab, horizontalMenu);
    }

    static getExportModes() {
        return {
            summaryAll: 'summary_all',
            detailedAll: 'detailed_all',
            competitiveAll: 'competitive_all',
            globalAll: 'global_all',
            advancedAll: 'advanced_all',
            dashboardsAll: 'dashboards_all',
        }
    }

    static getUserData() {
        const modes = {
            create: 'create',
            profileUpdate: 'profile_update',
            profileDisplay: 'profile_display',
        };

        return {
            title: (mode) => mode === modes.create ? 'Add New User' : '',
            subtitle: (mode) => mode === modes.create ?
                'Create a new User for HQB Dashboard' :
                mode === modes.profileDisplay || mode === modes.profileUpdate ? 'User ID:' : '',
            modes,
            form: {
                firstName: {
                    key: 'firstname',
                    label: 'First Name',
                    placeholder: 'First Name',
                },
                lastName: {
                    key: 'lastname',
                    label: 'Last Name',
                    placeholder: 'Last Name',
                },
                email: {
                    key: 'email',
                    label: 'Email Address',
                    placeholder: 'email@address.com',
                    inputMessages: {
                        validEmail: 'Please enter a valid email address',
                    },

                },
                phoneNumber: {
                    key: 'phone',
                    label: 'Phone Number',
                    placeholder: '000-000-0000',
                },
                password: {
                    key: 'password',
                    label: 'Password',
                    placeholder: 'Password',
                    inputMessages: {
                        validPassword: 'Password must be of minimum 8 characters, with combination of one uppercase character, numeric and special character',
                    },
                },
                company: {
                    key: 'company',
                    label: 'Company',
                    placeholder: 'Company',
                },
                position: {
                    key: 'position',
                    label: 'Position',
                    placeholder: 'Position',
                },
                groups: {
                    key: 'groups',
                    label: 'Groups',
                    placeholder: 'Search for Group to add to…',
                },
                userRoles: {
                    key: 'role',
                    label: 'Role',
                    placeholder: 'Role',
                    options: window.location.pathname ==='/adduser'? [
                        { id: 1, name: 'QuestBrand User', type: 'USER' },
                        hasPermission()? { id: 2, name: 'QuestBrand Admin', type: 'ADMIN' }:{id: 1}
                    ]:[ { id: 1, name: 'QuestBrand User', type: 'USER' },
                        { id: 2, name: 'QuestBrand Admin', type: 'ADMIN' }],
                },
            }, 
            buttons: {
                cancel: {
                    key: 'cancel',
                    label: 'Cancel'
                },
                add: {
                    key: 'add_user',
                    label: (mode) => mode === modes.create ? 'Add New User' : mode === modes.profileUpdate ? 'Update User' : '',
                },
                addToGroup: {
                    key: 'add_to_group',
                    label: 'Add to Group'
                }
            },
            inputMessages: {
                emailNotEdited: 'Email address cannot be edited.',
            },
            modals: {
                removeUser: {
                    title: 'Delete User',
                    message: 'Are you sure you want to delete the following user?',
                    extraMessage: 'This is a permanent action.',
                    buttons: [
                        { name: 'Cancel' },
                        { name: 'Delete User' },
                    ],
                }
            },
            table: {
                title: 'Groups',
                columns: [
                    {key:'displayName', title: 'Group Name', width: '160px', clickable: true },
                    {key:'usersAmount', title:'# of Users', width: '160px' },
                    {key:'brandsAmount', title:'# of Brands', width: '160px' },
                    {key:'added', title: 'Added', width: '160px' },
                ],
                noGroupsTitle: 'Uh,oh. No Groups.',
                noGroupMessage: 'User is not currently part of any groups. Add to a group in order to grant access to brands',
            }
        }
    }

    static getGroupData() {
        const modes = {
            create: 'create',
            profileUpdate: 'profile_update',
            profileDisplay: 'profile_display',
        };
        return {
            title: (mode) => mode === modes.create ? 'Add New Group' : '',
            subtitle: (mode) => mode === modes.create ?
                'Create a new Group for HQB Users' :
                mode === modes.profileDisplay || mode === modes.profileUpdate ? 'Group ID:' : '',
            modes,
            form: {
                groupName: {
                    key: 'groupname',
                    label: 'Group Name',
                    placeholder: 'Group Name',
                },
                company: {
                    key: 'company',
                    label: 'Client Company',
                    placeholder: 'Search for Client Company',
                },
                internalOwners: {
                    key: 'internalOwners',
                    label: 'Internal Owner',
                    placeholder: 'Search for Internal Owner',
                },
                users: {
                    key: 'users',
                    label: 'Users',
                    placeholder: 'Search for Users to add…',
                },
                brands: {
                    key: 'brands',
                    label: 'Active Brands',
                    placeholder: '',
                    primaryBrand: 'Primary Brand',
                },
            },
            buttons: {
                cancel: {
                    key: 'cancel',
                    label: 'Cancel'
                },
                add: {
                    key: 'add_group',
                    label: (mode) => mode === modes.create ? 'Add New Group' : mode === modes.profileUpdate ? 'Update Group' : '',
                },
                addToGroup: {
                    key: 'add_to_group',
                    label: 'Add User'
                }
            },
            modals: {
                removeGroup: {
                    title: 'Delete Group',
                    message: 'Are you sure you want to delete the following group?',
                    extraMessage: 'This is a permanent action.',
                    buttons: [
                        { name: 'Cancel' },
                        { name: 'Delete Group' },
                    ],
                },
                removeUser: {
                    title: 'Remove User',
                    message: 'Are you sure you want to remove the user from this group?',
                    extraMessage: 'They will loose access to dashboard and brand data.',
                    buttons: [
                        { name: 'Cancel' },
                        { name: 'Remove User' },
                    ],
                }
            },
            table: {
                title: 'Users',
                columns: [
                    {key:'email', title: 'Email Address', width: '470px', clickable: true },
                    {key:'firstname', title:'First Name', width: '160px' },
                    {key:'lastname', title:'Last Name', width: '160px' },
                    {key:'added', title: 'Added', width: '160px' },
                ],
                noGroupsTitle: 'Uh,oh. No Users.',
                noGroupMessage: 'There are no users in this group. Add users to grant access to brands available in this group.',
            }
        }
    }

    static getContractData() {
        const modes = {
            create: 'create',
            profileUpdate: 'profile_update',
            profileCopy:'profile_copy',
            profileDisplay: 'profile_display',
        };
        return {
            title: (mode) => mode === modes.create ? 'Add New Contract' : '',
            subtitle: (mode) => mode === modes.create || mode === modes.profileCopy ?
                'Create a new Contract for the following group' :
                mode === modes.profileDisplay || mode === modes.profileUpdate ? 'Contract ID: %{id}% | Last Updated By: %{userName}% | Last Updated Date: %{updatedDate}%' : '',
            modes,
            form: {
                contractName: {
                    key: 'contract_name',
                    label: 'Contract Name',
                    placeholder: 'Contract Name',
                },
                purpose: {
                    key: 'purpose',
                    label: 'Purpose',
                    placeholder: 'Purpose',
                    options: [
                        { id: 1, name: 'Lead / Demo'},
                        { id: 2, name: 'Trial / Pilot'},
                        { id: 3, name: 'Client Sale'},
                        { id: 4, name: 'Client Sale Add On'},
                        { id: 5, name: 'Client Sale Renewal'},
                        { id: 12, name: 'Client Sub Portal' },
                        { id: 6, name: 'Custom Study Value Add'},
                        { id: 7, name: 'Marketing Initiative'},
                        { id: 8, name: 'Stagwell Agency Pitch'},
                        { id: 9, name: 'Stagwell Agency Client'},
                        { id: 10, name: 'Internal Need'},
                        { id: 11, name: 'Other'},
                    ],
            },
                salesforceId: {
                    key: 'salesforce_id',
                    label: 'Salesforce ID',
                    placeholder: 'Salesforce ID #',
                },
                amount: {
                    key: 'amount',
                    label: 'Amount',
                    placeholder: '$0.00',
                },
                cost: {
                    key: 'cost',
                    label: 'Cost',
                    placeholder: '$0',
                    inputMessages: {
                        validCost: 'Cost should not be greater than 9,999,999,999.99',
                    },
                },
                startDate: {
                    key: 'start_date',
                    label: 'Start Date',
                    placeholder: 'Start Date',
                    warnings: {
                        duration: "Length of this contract falls outside of standard guidelines - Contact Ops for support",
                        extension: "The extension of this contract falls outside of standard guidelines - Contact Ops for support"
                    }
                },
                endDate: {
                    key: 'end_date',
                    label: 'End Date',
                    placeholder: 'End Date',
                    warnings: {
                        duration: "Length of this contract falls outside of standard guidelines - Contact Ops for support",
                        extension: "The extension of this contract falls outside of standard guidelines - Contact Ops for support"
                    }
                },
                brands: {
                    key: 'brands',
                    label: 'Contract Brands',
                    placeholder: 'Search for Brands to add...',
                    primaryBrand: 'Primary Brand',
                    warnings: {
                        activation: "Updating the contract may cause the brand to start fielding - Contact Ops for support",
                        reactivation: "Updating the contract may cause the brand to start fielding - Contact Ops for support",
                        deactivation: "Updating the contract may cause the brand to stop fielding - Contact Ops for support"
                    }
                },
                campaigns: {
                    key: 'geographies',
                    label: 'Contract Markets',
                    placeholder: 'Search for Markets to add...',
                }
            },
            buttons: {
                cancel: {
                    key: 'cancel',
                    label: 'Cancel'
                },
                add: {
                    key: 'add_contract',
                    label: (mode) => mode === modes.create ? 'Add New Contract' : mode === modes.profileUpdate ? 'Update Contract': modes.profileCopy ? 'Add New Contract' : '',
                },
            },
            modals: {
                suspendContract: {
                    title: 'Suspend Contract',
                    message: 'Are you sure you want to suspend the following contract?',
                    extraMessage: 'This is a permanent action.',
                    buttons: [
                        { name: 'Cancel' },
                        { name: 'Suspend Contract' },
                    ],
                },
            },
            header: {
                extraInfo: {
                    status: 'Group: ',
                    message: '',
                }
            }
        }
    }

    static getBrandData() {
        const modes = {
            create: 'create',
            profileUpdate: 'profile_update',
            profileDisplay: 'profile_display',
        };
        return {
            title: (mode) => mode === modes.create ? 'Add New Brand' : '',
            subtitle: (mode) => mode === modes.create ?
                'Create a new Brand for HQB platform' :
                mode === modes.profileDisplay || mode === modes.profileUpdate ? 'Brand ID:' : '',
            modes,
            form: {
                decipherName: {
                    key: 'decipher_name',
                    label: 'Decipher Name',
                    placeholder: 'Decipher Name',
                },
                decipherId: {
                    key: 'decipher_id',
                    label: 'Decipher ID',
                    placeholder: 'Decipher ID',
                },
                displayName: {
                    key: 'display_name',
                    label: 'Display Name',
                    placeholder: 'Display Name',
                },
                industry: {
                    key: 'industry',
                    label: 'Industry',
                    placeholder: 'Industry',
                },
                // subIndustry: {
                //     key: 'sub_industry',
                //     label: 'Sub-Industry',
                //     placeholder: 'Sub-Industry',
                // },
                logo: {
                    key: 'logo',
                    label: 'Logo',
                    placeholder: 'Logo',
                },
            },
            buttons: {
                cancel: {
                    key: 'cancel',
                    label: 'Cancel'
                },
                add: {
                    key: 'add_brand',
                    label: (mode) => mode === modes.create ? 'Add New Brand' : mode === modes.profileUpdate ? 'Update Brand' : '',
                },
            },
            modals: {
                removeBrand: {
                    title: 'Delete Brand',
                    message: 'Are you sure you want to delete the following brand?',
                    extraMessage: 'This is a permanent action.',
                    buttons: [
                        { name: 'Cancel' },
                        { name: 'Delete Brand' },
                    ],
                },
                updateBrand: {
                    title: 'Update Brand',
                    message: 'Are you sure you want to update the following brand details?',
                    buttons: [
                        { name: 'Cancel' },
                        { name: 'Proceed'},
                    ],
                },
            },
            table: [
                {
                    title: 'Contracts',
                    columns: [
                        {key:'brandContractName', field:'brandContractName', title: 'Contract Name', width: '160px' , sorting: 'active'},
                        {key:'brandGroupName', field:'brandGroupName', title:'Group', width: '160px',sorting: 'inactive'},
                        {key:'campaignGroups', field:'campaignGroups', title:'Markets', width: '160px',sorting: 'inactive'},
                        {key:'startDate', field:'startDate', title:'Start Date', width: '160px',sorting: 'inactive'},
                        {key:'endDate', field:'endDate', title: 'End Date', width: '160px',sorting: 'inactive'},
                        {key:'quota', field:'sampleSize', title: 'Sample Size', width: '160px',sorting: 'inactive'},
                    ],
                    noGroupsTitle: 'Uh,oh. No Contracts.',
                    noGroupMessage: 'There are no contracts for this brand.',
                },
                {
                    title: 'Markets',
                    columns: [
                        {key: 'campaignCountry', field:'campaignCountry', title: 'Country', width: '160px', sorting: 'active'},
                        {key: 'campaignRegion', field:'campaignRegion', title: 'Region/State', width: '160px', sorting: null},
                        {key: 'completes', field:'completes', title: 'Completes', width: '160px', sorting: 'inactive'},
                        {key: 'sampleSize', field:'sampleSize', title: 'Sample Size', width: '160px', sorting: 'inactive'},
                        // {key: 'status', title: 'Status', width: '160px'}
                    ],
                    noGroupsTitle: 'Uh,oh. No Markets.',
                    noGroupMessage: 'There are no Markets for this brand.',
                }
            ],
            inputMessages: {
                decipherNameNotEdited: 'Cannot edit Decipher Name.',
                decipherIdNotEdited: 'Cannot edit Decipher ID.',
            },
        }
    }

    static getCampaignFieldsAttributes() {
        const modes = {
            profileUpdate: 'profile_update',
            profileDisplay: 'profile_display',
            profileCreate: 'profile_create'
        };
        return {
            modes,
            form: {
                name: {
                    key: 'name',
                    label: 'Name',
                    placeholder: 'Market name',
                },
                regionState: {
                    key: 'regionState',
                    label: 'Region/State',
                    placeholder: 'Region/State',
                },
                totalCompletes: {
                    key: 'totalCompletes',
                    label: 'Total Weighted',
                    placeholder: '--'
                },
                decipherSurvey: {
                    key: 'decipherSurvey',
                    label: 'Decipher Survey',
                    placeholder: 'Decipher Survey'
                },
                sampleId: {
                    key: 'sampleId',
                    label: 'Sample ID',
                    placeholder: 'ex.sample_{id}'
                },
                identity: {
                    key: 'identity',
                    label: 'Identity',
                    placeholder: 'Identity'
                },
                code: {
                    key: 'code',
                    label: 'Code',
                    placeholder: 'Code'
                },
                qcampaign: {
                    key: 'qcampaign',
                    label: 'QCAMPAIGN',
                    placeholder: 'QCAMPAIGN'
                },
                displayName: {
                    key: 'displayName',
                    label: 'Display name',
                    placeholder: 'Display name'
                },
                weightQuota: {
                    key: 'weightQuota',
                    label: 'Weight Quota',
                    placeholder: 'Weight Quota'
                },
                lastWeightedAt: {
                    key: 'lastWeightedAt',
                    label: 'Last Weighted',
                    placeholder: '--'
                },
                ingestedCount: {
                    key: 'ingestedCount',
                    label: 'Total Ingested',
                    placeholder: '--'
                },
                weightDifference: {
                    key: 'weightedDifference',
                    label: 'Weighted Difference',
                    placeholder: '--'
                },
                // consumerQuota: {
                //     key: 'consumerQuota',
                //     label: 'Consumer Quota',
                //     placeholder: 'Consumer Quota',
                // },
                // vendorQuota: {
                //     key: 'vendorQuota',
                //     label: 'Vendor Quota',
                //     placeholder: 'Vendor Quota',
                // },
                campaignStatus: {
                    key: 'status',
                    label: 'Status',
                    placeholder: 'Market status',
                },
            },
            buttons: {
                cancel: {
                    key: 'cancel',
                    label: 'Cancel'
                },
                update: {
                    key: 'update_campaign',
                    label: 'Update Market',
                },
                create: {
                    key: 'create_campaign',
                    label: 'Create Market'
                }
            },
            modals: {
                enableMarket: {
                    title: 'Enable Market',
                    message: 'Are you sure you want to enable the following market?',
                    buttons: [
                        { name: 'Cancel' },
                        { name: 'Enable Market' },
                    ],
                },
                disableMarket: {
                    title: 'Disable Market',
                    message: 'Are you sure you want to disable the following market?',
                    buttons: [
                        { name: 'Cancel' },
                        { name: 'Disable Market' },
                    ],
                }
            },
            table: {
                title: 'Contracts',
                columns: [
                    {key:'brandContractName',field:'contractName', title: 'Contract Name', width: '170px', sorting: 'active' },
                    {key:'brandGroupName',field:'groupName', title:'Group', width: '160px', sorting: 'Inactive' },
                    {key:'startDate', field:'startDate', title:'Start Date', width: '160px', sorting: 'Inactive' },
                    {key:'endDate', field:'endDate',title: 'End Date', width: '160px', sorting: 'Inactive' },
                ],
                noGroupsTitle: 'Uh,oh. No Contracts.',
                noGroupMessage: 'There are no contracts for this market.',
            },
            inputMessages: {
                decipherNameNotEdited: 'Cannot edit Decipher Name.',
                decipherIdNotEdited: 'Cannot edit Decipher ID.',
            },
        }
    }

    static getUserDropdownOptions(user) {
        return {
            user: {
                options: [
                    {
                        name: 'edit_user',
                        label: 'Edit User',
                    },
                    {
                        name: 'reset_pass',
                        label: 'Reset Password',
                    },
                ],
                separateOptions: [
                    {
                        name: 'delete_user',
                        label: 'Delete User',
                    },
                ]
            },
            group: {
                options: [],
                separateOptions: [
                    {
                        name: 'remove_from_group',
                        label: 'Remove From Group',
                    },
                ]
            }
        }
    }

    static getGroupDropdownOptions() {
        return {
            group: {
                options: [
                    {
                        name: 'edit_group',
                        label: 'Edit Group',
                    },
                ],
                separateOptions: [
                    {
                        name: 'delete_group',
                        label: 'Delete Group',
                    },
                ]
            },
            user: {
                options: [],
                separateOptions: [
                    {
                        name: 'remove_user',
                        label: 'Remove User',
                    },
                ]
            },
        }
    }

    static getContractDropdownOptions() {
        return {
            options: [
                {
                    name: 'edit_contract',
                    label: 'Edit Contract',
                },
                {
                    name: 'copy_contract',
                    label: 'Copy Contract',
                },
            ],
            separateOptions: []
        }
    }

    static getContractBrandDropdownOptions(isPrimaryBrand) {
        return [
            {
                name: 'primary_brand',
                label: isPrimaryBrand ? 'Remove Primary Brand' : 'Set as Primary Brand',
            },
        ]
    }

    static getBrandDropdownOptions() {
        return {
            options: [
                {
                    name: 'edit_brand',
                    label: 'Edit Brand',
                },
            ],
            separateOptions: [
                {
                    name: 'delete_brand',
                    label: 'Delete Brand',
                },
            ]
        }
    }

    static getCampaignDropdownOptions() {
        return {
            options: [
                {
                    name: 'edit_campaign',
                    label: 'Edit Market',
                },
                {
                    name: 'disable_geo',
                    label: 'Disable Market',
                },
                {
                    name: 'enable_geo',
                    label: 'Enable Market',
                },
            ],
        }
    }

    static getUserContractOptions() {
        return {
            options: [
                {
                    name: 'brand',
                    label: 'Brand',
                    icon: detailedIcon
                },
                {
                    name: 'geography',
                    label: 'Geography',
                    icon: geoIcon
                },
            ],
        }
    }

    static getStatusMessages() {
        const subjects = {
            user: 'user',
            group: 'group',
            password: 'password',
            brand: 'brand',
            contract: 'contract',
            segments: 'segments',
            market: 'market',
            decipherSurvey: 'decipherSurvey',
            filter: 'filter'
        }
        const actionTypes = {
            add: 'add',
            remove: 'remove',
            update: 'update',
            setPrimaryBrand: 'set_primary',
            suspend: 'suspend',
            changeStatus: 'change_status',
            approve: 'approve',
            decline: 'decline',
            limit: 'limit',
            statusEnabled: 'statusEnabled',
            statusDisabled: 'statusDisabled',
            existing: 'existing'
        }
        return {
            types: {
                addUser: [subjects.user, actionTypes.add],
                removeUser: [subjects.user, actionTypes.remove],
                updateUser: [subjects.user, actionTypes.update],
                existingUser: [subjects.user, actionTypes.existing],
                changeUserStatus: [subjects.user, actionTypes.changeStatus],
                addGroup: [subjects.group, actionTypes.add],
                removeGroup: [subjects.group, actionTypes.remove],
                updateGroup: [subjects.group, actionTypes.update],
                existingGroup: [subjects.group, actionTypes.existing],
                resetPassword: [subjects.password, actionTypes.remove],
                setPrimaryBrand: [subjects.brand, actionTypes.setPrimaryBrand],
                createContract: [subjects.contract, actionTypes.add],
                suspendContract: [subjects.contract, actionTypes.suspend],
                updateContract: [subjects.contract, actionTypes.update],
                createBrand: [subjects.brand, actionTypes.add],
                updateBrand: [subjects.brand, actionTypes.update],
                existingBrand: [subjects.brand, actionTypes.existing],
                removeBrand: [subjects.brand, actionTypes.remove],
                approveBrand: [subjects.brand, actionTypes.approve],
                declineBrand: [subjects.brand, actionTypes.decline],
                segmentsLimit: [subjects.segments, actionTypes.limit],
                campaignEnabled: [subjects.campaign, actionTypes.statusEnabled],
                campaignDisabled: [subjects.campaign, actionTypes.statusDisabled],
                campaignUpdated: [subjects.campaign, actionTypes.update],
                campaignCreate: [subjects.campaign, actionTypes.add],
                existingCampaign: [subjects.campaign, actionTypes.existing],
                decipherSurveyCreate: [subjects.decipherSurvey, actionTypes.add],
                filterCreate: [subjects.filter, actionTypes.add],
                filterRemove: [subjects.filter, actionTypes.remove],
                filterUpdated: [subjects.filter, actionTypes.update],
                existingFilter: [subjects.filter, actionTypes.existing],
            },
            [subjects.user]: {
                [actionTypes.add]: {
                    status: 'User Created: ',
                    message: 'Successfully created user',
                },
                [actionTypes.remove]: {
                    status: 'User Deleted:',
                    message: 'Succesfully deleted user',
                },
                [actionTypes.update]: {
                    status: 'User Updated:',
                    message: 'Succesfully updated user',
                },
                [actionTypes.existing]: {
                    status: 'User Already Exists:',
                    // message: userEmail => `${userEmail} email address already exists.`
                },
                [actionTypes.changeStatus]: {
                    status: 'User Status:',
                    message: 'Succesfully updated user status',
                }
            },
            [subjects.group]: {
                [actionTypes.add]: {
                    status: 'Group Created:',
                    message: 'Successfully created group',
                },
                [actionTypes.existing]: {
                    status: 'Group Already Exists:',
                    message: groupName => `This ${groupName} group name already exists, Please change group name`
                },
                [actionTypes.remove]: {
                    status: 'Group Deleted:',
                    message: 'Successfully deleted group',
                },
                [actionTypes.update]: {
                    status: 'Group Updated:',
                    message: 'Successfully updated ',
                }
            },
            [subjects.password]: {
                [actionTypes.remove]: {
                    status: 'Reset Password:',
                    message: 'Email sent to',
                }
            },
            [subjects.brand]: {
                [actionTypes.add]: {
                    status: '',
                    message: brandName => `${brandName} brand created successfully.`,
                },
                [actionTypes.existing]: {
                    status: 'Brand Existing:',
                    message: 'Brand already exists. ',
                },
                [actionTypes.setPrimaryBrand]: {
                    status: 'Primary Brand:',
                    message: brandName => `${brandName} is now the primary brand.`
                },
                [actionTypes.remove]: {
                    status: 'Delete Brand: ',
                    message: brandName => `Successfully deleted brand.`
                },
                [actionTypes.approve]: {
                    status: 'Brand Approved: ',
                    message: brandName => `Successfully approved ${brandName}`
                },
                [actionTypes.update]: {
                    status: 'Brand Updated:',
                    message: 'Succesfully updated Brand',
                },
                [actionTypes.decline]: {
                    status: 'Brand Deleted: ',
                    message: brandName => `Declined ${brandName}`
                }
            },
            [subjects.contract]: {
                [actionTypes.add]: {
                    status: 'Contract Created:',
                    message: contractName => `${contractName} contract has been created.`
                },
                [actionTypes.suspend]: {
                    status: 'Contract Suspended:',
                    message: contractName => `${contractName} contract has been suspended.`
                },
                [actionTypes.update]: {
                    status: 'Contract Updated:',
                    message: contractName => `contract has been updated.`
                }
            },
            [subjects.segments]: {
                [actionTypes.limit]: {
                    status: 'Whoops: ',
                    message: () => 'Please limit your selection to 10 profiling attributes',
                },
            },
            [subjects.campaign]: {
                [actionTypes.add]: {
                    status: 'Market created: ',
                    message: 'Successfully created market',
                },
                [actionTypes.existing]: {
                    status: 'Market Already Exists',
                },
                [actionTypes.statusEnabled]: {
                    status: 'Market Enabled: ',
                    message: marketName => `Successfully enabled market ${marketName}`,
                },
                [actionTypes.statusDisabled]: {
                    status: 'Market Disabled: ',
                    message: marketName => `Successfully disabled market ${marketName}`,
                },
                [actionTypes.update]: {
                    status: 'Market Updated:',
                    message: 'Successfully updated market',
                },
            },
            [subjects.decipherSurvey]: {
                [actionTypes.add]: {
                    status: 'Decipher Survey created: ',
                    message: 'Successfully created Decipher Survey',
                },
            },
            [subjects.filter]: {
                [actionTypes.add]: {
                    status: 'Filter created: ',
                    message: 'Successfully created.',
                },
                [actionTypes.existing]: {
                    status: 'Filter Already Exists:',
                    message: filterName => `${filterName} already exists with same date range`
                },
                [actionTypes.remove]: {
                    status: 'Filter removed: ',
                    message: 'Successfully deleted.'
                },
                [actionTypes.update]: {
                    status: 'Filter Updated:',
                    message: `Successfully updated.`
                },
            },

        }
    }

    static getDefaultSegment() {
        return {
            id: -1,
            name: 'Age 18+',
            active: true,
            data: {
                filters: []
            }
        }
    }

    static get2x2AllSegments(filters) {
        const dateFilterId = 3;
        const ageFilterId = 8;
        const segmentsToExclude = [dateFilterId, ageFilterId];
        // by default we request and show the first 5 active segments (1 default + 4 generated)
        const maxActiveNumberByDefault = 4;
        const filterGroups = InitData.getFilterGroups();
        const allSegments = filterGroups.reduce((acc, filterGroup) => {
            const { filters: filterNames } = filterGroup;
            let i = 0;

            const segments = filterNames.reduce((acc, fName) => {
                if (!filters.length) {
                    return acc;
                }

                const filter = filters.find(({ name }) => name === fName);

                if (!filter)
                    return acc;

                const {
                    id,
                    filterOptions,
                    name: filterName
                } = filter;

                if (segmentsToExclude.includes(id)) {
                    return acc;
                }

                i++;

                const data = {
                    id,
                    data: {
                        filter: filterOptions,
                    }
                };

                const name = filterName
                    .split('_')
                    .map((wordInName) => {
                        const countries = ["us", "uk", "au", "ca"]
                        if (countries.includes(wordInName)) {
                            return wordInName.toUpperCase()
                        } else {
                            return wordInName.replace(/^[a-z]/, a => a.toUpperCase())
                        }
                    })
                    .join(' ');
                acc.push({
                    name,
                    filterName,
                    active: i < maxActiveNumberByDefault,
                    ...data,
                });

                return acc;
            }, []);

            return [...acc, ...segments];
        }, []);
        const parsedAllSegments = allSegments.filter((segment) => {
            const { id: segmentId } = segment;
            const filter = filters.find(({ id }) => id === segmentId);

            if (filter) {
                const { type } = filter;

                if (type !== filterGroupTypes.FILTER_GROUP) {
                    return segment;
                }
            }
        });
        return [this.getDefaultSegment(), ...parsedAllSegments];
    }

    static getSubcategoriesList() {
        return this.getStabOptions()
            .map((category, i) => (this.getFilterLabels(category.name.toLowerCase())
                .map((subcategory) => ({...subcategory, name: this.getLabelText(subcategory.name), id: subcategory.name}))));
    }

    static getOptionLabels() {
        return InitData.optionLabels;
    }

    static getAdvertisingAndEmotionalLabelText(key){
        const labelText = {
            "advertisingRecall": "Advertising Recall",
            "tvCableSatelliteBroadcast": "TV",
            "onlineStreamingVideo": "Online/Stream",
            "newsEntertainmentWebsiteOrApp": "News Website",
            "ecommerceReviewWebsiteOrApp": "Retail Website",
            "postPopUponSocialMedia": "Social Media",
            "influencerOnSocialMedia": "Influencer",
            "storePhysicalLocation": "Store Location",
            "onlineGaming": "Online Gaming",
            "SearchEngineegGoogleBing": "Search Engine",
            "streamingAudioPodcast": "Podcast",
            "radio": "Radio",
            "outdoor": "Outdoor",
            "printMedia": "Print",
            "email": "Email",
            "other": "Other",
            "corporate": "Corporate",
            "customerCentric": "Customer-centric",
            "dependable": "Dependable",
            "fun": "Fun",
            "approachable": "Approachable",
            "goodValue": "Good Value",
            "hip": "Hip",
            "bestInClass": "Best in Class",
            "innovative": "Innovative",
            "intelligent": "Intelligent",
            "knowledgeable": "Knowledgeable",
            "practical": "Practical",
            "premium": "Premium",
            "simple": "Simple",
            "sociallyConscious": "Socially Conscious",
            "smart": "Smart",
            "stylish": "Stylish",
            "personable": "Personable",
            "traditional": "Traditional",
            "trustworthy": "Trustworthy",
            "unconventional": "Unconventional",
            "visionary": "Visionary",
            "wholesome": "Wholesome",
            "classy": "Classy",
            "sophisticated": "Sophisticated",
            "young": "Young",
            "energetic": "Energetic",
            "confident": "Confident",
            "bold": "Bold"
        }
        return labelText[key] ? labelText[key] : key;
    }

    static getTooltipLabels(key) {
        const labels = {
            hetero: 'Heterosexual',
            lgbt: 'LGBT',
            transgender_other: 'Transgender/Other',
            female: 'Female',
            male: 'Male',
            baby_present_yes: 'Baby Present: Yes',
            baby_present_no: 'Baby Present: No',
            grade_schooler_present_yes: 'Grade Schooler Present: Yes',
            grade_schooler_present_no: 'Grade Schooler Present: No',
            toddler_present_yes: 'Toddler Present: Yes',
            toddler_present_no: 'Toddler Present: No',
            pre_schooler_present_yes: 'Pre Schooler Present: Yes',
            pre_schooler_present_no: 'Pre Schooler Present: No',
            teen_present_yes: 'Teen Present: Yes',
            teen_present_no: 'Teen Present: No',
            older_adult_present_yes: 'Older Adult Present: Yes',
            older_adult_present_no: 'Older Adult Present: No',
            senior_present_yes: 'Senior Present: Yes',
            senior_present_no: 'Senior Present: No',
            young_adult_present_yes: 'Young Adult Present: Yes',
            young_adult_present_no: 'Young Adult Present: No',
            'I am a sports fan but I am not interested in fantasy sports, sports wagering or online casino_no': 'I am a sports fan but I am not interested in fantasy sports, sports wagering or online casino: No',
            'I am not interested in sports or gaming_yes': 'I am not interested in sports or gaming: Yes',
            'I am interested in either fantasy sports, sports wagering or online casino, but do not currently do any of these activities_no': 'I am interested in either fantasy sports, sports wagering or online casino, but do not currently do any of these activities: No',
            'I am interested in either fantasy sports, sports wagering or online casino, but do not currently do any of these activities_yes': 'I am interested in either fantasy sports, sports wagering or online casino, but do not currently do any of these activities: Yes',
            'I am a sports fan who plays Daily Fantasy Sports_no': 'I am a sports fan who plays Daily Fantasy Sports: No',
            'I am a sports fan who plays Daily Fantasy Sports_yes': 'I am a sports fan who plays Daily Fantasy Sports: Yes',
            'I am a sports fan who wagers with an online sportsbook_yes': 'I am a sports fan who wagers with an online sportsbook: Yes',
            'I am a sports fan who wagers with an online sportsbook_no': 'I am a sports fan who wagers with an online sportsbook: No',
            'I am a casino player who wagers with an online casino_yes': 'I am a casino player who wagers with an online casino: Yes',
            'I am a sports fan but I am not interested in fantasy sports, sports wagering or online casino_yes': 'I am a sports fan but I am not interested in fantasy sports, sports wagering or online casino: Yes',
            'I am a casino player who wagers with an online casino_no': 'I am a casino player who wagers with an online casino: No',
            'I am not interested in sports or gaming_no': 'I am not interested in sports or gaming: No',
            'other_yes': 'Other: Yes',
            'other_no': 'Other: No',
            'dog_yes': 'Dog: Yes',
            'dog_no': 'Dog: No',
            'cat_yes': 'Cat: Yes',
            'cat_no': 'Cat: No',
            'none_yes': 'None: Yes',
            'none_no': 'None: No',
            'None_yes': 'None: Yes',
            'None_no': 'None: No',
            'Other_yes': 'Other: Yes',
            'Other_no': 'Other: No',
            'Children\'s well-being_yes': 'Children\'s well-being: Yes',
            'Children\'s well-being_no': 'Children\'s well-being: No',
            'Healthcare and hospital support_yes': 'Healthcare and hospital support: Yes',
            'Healthcare and hospital support_no': 'Healthcare and hospital support: No',
            'Hunger relief_yes': 'Hunger relief: Yes',
            'Hunger relief_no': 'Hunger relief: No',
            'Poverty relief_yes': 'Poverty relief: Yes',
            'Poverty relief_no': 'Poverty relief: No',
            'Climate change/environmental_yes': 'Climate change/environmental: Yes',
            'Climate change/environmental_no': 'Climate change/environmental: No',
            'Disease/health related support_yes': 'Disease/health related support: Yes',
            'Disease/health related support_no': 'Disease/health related support: No',
            'Education_yes': 'Education: Yes',
            'Education_no': 'Education: No',
            'Animal welfare_yes': 'Animal welfare: Yes',
            'Animal welfare_no': 'Animal welfare: No',
            'Surround system/home cinema_yes': 'Surround system/home cinema: Yes',
            'Surround system/home cinema_no': 'Surround system/home cinema: No',
            'Hi-speed broadband internet/WiFi_yes': 'Hi-speed broadband internet/WiFi: Yes',
            'Hi-speed broadband internet/WiFi_no': 'Hi-speed broadband internet/WiFi: No',
            'Cable TV_yes': 'Cable TV: Yes',
            'Cable TV_no': 'Cable TV: No',
            'Satellite TV_yes': 'Satellite TV: Yes',
            'Satellite TV_no': 'Satellite TV: No',
            'Internet video services (e.g. Netflix, Hulu, etc.)_yes': 'Internet video services (e.g. Netflix, Hulu, etc.): yes',
            'Internet video services (e.g. Netflix, Hulu, etc.)_no': 'Internet video services (e.g. Netflix, Hulu, etc.): No',
            'Smartphone (e.g. Apple iPhone, Samsung Galaxy, etc.)_yes': 'Smartphone (e.g. Apple iPhone, Samsung Galaxy, etc.): Yes',
            'Smartphone (e.g. Apple iPhone, Samsung Galaxy, etc.)_no': 'Smartphone (e.g. Apple iPhone, Samsung Galaxy, etc.): No',
            'Smart Speaker / Voice Assistant (e.g., Amazon Alexa/Echo, Google Home)_yes': 'Smart Speaker / Voice Assistant (e.g., Amazon Alexa/Echo, Google Home): Yes',
            'Smart Speaker / Voice Assistant (e.g., Amazon Alexa/Echo, Google Home)_no': 'Smart Speaker / Voice Assistant (e.g., Amazon Alexa/Echo, Google Home): No',
            'Motorcycle_yes': 'Motorcycle: Yes',
            'Motorcycle_no': 'Motorcycle: No',
            'Regular landline telephone service_yes': 'Regular landline telephone service: Yes',
            'Regular landline telephone service_no': 'Regular landline telephone service: No',
            'Internet radio (e.g. Pandora, Spotify, Sirius XM, etc.)_yes': 'Internet radio (e.g. Pandora, Spotify, Sirius XM, etc.): Yes',
            'Internet radio (e.g. Pandora, Spotify, Sirius XM, etc.)_no': 'Internet radio (e.g. Pandora, Spotify, Sirius XM, etc.): No',
            'Tablet (e.g. Apple iPad, Samsung Galaxy Tab, etc.)_yes': 'Tablet (e.g. Apple iPad, Samsung Galaxy Tab, etc.): Yes',
            'Tablet (e.g. Apple iPad, Samsung Galaxy Tab, etc.)_no': 'Tablet (e.g. Apple iPad, Samsung Galaxy Tab, etc.): No',
            'Digital camera_yes': 'Digital camera: Yes',
            'Digital camera_no': 'Digital camera: No',
            'Video game console (e.g. PlayStation, Xbox, etc.)_yes': 'Video game console (e.g. PlayStation, Xbox, etc.): Yes',
            'Video game console (e.g. PlayStation, Xbox, etc.)_no': 'Video game console (e.g. PlayStation, Xbox, etc.): No',
            'Digital media receiver (e.g. Apple TV, Google Chromecast, Roku, Amazon Fire TV, etc.)_yes': 'Digital media receiver (e.g. Apple TV, Google Chromecast, Roku, Amazon Fire TV, etc.): Yes',
            'Digital media receiver (e.g. Apple TV, Google Chromecast, Roku, Amazon Fire TV, etc.)_no': 'Digital media receiver (e.g. Apple TV, Google Chromecast, Roku, Amazon Fire TV, etc.): No',
            'Vehicle_yes': 'Vehicle: Yes',
            'Vehicle_no': 'Vehicle: No',
            'Digital Video Recorder (DVR)(e.g. TiVo, device from cable/satellite company)_yes': 'Digital Video Recorder (DVR)(e.g. TiVo, device from cable/satellite company): Yes',
            'Digital Video Recorder (DVR)(e.g. TiVo, device from cable/satellite company)_no': 'Digital Video Recorder (DVR)(e.g. TiVo, device from cable/satellite company): No',
            'partnerships_yes': 'Partnerships: Yes',
            'partnerships_no': 'Partnerships: no',
            'tv_advertising_yes': 'TV Advertising: Yes',
            'tv_advertising_no': 'TV Advertising: No',
            'print_advertising_yes': 'Print Advertising: Yes',
            'print_advertising_no': 'Print Advertising: No',
            'blog_posts_yes': 'Blog Posts: Yes',
            'blog_posts_no': 'Blog Posts: No',
            'news_media_yes': 'News Media: Yes',
            'news_media_no': 'News Media: No',
            'product_placement_yes': 'Product Placement: Yes',
            'product_placement_no': 'Product Placement: No',
            'outdoor_advertising_yes': 'Outdoor Advertising: Yes',
            'outdoor_advertising_no': 'Outdoor Advertising: No',
            'direct_mail_advertising_yes': 'Direct Mail Advertising: Yes',
            'direct_mail_advertising_no': 'Direct Mail Advertising: No',
            'digital_advertising_yes': 'Digital Advertising: Yes',
            'digital_advertising_no': 'Digital Advertising: No',
            'podcast_advertising_yes': 'Podcast Advertising: Yes',
            'podcast_advertising_no': 'Podcast Advertising: No',
            'sponsored_events_yes': 'Sponsored Events: Yes',
            'sponsored_events_no': 'Sponsored Events: No',
            'social_media_advertising_yes': 'Social Media Advertising: Yes',
            'social_media_advertising_no': 'Social Media Advertising: No',
            'celebrity_endorsements_yes': 'Celebrity Endorsements: Yes',
            'celebrity_endorsements_no': 'Celebrity Endorsements: No',
            'word_of_mouth_yes': 'Word of Mouth: Yes',
            'word_of_mouth_no': 'Word of Mouth: No',
            'in_store_yes': 'In Store: Yes',
            'in_store_no': 'In Store: No',
            'email_advertising_yes': 'Email Advertising: Yes',
            'email_advertising_no': 'Email Advertising: No',
            'radio_advertising_yes': 'Radio Advertising: Yes',
            'radio_advertising_no': 'Radio Advertising: No',
            'young_yes': 'Young: Yes',
            'young_no': 'Young: No',
            'confident_yes': 'Confident: Yes',
            'confident_no': 'Confident: No',
            'smart_yes': 'Smart: Yes',
            'smart_no': 'Smart: No',
            'corporate_yes': 'Corporate: Yes',
            'corporate_no': 'Corporate: No',
            'hip_yes': 'Hip: Yes',
            'hip_no': 'Hip: No',
            'visionary_yes': 'Visionary: Yes',
            'visionary_no': 'Visionary: No',
            'simple_yes': 'Simple: Yes',
            'simple_no': 'Simple: No',
            'trustworthy_yes': 'Trustworthy: Yes',
            'trustworthy_no': 'Trustworthy: No',
            'bold_yes': 'Bold: Yes',
            'bold_no': 'Bold: No',
            'stylish_yes': 'Stylish: Yes',
            'stylish_no': 'Stylish: No',
            'dependable_yes': 'Dependable: Yes',
            'dependable_no': 'Dependable: No',
            'premium_yes': 'Premium: Yes',
            'premium_no': 'Premium: No',
            'good_value_yes': 'Good Value: Yes',
            'good_value_no': 'Good Value: No',
            'sophisticated_yes': 'Sophisticated: Yes',
            'sophisticated_no': 'Sophisticated: No',
            'energetic_yes': 'Energetic: Yes',
            'energetic_no': 'Energetic: No',
            'socially_conscious_yes': 'Socially Conscious: Yes',
            'socially_conscious_no': 'Socially Conscious: No',
            'innovative_yes': 'Innovative: Yes',
            'innovative_no': 'Innovative: No',
            'fun_yes': 'Fun: Yes',
            'fun_no': 'Fun: No',
            'practical_yes': 'Practical: Yes',
            'practical_no': 'Practical: No',
            'wholesome_yes': 'Wholesome: Yes',
            'wholesome_no': 'Wholesome: No',
            'classy_yes': 'Classy: Yes',
            'classy_no': 'Classy: No',
            'customer_centric_yes': 'Customer Centric: Yes',
            'customer_centric_no': 'Customer Centric: No',
            'unconventional_yes': 'Unconventional: Yes',
            'unconventional_no': 'Unconventional: No',
            'traditional_yes': 'Traditional: Yes',
            'traditional_no': 'Traditional: No',
            'intelligent_yes': 'Intelligent: Yes',
            'trend_setter': 'Trend Setter',
            'laggard': 'Laggard',
            'wait_and_see': 'Wait and See',
            'early_adopter': 'Early Adopter',
            'business_yes': 'Business: Yes',
            'business_no': 'Business: No',
            'flies_yes': 'Flies: Yes',
            'flies_no': 'Flies: No',
            'leisure_yes': 'Leisure: Yes',
            'leisure_no': 'Leisure: No',
            'instore_first': 'Instore First',
            'online_first': 'Online First',
            'mobile_first': 'Mobile First',
            'mixed': 'Mixed',
            'Mastercard_yes': 'Mastercard: Yes',
            'Mastercard_no': 'Mastercard: No',
            'Twitch_yes': 'Twitch: Yes',
            'Twitch_no': 'Twitch: No',
            'Snapchat_yes': 'Snapchat: Yes',
            'Snapchat_no': 'Snapchat: No',
            'American Express_yes': 'American Express: Yes',
            'American Express_no': 'American Express: No',
            'Visa_yes': 'Visa: Yes',
            'Visa_no': 'Visa: No',
            'Facebook_yes': 'Facebook: Yes',
            'Facebook_no': 'Facebook: No',
            'Instacart_yes': 'Instacart: Yes',
            'Instacart_no': 'Instacart: No',
            'Instagram_yes': 'Instagram: Yes',
            'Instagram_no': 'Instagram: No',
        }

        return labels[key]
    }

    static getAwareOfProperty() {
        return {
            id: -2,
            type: 'group',
            value: 'Aware of',
            groupingIds: [68, 69, 70, 1519],
        }
    }

    static getTrendsOptionCategories() {
        return {
            // Equity
            0: [
                {
                    "id": "total",
                    "name": "Total"
                },
                {
                    "id": "momentum",
                    "name": "Momentum"
                },
                {
                    "id": "consideration",
                    "name": "Consideration"
                },
                {
                    "id": "quality",
                    "name": "Quality"
                },
                {
                    "id": "familiarity",
                    "name": "Familiarity"
                }
            ],
            // Conversion
            1: [
                {
                    "id": "awareness",
                    "name": "Awareness"
                },
                {
                    "id": "familiarity",
                    "name": "Familiarity"
                },
                {
                    "id": "trial",
                    "name": "Trial"
                },
                {
                    "id": "usage",
                    "name": "Usage"
                },
                {
                    "id": "recommend",
                    "name": "Recommend"
                }
            ],
            // Emotional
            2: [
                {
                    "id": "corporate",
                    "name": "Corporate"
                },
                {
                    "id": "customerCentric",
                    "name": "Customer-centric"
                },
                {
                    "id": "dependable",
                    "name": "Dependable"
                },
                {
                    "id": "fun",
                    "name": "Fun"
                },
                {
                    "id": "goodValue",
                    "name": "Good Value"
                },
                {
                    "id": "hip",
                    "name": "Hip"
                },
                {
                    "id": "innovative",
                    "name": "Innovative"
                },
                {
                    "id": "intelligent",
                    "name": "Intelligent"
                },
                {
                    "id": "practical",
                    "name": "Practical"
                },
                {
                    "id": "premium",
                    "name": "Premium"
                },
                {
                    "id": "simple",
                    "name": "Simple"
                },
                {
                    "id": "sociallyConscious",
                    "name": "Socially Conscious"
                },
                {
                    "id": "smart",
                    "name": "Smart"
                },
                {
                    "id": "stylish",
                    "name": "Stylish"
                },
                {
                    "id": "traditional",
                    "name": "Traditional"
                },
                {
                    "id": "trustworthy",
                    "name": "Trustworthy"
                },
                {
                    "id": "unconventional",
                    "name": "Unconventional"
                },
                {
                    "id": "visionary",
                    "name": "Visionary"
                },
                {
                    "id": "wholesome",
                    "name": "Wholesome"
                },
                {
                    "id": "classy",
                    "name": "Classy"
                },
                {
                    "id": "sophisticated",
                    "name": "Sophisticated"
                },
                {
                    "id": "young",
                    "name": "Young"
                },
                {
                    "id": "energetic",
                    "name": "Energetic"
                },
                {
                    "id": "confident",
                    "name": "Confident"
                },
                {
                    "id": "bold",
                    "name": "Bold"
                }
            ],
            // Advertising
            3: [
                {
                    "id": "advertisingRecall",
                    "name": "Advertising Recall"
                },
                {
                    "id": "tvCableSatelliteBroadcast",
                    "name": "TV (cable/satellite/broadcast)"
                },
                {
                    "id": "onlineStreamingVideo",
                    "name": "Online/Streaming Video"
                },
                {
                    "id": "newsEntertainmentWebsiteOrApp",
                    "name": "News/Entertainment Website or App"
                },
                {
                    "id": "ecommerceReviewWebsiteOrApp",
                    "name": "E-commerce/Review Website or App"
                },
                {
                    "id": "postPopUponSocialMedia",
                    "name": "Post/Pop-up on Social Media"
                },
                {
                    "id": "influencerOnSocialMedia",
                    "name": "Influencer on Social Media"
                },
                {
                    "id": "storePhysicalLocation",
                    "name": "Store/Physical Location"
                },
                {
                    "id": "onlineGaming",
                    "name": "Online Gaming"
                },
                {
                    "id": "streamingAudioPodcast",
                    "name": "Streaming Audio/Podcast"
                },
                {
                    "id": "radio",
                    "name": "Radio"
                },
                {
                    "id": "outdoor",
                    "name": "Outdoor"
                },
                {
                    "id": "printMedia",
                    "name": "Print Media"
                },
                {
                    "id": "email",
                    "name": "Email"
                },
                {
                    "id": "other",
                    "name": "Other"
                }
            ],
            //Irreplaceability
            4:[
                {
                    'id': 'positive',
                    'name': "Positive"
                },
                {
                    'id': 'neutral',
                    'name': "Neutral"
                },
                { 
                    'id': 'negative',
                     'name': "Negative" 
                    },
                { 
                    'id': 'net',
                     'name': "NET"
                 },
            ],
            5:[
                {
                    'id': 'positive',
                    'name': "Positive"
                },
                { 
                    'id': 'negative',
                     'name': "Negative" 
                    },
                { 
                    'id': 'absolutely',
                     'name': "Absolutely"
                 },
                 { 
                    'id': 'possibly',
                     'name': "Possibly"
                 },
                 { 
                    'id': 'notlikely',
                     'name': "Not Likely"
                 },
                 { 
                    'id': 'never',
                     'name': "Never"
                 },
            ],
            // Touchpoints
            6: [
                {
                    "id": "tvAd",
                    "name": "TV advertising"
                },
                {
                    "id": "radioAd",
                    "name": "Radio advertising"
                },
                {
                    "id": "directMailAd",
                    "name": "Direct mail advertising"
                },
                {
                    "id": "printAd",
                    "name": "Print advertising"
                },
                {
                    "id": "socialMediaAd",
                    "name": "Social media advertising"
                },
                {
                    "id": "digitalAd",
                    "name": "Digital advertising"
                },
                {
                    "id": "podcastAd",
                    "name": "Podcast advertising"
                },
                {
                    "id": "emailAd",
                    "name": "Email advertising"
                },
                {
                    "id": "blogPost",
                    "name": "Blog posts"
                },
                {
                    "id": "sponsoredEvent",
                    "name": "Sponsored events"
                },
                {
                    "id": "partnership",
                    "name": "Partnerships"
                },
                {
                    "id": "productPlacement",
                    "name": "Product placement"
                },
                {
                    "id": "wordOfMouth",
                    "name": "Word of mouth"
                },
                {
                    "id": "celebrityEndorsment",
                    "name": "Celebrity endorsements"
                },
                {
                    "id": "newsMedia",
                    "name": "News media"
                },
                {
                    "id": "inStore",
                    "name": "In store"
                },
                {
                    "id": "outdoorAdvertising",
                    "name": "Outdoor advertising"
                },
                {
                    "id": "other",
                    "name": "Other"
                }
            ]
        };
    }

    static optionLabels = {
        "ethnicity__white": ["White", ""],
        "ethnicity__black": ["Black", ""],
        "ethnicity__asian": ["Asian", ""],
        "ethnicity__hispanic": ["Hispanic", ""],
        "ethnicity__other": ["Other", ""],
        "generation__gen_z__years___1997_to_2004_": ["Gen Z", ""],
        "generation__millennials__years___1981_to_1996_": ["Millennials", ""],
        "generation__gen_x__years___1965_to_1980_": ["Gen X", ""],
        "generation__boomers__years___1946_to_1964_": ["Baby Boomers", ""],
        "generation__silent__years___1928_to_1945_": ["Silent", ""],
        "generation__greatest__years___1927_or_earlier_": ["Greatest Generation", ""],
        "employment__employed__ft__pt_or_self_": ["Employed", ""],
        "employment__not_employed": ["Not Employed", ""],
        "education_us__less_than_high_school_degree": ["< High School Degree", ""],
        "education_us__high_school_degree": ["High School Degree", ""],
        "education_us__college_degree": ["College Degree", ""],
        "education_us__graduate_degree": ["Graduate Degree", ""],
        "education_us__associate_degree": ["Associate Degree", ""],
        "education_us__high_school_graduate": ["High school graduate", ""],
        "education_us__some_college__but_no_degree": ["Some college, but no degree", ""],
        "education_us__job_specific_training_program_s__after_high_school": ["Job-specific training program(s) after high schoo", ""],
        "education_us__completed_some_high_school": ["Completed some high school", ""],
        "education_us__less_than_high_school": ["Less than high school", ""],
        "education_us__4_year_college_degree_or_more": ["4 year college degree or more", ""],
        "education_uk__vocational_qualifications___nvq1_nvq2_": ["Vocational qualifications (=NVQ1/NVQ2)", ""],
        "education_uk__gcse_o_level_cse": ["GCSE/O-Level/CSE", ""],
        "education_uk__a_level_scottish_higher_or_equivalent___nvq3_": ["A-Level/Scottish Higher or equivalent (=NVQ3)", ""],
        "education_uk__bachelor_degree_or_equivalent___nvq4_": ["Bachelor Degree or equivalent (=NVQ4)", ""],
        "education_uk__masters_phd_or_equivalent": ["Masters/PhD or equivalent", ""],
        "education_uk__no_formal_qualifications": ["No formal qualifications", ""],
        "education_au____high_school_degree": ["< High school degree", ""],
        "education_au__high_school_degree": ["High school degree", ""],
        "education_au__certificate_i__ii__iii__or_iv": ["Certificate I, II, III, or IV", ""],
        "education_au__diploma___advanced_diploma": ["Diploma / advanced diploma", ""],
        "education_au__bachelor_degree_or_above": ["Bachelor degree or above", ""],
//         education_au____high_school_degree
// FiltersList.js:91 education_au__high_school_degree
// FiltersList.js:91 education_au__certificate_i__ii__iii__or_iv
// FiltersList.js:91 education_au__diploma___advanced_diploma
// FiltersList.js:91 education_au__bachelor_degree_or_above
        "marital_status__never_married": ["Never Married", ""],
        "marital_status__married_living_with_partner": ["Married", ""],
        "marital_status__divorced_separated_widowed": ["Previously Married", ""],
        "sexuality__hetero": ["Heterosexual", ""],
        "sexuality__lgbt": ["LGBTQ", ""],
        "gender__male": ["Male", ""],
        "gender__female": ["Female", ""],
        "gender__other": ["Transgender/Other", ""],
        "sports_and_gaming__fan_daily_fantasy_sports": ["Plays Daily Fantasy Sports", ""],
        "sports_and_gaming__fan_online_sportsbook": ["Wagers Online w/ Sportsbook", ""],
        "sports_and_gaming__casino_online_player": ["Casino Player, Wagers Online", ""],
        "sports_and_gaming__interested_but_not_playing": ["Interested, don't participate", ""],
        "sports_and_gaming__fan_not_interested": ["Sports fan, don't participate", ""],
        "sports_and_gaming__not_interested": ["Not Interested", ""],
        "sports_and_gaming__transgender_other": ["", ""],
        "household_size__1": ["1", "Household: 1"],
        "household_size__2": ["2", "Household: 2"],
        "household_size__3": ["3", "Household: 3"],
        "household_size__4": ["4", "Household: 4"],
        "household_size__5_": ["5+", "Household: 5+"],
        "household_income_us__less_than__15_000": ["Less than $15,000", "Income: Less than $15,000"],
        "household_income_us___15_000__24_999": ["$15,000 to $24,999", "Income: $15,000 to $24,999"],
        "household_income_us___25_000__34_999": ["$25,000 to $34,999", "Income: $25,000 to $34,999"],
        "household_income_us___35_000__49_999": ["$35,000 to $49,999", "Income: $35,000 to $49,999"],
        "household_income_us___50_000__74_999": ["$50,000 to $74,999", "Income: $50,000 to $74,999"],
        "household_income_us___75_000__99_999": ["$75,000 to $99,999", "Income: $75,000 to $99,999"],
        "household_income_us___100_000_or_more": ["$100,000+", "Income: $100,000+"],
        "household_income_uk__up_to__13_499": ["Less than £13,499", "Income: Less than £13,499"],
        "household_income_uk___13_500_to__24_999": ["£13,500 to £24,999", "Income: £13,500 to £24,999"],
        "household_income_uk___25_000_to__39_999": ["£25,000 to £39,999", "Income: £25,000 to £39,999"],
        "household_income_uk___40_000_to__49_999": ["£40,000 to £49,999", "Income: £40,000 to £49,999"],
        "household_income_uk___50_000_to__74_999": ["£50,000 to £74,999", "Income: £50,000 to £74,999"],
        "household_income_uk___75_000_to__99_999": ["£75,000 to £99,999", "Income: £75,000 to £99,999"],
        "household_income_uk___100_000_or_more": ["£100,000+", "Income: £100,000+"],
        "household_income_au_____15k": ["Less than $15,000 ", "Income: Less than $15,000 "],
        "household_income_au___15____24k": ["$15,000 to $24,999", "Income: $15,000 to $24,999"],
        "household_income_au___25____39k": ["$25,000 to $39,999", "Income: $25,000 to $39,999"],
        "household_income_au___40____49k": ["$40,000 to $49,999", "Income: $40,000 to $49,999"],
        "household_income_au___50____74k": ["$50,000 to $74,999", "Income: $50,000 to $74,999"],
        "household_income_au___75____99k": ["$75,000 to $99,999", "Income: $75,000 to $99,999"],
        "household_income_au___100k_": ["$100,000+", "Income: $100,000+"],
        "household_assets_us__less_than__10_000": ["Less than $10,000", "Assets: Less than $10,000"],
        "household_assets_us___10_000_to__24_999": ["$10,000 to $24,999", "Assets: $10,000 to $24,999"],
        "household_assets_us___25_000_to__49_999": ["$25,000 to $49,999", "Assets: $25,000 to $49,999"],
        "household_assets_us___50_000_to__99_999": ["$50,000 to $99,999", "Assets: $50,000 to $99,999"],
        "household_assets_us___100_000_to__249_999": ["$100,000 to $249,999", "Assets: $100,000 to $249,999"],
        "household_assets_us___250_000_to__999_999": ["$250,000 to $999,999", "Assets: $250,000 to $999,999"],
        "household_assets_us___1_000_000_or_more": ["$1,000,000+", "Assets: $1,000,000+"],
        "household_assets_uk__less_than__15_000": ["Less than £15,000", "Assets: Less than £15,000"],
        "household_assets_uk___15_000_to__49_999": ["£15,000 to £49,999", "Assets: £15,000 to £49,999"],
        "household_assets_uk___50_000_to__99_999": ["£50,000 to £99,999", "Assets: £50,000 to £99,999"],
        "household_assets_uk___100_000_to__199_999": ["£100,000 to £199,999", "Assets: £100,000 to £199,999"],
        "household_assets_uk___200_000_to__499_999": ["£200,000 to £499,999", "Assets: £200,000 to £499,999"],
        "household_assets_uk___500_000_to__749_999": ["£500,000 to £749,999", "Assets: £500,000 to £749,999"],
        "household_assets_uk___750_000_to__999_999": ["£750,000 to £999,999", "Assets: £750,000 to £999,999"],
        "household_assets_uk___1_000_000_": ["£1,000,000+", "Assets: £1,000,000+"],
        "household_assets_au__less_than__10_000": ["Less than $10,000", "Assets: Less than $10,000"],
        "household_assets_au___10_000_to__24_999": ["$10,000 to $24,999", "Assets: $10,000 to $24,999"],
        "household_assets_au___25_000_to__49_999": ["$25,000 to $49,999", "Assets: $25,000 to $49,999"],
        "household_assets_au___50_000_to__99_999": ["$50,000 to $99,999", "Assets: $50,000 to $99,999"],
        "household_assets_au___100_000_to__249_999": ["$100,000 to $249,999", "Assets: $100,000 to $249,999"],
        "household_assets_au___250____999k": ["$250 - $999K", "Assets: $250 - $999K"],
        "household_assets_au___1m_": ["$1,000,000+", "Assets:$1,000,000+"],
        "household_composition__baby": ["Baby", ""],
        "household_composition__toddler": ["Toddler", ""],
        "household_composition__pre_schooler": ["Pre Schooler", ""],
        "household_composition__grade_schooler": ["Grade Schooler", ""],
        "household_composition__teen": ["Teenager", ""],
        "household_composition__young_adult": ["Young Adult", ""],
        "household_composition__older_adult": ["Older Adult", ""],
        "household_composition__senior": ["Senior", ""],
        "region_us__northeast": ["Northeast", ""],
        "region_us__midwest": ["Midwest", ""],
        "region_us__south": ["South", ""],
        "region_us__west": ["West", ""],
        "region_us__non_us_state": ["Non-US State", ""],
        "region_uk__east_of_england": ["East of England", ""],
        "region_uk__east_midlands": ["East Midlands", ""],
        "region_uk__london": ["London", ""],
        "region_uk__north_east": ["North East", ""],
        "region_uk__north_west": ["North West", ""],
        "region_uk__northern_ireland": ["Northern Ireland", ""],
        "region_uk__scotland": ["Scotland", ""],
        "region_uk__south_east": ["South East", ""],
        "region_uk__south_west": ["South West", ""],
        "region_uk__wales": ["Wales", ""],
        "region_uk__west_midlands": ["West Midlands", ""],
        "region_uk__yorkshire_and_the_humber": ["Yorkshire and the Humber", ""],
        "region_au__sydney": ["Sydney", ""],
        "region_au__other_new_south_wales": ["Other New South Wales", ""],
        "region_au__melbourne": ["Melbourne", ""],
        "region_au__other_victoria": ["Other Victoria", ""],
        "region_au__brisbane": ["Brisbane", ""],
        "region_au__other_queensland": ["Other Queensland", ""],
        "region_au__adelaide": ["Adelaide", ""],
        "region_au__other_south_australia": ["Other South Australia", ""],
        "region_au__perth": ["Perth", ""],
        "region_au__other_western_australia": ["Other Western Australia", ""],
        "region_au__hobart": ["Hobart", ""],
        "region_au__other_tasmania": ["Other Tasmania", ""],
        "region_au__act": ["ACT", ""],
        "region_au__northern_territory": ["Northern Territory", ""],
        "population_density__in_an_urban_or_city_area": ["Urban", ""],
        "population_density__in_a_suburban_area_next_to_a_city": ["Suburban", ""],
        "population_density__in_a_small_town_or_rural_area": ["Rural", ""],
        "political_party__conservative": ["Conservative", ""],
        "political_party__moderate": ["Moderate", ""],
        "political_party__liberal": ["Liberal", ""],
        "brand_engagement__just_know_the_name": ["Just know the name", ""],
        "brand_engagement__familiar_with": ["Familiar With", ""],
        "brand_engagement__regular_user": ["Regular User", ""],
        "brand_engagement__occasional_user": ["Occasional User", ""],
        "brand_engagement__tried_in_the_past": ["Tried in the Past", ""],
        "brand_engagement__aware_of": ["Aware of", ""],
        "brand_engagement__non_user": ["Non-user", ""],
        "ownership_usage__video_game_console": ["Video Game Console", ""],
        "ownership_usage__digital_camera": ["Digital Camera", ""],
        "ownership_usage__landline_telephone": ["Landline", ""],
        "ownership_usage__cable_tv": ["Cable TV", ""],
        "ownership_usage__satellite_tv": ["Satellite TV", ""],
        "ownership_usage__broadband_internet": ["Hi-speed Internet", ""],
        "ownership_usage__home_cinema": ["Home Cinema", ""],
        "ownership_usage__digital_media_receiver": ["Digital Media Player", ""],
        "ownership_usage__internet_video_services": ["Video Subscription", ""],
        "ownership_usage__tablet": ["Tablet", ""],
        "ownership_usage__digital_video_recorder": ["DVR", ""],
        "ownership_usage__smartphone": ["Smartphone", ""],
        "ownership_usage__internet_radio": ["Internet Radio", ""],
        "ownership_usage__vehicle": ["Vehicle", ""],
        "ownership_usage__voice_assistant": ["Smart Speaker", ""],
        "ownership_usage__motorcycle": ["Motorcycle", ""],
        "media_consumers__am_radio": ["AM Radio", ""],
        "media_consumers__fm_radio": ["FM Radio", ""],
        "media_consumers__satellite_radio": ["Satellite Radio", ""],
        "media_consumers__streaming": ["Streaming Audio", ""],
        "media_consumers__podcasts": ["Podcasts", ""],
        "media_consumers__live_broadcast_tv": ["Live Broadcast TV", ""],
        "media_consumers__live_cable_tv": ["Live Cable TV", ""],
        "media_consumers__live_sports_broadcast": ["Live Sports Video", ""],
        "media_consumers__on_demand": ["DVR/On Demand", ""],
        "media_consumers__internet_streaming": ["Streaming Video", ""],
        "media_consumers__internet_clips": ["Internet Video Clips", ""],
        "media_consumers__theater": ["Movies in Theater", ""],
        "media_consumers__social_media": ["Social Media", ""],
        "shopping_channel__mobile_first": ["Mobile-First", ""],
        "shopping_channel__online_first": ["Online-First", ""],
        "shopping_channel__instore_first": ["In-store First", ""],
        "shopping_channel__mixed": ["Mixed", ""],
        "traveler_type__business": ["Business", ""],
        "traveler_type__leisure": ["Leisure", ""],
        "traveler_type__other": ["Other", "Travel Type: Other", ""],
        "traveler_type__flies": ["All", "Travel Type: All", ""],
        "traveler_type__none": ["Did Not Fly", "", ""],
        "tech_status__trend_setter": ["Trend Setter", ""],
        "tech_status__early_adopter": ["Early Adopter", ""],
        "tech_status__wait_and_see": ["Wait and See", ""],
        "tech_status__laggard": ["Laggard", ""],
        "pet_ownership__none": ["None", ""],
        "pet_ownership__dog": ["Dog", ""],
        "pet_ownership__cat": ["Cat", ""],
        "pet_ownership__other": ["Other", ""],
        "cat_litter_users__cat_litter_users": ["Cat Litter Users", "Cat Litter Users"],
        "charitable_organizations__poverty_relief": ["Poverty Relief", ""],
        "charitable_organizations__hunger_relief": ["Hunger Relief", ""],
        "charitable_organizations__education": ["Education", "Charitable Donations: Education"],
        "charitable_organizations__children_well_being": ["Children's well-being", ""],
        "charitable_organizations__climate_change": ["Climate change", ""],
        "charitable_organizations__health_related_support": ["Disease/Health Related", ""],
        "charitable_organizations__healthcare_support": ["Healthcare/Hospital support", ""],
        "charitable_organizations__animal_welfare": ["Animal welfare", ""],
        "charitable_organizations__other": ["Other", "Charitable Donations: Other"],
        "charitable_organizations__none": ["None", "Charitable Donations: None"],
        "charitable_organizations__any": ["Any", "Charitable Donations: Any"],
        "personification__classy": ["Brand is: Classy", "Brand is: Classy"],
        "personification__bold": ["Brand is: Bold", "Brand is: Bold"],
        "personification__confident": ["Brand is: Confident", "Brand is: Confident"],
        "personification__corporate": ["Brand is: Corporate", "Brand is: Corporate"],
        "personification__customer_centric": ["Brand is: Customer-Centric", "Brand is: Customer-Centric"],
        "personification__dependable": ["Brand is: Dependable", "Brand is: Dependable"],
        "personification__energetic": ["Brand is: Energetic", "Brand is: Energetic"],
        "personification__fun": ["Brand is: Fun", "Brand is: Fun"],
        "personification__good_value": ["Brand is: Good Value", "Brand is: Good Value"],
        "personification__hip": ["Brand is: Hip", "Brand is: Hip"],
        "personification__innovative": ["Brand is: Innovative", "Brand is: Innovative"],
        "personification__intelligent": ["Brand is: Intelligent", "Brand is: Intelligent"],
        "personification__practical": ["Brand is: Practical", "Brand is: Practical"],
        "personification__premium": ["Brand is: Premium", "Brand is: Premium"],
        "personification__simple": ["Brand is: Simple", "Brand is: Simple"],
        "personification__smart": ["Brand is: Smart", "Brand is: Smart"],
        "personification__socially_conscious": ["Brand is: Socially Conscious", "Brand is: Socially Conscious"],
        "personification__sophisticated": ["Brand is: Sophisticated", "Brand is: Sophisticated"],
        "personification__stylish": ["Brand is: Stylish", "Brand is: Stylish"],
        "personification__traditional": ["Brand is: Traditional", "Brand is: Traditional"],
        "personification__trustworthy": ["Brand is: Trustworthy", "Brand is: Trustworthy"],
        "personification__unconventional": ["Brand is: Unconventional", "Brand is: Unconventional"],
        "personification__visionary": ["Brand is: Visionary", "Brand is: Visionary"],
        "personification__wholesome": ["Brand is: Wholesome", "Brand is: Wholesome"],
        "personification__young": ["Brand is: Young", "Brand is: Young"],
        "personification__brand_isnt_classy": ["Brand isn't: Classy", "Brand isn't: Classy"],
        "personification__brand_isnt_bold": ["Brand isn't: Bold", "Brand isn't: Bold"],
        "personification__brand_isnt_confident": ["Brand isn't: Confident", "Brand isn't: Confident"],
        "personification__brand_isnt_corporate": ["Brand isn't: Corporate", "Brand isn't: Corporate"],
        "personification__brand_isnt_customer_centric": ["Brand isn't: Customer-Centric", "Brand isn't: Customer-Centric"],
        "personification__brand_isnt_dependable": ["Brand isn't: Dependable", "Brand isn't: Dependable"],
        "personification__brand_isnt_energetic": ["Brand isn't: Energetic", "Brand isn't: Energetic"],
        "personification__brand_isnt_fun": ["Brand isn't: Fun", "Brand isn't: Fun"],
        "personification__brand_isnt_good_value": ["Brand isn't: Good Value", "Brand isn't: Good Value"],
        "personification__brand_isnt_hip": ["Brand isn't: Hip", "Brand isn't: Hip"],
        "personification__brand_isnt_innovative": ["Brand isn't: Innovative", "Brand isn't: Innovative"],
        "personification__brand_isnt_intelligent": ["Brand isn't: Intelligent", "Brand isn't: Intelligent"],
        "personification__brand_isnt_practical": ["Brand isn't: Practical", "Brand isn't: Practical"],
        "personification__brand_isnt_premium": ["Brand isn't: Premium", "Brand isn't: Premium"],
        "personification__brand_isnt_simple": ["Brand isn't: Simple", "Brand isn't: Simple"],
        "personification__brand_isnt_smart": ["Brand isn't: Smart", "Brand isn't: Smart"],
        "personification__brand_isnt_socially_conscious": ["Brand isn't: Socially Conscious", "Brand isn't: Socially Conscious"],
        "personification__brand_isnt_sophisticated": ["Brand isn't: Sophisticated", "Brand isn't: Sophisticated"],
        "personification__brand_isnt_stylish": ["Brand isn't: Stylish", "Brand isn't: Stylish"],
        "personification__brand_isnt_traditional": ["Brand isn't: Traditional", "Brand isn't: Traditional"],
        "personification__brand_isnt_trustworthy": ["Brand isn't: Trustworthy", "Brand isn't: Trustworthy"],
        "personification__brand_isnt_unconventional": ["Brand isn't: Unconventional", "Brand isn't: Unconventional"],
        "personification__brand_isnt_visionary": ["Brand isn't: Visionary", "Brand isn't: Visionary"],
        "personification__brand_isnt_wholesome": ["Brand isn't: Wholesome", "Brand isn't: Wholesome"],
        "personification__brand_isnt_young": ["Brand isn't: Young", "Brand isn't: Young"],
        "touchpoint__tv_advertising": ["Exposed: TV ad", "Exposed: TV ad"],
        "touchpoint__radio_advertising": ["Exposed: Radio ad", "Exposed: Radio ad"],
        "touchpoint__direct_mail_advertising": ["Exposed: Direct mail ad", "Exposed: Direct mail ad"],
        "touchpoint__print_advertising": ["Exposed: Print ad", " Exposed: Print ad"],
        "touchpoint__social_media_advertising": ["Exposed: Social media ad", "Exposed: Social media ad"],
        "touchpoint__digital_advertising": ["Exposed: Digital ad", "Exposed: Digital ad"],
        "touchpoint__podcast_advertising": ["Exposed: Podcast ad", "Exposed: Podcast ad"],
        "touchpoint__email_advertising": ["Exposed: Email ad", "Exposed: Email ad"],
        "touchpoint__blog_posts": ["Exposed: Blog posts", "Exposed: Blog posts"],
        "touchpoint__sponsored_events": ["Exposed: Sponsored events", "Exposed: Sponsored events"],
        "touchpoint__partnerships": ["Exposed: Partnerships", "Exposed: Partnerships"],
        "touchpoint__product_placement": ["Exposed: Product placement", "Exposed: Product placement"],
        "touchpoint__word_of_mouth": ["Exposed: Word of mouth", "Exposed: Word of mouth"],
        "touchpoint__celebrity_endorsements": ["Exposed: Celebrity endorsements", "Exposed: Celebrity endorsements"],
        "touchpoint__news_media": ["Exposed: News media", "Exposed: News media"],
        "touchpoint__in_store": ["Exposed: In Store", "Exposed: In Store"],
        "touchpoint__outdoor_advertising": ["Exposed: Outdoor Advertising", "Exposed: Outdoor Advertising"],
        "touchpoint__other": ["Exposed: Other", "Exposed: Other"],
        "touchpoint__unexposed_tv_advertising": ["Unexposed: TV ad", "Unexposed: TV ad"],
        "touchpoint__unexposed_radio_advertising": ["Unexposed: Radio ad", "Unexposed: Radio ad"],
        "touchpoint__unexposed_direct_mail_advertising": ["Unexposed: Direct mail ad", "Unexposed: Direct mail ad"],
        "touchpoint__unexposed_print_advertising": ["Unexposed: Print ad", "Unexposed: Print ad"],
        "touchpoint__unexposed_social_media_advertising": ["Unexposed: Social media ad", "Unexposed: Social media ad"],
        "touchpoint__unexposed_digital_advertising": ["Unexposed: Digital ad", "Unexposed: Digital ad"],
        "touchpoint__unexposed_podcast_advertising": ["Unexposed: Podcast ad", "Unexposed: Podcast ad"],
        "touchpoint__unexposed_email_advertising": ["Unexposed: Email ad", "Unexposed: Email ad"],
        "touchpoint__unexposed_blog_posts": ["Unexposed: Blog posts", "Unexposed: Blog posts"],
        "touchpoint__unexposed_sponsored_events": ["Unexposed: Sponsored events", "Unexposed: Sponsored events"],
        "touchpoint__unexposed_partnerships": ["Unexposed: Partnerships", "Unexposed: Partnerships"],
        "touchpoint__unexposed_product_placement": ["Unexposed: Product placement", "Unexposed: Product placement"],
        "touchpoint__unexposed_word_of_mouth": ["Unexposed: Word of mouth", "Unexposed: Word of mouth"],
        "touchpoint__unexposed_celebrity_endorsements": ["Unexposed: Celebrity endorsements", "Unexposed: Celebrity endorsements"],
        "touchpoint__unexposed_news_media": ["Unexposed: News media", "Unexposed: News media"],
        "touchpoint__unexposed_other": ["Unexposed: Other", "Unexposed: Other"],
        "touchpoint__unexposed_in_store": ["Unexposed: In Store", "Unexposed: In Store"],
        "touchpoint__unexposed_outdoor_advertising": ["Unexposed: Outdoor Advertising", "Unexposed: Outdoor Advertising"],
        "irreplaceability__i_would_be_thrilled__this_brand_underperforms_or_actually_offends_me": ["Thrilled", "Thrilled"],
        "irreplaceability__i_wouldn_t_mind_at_all__it_s_no_different_than_other_brands": ["Wouldn't mind", "Wouldn't mind"],
        "irreplaceability__i_would_miss_it__but_there_are_other_good_brands": ["Would miss it", "Would miss it"],
        "irreplaceability__i_would_be_unhappy__it_would_be_hard_to_replace": ["Would be unhappy", "Would be unhappy"],
        "irreplaceability__i_would_be_devastated__this_brand_is_irreplaceable": ["Would be devastated", "Would be devastated"],
        "irreplaceability__i_have_no_experience_with_these_types_of_products_or_services": ["No experience", "No experience"],
        "conversion_recommendation__detractor": ["Detractor", ""],
        "conversion_recommendation__passive": ["Passive", ""],
        "conversion_recommendation__promoter": ["Promoter", ""],
        "equity_momentum__is_holding_steady": ["Holding steady", ""],
        "equity_momentum__on_its_way_down": ["On way down", ""],
        "equity_momentum__on_its_way_up": ["On way up", ""],
        "equity_consideration__absolutely": ["Absolutely", ""],
        "equity_consideration__possibly": ["Possibly", ""],
        "equity_consideration__not_likely": ["Not Likely", ""],
        "equity_consideration__never": ["Never", ""],
        "state__north_carolina": ["North Carolina", ""],
        "state__indiana": ["Indiana", ""],
        "state__wyoming": ["Wyoming", ""],
        "state__utah": ["Utah", ""],
        "state__arizona": ["Arizona", ""],
        "state__montana": ["Montana", ""],
        "state__kentucky": ["Kentucky", ""],
        "state__kansas": ["Kansas", ""],
        "state__california": ["California", ""],
        "state__florida": ["Florida", ""],
        "state__delaware": ["Delaware", ""],
        "state__pennsylvania": ["Pennsylvania", ""],
        "state__mississippi": ["Mississippi", ""],
        "state__iowa": ["Iowa", ""],
        "state__non_states": ["Non-states", ""],
        "state__texas": ["Texas", ""],
        "state__illinois": ["Illinois", ""],
        "state__connecticut": ["Connecticut", ""],
        "state__georgia": ["Georgia", ""],
        "state__virginia": ["Virginia", ""],
        "state__maryland": ["Maryland", ""],
        "state__idaho": ["Idaho", ""],
        "state__vermont": ["Vermont", ""],
        "state__oregon": ["Oregon", ""],
        "state__tennessee": ["Tennessee", ""],
        "state__oklahoma": ["Oklahoma", ""],
        "state__maine": ["Maine", ""],
        "state__alabama": ["Alabama", ""],
        "state__arkansas": ["Arkansas", ""],
        "state__washington": ["Washington", ""],
        "state__south_carolina": ["South Carolina", ""],
        "state__nebraska": ["Nebraska", ""],
        "state__west_virginia": ["West Virginia", ""],
        "state__massachusetts": ["Massachusetts", ""],
        "state__colorado": ["Colorado", ""],
        "state__missouri": ["Missouri", ""],
        "state__alaska": ["Alaska", ""],
        "state__north_dakota": ["North Dakota", ""],
        "state__wisconsin": ["Wisconsin", ""],
        "state__nevada": ["Nevada", ""],
        "state__rhode_island": ["Rhode Island", ""],
        "state__new_york": ["New York", ""],
        "state__district_of_columbia": ["District of Columbia", ""],
        "state__south_dakota": ["South Dakota", ""],
        "state__hawaii": ["Hawaii", ""],
        "state__minnesota": ["Minnesota", ""],
        "state__new_jersey": ["New Jersey", ""],
        "state__michigan": ["Michigan", ""],
        "state__new_mexico": ["New Mexico", ""],
        "state__new_hampshire": ["New Hampshire", ""],
        "state__louisiana": ["Louisiana", ""],
        "state__ohio": ["Ohio", ""],
        "pay_premium__yes": ["Pay premium: yes", ""],
        "pay_premium__no": ["Pay premium: no", ""],
        "brands_products_used__mastercard": ["Mastercard",  ""],
        "brands_products_used__visa": ["Visa",  ""],
        "brands_products_used__american_express": ["American Express",  ""],
        "brands_products_used__instacart": ["Instacart",  ""],
        "brands_products_used__facebook": ["Facebook",  ""],
        "brands_products_used__instagram": ["Instagram",  ""],
        "brands_products_used__twitch": ["Twitch",  ""],
        "brands_products_used__snapchat": ["Snapchat",  ""],
        "brands_products_used__none": ["None",  ""],
        "brands_products_used__athleta": ["Athleta", ""],
        "brands_products_used__banana_republic": ["Banana Republic", ""],
        "brands_products_used__gap": ["Gap", ""],
        "brands_products_used__old_navy": ["Old Navy", ""],
        "social_used_30_days__twitter": ["Twitter",  ""],
        "social_used_30_days__facebook": ["Facebook",  ""],
        "social_used_30_days__instagram": ["Instagram",  ""],
        "social_used_30_days__snapchat": ["Snapchat",  ""],
        "social_used_30_days__tiktok": ["TikTok",  ""],
        "social_used_30_days__twitch": ["Twitch",  ""],
        "social_used_30_days__pinterest": ["Pinterest",  ""],
        "social_used_30_days__whatsapp": ["WhatsApp",  ""],
        "social_used_30_days__linkedin": ["LinkedIn",  ""],
        "social_used_30_days__reddit": ["Reddit",  ""],
        "social_used_30_days__clubhouse": ["Clubhouse",  ""],
        "social_used_30_days__youtube": ["YouTube",  ""],
        "social_used_30_days__none": ["None",  ""],
        "DMA__nc___charlotte": ["NC - Charlotte", ""],
        "DMA__nc___greensboro": ["NC - Greensboro", ""],
        "DMA__nc___greenville": ["NC - Greenville", ""],
        "DMA__nc___raleigh_durham": ["NC - Raleigh-Durham", ""],
        "DMA__nc___wilmington": ["NC - Wilmington", ""],
        "DMA__nd___fargo_moorhead": ["ND - Fargo-Moorhead", ""],
        "DMA__nd___minot_bismarck": ["ND - Minot-Bismarck", ""],
        "DMA__ne___lincoln_hastings_kear_": ["NE - Lincoln-Hastings-Kear.", ""],
        "DMA__ne___north_platte": ["NE - North Platte", ""],
        "DMA__ne___omaha": ["NE - Omaha", ""],
        "DMA__nm___albuquerque_santa_fe": ["NM - Albuquerque-Santa Fe", ""],
        "DMA__nv___las_vegas": ["NV - Las Vegas", ""],
        "DMA__nv___reno": ["NV - Reno", ""],
        "DMA__ny___albany": ["NY - Albany", ""],
        "DMA__ny___binghamton": ["NY - Binghamton", ""],
        "DMA__ny___buffalo": ["NY - Buffalo", ""],
        "DMA__ny___elmira": ["NY - Elmira", ""],
        "DMA__ny___new_york": ["NY - New York", ""],
        "DMA__ny___rochester__ny": ["NY - Rochester, NY", ""],
        "DMA__ny___syracuse": ["NY - Syracuse", ""],
        "DMA__ny___utica": ["NY - Utica", ""],
        "DMA__ny___watertown": ["NY - Watertown", ""],
        "DMA__oh___cincinnati": ["OH - Cincinnati", ""],
        "DMA__oh___cleveland_akron": ["OH - Cleveland-Akron", ""],
        "DMA__oh___columbus__oh": ["OH - Columbus, OH", ""],
        "DMA__oh___dayton": ["OH - Dayton", ""],
        "DMA__oh___lima": ["OH - Lima", ""],
        "DMA__oh___toledo": ["OH - Toledo", ""],
        "DMA__oh___youngstown": ["OH - Youngstown", ""],
        "DMA__oh___zanesville": ["OH - Zanesville", ""],
        "DMA__ok___oklahoma_city": ["OK - Oklahoma City", ""],
        "DMA__ok___tulsa": ["OK - Tulsa", ""],
        "DMA__or___bend__or": ["OR - Bend, OR", ""],
        "DMA__or___eugene": ["OR - Eugene", ""],
        "DMA__or___medford": ["OR - Medford", ""],
        "DMA__or___portland__or": ["OR - Portland, OR", ""],
        "DMA__pa___erie": ["PA - Erie", ""],
        "DMA__pa___harrisburg": ["PA - Harrisburg", ""],
        "DMA__pa___johnstown": ["PA - Johnstown", ""],
        "DMA__pa___philadelphia": ["PA - Philadelphia", ""],
        "DMA__pa___pittsburgh": ["PA - Pittsburgh", ""],
        "DMA__pa___wilkes_barre": ["PA - Wilkes-Barre", ""],
        "DMA__ri___providence": ["RI - Providence", ""],
        "DMA__sc___charleston__sc": ["SC - Charleston, SC", ""],
        "DMA__sc___columbia__sc": ["SC - Columbia, SC", ""],
        "DMA__sc___greenville_spartan_": ["SC - Greenville-Spartan.", ""],
        "DMA__sc___myrtle_beach": ["SC - Myrtle Beach", ""],
        "DMA__sd___rapid_city": ["SD - Rapid City", ""],
        "DMA__sd___sioux_falls": ["SD - Sioux Falls", ""],
        "DMA__tn___chattanooga": ["TN - Chattanooga", ""],
        "DMA__tn___jackson__tn": ["TN - Jackson, TN", ""],
        "DMA__tn___knoxville": ["TN - Knoxville", ""],
        "DMA__tn___memphis": ["TN - Memphis", ""],
        "DMA__tn___nashville": ["TN - Nashville", ""],
        "DMA__tn___tri_cities": ["TN - Tri-Cities", ""],
        "DMA__tx___abilene_sweetwater": ["TX - Abilene-Sweetwater", ""],
        "DMA__tx___amarillo": ["TX - Amarillo", ""],
        "DMA__tx___austin": ["TX - Austin", ""],
        "DMA__tx___beaumont_port_arthur": ["TX - Beaumont-Port Arthur", ""],
        "DMA__tx___corpus_christi": ["TX - Corpus Christi", ""],
        "DMA__tx___dallas_fort_worth": ["TX - Dallas-Fort Worth", ""],
        "DMA__tx___el_paso": ["TX - El Paso", ""],
        "DMA__tx___harlingen_weslaco": ["TX - Harlingen-Weslaco", ""],
        "DMA__tx___houston": ["TX - Houston", ""],
        "DMA__tx___laredo": ["TX - Laredo", ""],
        "DMA__tx___lubbock": ["TX - Lubbock", ""],
        "DMA__tx___odessa_midland": ["TX - Odessa-Midland", ""],
        "DMA__tx___san_angelo": ["TX - San Angelo", ""],
        "DMA__tx___san_antonio": ["TX - San Antonio", ""],
        "DMA__tx___sherman_ada": ["TX - Sherman-Ada", ""],
        "DMA__tx___tyler_longview": ["TX - Tyler-Longview", ""],
        "DMA__tx___victoria": ["TX - Victoria", ""],
        "DMA__tx___waco_temple_bryan": ["TX - Waco-Temple-Bryan", ""],
        "DMA__tx___wichita_falls_lawton": ["TX - Wichita Falls-Lawton", ""],
        "DMA__ut___salt_lake_city": ["UT - Salt Lake City", ""],
        "DMA__va___charlottesville": ["VA - Charlottesville", ""],
        "DMA__va___harrisonburg": ["VA - Harrisonburg", ""],
        "DMA__va___norfolk": ["VA - Norfolk", ""],
        "DMA__va___richmond_petersburg": ["VA - Richmond-Petersburg", ""],
        "DMA__va___roanoke_lynchburg": ["VA - Roanoke-Lynchburg", ""],
        "DMA__vt___burlington": ["VT - Burlington", ""],
        "DMA__wa___seattle_tacoma": ["WA - Seattle-Tacoma", ""],
        "DMA__wa___spokane": ["WA - Spokane", ""],
        "DMA__wa___yakima_pasco": ["WA - Yakima-Pasco", ""],
        "DMA__wi___green_bay_appleton": ["WI - Green Bay-Appleton", ""],
        "DMA__wi___la_crosse_eau_claire": ["WI - La Crosse-Eau Claire", ""],
        "DMA__wi___madison": ["WI - Madison", ""],
        "DMA__wi___milwaukee": ["WI - Milwaukee", ""],
        "DMA__wi___wausau_rhinelander": ["WI - Wausau-Rhinelander", ""],
        "DMA__wv___bluefield": ["WV - Bluefield", ""],
        "DMA__wv___charleston": ["WV - Charleston", ""],
        "DMA__wv___clarksburg_weston": ["WV - Clarksburg-Weston", ""],
        "DMA__wv___parkersburg": ["WV - Parkersburg", ""],
        "DMA__wv___wheeling": ["WV - Wheeling", ""],
        "DMA__wy___casper_riverton": ["WY - Casper-Riverton", ""],
        "DMA__wy___cheyenne_scottsbluff": ["WY - Cheyenne-Scottsbluff", ""],
        "DMA__other": ["Other", ""],
        "DMA__ak___anchorage": ["AK - Anchorage", ""],
        "DMA__ak___fairbanks": ["AK - Fairbanks", ""],
        "DMA__ak___juneau": ["AK - Juneau", ""],
        "DMA__al___birmingham": ["AL - Birmingham", ""],
        "DMA__al___dothan": ["AL - Dothan", ""],
        "DMA__al___huntsville_decatur": ["AL - Huntsville-Decatur", ""],
        "DMA__al___mobile_pensacola": ["AL - Mobile-Pensacola", ""],
        "DMA__al___montgomery_selma": ["AL - Montgomery-Selma", ""],
        "DMA__ar___fort_smith": ["AR - Fort Smith", ""],
        "DMA__ar___jonesboro": ["AR - Jonesboro", ""],
        "DMA__ar___little_rock": ["AR - Little Rock", ""],
        "DMA__az___phoenix": ["AZ - Phoenix", ""],
        "DMA__az___tucson": ["AZ - Tucson", ""],
        "DMA__az___yuma_el_centro__ca": ["AZ - Yuma-El Centro, CA", ""],
        "DMA__ca___bakersfield": ["CA - Bakersfield", ""],
        "DMA__ca___chico_redding": ["CA - Chico-Redding", ""],
        "DMA__ca___eureka": ["CA - Eureka", ""],
        "DMA__ca___fresno_visalia": ["CA - Fresno-Visalia", ""],
        "DMA__ca___los_angeles": ["CA - Los Angeles", ""],
        "DMA__ca___monterey_salinas": ["CA - Monterey-Salinas", ""],
        "DMA__ca___palm_springs": ["CA - Palm Springs", ""],
        "DMA__ca___sacramento": ["CA - Sacramento", ""],
        "DMA__ca___san_diego": ["CA - San Diego", ""],
        "DMA__ca___santa_barbara": ["CA - Santa Barbara", ""],
        "DMA__ca___sf_oak_sj": ["CA - SF-OAK-SJ", ""],
        "DMA__co___colorado_springs": ["CO - Colorado Springs", ""],
        "DMA__co___denver": ["CO - Denver", ""],
        "DMA__co___grand_junction": ["CO - Grand Junction", ""],
        "DMA__ct___hartford_new_haven": ["CT - Hartford-New Haven", ""],
        "DMA__dc___washington": ["DC - Washington", ""],
        "DMA__fl___fort_myers_naples": ["FL - Fort Myers-Naples", ""],
        "DMA__fl___gainesville": ["FL - Gainesville", ""],
        "DMA__fl___jacksonville": ["FL - Jacksonville", ""],
        "DMA__fl___miami_ft__lauderdale": ["FL - Miami-Ft. Lauderdale", ""],
        "DMA__fl___orlando": ["FL - Orlando", ""],
        "DMA__fl___panama_city": ["FL - Panama City", ""],
        "DMA__fl___tallahassee": ["FL - Tallahassee", ""],
        "DMA__fl___tampa": ["FL - Tampa", ""],
        "DMA__fl___west_palm_beach": ["FL - West Palm Beach", ""],
        "DMA__ga___albany__ga": ["GA - Albany, GA", ""],
        "DMA__ga___atlanta": ["GA - Atlanta", ""],
        "DMA__ga___augusta_aiken": ["GA - Augusta-Aiken", ""],
        "DMA__ga___columbus__ga": ["GA - Columbus, GA", ""],
        "DMA__ga___macon": ["GA - Macon", ""],
        "DMA__ga___savannah": ["GA - Savannah", ""],
        "DMA__hi___honolulu": ["HI - Honolulu", ""],
        "DMA__ia___cedar_rapids_water_": ["IA - Cedar Rapids-Water.", ""],
        "DMA__ia___davenport_rock_isl_": ["IA - Davenport-Rock Isl.", ""],
        "DMA__ia___des_moines_ames": ["IA - Des Moines-Ames", ""],
        "DMA__ia___ottumwa_kirksville": ["IA - Ottumwa-Kirksville", ""],
        "DMA__ia___sioux_city": ["IA - Sioux City", ""],
        "DMA__id___boise": ["ID - Boise", ""],
        "DMA__id___idaho_falls": ["ID - Idaho Falls", ""],
        "DMA__id___twin_falls": ["ID - Twin Falls", ""],
        "DMA__il___champaign_spring_": ["IL - Champaign-Spring.", ""],
        "DMA__il___chicago": ["IL - Chicago", ""],
        "DMA__il___peoria_bloomington": ["IL - Peoria-Bloomington", ""],
        "DMA__il___quincy_hannibal": ["IL - Quincy-Hannibal", ""],
        "DMA__il___rockford": ["IL - Rockford", ""],
        "DMA__in___evansville": ["IN - Evansville", ""],
        "DMA__in___fort_wayne": ["IN - Fort Wayne", ""],
        "DMA__in___indianapolis": ["IN - Indianapolis", ""],
        "DMA__in___lafayette__in": ["IN - Lafayette, IN", ""],
        "DMA__in___south_bend_elkhart": ["IN - South Bend-Elkhart", ""],
        "DMA__in___terre_haute": ["IN - Terre Haute", ""],
        "DMA__ks___topeka": ["KS - Topeka", ""],
        "DMA__ks___wichita_hutchinson": ["KS - Wichita-Hutchinson", ""],
        "DMA__ky___bowling_green": ["KY - Bowling Green", ""],
        "DMA__ky___lexington": ["KY - Lexington", ""],
        "DMA__ky___louisville": ["KY - Louisville", ""],
        "DMA__ky___paducah_cape_gir_": ["KY - Paducah-Cape Gir", ""],
        "DMA__la___alexandria__la": ["LA - Alexandria, LA", ""],
        "DMA__la___baton_rouge": ["LA - Baton Rouge", ""],
        "DMA__la___lafayette__la": ["LA - Lafayette, LA", ""],
        "DMA__la___lake_charles": ["LA - Lake Charles", ""],
        "DMA__la___monroe_el_dorado": ["LA - Monroe-El Dorado", ""],
        "DMA__la___new_orleans": ["LA - New Orleans", ""],
        "DMA__la___shreveport": ["LA - Shreveport", ""],
        "DMA__ma___boston": ["MA - Boston", ""],
        "DMA__ma___springfield_holyoke": ["MA - Springfield-Holyoke", ""],
        "DMA__md___baltimore": ["MD - Baltimore", ""],
        "DMA__md___salisbury": ["MD - Salisbury", ""],
        "DMA__me___bangor": ["ME - Bangor", ""],
        "DMA__me___portland_auburn": ["ME - Portland-Auburn", ""],
        "DMA__me___presque_isle": ["ME - Presque Isle", ""],
        "DMA__mi___alpena": ["MI - Alpena", ""],
        "DMA__mi___detroit": ["MI - Detroit", ""],
        "DMA__mi___flint": ["MI - Flint", ""],
        "DMA__mi___grand_rapids": ["MI - Grand Rapids", ""],
        "DMA__mi___lansing": ["MI - Lansing", ""],
        "DMA__mi___marquette": ["MI - Marquette", ""],
        "DMA__mi___traverse_city": ["MI - Traverse City", ""],
        "DMA__mn___duluth_superior__wi": ["MN - Duluth-Superior, WI", ""],
        "DMA__mn___mankato": ["MN - Mankato", ""],
        "DMA__mn___minneapolis_st__paul": ["MN - Minneapolis-St. Paul", ""],
        "DMA__mn___rochester_mason_city": ["MN - Rochester-Mason City", ""],
        "DMA__mo___columbia_jefferson": ["MO - Columbia-Jefferson", ""],
        "DMA__mo___joplin_pittsburg": ["MO - Joplin-Pittsburg", ""],
        "DMA__mo___kansas_city": ["MO - Kansas City", ""],
        "DMA__mo___springfield__mo": ["MO - Springfield, MO", ""],
        "DMA__mo___st__joseph": ["MO - St. Joseph", ""],
        "DMA__mo___st__louis": ["MO - St. Louis", ""],
        "DMA__ms___biloxi_gulfport": ["MS - Biloxi-Gulfport", ""],
        "DMA__ms___columbus_tupelo": ["MS - Columbus-Tupelo", ""],
        "DMA__ms___greenwood_greenville": ["MS - Greenwood-Greenville", ""],
        "DMA__ms___hattiesburg_laurel": ["MS - Hattiesburg-Laurel", ""],
        "DMA__ms___jackson__ms": ["MS - Jackson, MS", ""],
        "DMA__ms___meridian": ["MS - Meridian", ""],
        "DMA__mt___billings": ["MT - Billings", ""],
        "DMA__mt___butte_bozeman": ["MT - Butte-Bozeman", ""],
        "DMA__mt___glendive": ["MT - Glendive", ""],
        "DMA__mt___great_falls": ["MT - Great Falls", ""],
        "DMA__mt___helena": ["MT - Helena", ""],
        "DMA__mt___missoula": ["MT - Missoula", ""],
        "mosaic_segment__power_elite": ["Power Elite", "Power Elite"],
        "mosaic_segment__flourishing_families": ["Flourishing Families", "Flourishing Families"],
        "mosaic_segment__boom__w__confidence": ["Boom. w/ Confidence", "Boom. w/ Confidence"],
        "mosaic_segment__suburban_style": ["Suburban Style", "Suburban Style"],
        "mosaic_segment__thriving_boomers": ["Thriving Boomers", "Thriving Boomers"],
        "mosaic_segment__promising_families": ["Promising Families", "Promising Families"],
        "mosaic_segment__young__city_solos": ["Young, City Solos", "Young, City Solos"],
        "mosaic_segment__mid_class_melt__pot": ["Mid-class Melt. Pot", "Mid-class Melt. Pot"],
        "mosaic_segment__family_union": ["Family Union", "Family Union"],
        "mosaic_segment__autumn_years": ["Autumn Years", "Autumn Years"],
        "mosaic_segment__significant_singles": ["Significant Singles", "Significant Singles"],
        "mosaic_segment__blue_sky_boomers": ["Blue Sky Boomers", "Blue Sky Boomers"],
        "mosaic_segment__families_in_motion": ["Families In Motion", "Families In Motion"],
        "mosaic_segment__pastoral_pride": ["Pastoral Pride", "Pastoral Pride"],
        "mosaic_segment__singles_and_starters": ["Singles and Starters", "Singles and Starters"],
        "mosaic_segment__cultural_connections": ["Cultural Connections", "Cultural Connections"],
        "mosaic_segment__golden_year_guardians": ["Golden Year Guardians", "Golden Year Guardians"],
        "mosaic_segment__aspirational_fusion": ["Aspirational Fusion", "Aspirational Fusion"],
        "mosaic_segment__economic_challenges": ["Economic Challenges", "Economic Challenges"],
        "mosaic_segment__n_a": ["N/A", "N/A"],
        "device_to_watch_soccer__tv": ["TV", "TV"],
        "device_to_watch_soccer__streaming_box": ["Streaming Box", "Streaming Box"],
        "device_to_watch_soccer__mobile_phone": ["Mobile Phone", "Mobile Phone"],
        "device_to_watch_soccer__other_device": ["Other device", "Other device"],
        "device_to_watch_soccer__do_not_watch_soccer": ["Do not watch soccer", "Do not watch soccer"],
        "sports_fanhood__nba": ["NBA", "NBA"],
        "sports_fanhood__nfl": ["NFL", "NFL"],
        "sports_fanhood__e_sports": ["eSports", "eSports"],
        "sports_fanhood__nhl": ["NHL", "NHL"],
        "sports_fanhood__wnba": ["WNBA", "WNBA"],
        "sports_fanhood__pga_tour": ["PGA Tour", "PGA Tour"],
        "sports_fanhood__atp_tour": ["ATP Tour", "ATP Tour"],
        "sports_fanhood__wta_tour": ["WTA Tour", "WTA Tour"],
        "sports_fanhood__mlb": ["MLB", "MLB"],
        "sports_fanhood__epl_mls_soccer": ["EPL/MLS/Soccer", "EPL/MLS/Soccer"],
        "sports_fanhood__ncaa_football": ["NCAA Football", "NCAA Football"],
        "sports_fanhood__ncaa_basketball": ["NCAA Basketball", "NCAA Basketball"],
        "sports_fanhood__nascar_indycar_racing": ["NASCAR/IndyCar/Racing", "NASCAR/IndyCar/Racing"],
        "sports_fanhood__mma": ["MMA", "MMA"],
        "sports_fanhood__wba_ibf_boxing": ["WBA/IBF/Boxing", "WBA/IBF/Boxing"],
        "sports_fanhood__none": ["None", "None"],
        "job_websites_browsed__monster": ["Monster", "Monster"],
        "job_websites_browsed__indeed": ["Indeed", "Indeed"],
        "job_websites_browsed__career_builder": ["CareerBuilder", "CareerBuilder"],
        "job_websites_browsed__randstad": ["Randstad", "Randstad"],
        "job_websites_browsed__linked_in": ["LinkedIn", "LinkedIn"],
        "job_websites_browsed__ziprecruiter": ["Ziprecruiter", "Ziprecruiter"],
        "job_websites_browsed__glassdoor": ["Glassdoor", "Glassdoor"],
        "job_websites_browsed__google_for_jobs": ["Google for Jobs", "Google for Jobs"],
        "job_websites_browsed__military": ["Military", "Military"],
        "job_websites_browsed__none": ["None", "None"],
        "financial_products_own_use__checking_or_savings_account": ["Checking or savings account", "Checking or savings account"],
        "financial_products_own_use__ira": ["IRA (Individual Retirement Account)", "IRA (Individual Retirement Account)"],
        "financial_products_own_use__employer_sponsored_retirement_savings_plan": ["Employer sponsored retirement savings plan", "Employer sponsored retirement savings plan"],
        "financial_products_own_use__investment_account": ["Investment account(s)", "Investment account(s)"],
        "financial_products_own_use__brokerage_services": ["Brokerage services", "Brokerage services"],
        "financial_products_own_use__college_savings_account": ["College savings account", "College savings account"],
        "financial_products_own_use__health_savings_account": ["Health savings account", "Health savings account"],
        "financial_products_own_use__mutual_funds": ["Mutual funds", "Mutual funds"],
        "financial_products_own_use__life_insurance": ["Life insurance", "Life insurance"],
        "financial_products_own_use__annuity": ["Annuity", "Annuity"],
        "financial_products_own_use__credit_charge_card": ["Credit / charge card", "Credit / charge card"],
        "financial_products_own_use__home_mortgage": ["Home mortgage", "Home mortgage"],
        "financial_products_own_use__none": ["None", "None"],
        "geography__geography_national": ["National", "National"],
        "geography__geography_north_central": ["North Central", "North Central"],
        "geography__geography_north_east": ["North East", "North East"],
        "geography__geography_north_west": ["North West", "North West"],
        "geography__geography_south_central": ["South Central", "South Central"],
        "geography__geography_south_east": ["South East", "South East"],
        "geography__geography_west": ["West", "West"],
        "segments__difm_decision_maker": ["DIFM Decision Maker", "DIFM Decision Maker"],
        "segments__ready_setters": ["Ready Setters", "Ready Setters"],
        "segments__next_gen": ["Next Gen", "Next Gen"],
        "sugar_consumption_food_drink__seek_out_sugar": ["Seek out sugar", "Seek out sugar"],
        "sugar_consumption_food_drink__sometimes_seek_out_sugar": ["Sometimes seek out sugar", "Sometimes seek out sugar"],
        "sugar_consumption_food_drink__neither_seek_nor_avoid_sugar": ["Neither seek nor avoid sugar", "Neither seek nor avoid sugar"],
        "sugar_consumption_food_drink__actively_limit_sugar_intake": ["Actively limit sugar intake", "Actively limit sugar intake"],
        "sugar_consumption_food_drink__completely_avoid_sugar": ["Completely avoid sugar", "Completely avoid sugar"],
        "substances_consumed__cannabis": ["Cannabis", "Cannabis"],
        "substances_consumed__cannabidiol": ["CBD (cannabidiol)", "CBD (cannabidiol)"],
        "substances_consumed__spirits": ["Spirits", "Spirits"],
        "substances_consumed__beer": ["Beer", "Beer"],
        "substances_consumed__cigarettes": ["Cigarettes", "Cigarettes"],
        "substances_consumed__e_cigarettes": ["E-cigarettes", "E-cigarettes"],
        "substances_consumed__wine": ["Wine", "Wine"],
        "substances_consumed__none": ["None", "None"],
        "own_timeshare__total_own_timeshares": ["Total Own timeshares", "Total Own timeshares"],
        "own_timeshare__owners_by_transfers_only": ["Owners By Transfers Only", "Owners By Transfers Only"],
        "own_timeshare__owners_with_no_deeds": ["Owners with No Deeds", "Owners with No Deeds"],
        "own_timeshare__owners_with_deeds": ["Owners with Deeds", "Owners with Deeds"],
        "own_timeshare__total_non_owners": ["Total Non-Owners", "Total Non-Owners"],
        "own_timeshare__other_family_only": ["Other Family Only", "Other Family Only"],
        "own_timeshare__none": ["None", "None"],
        "regional_bank_customers__regions_bank": ["Regions Bank", "Regions Bank"],
        "regional_bank_customers__citizens_bank": ["Citizens Bank", "Citizens Bank"],
        "regional_bank_customers__us_bank": ["US Bank", "US Bank"],
        "regional_bank_customers__zions_bank": ["Zions Bank", "Zions Bank"],
        "regional_bank_customers__union_bank": ["Union Bank", "Union Bank"],
        "regional_bank_customers__truist_bank": ["Truist Bank", "Truist Bank"],
        "regional_bank_customers__none": ["None of these", "None of these"],
        "dine_out_restaurants__award_winning": ["Award winning restaurants", "Award winning restaurants"],
        "dine_out_restaurants__top_tier": ["Top-tier / fine dining", "Top-tier / fine dining"],
        "dine_out_restaurants__trendy": ["Trendy / hot and new", "Trendy / hot and new"],
        "dine_out_restaurants__casual": ["Casual dining", "Casual dining"],
        "dine_out_restaurants__fast_food": ["Fast food", "Fast food"],
        "dine_out_restaurants__other": ["Other", "Other"],
        "dine_out_restaurants__none": ["Don’t know / don’t dine out", "Don’t know / don’t dine out"],
        "has_best_restaurants__opentable": ["OpenTable", "OpenTable"],
        "has_best_restaurants__yelp": ["Yelp", "Yelp"],
        "has_best_restaurants__sevenrooms": ["SevenRooms", "SevenRooms"],
        "has_best_restaurants__resy": ["Resy", "Resy"],
        "has_best_restaurants__tock": ["Tock", "Tock"],
        "has_best_restaurants__tripadvisor": ["TripAdvisor", "TripAdvisor"],
        "has_best_restaurants__other": ["Other", "Other"],
        "has_best_restaurants__don_t_use_online_booking": ["Don’t use online booking", "Don’t use online booking"],
        "love_like__american_heart_association": ["American Heart Association", "American Heart Association"],
        "love_like__american_cancer_society": ["American Cancer Society", "American Cancer Society"],
        "love_like__american_red_cross": ["American Red Cross", "American Red Cross"],
        "love_like__st_judes": ["St. Jude’s", "St. Jude’s"],
        "love_like__susan_g_komen": ["Susan G. Komen", "Susan G. Komen"],
        "love_like__american_diabetes_association": ["American Diabetes Association", "American Diabetes Association"],
        "perfect_fit__american_heart_association": ["American Heart Association", "American Heart Association"],
        "perfect_fit__american_cancer_society": ["American Cancer Society", "American Cancer Society"],
        "perfect_fit__american_red_cross": ["American Red Cross", "American Red Cross"],
        "perfect_fit__st_judes": ["St. Jude’s", "St. Jude’s"],
        "perfect_fit__susan_g_komen": ["Susan G. Komen", "Susan G. Komen"],
        "perfect_fit__american_diabetes_association": ["American Diabetes Association", "American Diabetes Association"],
        "homelive_type__single_family_detached_home": ["Single-family detached home", "Single-family detached home"],
        "homelive_type__apartment___condo": ["Apartment / condo", "Apartment / condo"],
        "homelive_type__townhouse": ["Townhouse", "Townhouse"],
        "homelive_type__other": ["Other", "Other"],
        "homelive_ownrent__live_in_property_i_own": ["Live in property I own", "Live in property I own"],
        "homelive_ownrent__live_in_property_i_rent": ["Live in property I rent", "Live in property I rent"],
        "homelive_ownrent__other": ["Other", "Other"],
    }

    static variableOptionLabels = {
        "generation__gen_z__years___1997_to_2004_": ["Gen Z", "Gen Z"],
        "generation__millennials__years___1981_to_1996_": ["Millennials", "Millennials"],
        "generation__gen_x__years___1965_to_1980_": ["Gen X", "Gen X"],
        "generation__boomers__years___1946_to_1964_": ["Baby Boomers", "Baby Boomers"],
        "generation__greatest__years___1927_or_earlier_": ["Greatest", "Greatest"],
        "generation__silent__years___1928_to_1945_": ["Silent", "Silent"],
        "gender__male": ["Male", "Male"],
        "gender__female": ["Female", "Female"],
        "gender__transgender_other": ["Transgender/Other", "Transgender/Other"],
        "ethnicity__white": ["White", "White"],
        "ethnicity__black": ["Black", "Black"],
        "ethnicity__asian": ["Asian", "Asian"],
        "ethnicity__hispanic": ["Hispanic", "Hispanic"],
        "ethnicity__other": ["Other", "Other"],
        "region_us__northeast": ["Northeast", "Northeast"],
        "region_us__midwest": ["Midwest", "Midwest"],
        "region_us__south": ["South", "South"],
        "region_us__west": ["West", "West"],
        "region_us__non_us_state": ["Non-US State", "Non-US State"],
        "population_density__in_an_urban_or_city_area": ["Urban", "Urban"],
        "population_density__in_a_suburban_area_next_to_a_city": ["Suburban", "Suburban"],
        "population_density__in_a_small_town_or_rural_area": ["Rural", "Rural"],
        "education_us__less_than_high_school_degree": ["< HS Degree", "< HS Degree"],
        "education_us__high_school_degree": ["High School Degree", "High School Degree"],
        "education_us__college_degree": ["College Degree", "College Degree"],
        "education_us__graduate_degree": ["Graduate Degree", "Graduate Degree"],
        "employment__employed__ft__pt_or_self_": ["Employed", "Employed"],
        "employment__not_employed": ["Not Employed", "Not Employed"],
        "household_income_us__less_than__15_000": ["Less than $15,000", "Less than $15,000"],
        "household_income_us___15_000__24_999": ["$15,000 to $24,999", "$15,000 to $24,999"],
        "household_income_us___25_000__34_999": ["$25,000 to $34,999", "$25,000 to $34,999"],
        "household_income_us___35_000__49_999": ["$35,000 to $49,999", "$35,000 to $49,999"],
        "household_income_us___50_000__74_999": ["$50,000 to $74,999", "$50,000 to $74,999"],
        "household_income_us___75_000__99_999": ["$75,000 to $99,999", "$75,000 to $99,999"],
        "household_income_us___100_000_or_more": ["$100,000+", "$100,000+"],
        "marital_status__never_married": ["Never Married", "Never Married"],
        "marital_status__married_living_with_partner": ["Married", "Married"],
        "marital_status__divorced_separated_widowed": ["Previously Married", "Previously Married"],
        "sexuality__hetero": ["Heterosexual", "Heterosexual"],
        "sexuality__lgbt": ["LGBTQ", "LGBTQ"],
        "household_size__1": ["1", "1"],
        "household_size__2": ["2", "2"],
        "household_size__3": ["3", "3"],
        "household_size__4": ["4", "4"],
        "household_size__5_": ["5+", "5+"],
        "household_composition__baby": ["Baby", "Household Composition: Baby"],
        "household_composition__toddler": ["Toddler", "Household Composition: Toddler"],
        "household_composition__pre_schooler": ["Pre Schooler", "Household Composition: Pre-schooler"],
        "household_composition__grade_schooler": ["Grade Schooler", "Household Composition: Grade-schooler"],
        "household_composition__teen": ["Teenager", "Household Composition: Teenager"],
        "household_composition__young_adult": ["Young Adult", "Household Composition: Young Adult"],
        "household_composition__older_adult": ["Older Adult", "Household Composition: Older Adult"],
        "household_composition__senior": ["Senior", "Household Composition: Senior"],
        "political_party__conservative": ["Conservative", "Conservative"],
        "political_party__moderate": ["Moderate", "Moderate"],
        "political_party__liberal": ["Liberal", "Liberal"],
        "shopping_channel__mobile_first": ["Mobile-First", "Mobile-First"],
        "shopping_channel__online_first": ["Online-First", "Online-First"],
        "shopping_channel__instore_first": ["In-store First", "In-store First"],
        "shopping_channel__mixed": ["Mixed", "Mixed"],
        "media_consumers__am_radio": ["AM Radio", "AM Radio"],
        "media_consumers__fm_radio": ["FM Radio", "FM Radio"],
        "media_consumers__satellite_radio": ["Satellite Radio", "Satellite Radio"],
        "media_consumers__streaming_audio___music": ["Streaming Audio", "Streaming Audio"],
        "media_consumers__podcasts": ["Podcasts", "Podcasts"],
        "media_consumers__live_broadcast_television": ["Live Broadcast TV", "Live Broadcast TV"],
        "media_consumers__live_cable_television": ["Live Cable TV", "Live Cable TV"],
        "media_consumers__live_sports_broadcasts": ["Live Sports Video", "Live Sports Video"],
        "media_consumers__dvr___on_demand": ["DVR/On Demand", "DVR/On Demand"],
        "media_consumers__streaming_over_the_internet_television_or_movies__e_g___netflix__hulu__hbo_go_": ["Streaming Video", "Streaming Video"],
        "media_consumers__internet_video_clips": ["Video Clips online", "Video Clips online"],
        "media_consumers__movies_in_theater": ["Movies in Theater", "Movies in Theater"],
        "media_consumers__social_media": ["Social Media", "Social Media"],
        "tech_status__trend_setter": ["Trend Setter", "Trend Setter"],
        "tech_status__early_adopter": ["Early Adopter", "Early Adopter"],
        "tech_status__wait_and_see": ["Wait and See", "Wait and See"],
        "tech_status__laggard": ["Laggard", "Laggard"],
        "pet_ownership__none": ["Pet Ownership: None", "Pet Ownership: None"],
        "pet_ownership__dog": ["Pet Ownership: Dog", "Pet Ownership: Dog"],
        "pet_ownership__cat": ["Pet Ownership: Cat", "Pet Ownership: Cat"],
        "pet_ownership__other": ["Pet Ownership: Other", "Pet Ownership: Other"],
        "cat_litter_users__cat_litter_users": ["Cat Litter Users", "Cat Litter Users"],
        "ownership_usage__video_game_console": ["Video Game Console", "Owner/Subscriber: Video Game Console"],
        "ownership_usage__digital_camera": ["Digital Camera", "Owner/Subscriber: Digital Camera"],
        "ownership_usage__landline_telephone": ["Landline", "Owner/Subscriber: Landline"],
        "ownership_usage__cable_tv": ["Cable TV", "Owner/Subscriber: Cable TV"],
        "ownership_usage__satellite_tv": ["Satellite TV", "Owner/Subscriber: Satellite TV"],
        "ownership_usage__broadband_internet": ["Hi-speed Internet", "Owner/Subscriber: Hi-speed Internet"],
        "ownership_usage__home_cinema": ["Home Cinema", "Owner/Subscriber: Home Cinema"],
        "ownership_usage__digital_media_receiver": ["Digital Media Player", "Owner/Subscriber: Digital Media Player"],
        "ownership_usage__internet_video_services": ["Video Subscription", "Owner/Subscriber: Video Subscription"],
        "ownership_usage__tablet": ["Tablet", "Owner/Subscriber: Tablet"],
        "ownership_usage__digital_video_recorder": ["DVR", "Owner/Subscriber: DVR"],
        "ownership_usage__smartphone": ["Smartphone", "Owner/Subscriber: Smartphone"],
        "ownership_usage__internet_radio": ["Internet Radio", "Owner/Subscriber: Internet Radio"],
        "ownership_usage__vehicle": ["Vehicle", "Owner/Subscriber: Vehicle"],
        "ownership_usage__voice_assistant": ["Smart Speaker", "Owner/Subscriber: Smart Speaker"],
        "ownership_usage__motorcycle": ["Motorcycle", "Owner/Subscriber: Motorcycle"],
        "traveler_type__flies": ["All", "Flew last year: Overall",],
        "traveler_type__business": ["Business", "Flew last year: Business"],
        "traveler_type__leisure": ["Leisure", "Flew last year: Leisure"],
        "traveler_type__other": ["Other", "Flew last year: Other"],
        "charitable_organizations__none": ["None", "Charitable Donations: Any"],
        "charitable_organizations__children_well_being": ["Children's well-being", "Charitable Donations: Children's well-being"],
        "charitable_organizations__poverty_relief": ["Poverty Relief", "Charitable Donations: Poverty Relief"],
        "charitable_organizations__hunger_relief": ["Hunger Relief", "Charitable Donations: Hunger Relief"],
        "charitable_organizations__education": ["Education", "Charitable Donations: Education"],
        "charitable_organizations__animal_welfare": ["Animal welfare", "Charitable Donations: Animal welfare"],
        "charitable_organizations__climate_change": ["Climate change/Environmental", "Charitable Donations: Climate change/environmental"],
        "charitable_organizations__healthcare_support": ["Healthcare and Hospital support", "Charitable Donations: Healthcare and hospital support"],
        "charitable_organizations__health_related_support": ["Disease/Health Related", "Charitable Donations: Disease/health related support"],
        "charitable_organizations__other": ["Other", "Charitable Donations: Other"],
        "sports_and_gaming__fan_daily_fantasy_sports": ["Sports and Gaming: Plays Daily Fantasy Sports", "Sports and Gaming: Plays Daily Fantasy Sports"],
        "sports_and_gaming__fan_online_sportsbook": ["Sports and Gaming: Wagers Online w/ Sportsbook", "Sports and Gaming: Wagers Online w/ Sportsbook"],
        "sports_and_gaming__casino_online_player": ["Sports and Gaming: Casino Player, Wagers Online", "Sports and Gaming: Casino Player, Wagers Online"],
        "sports_and_gaming__interested_but_not_playing": ["Sports and Gaming: Interested, don't participate", "Sports and Gaming: Interested, don't participate"],
        "sports_and_gaming__fan_not_interested": ["Sports and Gaming: Sports fan, don't participate", "Sports and Gaming: Sports fan, don't participate"],
        "sports_and_gaming__not_interested": ["Sports and Gaming: Not Interested", ""],
        "personification__bold": ["Bold", "Bold"],
        "personification__classy": ["Classy", "Classy"],
        "personification__confident": ["Confident", "Confident"],
        "personification__corporate": ["Corporate", "Corporate"],
        "personification__customer_centric": ["Customer-Centric", "Customer-Centric"],
        "personification__dependable": ["Dependable", "Dependable"],
        "personification__energetic": ["Energetic", "Energetic"],
        "personification__fun": ["Fun", "Fun"],
        "personification__good_value": ["Good Value", "Good Value"],
        "personification__hip": ["Hip", "Hip"],
        "personification__innovative": ["Innovative", "Innovative"],
        "personification__intelligent": ["Intelligent", "Intelligent"],
        "personification__practical": ["Practical", "Practical"],
        "personification__premium": ["Premium", "Premium"],
        "personification__simple": ["Simple", "Simple"],
        "personification__smart": ["Smart", "Smart"],
        "personification__socially_conscious": ["Socially Conscious", "Socially Conscious"],
        "personification__sophisticated": ["Sophisticated", "Sophisticated"],
        "personification__stylish": ["Stylish", "Stylish"],
        "personification__traditional": ["Traditional", "Traditional"],
        "personification__trustworthy": ["Trustworthy", "Trustworthy"],
        "personification__unconventional": ["Unconventional", "Unconventional"],
        "personification__visionary": ["Visionary", "Visionary"],
        "personification__wholesome": ["Wholesome", "Wholesome"],
        "personification__young": ["Young", "Young"],
        "advertising__advertising_recall":["Advertising Recall","Advertising Recall"],
        "advertising__tv_(cable_slash_satellite_slash_broadcast)":["TV (cable/satellite/broadcast)","TV (cable/satellite/broadcast)"],
        "advertising__online_slash_streaming_video":["Online/Streaming Video","Online/Streaming Video"],
        "advertising__news_slash_entertainment_website_or_app":["News/Entertainment Website or App","News/Entertainment Website or App"],
        "advertising__e_hyphen_commerce_slash_review_website_or_app":["E-commerce/Review Website or App","E-commerce/Review Website or App"],
        "advertising__post_slash_pop_hyphen_up_on_social_media":["Post/Pop-up on Social Media","Post/Pop-up on Social Media"],
        "advertising__influencer_on_social_media":["Influencer on Social Media","Influencer on Social Media"],
        "advertising__store_slash_physical_location":["Store/Physical Location","Store/Physical Location"],
        "advertising__online_gaming":["Online Gaming","Online Gaming"],
        "advertising__streaming_audio_slash_podcast":["Streaming Audio/Podcast","Streaming Audio/Podcast"],
        "advertising__radio":["Radio","Radio"],
        "advertising__outdoor":["Outdoor","Outdoor"],
        "advertising__print_media":["Print Media","Print Media"],
        "advertising__email":["Email","Email"],
        "advertising__other":["Other","Other"],
        "touchpoint__tv_advertising": ["Tv advertising", "Tv advertising"],
        "touchpoint__radio_advertising": ["Radio advertising", "Radio advertising"],
        "touchpoint__direct_mail_advertising": ["Direct mail advertising", "Direct mail advertising"],
        "touchpoint__print_advertising": ["Print advertising", "Print advertising"],
        "touchpoint__social_media_advertising": ["Social media advertising", "Social media advertising"],
        "touchpoint__digital_advertising": ["Digital advertising", "Digital advertising"],
        "touchpoint__podcast_advertising": ["Podcast advertising", "Podcast advertising"],
        "touchpoint__email_advertising": ["Email advertising", "Email advertising"],
        "touchpoint__blog_posts": ["Blog posts", "Blog posts"],
        "touchpoint__sponsored_events": ["Sponsored events", "Sponsored events"],
        "touchpoint__partnerships": ["Partnerships", "Partnerships"],
        "touchpoint__product_placement": ["Product placement", "Product placement"],
        "touchpoint__word_of_mouth": ["Word of mouth", "Word of mouth"],
        "touchpoint__celebrity_endorsements": ["Celebrity endorsements", "Celebrity endorsements"],
        "touchpoint__news_media": ["News media", "News media"],
        "touchpoint__other": ["Other", "Other"],
        "irreplaceability__i_have_no_experience_with_these_types_of_products_or_services": ["No experience", "No experience"],
        "irreplaceability__i_would_be_thrilled__this_brand_underperforms_or_actually_offends_me": ["Thrilled", "Thrilled"],
        "irreplaceability__i_wouldn_t_mind_at_all__it_s_no_different_than_other_brands": ["Wouldn't mind", "Wouldn't mind"],
        "irreplaceability__i_would_miss_it__but_there_are_other_good_brands": ["Would miss it", "Would miss it"],
        "irreplaceability__i_would_be_unhappy__it_would_be_hard_to_replace": ["Would be unhappy", "Would be unhappy"],
        "irreplaceability__i_would_be_devastated__this_brand_is_irreplaceable": ["Would be devestated", "Would be devestated"],
        // "household_income_us__prefer_no_answer": ["Prefer No Answer", "Income: Prefer No Answer"],
        // "traveler_type__none": ["Did Not Fly", "", "Flew last year: None"],
        // "sports_and_gaming__transgender_other": ["", ""],
        // "brand_engagement__familiar_with": ["Familiar With", ""],
        // "brand_engagement__regular_user": ["Regular User", ""],
        // "brand_engagement__occasional_user": ["Occasional User", ""],
        // "brand_engagement__tried_in_the_past": ["Tried in the Past", ""],
        // "brand_engagement__non_user": ["Non-user", ""],
        // "equity_familiarity__never_heard_of": ["Never heard of", "Never heard of"],
        // "equity_familiarity__just_know_the_name": ["Just know the name", "Just know the name"],
        // "equity_familiarity__somewhat_familiar": ["Somewhat familiar", "Somewhat familiar"],
        // "equity_familiarity__very_familiar": ["Very familiar", "Very familiar"],
        // "equity_familiarity__extremely_familiar": ["Extremely familiar", "Extremely familiar"],
    }
}
