import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';


export class DecipherSurveyForm extends Component {
    static propTypes = {
        cancelForm: PropTypes.func.isRequired,
        createSurvey: PropTypes.func.isRequired,
        apiError: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            surveyName: '',
            surveyPath: '',
            invalidFields: [],
            apiError: false
        }
    }

    inputChange(e, key) {
        this.setState({[key]: e.target.value});
    }

    createSurvey = () => {
        const { createSurvey } = this.props;

        const decipherSurvey = {
            surveyName: this.state['surveyName'],
            surveyPath: this.state['surveyPath']
        }

        if (this.validate(decipherSurvey)) {
            createSurvey && createSurvey(decipherSurvey);
        }
    }

    onCancelClick = () => {
        const { cancelForm } = this.props;
        cancelForm && cancelForm();
    }

    validate(decipherSurvey) {
        const emptyFields = [];
        const invalidFields = [];

        if (this.state['surveyName'] === '')
            emptyFields.push('surveyName');
        if (this.state['surveyPath'] === '')
            emptyFields.push('surveyPath');

        if (emptyFields.length > 0) {
            invalidFields.push({
                fields: emptyFields,
                error: 'emptyField'
            });
        }

        if (!decipherSurvey.surveyPath.startsWith('/selfserve/53b/')) {
            invalidFields.push({
                fields: ['surveyPath'],
                error: 'invalidPattern'
            });
        }

        this.setState({invalidFields})

        if (invalidFields.length > 0)
            return false;

        return true;
    }

    render() {
        const { invalidFields } = this.state;

        let emptyFields = [];
        let invalidPatternFields = [];

        if (invalidFields.length > 0) {
            const emptyFieldsError = invalidFields.filter(validator => validator.error === 'emptyField');
            const invalidPatternFieldsError = invalidFields.filter(validator => validator.error === 'invalidPattern');

            if (emptyFieldsError.length > 0)
                emptyFields = emptyFieldsError[0].fields;

            if (invalidPatternFieldsError.length > 0)
                invalidPatternFields = invalidPatternFieldsError[0].fields;
        }

        return (
            <div className={"profileForm"} style={{ width: "50%" }}>
                <div className={"inputBlock"}>
                    <label htmlFor={'surveyName'} className={"inputLabel"}>
                        {'Survey Name'}
                    </label>
                    <div className={cx("inputBox formInput formInput--heightAuto", {'formError': emptyFields.indexOf('surveyName') > -1})}>
                        <input
                            id={'surveyName'}
                            placeholder={'ex. International'}
                            value={this.state['surveyName']}
                            onChange={(e) => this.inputChange(e, 'surveyName')}
                            required={true}
                        />
                    </div>
                </div>
                <div className={"inputBlock"}>
                    <label htmlFor={'surveyPath'} className={"inputLabel"}>
                        {'Survey Path'}
                    </label>
                    <div className={cx("inputBox formInput formInput--heightAuto", {'formError': emptyFields.indexOf('surveyPath') > -1 || invalidPatternFields.length > 0})}>
                        <input
                            id={'surveyPath'}
                            placeholder={'ex. /selfserve/53b/st-labels-final'}
                            value={this.state['surveyPath']}
                            onChange={(e) => this.inputChange(e, 'surveyPath')}
                            required={true}
                        />
                    </div>
                </div>
                {(invalidPatternFields.length > 0 && emptyFields.length === 0) ?
                    <div className={'errorMessage'}>
                        <span>{'Invalid Survey Path pattern - valid pattern: [/selfserve/53b/{name}]'}</span>
                    </div> : emptyFields.length > 0 ?
                    <div className={'errorMessage'}>
                        <span>Fields cannot be empty!</span>
                    </div> : this.props.apiError &&
                        <div className={'errorMessage'}>
                            <span>There is something wrong while saving Decipher Survey!</span>
                        </div>
                }
                <div className={"buttonsBlock"}>
                    <div
                        className={"button whiteButton addFormButton"}
                        onClick={this.onCancelClick}
                    >
                        {'Cancel'}
                    </div>
                    <div
                        className={cx("button", "blueButton", "addFormButton")}
                        onClick={this.createSurvey}
                    >
                        {'Create Decipher Survey'}
                    </div>
                </div>
            </div>
        );
    }
}