import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import closeIcon from '../../../assets/icons/icon_x_light.svg';
import './StatusMessage.scss';
import initData from '../../../utils/initData';

class StatusMessage extends Component {
    static propTypes = {
        statusText: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        hideTimeout: PropTypes.number.isRequired,
        opened: PropTypes.bool.isRequired,
    }

    componentDidUpdate(prevProps) {
        if(prevProps.opened !== this.props.opened) {
            this.setState({ isOpened: this.props.opened});
        }
        if(prevProps.opened !== this.props.opened && this.props.opened) {
            this.onClose();
        }
    }

    state = {
        isOpened: this.props.opened,
    }

    closeTimeoutId = null;

    onCloseCallBack = () => {
        const { onClose } = this.props;
        this.setState({ isOpened: false });
        clearTimeout(this.closeTimeoutId);
        this.closeTimeoutId = null;
        setTimeout(onClose, 1200);
    }

    onClose = () => {
        const { hideTimeout } = this.props;
        this.closeTimeoutId = setTimeout(() => {
            if(this.closeTimeoutId) {
                this.onCloseCallBack();
            }
        }, hideTimeout);
    }

    render() {
        const { statusText, message, error } = this.props;
        const { isOpened } = this.state;
        const { segments } = initData.getStatusMessages();

        return (
            <div className={error ? cx (
                'statusMessageExists',
                   {
                       'statusMessageExists--opened': isOpened,
                       'alternativeBackgroundColor': segments.limit.status === statusText,
                   }
               ): cx (
                   'statusMessage',
                      {
                          'statusMessage--opened': isOpened,
                          'alternativeBackgroundColor': segments.limit.status === statusText,
                      }
                  )}>
                <div className={'textContainer'}>
                    <span className={'statusText'}>{`${statusText}`}</span>
                    <span className={'messageText'}>{message}</span>
                </div>
                <div className={'closeButton'} onClick={this.onCloseCallBack}>
                    <img src={closeIcon} alt={'Close icon'} />
                </div>
            </div>
        )
    }
}

export default StatusMessage;
