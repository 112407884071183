import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {closeAblyConnection, createAblyClient} from '../config/ablyConfig';
import {STORAGE_KEY} from "../constants/app/storage";
import {GlobalAppEventType} from "../constants/enums/GlobalAppEventType";

const AblyContext = createContext(null);

export const useAbly = () => useContext(AblyContext);

export const AblyProvider = ({ children }) => {
    const [clientId, setClientId] = useState(localStorage.getItem(STORAGE_KEY.WS_CLIENT_ID) || null);

    useEffect(() => {
        const handleClientIdUpdate = (event) => {
            setClientId(event.detail);
        };

        window.addEventListener(GlobalAppEventType.WS_CLIENT_ID_UPDATED, handleClientIdUpdate);

        return () => {
            window.removeEventListener(GlobalAppEventType.WS_CLIENT_ID_UPDATED, handleClientIdUpdate);
        };
    }, []);

    const ablyClient = useMemo(() => {
        if (clientId) {
            return createAblyClient(clientId);
        }
        return null;
    }, [clientId]);

    useEffect(() => {
        if (ablyClient) {
            ablyClient.connection.once('connected', () => {
                console.debug('[Ably] Connection established');
            });

            ablyClient.connection.on('failed', (stateChange) => {
                console.error('[Ably] Connection failed', stateChange);

                if (stateChange.reason?.code === 40140) {
                    closeAblyConnection(ablyClient);
                }
            });

            return () => {
                closeAblyConnection(ablyClient);
            };
        }
    }, [ablyClient]);

    return (
        <AblyContext.Provider value={ablyClient}>
            {children}
        </AblyContext.Provider>
    );
};

export { AblyContext };
