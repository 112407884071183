import initData from "./initData";
import { getLocalStorage, setSessionStorage } from "./storageHelpers";
import filtersTransformations from "./filterTransformations";
import { DateTime } from 'luxon';
import { safeParseJSONLocalStorage, safeParseJSONSessionStorage } from "./getBrowserStorageData";
import { setUserSelectedBrandsByMarket, setUserSelectedMarkets } from "./common";

//Define the standard keys for params
const keys = ['audienceType', 'statTest', 'periodType', 'periodStartDate','periodEndDate','intervalType','postPeriodType','postStartDate','postEndDate',
                'segments','filters','market','brand','brands','markets','group','metric','response','module','screenType','chartType'];

export const getURLPathParams = () => {
    let localUrl = safeParseJSONSessionStorage("params");
    const urlParams = new URLSearchParams(localUrl?localUrl:window.location.search);
    const params = Object.fromEntries(urlParams.entries());
    //get define params from URL
    let audienceType = urlParams.get('audienceType');
    let statTest = urlParams.get('statTest');
    let periodType = urlParams.get('periodType');
    let periodStartDate = urlParams.get('periodStartDate');
    let periodEndDate = urlParams.get('periodEndDate');
    let intervalType = urlParams.get('intervalType');
    let postPeriodType = urlParams.get('postPeriodType');
    let postStartDate = urlParams.get('postStartDate');
    let postEndDate = urlParams.get('postEndDate');
    let selectedMarket = urlParams.get('market');
    let selectedBrand = urlParams.get('brand');
    let module = urlParams.get('module');
    let screenType = urlParams.get('screenType');
    let chartType = urlParams.get('chartType');
    let group = urlParams.get('group');
    let metric = urlParams.get('metric');
    let response = urlParams.get('response');

    let markets = urlParams.getAll('markets');
    let brands = urlParams.getAll('brands');
    let segmentIds = urlParams.getAll('segments');
    let filterIds = urlParams.getAll('filters');
    //get incorrect keys from URL params
    let incorrectKeys = Object.keys(params)?.filter(key => !keys?.includes(key));

    return {
        audienceType, 
        statTest, 
        periodType, 
        periodStartDate, 
        periodEndDate, 
        intervalType, 
        postPeriodType, 
        postStartDate,
        postEndDate,
        selectedMarket,
        selectedBrand,
        markets,
        brands,
        segmentIds,
        filterIds,
        group,
        metric,
        response,
        module,
        screenType,
        chartType,
        incorrectKeys
    };
} 
 
export const isInvalidValue = (value) => {
    return [undefined, 'undefined', null, 'null', ''].includes(value);
}

export const getScreenType = (pathName) => {
    let pathSegments = pathName.split('/');
    const lastSegment = pathSegments?.length >= 3 && pathSegments[pathSegments.length - 1];
    if(lastSegment){
         return lastSegment.toUpperCase();
    }
}

export const getAudienceType = (audienceType, page, selectedTab, selectedOption, audienceTypeOptions) => {
    let userModes = initData.getModes();
    audienceType = audienceType?.toUpperCase();
    let group = selectedOption?.group?.toUpperCase();
    let metric =  selectedOption?.metric?.toUpperCase();
    audienceType = (selectedTab === 4 && metric === "RECOMMEND")? 'USER' : (((audienceType === 'USER' && (selectedTab !== 4 && metric !== "RECOMMEND"))||(selectedTab === 4 && (metric === "AWARENESS" || metric === "FAMILIARITY"))) || (audienceType === 'ADRECALL' && (selectedTab !== 3 || page === 'summary')) || (selectedTab === 3 && page === 'advanced' && group === "CONVERSION")) ? 'GENPOP' : audienceType;
    audienceType = userModes?.filter(({name})=> name?.toUpperCase()?.replace(/ /g, "") == audienceType?.toUpperCase())[0];
    audienceType = isInvalidValue(audienceType) ? userModes[0] : audienceType;
    if(audienceTypeOptions?.length > 0){
        audienceType = audienceTypeOptions?.filter(({id})=> id == audienceType?.id)?.length>0 ? audienceType : audienceTypeOptions[0];
    }
    return audienceType;
}

export const getPeriodType = (periodType, startDate, endDate, page, selectedTab) => {
    let perionOptionsList =  initData.getPeriodOptions();
    periodType = perionOptionsList?.find(({key}) => key?.toUpperCase() === periodType?.toUpperCase());
    let defaultPeriodType = perionOptionsList?.find(({id}) => id === 6);
    if(!isInvalidValue(startDate) && !isInvalidValue(endDate) && periodType?.id === 1){
        let date1 = DateTime.fromFormat(startDate, 'yyyyMMdd');
        let date2 = DateTime.fromFormat(endDate, 'yyyyMMdd');
        if(date1 > date2 ){
            return periodType = defaultPeriodType;
        }
    }
    return periodType = (periodType?.id == 2 && (page == 'summary' || selectedTab == 4)) ?  defaultPeriodType : isInvalidValue(periodType) ? defaultPeriodType : periodType;
}

export const getInterval = (intervalType, defaultInterval) => {
    let getMovingAverageIntervals = true;
    let intervalsList = initData.getIntervals(getMovingAverageIntervals).flatMap(opt => opt.options);
    intervalType = intervalsList?.find(({key})=> key?.toUpperCase() === intervalType?.toUpperCase());
    return intervalType = isInvalidValue(intervalType?.key) ? intervalsList.find(({id})=>id === defaultInterval) : intervalType;
}

export const getStartEndDates = (periodType, startDate, endDate, defaultStartDate, defaultEndDate) => {
    //If selected period type is not custom range then set start and end date by selected range respectively with current date
    if(periodType?.key !== 'CUSTOM'){
        switch (periodType?.key) {
            case 'L7D':
                defaultStartDate = DateTime.now().minus({days: 6}).toFormat('yyyyMMdd');
                break;
            case 'L30D':
                defaultStartDate = DateTime.now().minus({days: 30}).toFormat('yyyyMMdd');
                break;
            case 'L90D':
                defaultStartDate = DateTime.now().minus({days: 90}).toFormat('yyyyMMdd');
                break;
            case 'YTD':
                const currentDate = DateTime.now();
                const startingDateOfYear = currentDate.startOf('year');
                defaultStartDate = startingDateOfYear.toFormat('yyyyMMdd');
                break;
            case 'L180D':
                defaultStartDate = DateTime.now().minus({days: 180}).toFormat('yyyyMMdd');
                break;
            default:
                defaultStartDate = DateTime.now().minus({days: 180}).toFormat('yyyyMMdd');
                break;
        }
        startDate = defaultStartDate;
        endDate = DateTime.now().minus({days: 1}).toFormat('yyyyMMdd');
    }else{
        startDate = isInvalidValue(startDate)?DateTime.fromFormat(defaultStartDate, 'yyyyMMdd'): startDate;
        endDate = isInvalidValue(endDate)? DateTime.fromFormat(defaultEndDate, 'yyyyMMdd') : endDate;
    }
    return { startDate, endDate};
}

const getSummarySegmentSelectedBrand = (selectedId, defaultSelectedBrand) => {
    let brands = safeParseJSONLocalStorage('myBrands');
    let selectedBrand, selectedBrandId, selectedBrandIndex;
    if(brands?.length > 0){
        selectedBrand = brands?.find(obj=>Number(obj.id) === Number(selectedId));
        selectedBrandIndex = isInvalidValue(selectedId)? 0:brands?.findIndex(obj=>Number(obj.id) == Number(selectedId));
        selectedBrandId = isInvalidValue(selectedBrand)?isInvalidValue(defaultSelectedBrand)?brands[0]?.id:brands.find((obj,i) => i == defaultSelectedBrand)?.id:selectedBrand?.id;
    }
    return { selectedBrandId, selectedBrandIndex};
}

const getSelectedSegments = (selectedsegmentIds,selectedMarket, page) => {
    let categorysList = safeParseJSONSessionStorage('segmentsList');
    let segmentsList = categorysList?.reduce((acc, sr) => {
        selectedsegmentIds?.length> 0 && sr.segments.forEach(obj=> {
            let {segmentId, segmentName} = obj;
            if(selectedsegmentIds?.includes(segmentId?.toString())) obj.isActive = true; 
            else if(!(segmentName === 'Age 18+' || segmentName === 'Age 35+' ) && page === 'segments') obj.isActive = false;   
        });
    acc.push(...sr?.segments);
        return acc;
    }, []);
    categorysList?.length> 0 && setSessionStorage('segmentsList',JSON.stringify(categorysList));
    let selectedSegments = segmentsList?.filter(({isActive, segmentMarkets}) => isActive === true &&  segmentMarkets?.includes(selectedMarket))?.map(({segmentId})=>segmentId);
    let inValidORSharedSegments = selectedsegmentIds?.filter((id) => !segmentsList.some(({segmentId}) => id == segmentId.toString()))
    if(selectedSegments?.length > 0 && inValidORSharedSegments?.length > 0){
        selectedSegments  = [...selectedSegments,...inValidORSharedSegments]
    }
    return {selectedSegments, categorysList, segmentsList, inValidORSharedSegments};
}

export const getCompetitiveSelectedMarketAndBrands  = (selectedMarket, selectedBrands) => {
    let userMarkets = safeParseJSONLocalStorage('markets');
    let brands = safeParseJSONLocalStorage('myBrands');
    let removedBrands = selectedBrands?.filter((id) => !brands.some((ele) => id == ele.id));
    let selectedBrandsList = selectedBrands?.reduce((acc, id, index) => {
        const brandId = brands.find((item) => item.id == id)?.id;
            acc.push(brandId);
        return acc;
    }, []);
    let defaultBrands = brands?.map(({id})=> id);
    selectedBrands = selectedBrandsList?.length>0 ? selectedBrandsList?.filter(id=>id):defaultBrands;
    let selectedDefaultMarket = [];
    userMarkets?.length > 0 && userMarkets.find(({marketGroupsList}) => {
        let findSelectedMarket = marketGroupsList?.find(({identity}) => identity?.toUpperCase() == selectedMarket?.toUpperCase());
        findSelectedMarket && selectedDefaultMarket.push(findSelectedMarket)
    });
    let defaultMarket = userMarkets?.length > 0 && userMarkets[0]?.marketGroupsList[0];
    let selectedMarketObj = isInvalidValue(selectedMarket)? defaultMarket: selectedDefaultMarket?.length>0 ? selectedDefaultMarket[0] : defaultMarket;
    let selectedURLCIBrands = safeParseJSONSessionStorage('userSelectedBrands')?.find(({marketId})=> marketId == selectedMarketObj?.marketId)?.brands;
    selectedBrands = selectedBrands?.filter(ele => selectedURLCIBrands?.some(id => id == ele));
    selectedBrands = selectedBrands?.length>0 ? selectedBrands:defaultBrands;
    let selectedBrandsIndex = selectedBrands?.reduce((acc, item, index) => {
        const matchingIndex = brands.findIndex(obj => item == obj.id);
        if (matchingIndex !== -1) acc.push(matchingIndex);
        return acc;
    }, []);
    return {selectedMarketObj, selectedBrands, selectedBrandsIndex, removedBrands};
}

export const getGlobalSelectedBrandAndMarkets = (selectedBrand, defaultGlobalbrand, selectedMarkets) => {
    let globalBrands = safeParseJSONLocalStorage('globalBrands');
    let selectedGlobalBrand = isInvalidValue(selectedBrand)?globalBrands?.length > 0 && globalBrands.find((obj,i) => i == defaultGlobalbrand):globalBrands?.find(obj=>Number(obj.id) === Number(selectedBrand));
    let selectedGlobalBrandId = selectedGlobalBrand?.id;
    let selectedURLMarkets = safeParseJSONSessionStorage('userSelectedGlobalMarkets')?.find(({brandId})=> brandId == selectedGlobalBrandId)?.markets;
    selectedMarkets = selectedURLMarkets?.length > 0? selectedURLMarkets :selectedMarkets;
    let selectedGlobalBrandIndex = globalBrands?.findIndex(obj=>Number(obj.id) == Number(selectedBrand));
    let marketList = selectedGlobalBrand?.marketGroups?.reduce((acc, mg) => {
        acc.push(...mg.marketGroupsList);
        return acc;
    }, []).sort((a,b) => a.position - b.position).map(({identity})=>identity);
    selectedMarkets = selectedMarkets?.filter(ele=> ele)?.length>0 ? selectedMarkets:marketList;
    let removedMarkets = selectedMarkets?.filter((id) => !marketList?.some((ele) => id == ele));
    selectedMarkets = selectedMarkets?.reduce((acc, id, index) => {
        const marketIdentity = marketList?.find((item) => item == id);
        acc.push(marketIdentity);
        return acc;
    }, []);
    selectedMarkets = selectedMarkets?.filter(identity=> identity);

    let selectedMarketIndexes = marketList?.reduce((acc, identity, index) => {
        const matchingIndex = selectedMarkets.findIndex(item => item == identity);
        if (matchingIndex !== -1) {
            acc.push(index);
        }
        return acc;
    }, []);

    return { selectedGlobalBrandId, selectedGlobalBrandIndex, selectedMarkets, removedMarkets, selectedMarketIndexes};
}

export const getSelectedDropDownOption = (tabOptions,selectedOption,defaultValue) => {
    let paramsselectedGroup = tabOptions?.find(({name})=> name?.toLowerCase()?.replace(/ /g,"") == selectedOption?.toLowerCase());
    return isInvalidValue(selectedOption)?isInvalidValue(defaultValue)?tabOptions[0]:defaultValue:isInvalidValue(paramsselectedGroup)?defaultValue:paramsselectedGroup;
}

export const getTrendsProfilePrepostOptions = (paramsSelectedOptions, path, stateObject) => {
    let {group, metric, response} = paramsSelectedOptions;
    let {stab, selectedOption,  selectedScoreOption} = stateObject.lineOption;
    let {mainVariableFilterOptions, variableGroupOptions, variableFilterOptions, currentVariableMainFilter ,currentVariableGroup ,currentVariableFilter, prePostLineOption} = stateObject;
    if(path.includes('trends')){
        let trendsTabOptions = initData.gettrendsTabOptions();
        let trendsOptionCategories = initData.getTrendsOptionCategories();
        let trendsScoreDecompositionvalues = initData.getTrendsOptionCategories();

        let defaultselectedGroup = trendsTabOptions?.find(({id})=> id == stab);
        group = getSelectedDropDownOption(trendsTabOptions, group, defaultselectedGroup);
        
        let defaultselectedMetric = trendsOptionCategories[group?.id]?.find(({id})=> id == selectedOption);
        metric = getSelectedDropDownOption(trendsOptionCategories[group?.id], metric, defaultselectedMetric);
        
        trendsScoreDecompositionvalues = metric ==='consideration'?initData.getTrendsOptionCategories()[5]:initData.getTrendsOptionCategories()[4];
        let defaultselectedResponse = trendsScoreDecompositionvalues?.find(({id})=> id == selectedScoreOption);
        response = getSelectedDropDownOption(trendsScoreDecompositionvalues, response, defaultselectedResponse);
    }else if(path.includes('profile')){
        let defaultselectedGroup = mainVariableFilterOptions.find(({id})=> id == currentVariableMainFilter);
        group = getSelectedDropDownOption(mainVariableFilterOptions, group, defaultselectedGroup);

        let defaultselectedMetric = variableGroupOptions.find(({id})=> id == currentVariableGroup);
        metric = getSelectedDropDownOption(variableGroupOptions, metric, defaultselectedMetric);;

        let defaultCurrentVariableFilterOption = variableFilterOptions.find(({id})=> id == currentVariableFilter?.index);
        let paramsCurrentVariableFilterOption = variableFilterOptions.find(({name})=> name.replace(/ /g,"")?.toLowerCase() == response?.toLowerCase());

        let defaultCurrentVariableFilterIndex = variableFilterOptions.findIndex(({id})=> id == currentVariableFilter?.index);
        let paramsCurrentVariableFilterIndex = variableFilterOptions.findIndex(({name})=> name.replace(/ /g,"")?.toLowerCase() == response?.toLowerCase());

        let currentVariableFilterOption = isInvalidValue(response)?isInvalidValue(defaultCurrentVariableFilterOption)?variableFilterOptions[0]:defaultCurrentVariableFilterOption:isInvalidValue(paramsCurrentVariableFilterOption)?defaultCurrentVariableFilterOption:paramsCurrentVariableFilterOption;
        let currentVariableFilterIndex = isInvalidValue(response)?isInvalidValue(defaultCurrentVariableFilterIndex)?0:defaultCurrentVariableFilterIndex:isInvalidValue(paramsCurrentVariableFilterIndex)?defaultCurrentVariableFilterIndex:paramsCurrentVariableFilterIndex;
        response = {
            index: currentVariableFilterIndex,
            option: currentVariableFilterOption
        }
    }else if(path.includes('prepost')){
        let tabOptions = initData.gettrendsTabOptions();
        let defaultselectedGroup = tabOptions?.find(({id})=> id == prePostLineOption?.stab);
        group = getSelectedDropDownOption(tabOptions, group, defaultselectedGroup);
    }
    return {
        selectedGroup: group, 
        selectedMetric: metric, 
        selectedResponse: response
    };
}

const getAppliedFilters = (applyFilters, segmentIds, filterIds, obj) => {
    let applySegmentsList = [], applyFiltersList = [];
    if(applyFilters?.length > 0){ 
        applyFilters.forEach(({filterType,filterId,filterOptions})=>{
            if(filterType === 'SEGMENT') applySegmentsList.push(filterId);
            else if (filterId === 8 && filterType !== 'SEGMENT') applyFiltersList.push(`Age_${filterOptions[0]}-${filterOptions[1]}`)
            else applyFiltersList.push(...filterOptions);
        })
    }else{
        applyFiltersList.unshift(...filterIds)
        applySegmentsList.unshift(...segmentIds)
    }
    applyFiltersList = [...new Set(applyFiltersList)];
    applySegmentsList = [...new Set(applySegmentsList)];
    //Format apply filters to store in localstorage
    let {validFilters, validSegments, applyFiltersData, expiredFilters} = filtersTransformations.formatURLApplyFilters(applySegmentsList, applyFiltersList, obj);
    applyFiltersList = [...new Set(validFilters)];
    applySegmentsList = [...new Set(applySegmentsList)];

    return {applySegmentsList, applyFiltersList, applyFiltersData, expiredFilters};
}

export const addParamsToUrl = async(obj, getFilters) => {
    let {statTestingEnabled, startdate, enddate, interval, pstartdate, penddate, selectedTab, page, applyFilters, selectedGlobalBrand, horizontalMenu, audienceTypeOptions} = obj;
    let {audienceType, statTest, periodType, periodStartDate, periodEndDate, intervalType, postPeriodType, postStartDate, postEndDate, 
        selectedMarket, selectedBrand, segmentIds, filterIds, group, metric, response, brands, markets, module, screenType, chartType} = getURLPathParams();
        //valid all params if not found set default values
    audienceType = getAudienceType(audienceType, page, selectedTab, {group, metric},audienceTypeOptions);
    periodType = getPeriodType(periodType, periodStartDate, periodEndDate, page, selectedTab);
    intervalType = getInterval(intervalType, interval);
    postPeriodType = getPeriodType(postPeriodType, postStartDate, postEndDate, page, selectedTab);
    statTest = isInvalidValue(statTest) ? (statTest == 'true' || statTest == 'false') ? statTest : statTestingEnabled : statTestingEnabled;
    let periodStatEndDates = getStartEndDates(periodType, periodStartDate, periodEndDate, startdate, enddate);
    let postPeriodTypeStarEndtDate = getStartEndDates(postPeriodType, postStartDate, postEndDate, pstartdate, penddate);
    periodStartDate = periodStatEndDates.startDate;
    periodEndDate = periodStatEndDates.endDate;
    postStartDate = postPeriodTypeStarEndtDate.startDate;
    postEndDate = postPeriodTypeStarEndtDate.endDate;
    chartType = isInvalidValue(chartType)? horizontalMenu?.toUpperCase():(Object.values(initData.getHorizontalMenuNames()).includes(chartType?.toLowerCase())?chartType:'primary')?.toUpperCase();

    let pathName = window?.location?.pathname;

    //get selected market and it brands/selected global brand and it's markets
    let {selectedBrandId, selectedBrandIndex} = getSummarySegmentSelectedBrand(selectedBrand, obj?.selectedBrand[0]);
    let {selectedMarketObj, selectedBrands, selectedBrandsIndex, removedBrands} = getCompetitiveSelectedMarketAndBrands(selectedMarket, brands);
    let {selectedSegments, categorysList, segmentsList, inValidORSharedSegments} = getSelectedSegments(segmentIds, selectedMarketObj?.marketName, page);
    let {selectedGlobalBrandId, selectedGlobalBrandIndex, selectedMarkets, removedMarkets, selectedMarketIndexes} = getGlobalSelectedBrandAndMarkets(selectedBrand, selectedGlobalBrand[0], markets);
    //get selected trends/profile/prepost options
    let {selectedGroup, selectedMetric, selectedResponse} = getTrendsProfilePrepostOptions({group,metric,response}, pathName, obj);
    
    let params = {
        audienceType: audienceType?.name?.toUpperCase().replace(/ /g, ""),
        statTest: JSON.parse(statTest),
        periodType: periodType?.key,
        periodStartDate : periodStartDate,
        periodEndDate : periodEndDate,
        intervalType: intervalType?.key,
        postPeriodType: postPeriodType?.key,
        postStartDate: postStartDate,
        postEndDate: postEndDate,
        market: selectedMarketObj?.identity,
        chartType: chartType,
        module: isInvalidValue(module)? page:module,
    }

    //Add screenType if it is exists
    const screenTypeVal = getScreenType(pathName);
    if(!isInvalidValue(screenTypeVal)){
        params.screenType = screenTypeVal;
    }
    let filters =  safeParseJSONLocalStorage('filters');
    if(pathName?.includes('summary') || pathName?.includes('segments')){
        !filters && await getFilters(page,selectedMarketObj?.marketId);
        params.brand = selectedBrandId;
        if(pathName?.includes('segments')) params.segments = selectedSegments;
    }else if(pathName?.includes('competitive') || pathName?.includes('advanced')){
        !filters && await getFilters(page,selectedMarketObj?.marketId);
        params.brands = selectedBrands;
        selectedBrandsIndex?.length !== 0 && setUserSelectedBrandsByMarket(selectedMarketObj?.marketId,selectedBrands);
    }else if(pathName?.includes('global')){
        !filters && await getFilters(page,selectedGlobalBrandIndex);
        params.brand = selectedGlobalBrandId;
        params.markets = selectedMarkets;
        selectedBrandsIndex?.length !== 0 && setUserSelectedMarkets(selectedGlobalBrandId, selectedMarkets);
    }

    if(pathName?.includes('trends')){
        params.group = selectedGroup?.name?.toUpperCase();
        params.metric = selectedMetric?.name?.toUpperCase()?.replace(/ /g,"");
        let scoreDecompositionEnabled = (selectedMetric?.id === 'momentum' || selectedMetric?.id === 'quality' || selectedMetric?.id === 'recommend' || selectedMetric?.id ==='consideration')
        scoreDecompositionEnabled && (params.response = selectedResponse?.name?.toUpperCase()?.replace(/ /g,""));
    }else if(pathName?.includes('profile')){
        params.group = selectedGroup?.name?.replace(/ /g,"")?.toUpperCase();
        params.metric = selectedMetric?.name?.replace(/ /g,"")?.toUpperCase();
        params.response = selectedResponse?.option?.name?.replace(/ /g,"")?.toUpperCase();
    }else if(pathName?.includes('prepost')){
        params.group = selectedGroup?.name;
    }

    //get apply filters
    let {applySegmentsList, applyFiltersList, applyFiltersData, expiredFilters} = await getAppliedFilters(applyFilters, segmentIds, filterIds, {page, selectedMarketObj, selectedGlobalBrandIndex});
    applySegmentsList?.length > 0 && page !== 'segments' && (params.segments = applySegmentsList);
    applyFiltersList?.length > 0 && (params.filters = applyFiltersList);

    //get url
    const url = new URL(window.location.origin + window.location.pathname + window.location.hash);

    //Append parameters to the URL
    Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            url.searchParams.delete(key);
            value.forEach((val) => {
                const existingValues = url.searchParams.getAll(key);
                if (!existingValues.includes(val?.toString())) {
                    url.searchParams.append(key, val.toString());
                }
            });
        } else {
            url.searchParams && url.searchParams.set(key, value);
        }
    });
    // Replace the current URL with the modified one when user having active contracts
    let authorizedType = getLocalStorage('authorizedType');
    let userBrands = safeParseJSONLocalStorage('myBrands');
    if(authorizedType === 'USER' && userBrands?.length > 0){
        window.history.pushState({}, '', url);
        setSessionStorage("params",JSON.stringify(window.location.search));
    }

    return {
        currentOption: audienceType?.id,
        statTestingEnabled: JSON.parse(statTest),
        startdate: DateTime.fromFormat(periodStartDate, 'yyyyMMdd').toFormat('yyyy-MM-dd'),
        enddate: DateTime.fromFormat(periodEndDate, 'yyyyMMdd').toFormat('yyyy-MM-dd'),
        selectedPeriod: periodType?.id,
        interval: intervalType?.id,
        pselectedPeriod: postPeriodType?.id,
        pstartdate: DateTime.fromFormat(postStartDate, 'yyyyMMdd').toFormat('yyyy-MM-dd'),
        penddate: DateTime.fromFormat(postEndDate, 'yyyyMMdd').toFormat('yyyy-MM-dd'),
        market: selectedMarketObj,
        applyFilters: applyFiltersData,
        expiredFilters,
        selectedBrandIndex,
        selectedGlobalBrandIndex,
        lineOption:{
            stab: selectedGroup?.id,
            selectedOption: selectedMetric?.id,
            selectedScoreOption: selectedResponse?.id?selectedResponse?.id:"positive"
        },
        currentVariableMainFilter: selectedGroup?.id,
        currentVariableGroup: selectedMetric?.id,
        currentVariableFilter: selectedResponse,
        removedBrands,
        selectedMarketIndexes,
        selectedCompetitiveBrands: selectedBrandsIndex,
        horizontalMenu: chartType?.toLowerCase(),
        segments: categorysList,
        segmentsList,
        inValidORSharedSegments
    }
}

export const updateURLParams = (updatedParams, deletedParams) => {
    let {audienceType, statTest, periodType, periodStartDate, periodEndDate, intervalType, postPeriodType, postStartDate, postEndDate, chartType,
        selectedMarket, selectedBrand, segmentIds, filterIds, incorrectKeys, group, metric, response, brands, markets, module, screenType} = getURLPathParams();
        if(updatedParams?.hasOwnProperty('segments')||updatedParams?.hasOwnProperty('filters')){
        !deletedParams && (deletedParams = []);
        segmentIds = [...new Set(updatedParams?.segments)].map(Number);
        filterIds = [...new Set(updatedParams?.filters)].map(Number);
        if(updatedParams?.filters?.length == 0)deletedParams.push('filters')
        if(updatedParams?.segments?.length == 0)deletedParams.push('segments')
    }

    let params = {
        audienceType: audienceType,
        statTest: statTest,
        periodType: periodType,
        periodStartDate : periodStartDate,
        periodEndDate : periodEndDate,
        intervalType: intervalType,
        postPeriodType: postPeriodType,
        postStartDate: postStartDate,
        postEndDate: postEndDate,
        segments: segmentIds,
        filters: filterIds,
        market: selectedMarket,
        module: module,
        chartType: chartType
    }
    
    if(!isInvalidValue(screenType)){
        params.screenType = screenType;
    }

    const url = new URL(window.location.href);
    let pathName = window?.location?.pathname;
    if(pathName?.includes('summary') || pathName?.includes('segments')){
        params.brand = selectedBrand;
        if(pathName?.includes('segments')) params.segments = segmentIds;
    }else if(pathName?.includes('competitive')|| pathName?.includes('advanced')){
        params.brands = brands;
    }else if(pathName?.includes('global')){
        params.brand = selectedBrand;
        params.markets = markets;
    }

    if(pathName?.includes('trends')){
        params.group = group;
        params.metric = updatedParams?.metric?updatedParams?.metric:metric;
        let scoreDecompositionEnabled = (metric === 'MOMENTUM' || metric === 'QUALITY' || metric === 'RECOMMEND' || metric ==='CONSIDERATION')
        scoreDecompositionEnabled ? (params.response = response):(url.searchParams.delete('response'));
    }else if(pathName?.includes('profile')){
        params.group = group;
        params.metric = metric;
        params.response = response;
    }else if(pathName?.includes('prepost')){
        params.group = group;
        url.searchParams.delete('metric');
        url.searchParams.delete('response');
    }

    //Remove incorrect keys and params
    incorrectKeys.forEach(incorrectKey => {
        url.searchParams.delete(incorrectKey);
    });

    Object.entries(params).forEach(([key, value]) => {
        //Add multiple values with same key to URL params
        if (Array.isArray(value)) {
            value.forEach((val) => {
                const existingValues = url.searchParams.getAll(key);
                if (!existingValues.includes(val.toString())) {
                    url.searchParams.append(key, val.toString());
                }
            });
        } else {
            //add unique keys to URL params
            url.searchParams && url.searchParams.set(key, value);
        }
    });

     //Delete params
     deletedParams?.length> 0 && deletedParams.forEach(incorrectKey => {
        url.searchParams.delete(incorrectKey);
    });
   
    //update the existing params based on user actions
    updatedParams instanceof Object && Object.keys(updatedParams).forEach(key => {
        if (Array.isArray(updatedParams[key])) {
            updatedParams[key].forEach((val) => {
                const existingValues = url.searchParams.getAll(key);
                if (!existingValues?.includes(val?.toString())) {
                    url.searchParams.append(key, val);
                }
            });
        } else {
            //add unique keys to URL params
            url.searchParams && url.searchParams.set(key, updatedParams[key]);
        }
    });

    const isAllValuesNull = Object.values(params)?.every(value => (isInvalidValue(value) || value?.length == 0));

    let authorizedType = getLocalStorage('authorizedType');
    let userBrands = safeParseJSONLocalStorage('myBrands');
    if(authorizedType === 'USER' && userBrands?.length > 0 && !isAllValuesNull){
        window.history.pushState({}, '', url);
        setSessionStorage("params",JSON.stringify(window.location.search));
    }
}

export const excludePagesFromGetParamUpdateURLListData = [
    "users",
    "groups",
    "brands",
    "markets",
    "group",
    "user",
];