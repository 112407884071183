import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CreateBrandComponent from './components/CreateBrandComponent/CreateBrandComponent';
import FailComponent from './components/SignUpSuccessComponent/FailComponent';
import SelectBrandComponent from './components/SelectBrandComponent/SelectBrandComponent';
import AuthCheckWrapper from './Auth';
import pageContainer from './components/LayoutComponent/ContainerPage';
import './assets/css/main.scss';
import 'react-circular-progressbar/dist/styles.css';
import * as Sentry from "@sentry/react";
import  LogOutComponent  from './components/LoginComponent/LogoutComponent';
import { getLocalStorage, setSessionStorage } from './utils/storageHelpers';

const isLoggedIn = getLocalStorage('token', { doNotParse: false });
const userDetail = JSON.parse(getLocalStorage('userInfo'));

if (process.env.REACT_APP_SENTRY_ENABLED && isLoggedIn && userDetail) {
  Sentry.setUser({
      id: userDetail?.id,
      email: userDetail?.email,
      name: `${userDetail?.firstName} ${userDetail?.lastName}`,
  });
}

const App = () => {
  if(getLocalStorage('authorizedType') === 'USER'){
    setSessionStorage("params",JSON.stringify(window.location.search));
  };
  
  return (
      <Sentry.ErrorBoundary>
        <div id="app">
          <Switch>
            <Route exact path="/" component={AuthCheckWrapper} />
            <Route exact path="/register/fail">
              <FailComponent failType={'account'} />
            </Route>
            <Route exact path="/contracts/fail">
              <FailComponent failType={'contracts'} />
            </Route>
            <Route exact path="/admin" render={props => <AuthCheckWrapper {...props} authorizedType='ADMIN' />} />
            <Route exact path="/userdashbord" render={props => <AuthCheckWrapper {...props} authorizedType='USER' />} />
            <Route exact path="/users" render={props => <AuthCheckWrapper {...props} authorizedType='ADMIN' page='users' Component={pageContainer} />} />
            <Route exact path="/user/:userId/" render={props => <AuthCheckWrapper {...props} authorizedType='ADMIN' page='user' Component={pageContainer} />} />
            <Route exact path="/adduser" render={props => <AuthCheckWrapper {...props} authorizedType='ADMIN' page='adduser' Component={pageContainer} />} />
            <Route exact path="/groups" render={props => <AuthCheckWrapper {...props} authorizedType='ADMIN' page='groups' Component={pageContainer} />} />
            <Route exact path="/group/:groupId/" render={props => <AuthCheckWrapper {...props} authorizedType='ADMIN' page='group' Component={pageContainer} />} />
            <Route exact path="/addgroup" render={props => <AuthCheckWrapper {...props} authorizedType='ADMIN' page='addgroup' Component={pageContainer} />} />
            <Route exact path="/:groupId/contract/:contractId/" render={props => <AuthCheckWrapper {...props} authorizedType='ADMIN' page='contract' Component={pageContainer} />} />
            <Route exact path="/:groupId/addcontract" render={props => <AuthCheckWrapper {...props} authorizedType='ADMIN' page='addcontract' Component={pageContainer} />} />
            <Route exact path="/brands" render={props => <AuthCheckWrapper {...props} authorizedType='ADMIN' page='brands' Component={pageContainer} />} />
            <Route exact path="/brand/:brandId/" render={props => <AuthCheckWrapper {...props} authorizedType='ADMIN' page='brand' Component={pageContainer} />} />
            <Route exact path="/markets" render={props => <AuthCheckWrapper {...props} authorizedType='ADMIN' page='markets' Component={pageContainer} />} />
            <Route exact path="/market/:marketId/" render={props => <AuthCheckWrapper {...props} authorizedType='ADMIN' page='market' Component={pageContainer} />} />
            <Route exact path="/addbrand" render={props => <AuthCheckWrapper {...props} authorizedType='ADMIN' page='addbrand' Component={pageContainer} />} />
            <Route exact path="/logout" render={props => <AuthCheckWrapper {...props} authorizedType='USER' Component={LogOutComponent} />} />
            <Route exact path="/createbrand" render={props => <AuthCheckWrapper {...props} authorizedType='USER' Component={CreateBrandComponent} />} />
            <Route exact path="/selectbrand" render={props => <AuthCheckWrapper {...props} authorizedType='USER' Component={SelectBrandComponent} />} />
            <Route exact path="/summary" render={props => <AuthCheckWrapper {...props} authorizedType='USER' page='summary' Component={pageContainer} />} />
            <Route exact path="/competitive" render={props => <AuthCheckWrapper {...props} authorizedType='USER' page='competitive' Component={pageContainer} />} />
            <Route exact path="/competitive/:tab" render={props => <AuthCheckWrapper {...props} authorizedType='USER' page='competitive' Component={pageContainer} />} />
            <Route exact path="/segments" render={props => <AuthCheckWrapper {...props} authorizedType='USER' page='segments' Component={pageContainer} />} />
            <Route exact path="/segments/:tab" render={props => <AuthCheckWrapper {...props} authorizedType='USER' page='segments'  Component={pageContainer} />} />
            <Route exact path="/global" render={props => <AuthCheckWrapper {...props} authorizedType='USER' page='global' Component={pageContainer} />} />
            <Route exact path="/global/:tab" render={props => <AuthCheckWrapper {...props} authorizedType='USER' page='global' Component={pageContainer} />} />
            <Route exact path="/advanced" render={props => <AuthCheckWrapper {...props} authorizedType='USER' page='advanced' Component={pageContainer} />} />
            <Route exact path="/advanced/:tab" render={props => <AuthCheckWrapper {...props} authorizedType='USER' page='advanced' Component={pageContainer} />} />
            <Route exact path="/addmarket" render={props => <AuthCheckWrapper {...props} authorizedType='ADMIN' page='addmarket' Component={pageContainer} />} />
            <Route exact path="/addsurvey" render={props => <AuthCheckWrapper {...props} authorizedType='ADMIN' page='addsurvey' Component={pageContainer} />} />
            {/* <Route exact path="/quest-suite" render={props => <AuthCheckWrapper {...props} authorizedType='USER' page ='quest-suite' Component={pageContainer} />} /> */}
          </Switch>
        </div>
      </Sentry.ErrorBoundary>
  )
}
export default App;
