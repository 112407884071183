import React, { Component } from 'react';
import BrandLogo from "../../assets/icons/harris-logo.png";
import { logOut } from '../LoginComponent/Login.helpers';
import { getLocalStorage } from '../../utils/storageHelpers';
import './FailComponent.scss';
import { withRouter } from 'react-router-dom';

class FailComponent extends Component {
    constructor(props) {
        super(props);

        this.messages = {
            account: {
                title: 'Unable to Create Account',
                description: 'We appear to be having difficulties creating your account.',
            },
            contracts: {
                title: 'No Active Contracts',
                description: 'It looks like all your contracts have expired.',
            },
        };
    }
    onPressStartButton() {
        if (getLocalStorage('token')) {
            logOut();
        } else {
            this.props.history.push("/");
        }
    }

    render() {
    const { failType } = this.props;
    const { description, title } = this.messages[failType];

    return (
        <div className="failBackground">
            <div className="failContainer">
                <div className="failHeader">
                    <img
                        src={BrandLogo}
                        className="logo"
                        alt="HICOMPASS"
                        style={{width: '251px', height: '50px'}}
                    />
                </div>
                <div className="failContent">
                    <div className="failTitle">{title}</div>
                    <div className="description">
                        {description} <br/>Contact <a href="mailto:hbp@harrispoll.com">hbp@harrispoll.com</a> for support.
                    </div>
                </div>
                <div className="buttonContainer">
                    <div
                        className="blueButton"
                        onClick={this.onPressStartButton.bind(this)}
                    >
                        Back to Log In
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default withRouter(FailComponent);
