import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './InfoModal.scss';

export default class InfoModal extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        extraMessage: PropTypes.string.isRequired,
        subjectInfo: PropTypes.shape({
            title: PropTypes.string.isRequired,
            subtitle: PropTypes.string.isRequired,
        }).isRequired,
        buttons: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            action: PropTypes.func.isRequired,
        }))
    }

    render() {
        const { title, message, extraMessage, buttons, subjectInfo } = this.props;
        
        return (
            <div className="modalOverlay infoModal">
                <div className= {title !== 'Remove User'?"modalContent infoModalContent":"modalContent infoModalContent removeUser"}>
                    <h2 className={'infoModalTitle'}>{title}</h2>
                    <div className="infoContent">
                        <p className={'message'}>{message}</p>
                        <p className={'message red'}>{extraMessage}</p>
                    </div>
                   {this.props.isLogoChange === true?'': <div className={'extraInfoBlock'}>
                        <p className={'extraInfo title'}>{`${subjectInfo?.title}`}</p>
                        <p className={'extraInfo'}>{subjectInfo?.subtitle}</p>
                    </div>
                    }
                    <div className={'buttonGroup'}>
                        <div className="whiteButton infoModalButton" onClick={buttons[0]?.action}>
                            {buttons[0]?.name}
                        </div>
                        <div className={this.props.isUpdateModalOpened?"blueButton infoModalButton":"redButton infoModalButton"} onClick={buttons[1].action}>
                            {buttons[1].name}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
