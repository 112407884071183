import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const useClickedOutside = (ref, handler) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                if (typeof handler === 'function') {
                    handler();
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [ref, handler]);
}

export default function OutsideClickCather(props) {
    const {  children, handler } = props;

    const wrapperRef = useRef(null);
    useClickedOutside(wrapperRef, handler);

    return (
        <div ref={wrapperRef}>{children}</div>
    );
}

OutsideClickCather.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    handler: PropTypes.func,
};
