import combineEvents from '../../utils/combineEvents';
import {
	SET_CHART_DATA,
	DISABLE_BRAND,
	ENABLE_BRAND,
	DISABLE_SEGMENT,
	ENABLE_SEGMENT,
	CLEAR_ALL_DATA,
} from './actions';

const initialState = {
	hiddenList: [],
	chartData: [],
};

export default combineEvents({
	[SET_CHART_DATA]: (state, { chartData }) => ({
		...state,
		chartData,
	}),
	[DISABLE_SEGMENT]: (state, { id }) => ({
		...state,
		hiddenList: [...state.hiddenList, id],
	}),
	[DISABLE_BRAND]: (state, { id }) => ({
		...state,
		hiddenList: [...state.hiddenList, id],
	}),
	[ENABLE_SEGMENT]: (state, { id }) => ({
		...state,
		hiddenList: state.hiddenList.filter((hiddenId) => hiddenId !== id),
	}),
	[ENABLE_BRAND]: (state, { id }) => ({
		...state,
		hiddenList: state.hiddenList.filter((hiddenId) => hiddenId !== id),
	}),
	[CLEAR_ALL_DATA]: () => initialState,
}, initialState);
