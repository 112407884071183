import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './FilterCommonItem.scss';

const FilterCommonItem = (props) => {

    const onClickSelf = () => {
        const {categoryObj, filterObj, optionObj, active, filterType} = props;
        if (props.onToggle) {
            props.onToggle(categoryObj, filterObj, optionObj, !active, filterType)
        }
    }

    const escapeRegExp = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    const {title, active, searchKey, searchFound, isExpired} = props;
    const matchOption = title?.toLowerCase()?.includes(searchKey?.toLowerCase());
    return (
        <div className={cx("filterCommonItemContainer", {"active": active}, {"isExpired": isExpired})}
            onClick={onClickSelf}>
            {(searchFound && matchOption) ?
                <div className="filterOptionTitle" dangerouslySetInnerHTML={{ __html: title.toLowerCase()?.replace(new RegExp(escapeRegExp(searchKey)), match => `<span class='match'>${match}</span>`) }} />:
                <div className="filterOptionTitle">{title}</div>
            }
        </div>
    )
}

FilterCommonItem.propTypes = {
    oId: PropTypes.number,
    title: PropTypes.string,
    active: PropTypes.bool,
    onToggle: PropTypes.func,
};

FilterCommonItem.defaultProps = {
    oId: 0,
    title: 'Default',
    active: false,
};

export default FilterCommonItem;

