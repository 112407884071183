import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CircleProgressBar from './Shared/CircleProgressBar';
import SquareRadioButton from '../widgets/OptionFilters/SquareRadioButton';
import initData from '../../utils/initData';
import "./ExportDocumentsDropdown.scss";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const Tooltip = styled(Tippy)`
  background-color: white;
  color: #4c4d4e;
  border: 1px solid #DFE2EA;
  box-shadow: 0 2px 12px 0 #DFE2EA;
`;
export default class ExportDocumentsDropdown extends Component {
    static propTypes = {
        closeDropdown: PropTypes.func.isRequired,
        fileList: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            downloadProgress: PropTypes.number.isRequired,
            date: PropTypes.string.isRequired,
        })),
        getExportFile: PropTypes.func.isRequired,
        initExportTemplate: PropTypes.func.isRequired,
        currentRoute: PropTypes.string.isRequired,
    }

    state = {
        selectedRadioOption: initData.getExportOptions(this.props.currentRoute).options[0],
        currentFormat: initData.getExportFormatsOptions()[0],
    }
    progressBarColors = initData.getProgressBarColors();

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

        try {
            this.props.initExportTemplate();
        } catch(error) {
            console.log('Export template init error', error);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    handleClickOutside = (event) => {
        const exportButton = document.querySelector('#exportBtn');
        const { closeDropdown } = this.props;

        if(exportButton && exportButton.contains(event.target)) return;
        else if(this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            closeDropdown();
        }
    }

    setCurrentFormat = (currentFormat) => {
        this.setState({
            currentFormat,
        });
    }

    setCurrentOption = (selectedRadioOption) => {
        const formatOptions = initData.getExportFormatsOptions();
        const { currentFormat } = this.state;
        const { currentRoute } = this.props;
        let nextFormat = currentFormat.id === 2 || currentFormat.id === 3 ? currentFormat : formatOptions[1];
        nextFormat = (currentRoute === '/advanced' && selectedRadioOption.id === 2) ? formatOptions[2] : nextFormat;
        this.setState({
            selectedRadioOption,
            currentFormat: selectedRadioOption === 1 ? currentFormat : nextFormat,
        });
    }

    downloadXlsx = (url,fileName) => {
        const link = document.createElement('a');
        link.href = url ;
        link.download = fileName;
        link.setAttribute('download', fileName);
        link.click();
    }

    renderFileList = () => {
        const { fileList } = this.props;
        const { pathColor, trailColorMain } = this.progressBarColors;

        return fileList.length ?
            (
                <div className={'fileList'}>
                    <div className={'fileListTitle'}>Files</div>
                    {
                        fileList.map(file => {
                            return (
                                <div className={'fileListString'} key={`filelist_${file.id}`}>
                                    <div className={'fileListItem'}>
                                        <div className={'fileListItem_name'}>{file.name}</div>
                                        <div className={'fileListItem_date'}>{file.date}</div>
                                    </div>

                                    {file.isLoaded && file.url ?
                                    file.fileType !== 'xlsx' ? <a className={'fileListLink'} href={file.url} download={file.name}>Download</a>:
                                    <div className={'fileListLink'} onClick={()=>this.downloadXlsx(file.url,file.name)}>Download</div>:
                                    file.isLoaded && !file.url && file.icon ?
                                    file.errorMessage?.length > 0 ? <>
                                    <Tooltip
                                        allowHTML={true} placement="top"
                                        arrow={false}
                                        content={<span style={{fontSize: "14px"}}>{file.errorMessage}</span>}>
                                        <div style={{ fontSize: "12px" }}><img src={file.icon} alt="File icon" /></div> 
                                    </Tooltip></> 
                                    :<img src={file.icon} alt="File icon" />  
                                    :<div className={'progressBarContainer'}>
                                        <CircleProgressBar
                                            progress={file.downloadProgress}
                                            strokeWidth={8}
                                            pathColor={pathColor}
                                            trailColor={trailColorMain}
                                        />
                                    </div>}
                                </div>
                            )
                        })
                    }
                </div>
            ) : null;
    }

    renderFormatList = (selectedRadioOption) => {
        const { currentFormat } = this.state;
        const { currentRoute, selectedTab } = this.props;
        const perceptualTab = 1;
        const excelFormat = 'xlsx';

        return (
            <div className={'formatContainer'}>
                {initData.getExportFormatsOptions(selectedRadioOption.id).map(format => {
                    const isCurrentFormat = currentFormat.id === format.id;
                    // https://jira.hubub.com/browse/COMPII-416
                    const hideExcelExport = currentRoute === '/advanced' && selectedTab === perceptualTab && format.name === excelFormat;
                    const hidePptExport = currentRoute === '/advanced' && selectedRadioOption.id === 2 && format.name === 'pptx';
                 
                    // https://jira.hubub.com/browse/COMPII-393
                    if (currentRoute === '/summary' && format.name === 'xlsx') {
                        format.disabled = true;
                    }
                    return (
                        <div
                            key={format.id}
                            className={cn(
                                "formatItem",
                                {
                                    "formatItem--selected": isCurrentFormat,
                                    "formatItem--disabled": format.disabled,
                                    "formatItem--hidden": (hideExcelExport||hidePptExport),
                                }
                            )}
                            onClick={format.disabled? null : () => this.setCurrentFormat(format)}
                        >
                            <img src={format.icon} alt="File format icon" />
                        </div>
                    )
                })}
            </div>
        )
    }

    renderDownloadOptions = () => {
        const { currentRoute, selectedTab } = this.props;
        const optionsData = initData.getExportOptions(currentRoute, selectedTab);
        return (
            <div className={'radioButtonGroup'}>
                <div className={'radioButtonGroupTitle'}>{optionsData.title}</div>
                {optionsData.options.map(option => {
                    const { selectedRadioOption } = this.state;
                    const checked = selectedRadioOption.id === option.id;
                    const disabled = false;

                    return (
                        <SquareRadioButton
                            key={option.id}
                            option={option}
                            checked={checked}
                            onChange={() => this.setCurrentOption(option)}
                            disabled={disabled}
                        />
                    )
                })}
            </div>
        )
    }

    onPressDownload = () => {
        const { getExportFile } = this.props;
        const { currentFormat, selectedRadioOption } = this.state;

        getExportFile(currentFormat.name, selectedRadioOption.id);
    };

    render() {
        const { selectedRadioOption } = this.state;
        const { isDataAvailable } = this.props
        return (
            <div className={'exportDropdown'} ref={this.setWrapperRef}>
                {this.renderFileList()}
                {this.renderDownloadOptions()}
                {this.renderFormatList(selectedRadioOption)}
                <div className={'btnContainer'}>
                    <div
                        className={cn("blueButton exportModalButton", {
                            "disabled": !isDataAvailable && selectedRadioOption.id === 1,
                        })}
                        onClick={!isDataAvailable && selectedRadioOption.id === 1 ? null : this.onPressDownload}
                    >
                        Prepare
                    </div>
                </div>
            </div>
        )
    }
}
