import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { XMarkIcon, CheckIcon, PlusIcon } from '@heroicons/react/20/solid';
import initData from '../../../utils/initData';
import './StatusLabel.scss';

const StyleStatus = styled.span`
    display: inline-flex;
    align-items: center;
    & .icon {
        width: 24px;
        color: #1BA379;
        &.inactive {
            color: inherit;
        }
    }
`;
const StatusLabel = ({ status, onClickItemFunction, customClassname }) => {
    const showStatus = initData.getStatus(status?.toLowerCase());
    const statusName = showStatus.name;
    const getIcon = () => {
        let ele;
        if (statusName === 'active') {
            ele = <CheckIcon className="icon" />;
        } else if (statusName === 'inactive') {
            ele = <XMarkIcon className="icon inactive" />
        } else {
            ele = <PlusIcon className="icon" />
        }
        return ele;
    }
    return (
        <StyleStatus className={cn('StatusLabel', { [showStatus.name]: true, [customClassname]: !!customClassname })} onClick={(typeof onClickItemFunction) === 'function' ? () => onClickItemFunction() : null}>
            {getIcon()}<span className='statusText'>{showStatus.label}</span>
        </StyleStatus>
    );

}
export default StatusLabel;
