import { get } from 'lodash';
import { getLocalStorage } from '../utils/storageHelpers';

const basicGroupId = 1;
/*
    new users are automatically placed into Basic group
    to verify that the user is not a new one to the system - check if (s)he is a part of Basic group
 */

const verifyExistingUser = (groups) => {
    const isInBasicGroup = groups.filter(({ id }) => id === basicGroupId).length;

    return !isInBasicGroup;
};

const verifyHasActiveContracts = (activeContracts) => activeContracts > 0;

const verifyExistingUserWithoutContracts = () => {
    const userInfo = JSON.parse(getLocalStorage('userInfo'));
    const activeContracts = get(userInfo, 'activeContracts', 0);
    const groups = get(userInfo, 'groups', []);
    const isExistingUser = verifyExistingUser(groups);
    const hasActiveContracts = verifyHasActiveContracts(activeContracts);

    return (isExistingUser && !hasActiveContracts);
};

export default verifyExistingUserWithoutContracts;
