import React, { Component } from "react";
import PropTypes from "prop-types";

import CardHeader from "../UsersManagement/CardHeader";
import ContractForm from "./ContractForm";
import initData from "../../../utils/initData";
import LoadingLayer from "../../widgets/Shared/LoadingLayer";
import ApiData from "../../../utils/Api";
import AddBrandsGeoModal from "./AddBrandsGeoModal";
import "../UsersManagement/Profile.scss";

export default class CreateContractComponent extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    showMessage: PropTypes.func.isRequired,
  };
constructor(props) {
  super(props);
  this.state = {
    inProgress: false,
    title: "",
    openModal: false,
    brandList: [],
    geoList: [],
    quotas: []
  };
  this.openModalHandler = this.openModalHandler.bind(this);
  this.setBrandListHandler = this.setBrandListHandler.bind(this);
  this.removeMyBrandItem = this.removeMyBrandItem.bind(this);
  this.setGeoListHandler = this.setGeoListHandler.bind(this);
  this.removeGeoColumn = this.removeGeoColumn.bind(this);
  this.editQuota = this.editQuota.bind(this);
}
setBrandListHandler = (newBrands) => {
    const campaignIds = this.state.geoList.map((m) => m.id);
    this.setState({ brandList: [...this.state.brandList, ...newBrands] });
    this.setState({
      quotas: [
        ...this.state.quotas,
        ...newBrands.map((brand) =>
          campaignIds.map((id) => {
            return { brandId: brand.id, quota: null, marketId: id };
          })
        ),
      ].flat(),
    });
  };
  editQuota(brandId, marketId, insertedQuota) {
    const { quotas } = this.state;
    this.setState({
      quotas: quotas.map((quota) =>
        quota.brandId === brandId && quota.marketId === marketId
          ? {
              brandId: quota.brandId,
              marketId: quota.marketId,
              quota: insertedQuota,
            }
          : quota
      ),
    });
  }
  removeGeoColumn = (id) => {
    const { geoList, quotas } = this.state;
    this.setState({
      geoList: geoList.filter((geo) => geo.id !== id),
      quotas: quotas.filter((quota) => quota.marketId !== id),
    });
  };
  removeMyBrandItem(id) {
    const { brandList, quotas, geoList } = this.state;
    console.log(brandList.filter((brand) => brand.id !== id).length)
    this.setState({
      brandList: brandList.filter((brand) => brand.id !== id),
      geoList: !brandList.filter((brand) => brand.id !== id).length? [] : geoList,
      quotas: quotas.filter((quota) => quota.brandId !== id),
    });
  }
  openModalHandler = (childTitle) => {
    this.setState({ title: childTitle });
    this.setState({ openModal: true });
  };
  closeOpenModal = () => {
    this.setState({ openModal: false });
  };
  setGeoListHandler = (newGeos) => {
    console.log(this.state.quotas);
    const brandIds = this.state.brandList.map((m) => m.id);
    this.setState({ geoList: [...this.state.geoList, ...newGeos] });
    this.setState({
      quotas: [
        ...this.state.quotas,
        ...newGeos.map((geo) =>
          brandIds.map((id) => {
            return { brandId: id, quota: null, marketId: geo.id };
          })
        ),
      ].flat(),
    });
  };
  addContract = (contract) => {
    const { types } = initData.getStatusMessages();
    const { history, showMessage } = this.props;
    this.setState({ inProgress: true });

    contract.brandMarketContracts = contract.brandMarketContracts.filter(bcc => bcc.quota !== null);

    ApiData.createContract(contract)
      .then((result) => {
        typeof showMessage === "function" &&
          showMessage(types.createContract, contract.name);
        history.push("/groups");
      })
      .catch((error) => {
        this.setState({ inProgress: false });
      });
  };

  cancelAddingContract = () => {
    this.props.history.push("/groups");
  };

  triggerLoader = (show) => {
    this.setState({ inProgress: show });
}

  render() {
    const addContractData = initData.getContractData();
    const { inProgress, brandList, geoList } = this.state;
    const { match } = this.props;


    return (
      <div className={"profile"}>
        <CardHeader
          primaryTitle={addContractData.title(addContractData.modes.create)}
          secondaryTitle={addContractData.subtitle(
            addContractData.modes.create
          )}
        />
        {this.state.openModal && (
          <AddBrandsGeoModal
            setBrandListHandler={this.setBrandListHandler}
            setGeoListHandler={this.setGeoListHandler}
            title={this.state.title}
            closeOpenModal={this.closeOpenModal}
            currentBrandList={brandList}
            currentGeoList={geoList}
          />
        )}
        <div className={"profileContent"}>
          <ContractForm
            quotas={this.state.quotas}
            geoList={this.state.geoList}
            brandList={this.state.brandList}
            editQuota={this.editQuota}
            removeGeoColumn={this.removeGeoColumn}
            removeMyBrandItem={this.removeMyBrandItem}
            setBrandListHandler={this.setBrandListHandler}
            openModalHandler={this.openModalHandler}
            cancelForm={this.cancelAddingContract}
            createContract={this.addContract}
            editMode={addContractData.modes.create}
            groupId={match.params.groupId}
            triggerLoader={this.triggerLoader}
          />
        </div>
        {inProgress && <LoadingLayer />}
      </div>
    );
  }
}
