import React, { Component } from 'react';
import moment from 'moment';
import LoadingLayer from '../../widgets/Shared/LoadingLayer';
import ListTable from '../../widgets/Shared/ListTable';
import initData from '../../../utils/initData';
import ApiData from '../../../utils/Api';
import ApproveBrandComponent from './ApproveBrand';
import SearchComponent from '../../widgets/Shared/SearchComponent';
import  { ADMINSEARCH_TERM } from '../../../reducers/AdminSearch/SearchReducer'
import { connect } from 'react-redux';
import Toaster from '../../widgets/Toaster';

 class BrandsManagementComponent extends Component {
    constructor(props) {
        super(props);
        this.approvedBrandsColumns = initData.getColumns(4);
        this.pendingBrandsColumns = initData.getColumns(5);
        this.sortingTypes = initData.getSortingTypes();
        const approvedActiveSortColumn = this.approvedBrandsColumns.find(column => column.sorting === 'active');
        const pendingActiveSortColumn = this.pendingBrandsColumns.find(column => column.sorting === 'active');

        this.state = {
            listData:[],
            loading: true,
            page: 1,
            pageLimit: 150,
            offsetHeight: 0,
            approveId: 0,
            approvedBrandsSorting: {
                key: approvedActiveSortColumn.field,
                type: this.sortingTypes.asc,
            },
            pendingBrandsSorting: {
                key: pendingActiveSortColumn.field,
                type: this.sortingTypes.asc,
            },
            toasts: [],
            toastConfig: {
                autoDismiss: false,
                dismissTime: 2000 // 2s
            },
        }
    }

    getListData = () => {
        this.setState({loading: true, listData: []});
        const {offsetHeight, page, pageLimit, approvedBrandsSorting, pendingBrandsSorting} = this.state;
        const {selectedTab, searchKey} = this.props;
        const listData = [];
        const approvedBrandSort = approvedBrandsSorting.key;
        const approvedBrandSortType = this.sortingTypes.asc === approvedBrandsSorting.type;
        const pendingBrandSort = pendingBrandsSorting.key;
        const pendingBrandSortType = this.sortingTypes.asc === pendingBrandsSorting.type;

        if (selectedTab===0) {
            ApiData.getBrandList('all', page, pageLimit, approvedBrandSort, approvedBrandSortType, searchKey).then(
                (result) => {
                    result.data.forEach(item=>{
                        const group = item && item.groupsList ? item.groupsList.filter((group, index) => index < 2).map(group => group).join(', ') : '';
                        const groupTrail = item.groupsList && item.groupsList.length > 2 ? ` (+${item.groupsList.length - 2} more)` : '';
                        const campaigns = item && item.marketsList ? item.marketsList.filter((campaign, index) => index < 2).map(market => market).join(', ') : '';
                        const campaignsTrail = item.marketsList && item.marketsList.length > 2 ? ` (+${item.marketsList.length - 2} more)` : '';
                        listData.push({
                            id: item.id,
                            brand: { name: item.displayName, decipherName: item.name, logo: item.logo },
                            displayName: item.displayName,
                            brandId: item.externalId+'-'+item.id, tag: item.tag,
                            brandGroups: {
                                main: group,
                                trail: groupTrail,
                                total: item && item.groupsList ? item.groupsList.filter((group, index) => index > 1).map(group => group).join(', ') : ''
                            }, 
                            campaignGroups: {
                                main: campaigns,
                                trail: campaignsTrail,
                                total: item && item.marketsList ? item.marketsList.filter((campaign, index) => index > 1).map(market => market).join(', ') : ''
                            },
                            industry:item?.industry,
                            subIndustry:item?.subIndustry,
                            totalCompletes: item.totalCompletes,
                            contracts: item.anyContracts? item.activeContracts : "No contracts",
                            expired: item.expired,
                            status: item.status,
                        });
                    });

                    this.setState({
                        selectedTab: 0,
                        listData,
                        loading: false,
                        page: page,
                        pageSize: result.pageInfo.pageSize,
                        totalCount: result.pageInfo.totalCount,
                    }, () => {
                        window.scrollTo(0, offsetHeight);
                    });
                }
            ).catch(
                (err) => {
                    console.log("error=" + err );
                    this.setState({loading: false});
                }
            );
        } else if (selectedTab===1) {
            ApiData.getBrandList('pending', page, pageLimit, pendingBrandSort, pendingBrandSortType, searchKey).then(
                (result) => {
                    result.data.forEach(item=>{
                        listData.push({
                            id: item.id,
                            brand: { name: item.displayName, decipherName: item.name, logo: item.logo },
                            decipherName: item.name,
                            displayName: item.displayName,
                            tag: item.tag,
                            industry:item?.industry,
                            subIndustry:item?.subIndustry,
                            createdBy: item.createdBy ? item.createdBy : '',
                            createdOn: moment(item.createdOn).format('MM/DD/YYYY')
                        })
                    });
                    this.setState({
                        selectedTab: 1,
                        listData,
                        loading: false,
                        page: page,
                        pageSize: result.pageInfo.pageSize,
                        totalCount: result.pageInfo.totalCount,
                    }, () => {
                        window.scrollTo(0, offsetHeight);
                    });
                }
            ).catch(
                (err) => {
                    console.log("error=" + err );
                    this.setState({loading: false});
                }
            );
        }   
    }

    sortApprovedBrands = (column) => {
        const { approvedBrandsSorting } = this.state;
        const type = approvedBrandsSorting.type === this.sortingTypes.asc ? this.sortingTypes.desc : this.sortingTypes.asc;

        this.setState({
            approvedBrandsSorting: { key: column.field, type },
        }, this.getListData);
    }

    sortPendingBrands = (column) => {
        const { pendingBrandsSorting} = this.state;
        const type = pendingBrandsSorting.type === this.sortingTypes.asc ? this.sortingTypes.desc : this.sortingTypes.asc;

        this.setState({
            pendingBrandsSorting: { key: column.field, type },
        }, this.getListData);
    }

    openBrandProfile = (id) => {
        const { history } = this.props;
        history.push(`/brand/${id}`)
    }

    addNewBrand = () => {
        this.props.history.push('addbrand');
    }
    showToast = (params) => {
        let toastId = Math.floor((Math.random() * 101) + 1);

        let toastProperties = {
            id: toastId,
            title: params.title ?? "Notification",
            content: params.content,
            action: params.action ?? null,
            preventAutoDismiss: params.preventAutoDismiss ?? false
        };

        this.setState({ toasts: [toastProperties]  });
    }
    
    exportData = (type) => {
        switch (type) {
            case "brand":
                this.showToast({
                    title: "Data Export",
                    content: "Please Wait! Data is being exported.",
                    action: { type: "text", content: "Processing" }
                });
                ApiData.getBrandExportDocument()
                .then(response => {
                    const downloadLink = window.URL.createObjectURL(new Blob([response.data]));

                    this.showToast({
                        title: "Data Export",
                        content: "Brands export is ready for download.",
                        action: {
                            type: "download",
                            fileName: `QuestBrand-brands-export-${moment().format("MMDDYYYY")}.xlsx`,
                            link: downloadLink
                        },
                        preventAutoDismiss: true
                    });
                })
                .catch(error => {
                    console.log('Getting export file error', error);
                    this.showToast({
                        title: "Data Export",
                        content: "We are having some trouble exporting the data.",
                        action: { type: "text", content: "Error" }
                    });
                })
                break;

            default:
                break;
        }
       
    }

    componentDidMount() {
        this.getListData();
       // window.addEventListener('scroll', this.lazyLoad);
    }

    componentDidUpdate(nextProps) {
        if (nextProps.selectedTab !== this.props.selectedTab) {
            this.setState({page: 1})
        }
        const {searchKey } = this.props;
        if(searchKey !==nextProps.searchKey && searchKey !==''){
            clearTimeout(this.debounceTimer);
               this.debounceTimer = setTimeout(() => this.getListData(true),1500);
            }
    }
    render() {
        const { listData, loading, approveId, pageSize, totalCount, page, approvedBrandsSorting, pendingBrandsSorting } = this.state;
        const { selectedTab, showMessage} = this.props;
        let dataItemsContainerCls = "dataItemsContainer" + (loading ? " loading":"");
        return (
            <div>
                <Toaster 
                    toastList={this.state.toasts}
                    position="bottom-left"
                    autoDismiss={this.state.toastConfig.autoDismiss}
                    dismissTime={this.state.toastConfig.dismissTime}
                />
                <div className="actionableButtons">
                {selectedTab===0 && <SearchComponent/> }
                {selectedTab > 0 && <SearchComponent/> }
                {selectedTab===0 &&
                        <div className="button orangeButton" onClick={() => this.exportData('brand')}>
                            Export Brands
                        </div>
                    }
                <div className="button blueButton" onClick={this.addNewBrand}>
                    Add New Brand
                </div>
                </div>
                {approveId > 0 && 
                <div className="modalLayer">
                    <ApproveBrandComponent
                        showMessage={showMessage}
                        approveBrand={listData.find(l=>{return l.id===approveId})}
                        act={selectedTab===1?'Approve': 'Edit'}
                        closeFunction={()=>this.setState({approveId: 0}, this.getListData)}
                    />
                </div>}
                <div className={dataItemsContainerCls} style={{overflowX:"auto"}}>
                    {loading && <LoadingLayer />}
                    {listData.length>0 &&
                    <ListTable
                        listData={listData}
                        total={totalCount}
                        limit={pageSize}
                        current={page}
                        listColumns={selectedTab===1?initData.getColumns(5):initData.getColumns(4)}
                        handlePageChange={(current)=>this.setState({page: current}, this.getListData) }
                        onItemClick={selectedTab===0 ? this.openBrandProfile : selectedTab===1?(id)=>this.setState({approveId: id }) : null}
                        sortedColumn={selectedTab === 0 ? approvedBrandsSorting : pendingBrandsSorting}
                        sortTable={selectedTab === 0 ? this.sortApprovedBrands : this.sortPendingBrands}
                    />}
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
      searchKey: state.SearchReducer.adminsearch_term,
    };
  };
  const mapDispatchToProps = (dispatch) => ({
    searchUser: (value) => dispatch({ type: ADMINSEARCH_TERM, payload: value }),  
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BrandsManagementComponent);


