import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import "./OptionsDropdown.scss";

export default class OptionsDropdown extends Component {
    static propTypes = {
        closeDropdown: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            action: PropTypes.func.isRequired,
        })).isRequired,
        separateOptions: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            action: PropTypes.func.isRequired,
        })),
        buttonId: PropTypes.string.isRequired,
        style: PropTypes.object,
        customClassname: PropTypes.string,
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    handleClickOutside = (event) => {
        const { buttonId } = this.props;
        const optionsBtn = document.querySelector(`#${buttonId}`);
        const { closeDropdown } = this.props;
        if(optionsBtn && optionsBtn.contains(event.target)) return;
        else if(this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            closeDropdown();
        }
    }

    renderOptions = () => {
        const { options } = this.props;
        return (
            <div className={'optionsBlock'}>
                {
                    options.map(option => {
                        const clickHandler = (event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            typeof option.action === 'function' && option.action();
                        }
                        return (
                            <div key={option.name} className={'optionItem'} onClick={clickHandler}>
                                {option.label}
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderSeparateOptions = () => {
        const { separateOptions } = this.props;

        return (
            <div className={'optionsBlock'}>
                {
                    separateOptions.map(option => {
                        const clickHandler = (event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            typeof option.action === 'function' && option.action();
                        }
                        return (
                            <div key={option.name} className={'optionItem separate'} onClick={clickHandler}>
                                {option.label}
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    render() {
        const { customClassname, style, separateOptions, options } = this.props;
        const deleteBrandOptionExists = separateOptions && separateOptions.some(option => option.name === 'delete_brand')
        return (
            <div className={cn('optionsDropdown', {[customClassname]: !!customClassname})} ref={this.setWrapperRef} style={style}>
                {!!options && !!options.length && this.renderOptions()}
                {separateOptions && !!separateOptions.length && !deleteBrandOptionExists &&
                    <>
                        {!!options && !!options.length && <div className={'divider'} />}
                        {this.renderSeparateOptions()}
                    </>
                }
                
            </div>
        )
    }
}
