import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'materialize-css';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import searchIcon from "../../../assets/icons/icon_search.svg";


const StyledFormLabel = styled(FormLabel)`
&.MuiFormLabel-root {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
}
`;
const InputBox = styled(Input)`
    background-color: #fff;
    border: 0.5px solid #dfe2ea;
    border-radius: 2px;
    & > .MuiSelect-root {
        min-height: 24px;
    }
    &: before, &:after {
        display: none;
    }
    & > .icon {
        width: 30px;
        pointer-events: none;
    }
    & > .prefixIcon {
        pointer-events: none;
    }
`;

const ComboBox = ({ icon, name='', data=[], placeholder = 'Search', style={}, value, setValue }) => {
    const [checkboxValues, setCheckboxValues] = useState(data.map(({name}) => {
        return (value.indexOf(name) >= 0)
    }));

    const getDefaultIcon = () => {
        if (icon) {
            return <>{icon}</>
        }
        return (<>
            <img src={searchIcon} className="prefixIcon" alt='search'  />
        </>);
    }
    const handleChange = (event) => {
        setValue(event.target.value);
    }
    const handleOptionClick = (event, position) => {
        const updatedCheckedState = checkboxValues.map((item, index) =>
        index === position ? !item : item
        );
        setCheckboxValues(updatedCheckedState);
    }
    useEffect(() =>{
        if(data?.length > 0){
            setCheckboxValues(data.map(({name}) => {
                return (value.indexOf(name) >= 0)
            }));
        }
    },[data]);
    return (
        <FormControl style={{...style}}>
            <StyledFormLabel component="legend">{name}</StyledFormLabel>
            <Select
                labelId={`${name}-label`}
                id={name}
                multiple
                displayEmpty
                value={value}
                input={<InputBox />}
                IconComponent={() => getDefaultIcon()}
                onChange={handleChange}
                renderValue={(selected) => {
                    return selected.join(', ');
                }}
            >
                <MenuItem value="" disabled><em>{placeholder}</em></MenuItem>
                {data.map((value, index) => (
                    <MenuItem key={index} value={value.name} onClick={(event) => handleOptionClick(event, index)}>
                        <input type="checkbox" checked={checkboxValues[index]} id={value.name}/>
                        {value.name}<span style={{ 'marginLeft': 'auto' }}>{value.count}</span>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
};

export default ComboBox;
