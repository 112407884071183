import {
    DISABLE_Z_AXIS,
    ENABLE_Z_AXIS,
    SET_SEGMENTS,
    SET_CUSTOM_SEGMENTS,
    DISABLE_SEGMENT,
    DISABLE_SEGMENTS,
    DISABLE_CUSTOM_SEGMENT,
    DISABLE_CUSTOM_SEGMENTS,
    ENABLE_SEGMENT,
    ENABLE_CUSTOM_SEGMENT,
    SET_BRANDS,
    DISABLE_BRAND,
    ENABLE_BRAND,
    SET_CHART_DATA,
    CLEAR_ALL_DATA,
} from './actions';

export const disableZAxis = () => ({
    type: DISABLE_Z_AXIS,
});

export const enableZAxis = () => ({
    type: ENABLE_Z_AXIS,
});

export const setSegments = (segments) => ({
    type: SET_SEGMENTS,
    segments,
});

export const setCustomSegments = (segments) => ({
    type: SET_CUSTOM_SEGMENTS,
    segments,
});

export const disableSegment = (segmentId) => ({
    type: DISABLE_SEGMENT,
    segmentId,
});

export const disableSegments = (segmentIds) => ({
    type: DISABLE_SEGMENTS,
    segmentIds,
});

export const disableCustomSegment = (customSegmentId) => ({
    type: DISABLE_CUSTOM_SEGMENT,
    customSegmentId,
});

export const disableCustomSegments = (customSegmentIds) => ({
    type: DISABLE_CUSTOM_SEGMENTS,
    customSegmentIds,
});

export const enableSegment = (segmentId) => ({
    type: ENABLE_SEGMENT,
    segmentId,
});

export const enableCustomSegment = (customSegmentId) => ({
    type: ENABLE_CUSTOM_SEGMENT,
    customSegmentId,
});

export const setBrands = (brands) => ({
    type: SET_BRANDS,
    brands,
});

export const disableBrand = (brandId) => ({
    type: DISABLE_BRAND,
    brandId,
});

export const enableBrand = (brandId) => ({
    type: ENABLE_BRAND,
    brandId,
});

export const setChartData = (chartData) => ({
    type: SET_CHART_DATA,
    chartData,
});

export const clearAllData = () => ({
    type: CLEAR_ALL_DATA,
});
