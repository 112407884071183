const patchFilterName = 'conversion_ownership_usage';
const patchFilterOptions = [
	'I regularly purchase/use products or services of this brand',
	'I occasionally purchase/use products or services of this brand',
];
const localStorageFiltersKey = 'filters';
const conversionCategory = 1;
const conversionSubcategory = 'recommend';

/*
	'Conversion: Recommend' option on TwoByTwoChart requires additional filter to return the correct data.
	This service provides the method to patch the payload with conversion_ownership_usage filter if needed.
 */

export default class TwoByTwoChartAxisPatchService {
	#patchData;

	patchAxisPayload = (axisSelect, axisPayload) => {
		const { category, subcategory: { id } } = axisSelect;

		return (category === conversionCategory &&  id === conversionSubcategory)
			? [...axisPayload, this.#patchData]
			: axisPayload;
	};
	#getItemFromLocalStorage = (key) => JSON.parse(window.localStorage.getItem(key));

	#getFilterData = () => {
		const filters  = this.#getItemFromLocalStorage(localStorageFiltersKey) || {};
		let filteredObj;
		filters.map(category => category.filters.find(filter =>{ if(filter.name === patchFilterName) filteredObj = filter}));
		return filteredObj;
	};

	#getPatchData = () => {
		const {
			id: filterId,
			filterOptions,
		} = this.#getFilterData();

		const filterProperties = filterOptions
			.filter(({ value }) => patchFilterOptions.includes(value))
			.map(({ id }) => id);

		return { filterId, filterOptions:filterProperties };
	};
}
