import React, { Component } from 'react';
import LoadingLayer from '../../widgets/Shared/LoadingLayer';
import ListTable from '../../widgets/Shared/ListTable';
import initData from '../../../utils/initData';
import ApiData from '../../../utils/Api';
import SearchComponent from '../../widgets/Shared/SearchComponent';
import  { ADMINSEARCH_TERM } from '../../../reducers/AdminSearch/SearchReducer'
import { connect } from 'react-redux';
import Toaster from '../../widgets/Toaster';
import moment from 'moment';
import {DateTimeHelper, formatDateString, formatDateStringAsUTC} from "../../../utils/dateTimeHelper";
import {MarketHelper} from "./helpers/marketHelper";
import './CampaignManagementComponent.scss'
import {DateTime} from "luxon";

class CampaignManagementComponent extends Component {
    constructor(props) {
        super(props);
        this.campaignColumns = initData.getColumns(6);
        this.sortingTypes = initData.getSortingTypes();
        const campaignActiveSortColumn = this.campaignColumns.find(column => column.sorting === 'active');

        this.state = {
            listData:[],
            loading: true,
            page: 1,
            pageLimit: 150,
            offsetHeight: 0,
            activeCampaignsSorting: {
                key: campaignActiveSortColumn.field,
                type: this.sortingTypes.asc,
            },
            inactiveCampaignsSorting: {
                key: campaignActiveSortColumn.field,
                type: this.sortingTypes.asc,
            },
            toasts: [],
            toastConfig: {
                autoDismiss: false,
                dismissTime: 2000 // 2s
            },
        }
    }

    getListData = () => {
        this.setState({loading: true, listData: []});
        const {offsetHeight, page, pageLimit, activeCampaignsSorting, inactiveCampaignsSorting} = this.state;
        const {selectedTab} = this.props;
        const listData = [];
        const campaignStatus = selectedTab===0 ? 'active' : 'inactive';

        let campaignSort = selectedTab===0 ? activeCampaignsSorting.key : inactiveCampaignsSorting.key;

        if (campaignSort === 'regionState') {
            campaignSort = 'region';
        }
        if (campaignSort === 'contractsCount') {
            campaignSort = 'activeContractsCount';
        }
        if (campaignSort === 'vendorQuota') {
            campaignSort = 'vendorsQuotaSum';
        }
        if (campaignSort === 'modifiedOn') {
            campaignSort = 'updatedAt';
        }

        const campaignSortType = selectedTab===0 ? (this.sortingTypes.asc === activeCampaignsSorting.type) : (this.sortingTypes.asc === inactiveCampaignsSorting.type);

        this.getListDataForTab(campaignStatus, page, pageLimit, campaignSort, campaignSortType, listData, offsetHeight, selectedTab);
    }

    getListDataForTab(campaignStatus, page, pageLimit, campaignSort, campaignSortType, listData, offsetHeight, currentlySelectedTab) {
        const {searchKey} = this.props;
        ApiData.getCampaignList(campaignStatus, page, pageLimit, campaignSort, campaignSortType, searchKey).then(
            (result) => {
                result.data.forEach(item => {
                    const group = item && item.groups ? item.groups.filter((group, index) => index < 2).join(', ') : '';
                    const groupTrail = item.groups && item.groups.length > 2 ? ` (+${item.groups.length - 2} more)` : '';

                    listData.push({
                        id: item.id,
                        name: item.name,
                        regionState: item.regionState,
                        campaignGroups: {
                            main: group,
                            trail: groupTrail,
                            total: item && item.groups ? item.groups.filter((group, index) => index > 1).join(', ') : ''
                        },
                        consumerQuota: item.consumerQuota,
                        vendorQuota: item.vendorQuota,
                        totalCompletes: item.totalCompletes ?? '--',
                        modifiedOn: item.modifiedOn && item.modifiedOn.split('T')[0],
                        contractsCount: item.contractsCount,
                        code: item.code,
                        qCampaign: item.qcampaign,
                        weightQuota: item.weightQuota,
                        lastWeightedAt: DateTimeHelper.formatDateStringAsUTC(item.lastWeightedAt),
                        ingestedCount: item.ingestedCount ?? '--',
                        weightDifference: MarketHelper.calculateIngestionWeighedDifference(item.ingestedCount, item.totalCompletes),
                    });
                });

                this.setState({
                    selectedTab: currentlySelectedTab,
                    listData,
                    loading: false,
                    page: page,
                    pageSize: result.pageInfo.pageSize,
                    totalCount: result.pageInfo.totalCount,
                }, () => {
                    window.scrollTo(0, offsetHeight);
                });
            }
        ).catch(
            (err) => {
                console.log("error=" + err);
                this.setState({loading: false});
            }
        );
    }

    sortActiveCampaigns = (column) => {
        const { activeCampaignsSorting } = this.state;
        const type = activeCampaignsSorting.type === this.sortingTypes.asc ? this.sortingTypes.desc : this.sortingTypes.asc;

        this.setState({
            activeCampaignsSorting: { key: column.field, type },
        }, this.getListData);
    }

    sortInactiveCampaigns = (column) => {
        const { inactiveCampaignsSorting } = this.state;
        const type = inactiveCampaignsSorting.type === this.sortingTypes.asc ? this.sortingTypes.desc : this.sortingTypes.asc;

        this.setState({
            inactiveCampaignsSorting: { key: column.field, type },
        }, this.getListData);
    }

    showToast = (params) => {
        let toastId = Math.floor((Math.random() * 101) + 1);

        let toastProperties = {
            id: toastId,
            title: params.title ?? "Notification",
            content: params.content,
            action: params.action ?? null,
            preventAutoDismiss: params.preventAutoDismiss ?? false
        };

        this.setState({ toasts: [toastProperties]  });
    }
    
    exportData = (type) => {
        const userTimezone = DateTime.local().zoneName;
        switch (type) {
            case "market":
                this.showToast({
                    title: "Data Export",
                    content: "Please Wait! Data is being exported.",
                    action: { type: "text", content: "Processing" }
                });
                ApiData.getMarketExportDocument(userTimezone)
                .then(response => {
                    const downloadLink = window.URL.createObjectURL(new Blob([response.data]));

                    this.showToast({
                        title: "Data Export",
                        content: "Brands export is ready for download.",
                        action: {
                            type: "download",
                            fileName: `QuestBrand-market-export-${moment().format("MMDDYYYY")}.xlsx`,
                            link: downloadLink
                        },
                        preventAutoDismiss: true
                    });
                })
                .catch(error => {
                    console.log('Getting export file error', error);
                    this.showToast({
                        title: "Data Export",
                        content: "We are having some trouble exporting the data.",
                        action: { type: "text", content: "Error" }
                    });
                })
                break;

            default:
                break;
        }
       
    }

    componentDidMount() {
        this.getListData();
    }
    componentDidUpdate(nextProps){
        const {searchKey } = this.props;
        if(searchKey !==nextProps.searchKey && searchKey !==''){
            clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(() => this.getListData(true),1500);
            }
        }

    openCampaignDetails = (id) => {
        const { history } = this.props;
        history.push(`/market/${id}`)
    }

    addNewGeo = () => {
        this.props.history.push('addmarket');
    }

    render() {
        const { listData, loading, pageSize, totalCount, page, activeCampaignsSorting, inactiveCampaignsSorting} = this.state;
        const { selectedTab} = this.props;
        let dataItemsContainerCls = "dataItemsContainer" + (loading ? " loading":"");
        return (
            <div>
                <Toaster 
                    toastList={this.state.toasts}
                    position="bottom-left"
                    autoDismiss={this.state.toastConfig.autoDismiss}
                    dismissTime={this.state.toastConfig.dismissTime}
                />
                <div className="actionableButtons">
                    <SearchComponent />
                    {selectedTab===0 &&
                        <div className="button orangeButton" onClick={() => this.exportData('market')}>
                            Export Markets
                        </div> 
                    }
                    <div className="button blueButton" onClick={this.addNewGeo}>
                        Add New Market
                    </div>
                </div>
                <div className={dataItemsContainerCls} style={{overflow:"hidden"}}>
                    {loading && <LoadingLayer />}
                    {listData.length>0 &&
                    <ListTable
                        listData={listData}
                        total={totalCount}
                        limit={pageSize}
                        current={page}
                        listColumns={initData.getColumns(6)}
                        handlePageChange={(current)=>this.setState({page: current}, this.getListData) }
                        sortedColumn={selectedTab === 0 ? activeCampaignsSorting : inactiveCampaignsSorting}
                        sortTable={selectedTab === 0 ? this.sortActiveCampaigns : this.sortInactiveCampaigns}
                        onItemClick = {this.openCampaignDetails}
                        selectedPage={'brands'}
                    />}
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
      searchKey: state.SearchReducer.adminsearch_term,
    };
  };
  const mapDispatchToProps = (dispatch) => ({
    searchUser: (value) => dispatch({ type: ADMINSEARCH_TERM, payload: value }),  
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CampaignManagementComponent);
