import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const DotIcon = styled(Typography)`
position: relative;
color: #71767D;
&:before {
    content: '';
    height: 5px;
    width: 5px;
    background: red;
    position: absolute;
    top: 8px;
    left: -10px;
    border-radius: 100%;
}
`;

const ShowTabCount = ({ data=[] }) => {
    return (<Box p={1} style={{ display: 'flex', columnGap: '30px', marginLeft: 'auto' }}>
        {data.map((val) => {
            return (
                <Box>
                    <DotIcon variant='body1'>{val.name}</DotIcon>
                    <Typography variant='h4'>{val.count}</Typography>
                </Box>
            );
        })}
    </Box>)
};
export default ShowTabCount;
