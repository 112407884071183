import React, { useEffect } from 'react';
import cx from 'classnames';
import './BrandsDropDownSelect.scss';
import ddIcon from '../../../assets/icons/icon_arrow_down.svg';
import BrandTitle from './BrandTitle';
import { IoCloseSharp } from "react-icons/io5";

const BrandsDropDownSelect = (props) => {
    useEffect(() => {
        document.addEventListener('click', outsideClickListener);
        return (() => {
            document.removeEventListener('click', outsideClickListener);
        })
    })
    const onHeaderClick = () => {
        props.onOpenSelect();
        if (!props.open) {
            document.addEventListener('click', outsideClickListener)
        }else{
            document.removeEventListener('click', outsideClickListener)
        }
    }

    const outsideClickListener = () => {
        if (props.open) {
            props.onOpenSelect();
        }
        document.removeEventListener('click', outsideClickListener);
    }

    const handleSelect = (index) => {
        if(multiSelect){
            document.removeEventListener('click', outsideClickListener);
        }else{
            outsideClickListener();
        }
        const {sIndex} = props;
        props.onSelectFunction(index, sIndex);
    }

    const {data , open, selected, skey, hideList, multiSelect, brandSsize, page, selectedTab, filterOn } = props;
    const selectedData = multiSelect ? [] : data[selected];
    if (multiSelect) {
        selected?.length > 0 && selected.forEach(s=>{
            if (!hideList || (Array.isArray(hideList) && hideList.indexOf(s)<0)) {
                selectedData.push(data[s]);
            }
        })
    }
    let brandNames = selectedData?.length > 4 && selectedData.slice(4).map(obj=> obj?.displayName?obj?.displayName:obj?.name);
    return (
        <div className={cx('dropDown', { 'open': open })}>
            <div className="dropDownHeaderContainer" onClick={onHeaderClick}>
                <img className="ddArrow" src={ddIcon} alt="" />
                {multiSelect ?
                    <div className="multiSelected">
                        <div className="selectedListConatiner">
                            {selectedData.map((brand, index)=>{
                                const brandStyle = { zIndex: 1-index, left: 30*index+'px'}; 
                                return index<4 && <div className="brandItem" key={index} style={brandStyle}><BrandTitle brand={brand} /></div>;
                            })}
                        </div>
                        {selectedData.length>4 && 
                            <div className="selectedNum">
                                + {selectedData.length-4}
                                {brandNames?.length > 0 && <span className='tooltiptext'>{brandNames?.join(', ')}</span>}
                            </div>
                        }    
                    </div> : 
                    <BrandTitle brand={selectedData} page={page} selectedTab={selectedTab} filterOn={filterOn} dropdown="dropdown"/>  
                }
            </div>
            <div className='dropDownContainer'>
                {multiSelect && 
                    <div className='selectedBrands'>
                        <span>{selectedData?.length} {selectedData?.length>1?'brands':'brand'} selected</span>
                        <IoCloseSharp onClick={(e) => onHeaderClick(e)}/>
                    </div>
                }
                <div className="dropdownListContainer">
                    {data.map((c, index) => {
                        const brandName = c.displayName ? c.displayName : c.name;
                        if (!hideList || (Array.isArray(hideList) && hideList?.indexOf(index)<0)) {
                            const logo = multiSelect?
                            <>
                                <input type='checkbox' checked={selected?.includes(index)}/>
                                {(c.logo) ? 
                                    <img src={c.logo} className="icon" alt={"logo" + index} /> 
                                    :
                                    <div className="icon textIcon">
                                        <div>{brandName?.slice(0, 1).toUpperCase()}</div>
                                    </div>
                                }
                            </>:
                            ((c.logo) ? 
                                <img src={c.logo} className="icon" alt={"logo" + index} /> 
                                :
                                <div className="icon textIcon">
                                    <div>{brandName?.slice(0, 1).toUpperCase()}</div>
                                </div>
                            );
                            return (
                                <div key={skey + index} className={cx("selectItem", {"multi": multiSelect, "selected": (!multiSelect && index === selected)})} onClick={((!multiSelect && index!== selected)||(multiSelect&&(selected?.length>multiSelect||selected?.indexOf(index)<0))) ? () => handleSelect(index) : null}>
                                    {logo}
                                    <div className="name">{brandName}
                                    {(brandSsize && brandSsize[c.id]) && <div className="sSize">n={brandSsize[c.id].toLocaleString('en-En')}</div>}
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        </div>
    )
}

export default BrandsDropDownSelect;

