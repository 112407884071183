import { getLocalStorage, getSessionStorage } from "./storageHelpers";

export const safeParseJSONLocalStorage = (key) => {
    try {
      return JSON.parse(getLocalStorage(key));
    } catch {
      return null;
    }
};

export const safeParseJSONSessionStorage = (key) => {
  try {
    return JSON.parse(getSessionStorage(key));
  } catch {
    return null;
  }
};

