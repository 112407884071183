import React, { Component } from 'react';
import moment from 'moment';
import cx from 'classnames';
import DateInput from 'react-date-picker';
import initData from '../../../utils/initData';
import SimpleSelect from '../Shared/SimpleDropDownSelect';
import ddArrowIcon from '../../../assets/icons/icon_arrow_down.svg';
import './DateSelecter.scss';
import GroupedDropDownSelect from "../Shared/GroupedDropDownSelect";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { setSessionStorage } from '../../../utils/storageHelpers';
export default class RangeSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandPeriodSelection: false,
            expandIntervalSelection: false,
            intervalList: initData.getIntervals(this.props.showMovingAverage),
            marketLastUpdated: false
        }
        this.outsideClickListener = this.outsideClickListener.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.showMovingAverage !== nextProps.showMovingAverage) {
            this.setState({ intervalList: initData.getIntervals(nextProps.showMovingAverage) });
        }
    }

    onSelectInterval(value) {
        const { startdate, enddate, selectedPeriod } = this.props;
        setSessionStorage('previousInterval', value)
        this.setState({
            expandPeriodSelection: false,
            expandIntervalSelection: false
        }, this.props.onChangeFunction(
            selectedPeriod,
            startdate,
            enddate,
            {
                "filterId": this.props.dateFilterId,
                "range":[startdate, enddate]
            },
            value));
    }

    onSelectPeriod(value) {
        this.props.onPeriodChange(true)
        this.props.onPostPeriodDate(false)
        let showMovingAverage = this.props.showMovingAverage;
        let enddate = this.props.enddate;
        let startdate = this.props.startdate;
        let interval = this.props.interval;
        let intervalList;
        let intervalExclude = [];
        switch (value) {
            case 1:
                startdate = moment(startdate)
                    .add(1, 'days')
                    .format('YYYY-MM-DD');
                break;
            case 3:
                startdate = moment()
                    .subtract(30, 'days')
                    .add(1, 'days')
                    .format('YYYY-MM-DD');
                interval = 'WEEKS';
                break;
            case 4:
                startdate = moment()
                    .subtract(90, 'days')
                    .add(1, 'days')
                    .format('YYYY-MM-DD');
                break;
            case 5:
                startdate = moment().year()+'-01-01';
                interval = 'WEEKS';
                break;
            case 6:
                startdate = moment()
                    .subtract(180, 'days')
                    .add(1, 'days')
                    .format('YYYY-MM-DD');
                break;
            default:
                startdate = moment()
                    .subtract(7, 'days')
                    .add(1, 'days')
                    .format('YYYY-MM-DD');
                interval = 'DAYS';
                intervalList = [
                    {id: "DAYS", name: "Days"}
                ];
                break;
        }
        enddate = moment().subtract(1, "days").format('YYYY-MM-DD');
        const range=moment(enddate).diff(startdate, 'days');
        
        if( enddate === moment().format('YYYY-MM-DD')|| value === 5){
        }else{
            startdate =  moment(startdate).subtract(1, 'days').format('YYYY-MM-DD');
        }

    // HBP-1083:Persist Date Period Settings throughout the application.
        let datePeriodResult ={
            selectedPeriod: value,
            startdate: startdate,
            enddate: enddate
        }

        { this.props.page !== "advanced" && setSessionStorage('previousDatePeriod', JSON.stringify(datePeriodResult)) }
        if (this.props.page === "advanced" && this.props.ppLabel === "post") {
            this.props.onPostPeriodDate(true)
        }

        if(this.props.selectedTab === 4 || (this.props.page === 'summary')){
            if(!this.props.selectedInterval){
                interval = 'TWELVEWEEKS';
            }else{
                if(value === 3){
                  if(this.props.selectedInterval === 'MONTHS' || this.props.selectedInterval === 'QUARTERS'){
                        interval = 'TWELVEWEEKS';
                    }else{
                        interval = this.props.selectedInterval
                    }
                    intervalExclude.push(['MONTHS','QUARTERS']);  
                }else if(value === 1){
                    if((this.props.selectedInterval === 'MONTHS' && range < 31 ) || (this.props.selectedInterval === 'QUARTERS' && range< 91)){
                        interval = 'TWELVEWEEKS';
                    }else {
                        interval = this.props.selectedInterval
                    }
                    if(range < 31){
                        intervalExclude.push(['MONTHS','QUARTERS']);
                    }
                }else{
                    interval = this.props.selectedInterval
                }       
            }
        }
        else if (range < 8) {
            if (!showMovingAverage) {
                interval = 'DAYS';
                intervalExclude.push(['WEEKS', 'MONTHS']);
            }
            else {
                interval = 'FOURWEEKS';
                intervalExclude.push(['DAYS', 'MONTHS']);
            }
        } else if (range > 7 && range < 31) {
            if (!showMovingAverage) {
                interval = 'DAYS';
                intervalExclude.push(['MONTHS']);
            }
            else {
                interval = 'FOURWEEKS';
                intervalExclude.push(['DAYS', 'MONTHS']);
            }
        } else if (range > 30 && range < 91) {
            if(!showMovingAverage){
            interval = 'WEEKS';
            }else{
                interval = 'FOURWEEKS';
            }
        }else if (range > 90 && range < 181) {
            if(!showMovingAverage){
            interval = 'WEEKS';
            }else{
                interval = 'TWELVEWEEKS';
            }
        } else {
            if(!showMovingAverage){
                interval = 'WEEKS';
                }else{
                    interval = 'FOURWEEKS';
                }
            intervalExclude.push(['DAYS']);
        }

        intervalList = initData.getIntervals(showMovingAverage, ...intervalExclude);
        let dateFilter = {
            "filterId": this.props.dateFilterId,
            "range":[startdate, enddate]
        };
        this.setState({
            expandPeriodSelection: false,
            expandIntervalSelection: false,
            intervalList
        }, this.props.onChangeFunction(value, startdate, enddate, dateFilter, interval));
    }

    dateChange = (e, datechanged) => {
        this.props.onIntervalChange(true)
        let showMovingAverage = this.props.showMovingAverage;
        let enddate = this.props.enddate;
        let startdate = this.props.startdate;
        let interval = this.props.interval;
        let intervalList = initData.getIntervals(showMovingAverage);
        let intervalExclude = [];
        if (moment(e).isValid()) {
            if (datechanged === 'startdate') {
                startdate =  moment(e).format('YYYY-MM-DD');
            }
            if (datechanged === 'enddate') {
                if( moment(e).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')){
                  enddate =  moment(e).subtract(1, "days").format('YYYY-MM-DD');
                }else{
                    enddate =  moment(e).format('YYYY-MM-DD');
                }
            }
        // HBP-1083:Persist Date Period Settings throughout the application.
            let datePeriodResult ={
                selectedPeriod: 1,
                startdate: startdate,
                enddate: enddate
            }
            {this.props.page !== "advanced" && setSessionStorage('previousDatePeriod',JSON.stringify(datePeriodResult))}

            const range=moment(enddate).diff(startdate, 'days');
            if(this.props.selectedTab === 4 || this.props.page === 'summary'){
                if(this.props.selectedPeriod === 1){
                    if(!this.props.selectedInterval){
                        interval = 'FOURWEEKS';
                    }else{ 
                        interval = this.props.selectedInterval
                        if(range<60){
                            intervalExclude.push(['MONTHS']);
                        }
                    }
                }
            } else if (range < 8) {
                if (!showMovingAverage) {
                    interval = 'DAYS';
                    intervalExclude.push(['WEEKS', 'MONTHS']);
                }
                else {
                    interval = 'FOURWEEKS';
                    intervalExclude.push(['MONTHS']);
                }
            } else if (range > 7 && range < 31) {
                if (!showMovingAverage) {
                    interval = 'DAYS';
                    intervalExclude.push(['MONTHS']);
                }
                else {
                    interval = 'FOURWEEKS';
                    intervalExclude.push(['DAYS', 'MONTHS']);
                }
            } else if (range > 30 && range < 182) {
                if(!showMovingAverage){
                interval = 'WEEKS';
                }else{interval = 'FOURWEEKS';}
            } else {
                if(!showMovingAverage){
                    interval = 'MONTHS';
                    }else{interval = 'FOURWEEKS';}
            }
            
            intervalList = initData.getIntervals(showMovingAverage, ...intervalExclude);
            let dateFilter = {
                "filterId": this.props.dateFilterId,
                "range":[startdate, enddate]
            };
            this.setState({
                expandPeriodSelection: false,
                expandIntervalSelection: false,
                intervalList
            }, this.props.onChangeFunction(1, startdate, enddate, dateFilter, interval));
        }
    }

    onClickPeriodHeader() {
        this.setState({
            expandPeriodSelection: !this.state.expandPeriodSelection
        })
    }

    onClickIntervalHeader() {
        this.setState({
            expandIntervalSelection: !this.state.expandIntervalSelection
        })
    }

    onHeaderClick = (e) => {
        const {sIndex} = this.props;
        if (!this.props.open) {
            document.addEventListener('click', this.outsideClickListener)
        } else {
            document.removeEventListener('click', this.outsideClickListener)
        }
    }

    outsideClickListener() {
        this.setState({marketLastUpdated:false})
        if (this.props.open) {
            this.props.onOpenSelect();
        }
        document.removeEventListener('click', this.outsideClickListener);
    }

    marketUpdated = () => {
        this.setState({marketLastUpdated:true})
    }

    renderMarketPopOver = () => {
        const { data, openTop} = this.props;
        const { marketLastUpdated } = this.state
        return (
            marketLastUpdated &&  <div id="lastupdate" style={{position:'absolute', display: 'block',top: '42px',right: '100px',zIndex: 999}} className={cx("selectListContainer", { openTop: openTop })}>
                    <label className='warninglabel'>Data has been imported into the portal for the market and is current through the date shown.</label>
                    {data.map((campaign, index) => { return (
                            <div key={index} className={'selectCampaignContainer'}>
                                <div className={'campaignNameBox'}>
                                    <span>{campaign[0]}</span>
                                </div>
                                <div className={'campaignRegionBox'}>
                                            <span  className={cx('campaignRegion')}>
                                                {moment(campaign[1].split('T')[0]).format('MM/DD/YYYY')}
                                            </span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderDataLastUpdated() {
        return(
             <div className='iconWarning' onClick={(e) => this.onHeaderClick(e)}>
                <HiOutlineInformationCircle className='iconInformation' onClick={this.marketUpdated}/>
                {this.renderMarketPopOver()}
            </div>

            )
    }

    render() {
        const { startdate, enddate, selectedPeriod, selectedInterval, editInterval, sSize, ppLabel, showMovingAverage, currentVariableGroup, page, prePostLineOption, selectedTab, lineOption} = this.props;
        const {expandPeriodSelection, expandIntervalSelection, intervalList} = this.state;
        const adMinDateCondition = ((page === "segments" || page === "competitive" || page === "global" ) && lineOption?.stab === 3 && (selectedTab === 4)) 
        || (page ==='advanced' && selectedTab === 3 && prePostLineOption?.stab === 3)
        || ((page ==='segments'||page ==='competitive'||page === 'global') && selectedTab === 3)
        || (selectedTab === 5 && currentVariableGroup === 5)
        if(selectedTab === 4){
            intervalList.forEach(function(el) {
              if(el.label === "Periodic"){el.options = el.options.filter(s => s.id !== 'TWELVEWEEKS')}
            });
        }

        return (
            <div className={cx("periodPickerContainer", {"fixedPeriod":selectedPeriod!==1})}>
                {ppLabel &&
                    <div className={"preLabel " + ppLabel}>
                        {ppLabel}
                        <div className="sampleSize">n={Math.round(sSize).toLocaleString('en-En')}</div>
                    </div>}
                <div className="iconContainer">
                    {/* <img src={periodIcon} className="periodIcon" alt="period" /> */}
                    <SimpleSelect data={initData.getPeriodOptions(showMovingAverage)} open={expandPeriodSelection} selected={selectedPeriod} placeholder="Period" skey="tp_" onOpenSelect={this.onClickPeriodHeader.bind(this)} onSelectFunction={this.onSelectPeriod.bind(this)} />
                    {editInterval && <GroupedDropDownSelect customClass="interval" data={intervalList} open={expandIntervalSelection} selected={selectedInterval} placeholder="Interval" skey="tp_"  onOpenSelect={this.onClickIntervalHeader.bind(this)} onSelectFunction={this.onSelectInterval.bind(this)} />}
                    {ppLabel !== 'pre' && <div>{this.renderDataLastUpdated()}</div>}

                </div> 
                <div className="customInputDate">
                    <div className="inputBox dateInput">
                        {selectedPeriod===1 ?
                            <DateInput className="dateInput_picker" format='M/d/yyyy' calendarIcon={<img src={ddArrowIcon} className="arrowIcon" alt="" />} clearIcon={null} value={moment(startdate).toDate()} minDate={adMinDateCondition ? moment('2022-09-21').toDate():''} maxDate={moment(enddate).toDate()} onChange={(e) => this.dateChange(e, 'startdate')} /> :
                            <span>{moment(startdate).format('M/DD/YYYY')}</span> }
                    </div>
                    <div className="inputBox dateInput">
                        {selectedPeriod===1 ?
                            <DateInput className="dateInput_picker" format='M/d/yyyy' calendarIcon={<img src={ddArrowIcon} className="arrowIcon" alt="" />} clearIcon={null} value={moment(enddate).toDate()} minDate={moment(startdate).toDate()} maxDate={moment().subtract(1, "days").toDate()} onChange={(e) => this.dateChange(e, 'enddate')} /> :
                            <span>{moment(enddate).format('M/DD/YYYY')}</span> }
                    </div>
                </div>
            </div>
        )
    }
}