import React, { Component } from 'react';
import moment from 'moment';
import LoadingLayer from '../../widgets/Shared/LoadingLayer';
import ListTable from '../../widgets/Shared/ListTable';
import initData from '../../../utils/initData';
import ApiData from '../../../utils/Api';
import SearchComponent from '../../widgets/Shared/SearchComponent';
import  { ADMINSEARCH_TERM } from '../../../reducers/AdminSearch/SearchReducer'
import { connect } from 'react-redux';
import Toaster from '../../widgets/Toaster';

import './GroupManagementComponent.scss'
class GroupManagementComponent extends Component {
    constructor(props) {
        super(props)
        this.groupColumns = initData.getColumns(1);
        this.sortingTypes = initData.getSortingTypes();
        const groupActiveSortColumn = this.groupColumns.find(column => column.sorting === 'active');

        this.state = {
            listData:[],
            loading: true,
            addNew: false,
            page: 1,
            pageLimit: 150,
            offsetHeight: 0,
            groupSorting: {
                key: groupActiveSortColumn.field,
                type: this.sortingTypes.desc,
            },
            toasts: [],
            toastConfig: {
                autoDismiss: false,
                dismissTime: 2000 // 2s
            },
        }
    }

    addNew = (option) => {
        const route = option === 'user' ? 'adduser' : 'addgroup';
        this.props.history.push(route);
    }

    getInternalOwner = (item) => {
        if(!item.internalOwners.length) return;
        const name = `${item.internalOwners[0].firstName} ${item.internalOwners[0].lastName}`;
        if(Number(item.internalOwners.length) === 1) {
            return name;
        } else {
            return `${name} +${item.internalOwners.length -1} more`;
        }
    }

    getListData = () => {
        this.setState({loading: true});
        const { searchKey } = this.props;
        const {offsetHeight, page, pageLimit, groupSorting} = this.state;
        const listData = [];
        const groupSort = groupSorting.key;
        const groupSortType = this.sortingTypes.asc === groupSorting.type;
            ApiData.getGroups(page, pageLimit, groupSort, groupSortType, searchKey).then(
                (result) => {
                    //let list = [];
                    result.data.forEach(item=>{
                        
                        const status = !!item.contracts && !!item.contracts.length && item.contracts.some(contract => {
                            return contract.startDate && contract.endDate && 
                            moment(contract.startDate).isBefore(moment()) && moment(contract.endDate).isSameOrAfter(moment().format('YYYY-MM-DD'))
                        }) ? 'active' :
                        !!item.contracts && !!item.contracts.length && item.contracts.some(contract => {
                            return contract.startDate && contract.endDate && 
                            moment(contract.startDate).isBefore(moment()) && moment(contract.endDate).isBefore(moment())
                        }) ? 'expired' : 
                        !!item.contracts && !!item.contracts.length && item.contracts.some(contract => {
                            return contract.startDate && contract.endDate && 
                            moment(contract.startDate).isAfter(moment())
                        }) ? 'future' : 'expired';

                        const campaigns = item.markets && item.markets.length > 0 ? (
                                {
                                    main: (item && item.markets && item.markets.length > 0) ? item.markets.filter((market, index) => index < 2).map(c => c).join(', ') : '',
                                    trail: item.markets && item.markets.length > 2 ? `(+${item.markets.length - 2} more)` : '',
                                    total:(item && item.markets && item.markets.length > 0) ? item.markets.filter((market, index) => index > 1).map(c => c).join(', ') : '',
                                }
                            ) : null;
                        listData.push({
                            id: item.id,
                            displayName: item.displayName,
                            company: item.company,
                            campaignGroups: campaigns,
                            brands: item.brandsQuantity,
                            internalOwners: this.getInternalOwner(item),
                            contractsStatus: status ,
                            users: item.usersQuantity,
                            createdOn: moment(item.createdAt).format('MM/DD/YYYY'),
                            lastContractDate: item.lastContractDate === null ? '': moment(item.lastContractDate).format('MM/DD/YYYY')
                        })
                    });
                    this.setState({
                        selectedTab: 0,
                        listData,
                        loading: false,
                        page: page,
                        pageSize: result.pageInfo.pageSize,
                        totalCount: result.pageInfo.totalCount,
                    }, () => {
                        window.scrollTo(0, offsetHeight);
                        // this.sortGroups(this.groupColumns[5]);
                    });
                }
            ).catch(
                (err) => {
                    console.log("error=" + err );
                }
            );
        }

    sortGroups = (column) => {
        const { groupSorting } = this.state;
        const type = groupSorting.type === this.sortingTypes.asc ? this.sortingTypes.desc : this.sortingTypes.asc;

        this.setState({
            groupSorting: { key: column.field, type },
        }, this.getListData);
    }
    openGroupProfile = (id) => {
        const { history } = this.props;
        history.push(`/group/${id}`)
    }

    showToast = (params) => {
        let toastId = Math.floor((Math.random() * 101) + 1);

        let toastProperties = {
            id: toastId,
            title: params.title ?? "Notification",
            content: params.content,
            action: params.action ?? null,
            preventAutoDismiss: params.preventAutoDismiss ?? false
        };

        this.setState({ toasts: [toastProperties]  });
    }
    exportData = (type) => {
        const exportDate = moment().format("MMDDYYYY");

        switch (type) {
            case "group":
                this.showToast({
                    title: "Data Export",
                    content: "Please Wait! Data is being exported.",
                    action: { type: "text", content: "Processing" }
                });
                ApiData.getGroupExportDocument()
                .then(response => {
                    const downloadLink = window.URL.createObjectURL(new Blob([response.data]));

                    this.showToast({
                        title: "Data Export",
                        content: "Groups export is ready for download.",
                        action: {
                            type: "download",
                            fileName: `QuestBrand-groups-export-${exportDate}.xlsx`,
                            link: downloadLink
                        },
                        preventAutoDismiss: true
                    });
                })
                .catch(error => {
                    console.log('Getting export file error', error);
                    this.showToast({
                        title: "Data Export",
                        content: "We are having some trouble exporting the data.",
                        action: { type: "text", content: "Error" }
                    });
                })
                break;

            case "contract":
                this.showToast({
                    title: "Data Export",
                    content: "Please Wait! Data is being exported.",
                    action: { type: "text", content: "Processing" }
                });
                ApiData.getContractExportDocument()
                    .then(response => {
                        const downloadLink = window.URL.createObjectURL(new Blob([response.data]));

                        this.showToast({
                            title: "Data Export",
                            content: "Contracts export is ready for download.",
                            action: {
                                type: "download",
                                fileName: `QuestBrand-contracts-export-${exportDate}.xlsx`,
                                link: downloadLink
                            },
                            preventAutoDismiss: true
                        });
                    })
                    .catch(error => {
                        console.log('Getting export file error', error);
                        this.showToast({
                            title: "Data Export",
                            content: "We are having some trouble exporting the data.",
                            action: { type: "text", content: "Error" }
                        });
                    })
                break;
        
            default:
                break;
        }
       
    }


    componentDidMount() {
        this.getListData();
    }
    componentDidUpdate(nextProps){
        const {searchKey } = this.props;
        if(searchKey !==nextProps.searchKey && searchKey !==''){
            clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(() => this.getListData(true),1500);
            }
      
    } 

    render() {
        const { listData, loading, totalCount, pageSize, page, groupSorting } = this.state;
        const dataItemClickCallback = (groupId) => this.props.dataItemClick(0,`/group/${groupId}/`)
          ;
        let dataItemsContainerCls = "dataItemsContainer" + (loading ? " loading":"");
        return (
            <div>
            <Toaster 
                toastList={this.state.toasts}
                position="bottom-left"
                autoDismiss={this.state.toastConfig.autoDismiss}
                dismissTime={this.state.toastConfig.dismissTime}
            />
                
                  <div className="actionableButtons">
                  <SearchComponent />
                  <div className="button greyButton" onClick={() => this.exportData('contract')}>
                            Export Contracts
                        </div>
                        <div className="button orangeButton" onClick={() => this.exportData('group')}>
                            Export Groups
                        </div>
                    <div className="button blueButton" onClick={() => this.addNew('group')}>
                        Add New Group
                    </div>
                    </div>
                <div className={dataItemsContainerCls} style={{overflowX:"auto"}}>
                    {loading && <LoadingLayer />}
                    {listData.length>0 && 
                        <ListTable 
                            listData={listData}
                            total={totalCount}
                            limit={pageSize}
                            current={page}
                            listColumns={initData.getColumns(1)}
                            handlePageChange={(current)=>this.setState({page: current}, this.getListData) }
                            onItemClick={dataItemClickCallback}
                            sortedColumn={ groupSorting}
                            sortTable={ this.sortGroups}
                        />}
                </div>
            </div>    
        )
    }
}

const mapStateToProps = state => {
    return {
      searchKey: state.SearchReducer.adminsearch_term,
    };
  };
  const mapDispatchToProps = (dispatch) => ({
    searchUser: (value) => dispatch({ type: ADMINSEARCH_TERM, payload: value }),  
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GroupManagementComponent);


