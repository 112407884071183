import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import initData from '../../../utils/initData';
import ApiData from '../../../utils/Api';
import searchIcon from "../../../assets/icons/icon_search.svg";
import delGroupIcon from "../../../assets/icons/icon_x.svg";
import RequiredIndicator from '../UsersManagement/RequiredIndicator';
import OptionsDropdown from '../../widgets/Shared/OptionsDropdown';
import '../UsersManagement/ProfileForm.scss';

export default class GroupForm extends Component {
    static propTypes = {
        cancelForm: PropTypes.func.isRequired,
        createGroup: PropTypes.func.isRequired,
        editMode: PropTypes.string.isRequired,
        fieldValues: PropTypes.object,
        groupData: PropTypes.object,
        brandItemClick: PropTypes.func,
        closeBrandDropdown: PropTypes.func,
        isBrandDropdownOpened: PropTypes.bool,
        dropdownClassname: PropTypes.string,
        currentBrandDropdownId: PropTypes.string,
        getDropdownOptions: PropTypes.func,
        brandList: PropTypes.array,
        brandClick: PropTypes.func,
    }

    constructor(props) {
        super(props);
        const addGroupData = initData.getGroupData();

        const { form: { groupName, company, users, internalOwners }} = addGroupData;
        const fieldValues = this.props.fieldValues;

        this.state = {
            [groupName.key]: fieldValues ? fieldValues[groupName.key] : '',
            [company.key]: fieldValues ? fieldValues[company.key] : '',
            selectedUsers: fieldValues && fieldValues[users.key] ? fieldValues[users.key] : [],
            selectedInternalOwners: fieldValues && fieldValues[internalOwners.key] ? fieldValues[internalOwners.key] : [],
            searchUser: '',
            userSuggestions: [],
            companySuggestions:[],
            internalOwnersSuggestions:[],
            addGroupData,
            sessionTimeoutErr:false
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { fieldValues } = props;
        const { form: { groupName, company, users, internalOwners }} = state.addGroupData;

        if(props.editMode === state.addGroupData.modes.profileDisplay && fieldValues && fieldValues[groupName.key] !== state[groupName.key]) {
            return {
                [groupName.key]: fieldValues && fieldValues[groupName.key] ? fieldValues[groupName.key] : [],
                [company.key]: fieldValues && fieldValues[company.key] ? fieldValues[company.key] : '',
                selectedUsers: fieldValues && fieldValues[users.key] ? fieldValues[users.key] : [],
                selectedInternalOwners: fieldValues && fieldValues[internalOwners.key] ? fieldValues[internalOwners.key] : [],
            }
        } else return null;
    }
    
    inputChange = (e, inputKey) => {
        this.setState({[inputKey]: e.target.value});
    }

    createGroup = () => {
        const { addGroupData: { form: { groupName, company }}} = this.state;
        const group = {
            displayName: this.state[groupName.key],
            name: this.state[groupName.key].replace(/ /g, '').toLowerCase(),
            company: this.state[company.key],
            internalOwnersIds: this.state.selectedInternalOwners.map(user => user.id),
            usersId: this.state.selectedUsers.map(user => user.id),
        };

        const { createGroup } = this.props;
        if(this.validateFields() && typeof createGroup === 'function') createGroup(group, this.state);
    }

    validateFields = () => {
        const { addGroupData: { form: { groupName , company}}} = this.state;
        return !!(this.state[groupName.key] && this.state[company.key] && !!this.state.selectedInternalOwners.length );
    }

    removeUser = (id) => {
        const selectedUsers = [...this.state.selectedUsers];
        const removeIndex = selectedUsers.findIndex(user => user.id === id);
        if (removeIndex > -1) {
            selectedUsers.splice(removeIndex, 1);
        }
        this.setState({
            searchUser: '', 
            selectedUsers,
            userSuggestions: [],
        });
    }

    onSelectUser = (id) => {
        const { userSuggestions } = this.state;
        const item = userSuggestions.find(suggestion => suggestion.id === id);
        let { selectedUsers } = this.state;
        let users = [...selectedUsers];
        if (!users.find(user => user.id === id)) {
            users = [...users, item];
        }
        this.setState({
            searchUser: '', 
            selectedUsers: users,
            userSuggestions: []
        });
      }
    
    searchUserChanged = (event) => {
        const name = event.target.value;
        if (name.trim() === '') {
            this.setState({
                searchUser: '',
                userSuggestions: []
            });
        } else {
            this.setState({
                searchUser: name,  
            });
            ApiData.searchUserByname(encodeURI(name)).then(
                (result) => {
                this.setState({
                    userSuggestions: result,
                })
            }
            ).catch(
                (err) => {
                    console.log("error=" + err )
                    this.setState({
                        userSuggestions: []
                    });
                    if(err.message === "Network Error")this.setState({sessionTimeoutErr:true});
                }
            );
        }
    }

    onSelectCompany = (companyName) => {
        const { companySuggestions } = this.state;
        const item = companySuggestions.find(suggestion => suggestion.company === companyName);
        this.setState({
            company: item.company,
            companySuggestions: []
        });
      }

    searchCompanyChanged = (event) => {
        const companyValue = event.target.value;
        if (companyValue.trim() === ''){
            this.setState({
                company: '',
                companySuggestions: []
            });
        } else {
            this.setState({
                company: companyValue, 
            });
            ApiData.searchCompany(companyValue).then(
                (result) => {
                this.setState({
                    companySuggestions: result,
                })
            }
            ).catch(
                (err) => {
                    console.log("error=" + err )
                    this.setState({
                        companySuggestions: []
                    });
                    if(err.message === "Network Error")this.setState({sessionTimeoutErr:true});
                }
            );
        }
        
    }


    onSelectinternalOwners = (id) => {
        const { internalOwnersSuggestions } = this.state;
        const item = internalOwnersSuggestions.find(suggestion => suggestion.id === id);
        let { selectedInternalOwners } = this.state;
        let internalOwners = [...selectedInternalOwners];
        if (!internalOwners.find(internalOwners => internalOwners.id === id)) {
            internalOwners = [...internalOwners, item];
        }
        this.setState({
            internalOwners: '', 
            selectedInternalOwners: internalOwners,
            internalOwnersSuggestions: []
        });
      }

      removeinternalOwners = (id) => {
        const selectedInternalOwners = [...this.state.selectedInternalOwners];
        const removeIndex = selectedInternalOwners.findIndex(internalOwners => internalOwners.id === id);
        if (removeIndex > -1) {
            selectedInternalOwners.splice(removeIndex, 1);
        }
        this.setState({
            internalOwners: '', 
            selectedInternalOwners,
            internalOwnersSuggestions: []
        });
    }

    searchinternalOwnersChanged = (event) => {
        const internalOwnersValue = event.target.value;
        if (internalOwnersValue.trim() === ''){
            this.setState({
                internalOwners: '',
                internalOwnersSuggestions: []
            });
        } else {
            this.setState({
                internalOwners: internalOwnersValue, 
            });
            ApiData.getinternalOwners(internalOwnersValue).then(
                (result) => {
                this.setState({
                    internalOwnersSuggestions: result,
                })
            }
            ).catch(
                (err) => {
                    console.log("error=" + err )
                    if(err.message === "Network Error")this.setState({sessionTimeoutErr:true});
                    this.setState({
                        internalOwnersSuggestions: []
                    });
                }
            );
        }
        
    }

    onBrandOptionsClick = (event, id) => {
        const { brandItemClick } = this.props;
        event.preventDefault();
        event.stopPropagation();
        typeof brandItemClick === 'function' && brandItemClick(id)
    }

    getValidCampaignsLength(brandCampaigns = []) {
        return brandCampaigns.filter(campaign => (campaign.brandQuota !== null && campaign.brandQuota >= 0)).length;
    }

    render() {
        const { userSuggestions,companySuggestions,selectedUsers, searchUser,addGroupData, internalOwnersSuggestions, selectedInternalOwners } = this.state;
        const { form: { groupName, company, users, brands, internalOwners }, buttons, modes  } = addGroupData;
        const {
            cancelForm,
            editMode,
            groupData,
            closeBrandDropdown,
            dropdownClassname,
            currentBrandDropdownId,
            isBrandDropdownOpened,
            getDropdownOptions,
            brandList,
            brandClick,
            error,
        } = this.props;
        const isSubmitBtnDisabled = !this.validateFields();

        return (
            <div className={'profileForm'}>
                {/* {sessionTimeoutErr && <SessionTimeoutModal />} */}
                <div className={'inputBlock'}>
                    <label htmlFor={groupName.key} className={'inputLabel'}>{groupName.label}<RequiredIndicator /></label>
                    <div className={'inputBox formInput'}>
                        <input 
                            id={groupName.key}
                            placeholder={groupName.placeholder}
                            value={this.state[groupName.key]}
                            onChange={(e) => this.inputChange(e, groupName.key)}
                            disabled={editMode === modes.profileDisplay}
                        />
                    </div>
                </div>
                <div className={'inputBlock'}>
                    <label htmlFor={company.key} className={'inputLabel'}>{company.label}<RequiredIndicator /></label>
                    <div className={editMode === modes.profileDisplay ?'disabledInputContainer':'selectInputContainer'}>
                            <div className={'searchIconContainer'}>
                             { this.state.company?.length > 0 ? '' : <img src={searchIcon} className={'prefixIcon'} alt={'search icon'} />}
                            </div>
                        <input
                            id={company.key}
                            placeholder={company.placeholder}
                            value={this.state[company.key]}
                            onChange={this.searchCompanyChanged}
                            disabled={editMode === modes.profileDisplay}
                        />
                        </div>
                    {companySuggestions && companySuggestions.length > 0 && 
                            <ul className={'profileSuggestionsContainer formSuggestionsContainer'}>
                            {companySuggestions.map((item) => {
                                return (
                                    <li key={`company${item}`} onClick={()=>this.onSelectCompany(item.company)}>
                                        <span className={'suggestTitle'}>{`${item.company}`}</span>
                                    </li>
                                );
                            })}
                            </ul>    
                        }
                </div>
                
                <div className={'inputBlock'}>
                    <label htmlFor={internalOwners.key} className={'inputLabel'}>{internalOwners.label}<RequiredIndicator /></label>
                    <div className={editMode === modes.profileDisplay ?'disabledInputContainer':'selectInputContainer'}>
                            <div className={'searchIconContainer'}>
                              <img src={searchIcon} className={'prefixIcon'} alt={'search icon'} />
                            </div>
                        <input
                            id={internalOwners.key}
                            placeholder={internalOwners.placeholder}
                            value={this.state[internalOwners.key]}
                            onChange={this.searchinternalOwnersChanged}
                            disabled={editMode === modes.profileDisplay}
                        />
                        </div>
                    {internalOwnersSuggestions && internalOwnersSuggestions.length > 0 && 
                            <ul className={'profileSuggestionsContainer formSuggestionsContainer'}>
                            {internalOwnersSuggestions.map((item) => {
                                return (
                                    <li key={`company${item}`} onClick={()=>this.onSelectinternalOwners(item.id)}>
                                         <span className={'suggestTitle'}>{`${item.firstName} ${item.lastName}`}</span>
                                        <span className={'suggestTitle'}>{`${item.email}`}</span>
                                    </li>
                                );
                            })}
                            </ul>    
                        }
                </div>
                <>
                <div className={'selectedList'}>
                        {selectedInternalOwners.length > 0 && selectedInternalOwners.map((user) => {
                            return (
                                <div className={'item'} key={user.id}>
                                    <div className={'titleBlock'}>
                                        <div className={'title'}>{`${user.firstName} ${user.lastName}`}</div>
                                        <div className={'itemId'}>{user.email}</div>
                                    </div>
                                    <img src={delGroupIcon} alt="remove" className="removeIcon" onClick={()=>this.removeinternalOwners(user.id)}/>
                                </div>
                            )
                        })}
                    </div>
                    </>

                {editMode === modes.profileDisplay && !!brandList.length &&
                <>
                    <div className={'divider'} />
                    <div className={'inputBlock brandBlock'}>
                        <label className={'inputLabel brandLabel'}>{brands.label}</label>
                        <div className={'brandList'}>
                            {brandList.map(brand => {
                                const brandName = brand.displayName ? brand.displayName : brand.name;
                                return (
                                    <div key={brand.id} className={'brandItem brandData'} onClick={() => brandClick(brand.id)}>
                                        {brand.logo ?
                                        <div className={'logoContainer'}>
                                            <img src={brand.logo} className={'logo'} alt={'Brand logo'} />
                                        </div> :
                                        <div className="brandDefault brandLogoDefault">
                                            <div>{!!brandName && brandName.slice(0, 1).toUpperCase()}</div>
                                        </div>}
                                        <div className={'titleContainer'}>
                                            <div className={'brandName brandStyle'}>{brandName}</div>
                                            {groupData && (
                                                <div className={"primaryBrand"} style={{color: "#71767D"}}>
                                                {brand.campaigns && brand.campaigns.length? this.getValidCampaignsLength(brand.campaigns): 0} Markets
                                                </div>
                                            )}
                                            {groupData && groupData.mainBrandId === brand.id && <div className={'primaryBrand'}>{brands.primaryBrand}</div>}
                                        </div>
                                        {/* <div 
                                            id={`groupOptionsButton${brand.id}`}
                                            className="hoverIcon primarySetting" 
                                            onClick={(event) => this.onBrandOptionsClick(event, brand.id)}
                                        >
                                            <img className={'groupOptionsButton'} src={moreIcon} alt={'Option icon'} />
                                        </div> */}
                                        {isBrandDropdownOpened && currentBrandDropdownId === brand.id &&
                                            <OptionsDropdown
                                                customClassname={dropdownClassname}
                                                closeDropdown={closeBrandDropdown}
                                                options={getDropdownOptions(groupData.mainBrandId === brand.id)}
                                                buttonId={`groupOptionsButton${brand.id}`}
                                            />
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </>
                }
                {editMode === modes.create &&
                <>
                    <div className={'divider'} />
                    <div className={'inputBlock'}>
                        <label htmlFor={users.key} className={'inputLabel'}>{users.label}</label>
                        <div className={'selectInputContainer'}>
                            <div className={'searchIconContainer'}>
                                <img src={searchIcon} className={'prefixIcon'} alt={'search icon'} />
                            </div>
                            <input
                                id={users.key}
                                placeholder={users.placeholder}
                                onChange={this.searchUserChanged}
                                value={searchUser}
                                autoComplete={"off"}
                            />
                        </div>
                        {userSuggestions && userSuggestions.length > 0 && 
                            <ul className={'profileSuggestionsContainer formSuggestionsContainer'}>
                            {userSuggestions.map((item) => {
                                return (
                                    <li key={`user${item.id}`} onClick={()=>this.onSelectUser(item.id)}>
                                        <span className={'suggestTitle'}>{`${item.firstName} ${item.lastName}`}</span>
                                        <span>{item.email}</span>
                                    </li>
                                );
                            })}
                            </ul>    
                        }
                    </div>
                    <div className={'selectedList'}>
                        {selectedUsers.length > 0 && selectedUsers.map((user) => {
                            return (
                                <div className={'item'} key={user.id}>
                                    <div className={'titleBlock'}>
                                        <div className={'title'}>{`${user.firstName} ${user.lastName}`}</div>
                                        <div className={'itemId'}>{user.email}</div>
                                    </div>
                                    <img src={delGroupIcon} alt="remove" className="removeIcon" onClick={()=>this.removeUser(user.id)}/>
                                </div>
                            )
                        })}
                    </div>
                </>
                }
                {(editMode === modes.create || editMode === modes.profileUpdate) && 
                <div className={'buttonsBlock'}>
                    <div className={"button whiteButton addFormButton"} onClick={cancelForm}>
                        {buttons.cancel.label}
                    </div>
                    <div className={cx("button", "blueButton", "addFormButton", {"addFormButton--disabled": isSubmitBtnDisabled})} onClick={this.createGroup}>
                        {buttons.add.label(editMode)}
                    </div>
                </div>}
                {error && <span className={'error'}>{error}</span>}
            </div>
        )
    }
}
