import React, { Component } from 'react';
import graylogoIcon from '../../../assets/icons/compass_logo_mark_grey.svg';
import './NoDataBox.scss';

export default class NoDataBox extends Component {
   render() {
        return (
            <div className="emptyContent">
              <div className="icon">
                <img alt="" src={graylogoIcon} />
              </div>
              <h3>NO DATA FOUND</h3>
              <p>Try adjusting your filters</p>
            </div>
        )
    }
}