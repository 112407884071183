import InitData from "./initData";

//Role based permissions are implemented based on Auth0 and External ID, We restrict from UI. 
//Able to assign “Compass Admin” access when creating/editing a user and Brand Management/Pending Page.
export function  hasPermission(){
    const externalUserIDs = InitData.getExternalUserIDs();
    const externalUserId = JSON.parse(localStorage.getItem('userInfo'))?.externalId;
    const permission = externalUserIDs?externalUserIDs.includes(externalUserId):false;
    return  permission;
}
export default hasPermission;