import React, { Component } from 'react';
import BrandTitle from './BrandTitle';
import './BrandItem.scss';

export default class BrandItem extends Component {

    render() {
        const { index, name, icon, id } = this.props;
        return (
            <li id={index} className="brandItemContainer" onClick={this.props.onClick}>
                <BrandTitle brand={{logo: icon, name: name, id: id}} />
            </li>
        );
    }
}