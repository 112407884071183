import React, { useState } from "react";
import cx from 'classnames';
import '../OptionFilters/filterGroup.scss';
import '../OptionFilters/FilterCommonItem';
import { IoIosArrowUp } from "react-icons/io";

const SelectedFiltersList = (props) => {

    let [expanded, setExpanded] = useState(true);

    const onExpandClick = (categoryId) => {
        setExpanded(!expanded);
        props.currentSelectedFilters.forEach(obj=>{
            if(obj.categoryId === categoryId) obj.expanded = !obj.expanded;
        })
        props.changeCurrentFilters(props.currentSelectedFilters)
    }


    let { currentSelectedFilters , iscreateOrEditPage } = props;
    return(
        <div className="selectedFilterListContainer">
            <div>
                {currentSelectedFilters?.map((categoryObj,ind)=>{
                    let selectedOptionsCount = categoryObj?.filters?.reduce((acc, obj)=>  acc + ( !obj.isExpired?(obj?.filterId !== 8 ? obj?.filterOptions?.length: 1): (iscreateOrEditPage ? 0:1)), 0);
                    let allFiltersExpired = iscreateOrEditPage && categoryObj?.filters?.length === 1 && categoryObj?.filters[0].isExpired;
                    let filters = iscreateOrEditPage ? categoryObj?.filters?.reduce((acc, obj) =>{ !obj.isExpired && acc.push(obj); return acc;},[]): categoryObj?.filters;
                    return(
                        !allFiltersExpired && <div key={ind} className={cx("filterCardContainer", {"collapse": !categoryObj?.expanded},{"active": selectedOptionsCount > 0})}>
                            <div className={cx("filterCardHeader", {"active": selectedOptionsCount > 0 },{"expanded" : (selectedOptionsCount > 0 && categoryObj?.expanded)})}>
                                <div className="categoryTitle">{categoryObj?.filters[0]?.type !== 'SEGMENT'?categoryObj.categoryName:'Segments'}
                                    {selectedOptionsCount > 0 && 
                                        <span className='countContainer'>{selectedOptionsCount}</span>
                                    }
                                </div>
                                <IoIosArrowUp className="arrowIcon" alt="" onClick={(e)=>onExpandClick(categoryObj.categoryId)} />
                            </div>
                            {categoryObj?.expanded && filters.map((filtersObj,index) =>
                                <>
                                    <div className="filtersListContainer" key={index}>
                                        <div>
                                            <div key={"ftitle"+ind} className='titleContainer'>
                                                <div className="title">{filtersObj.type !== 'SEGMENT'?filtersObj?.filterName:categoryObj.categoryName}</div>
                                            </div>
                                            {filtersObj && filtersObj.filterId !== 8 && filtersObj?.filterOptions?.map(option => 
                                                
                                                <div key={option.filterOptionId} className={cx("filterCommonItemContainer", {"active": true})}>
                                                    <div className="filterOptionTitle">{option?.filterOptionName?option?.filterOptionName:filtersObj?.filterName}</div>
                                                </div>
                                            )}
                                            {filtersObj && filtersObj.filterId === 8 &&
                                                <div className={cx("filterCommonItemContainer", {"active": true})}>
                                                    <div className="filterOptionTitle">Age :{filtersObj?.filterOptions[0].filterOptionId}-{filtersObj?.filterOptions[1].filterOptionId}</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )
                })}
            </div>
        </div> 
    )
}

export default SelectedFiltersList;