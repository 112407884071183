
/**
 * Calculates the difference between the ingested count and the weighted count.
 *
 * @param {number} ingestedCount - The number of items ingested.
 * @param {number} weightedCount - The number of items weighted.
 * @returns {number} The difference between the ingested count and the weighted count.
 */
export const calculateIngestionWeighedDifference = (ingestedCount, weightedCount) => {
    if (ingestedCount && weightedCount) {
        return ingestedCount - weightedCount;
    } else if (ingestedCount && !weightedCount) {
        return ingestedCount;
    } else if (!ingestedCount && weightedCount) {
        return -weightedCount;
    } else {
        return 0;
    }
}

export const MarketHelper = {
    calculateIngestionWeighedDifference
};
