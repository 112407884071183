import React, { Component } from 'react';
import moment from 'moment';
import cx from 'classnames';
import CardHeader from '../UsersManagement/CardHeader';
import GroupForm from './GroupForm';
import Filters from './AddFilters/Filters';
import ShowTabCount from './AddFilters/ShowTabCount';
import Tabs from '../../../components/LayoutComponent/Tabs';
import LoadingLayer from '../../widgets/Shared/LoadingLayer';
import ListTable from '../../widgets/Shared/ListTable';
import initData from '../../../utils/initData';
import ApiData from '../../../utils/Api';
import InfoModal from '../InfoModal';
import searchIcon from "../../../assets/icons/icon_search.svg";
import searchIconBlue from "../../../assets/icons/icon_search_blue.svg";
import geoIcon from "../../../assets/icons/icon_geo.svg";
import moreIcon from '../../../assets/icons/icon_more.svg';
import dateIcon from '../../../assets/icons/icon_calendarpicker.svg';
import plusIcon from '../../../assets/icons/icon_plus.svg';
import noGroupImg from '../../../assets/images/zero-illustration.png';
import StatusLabel from '../../widgets/Shared/StatusLabel';
import '../UsersManagement/Profile.scss';


const ACTIVE = 'Active';
const FILTERS = 'Filters';

export default class GroupProfile extends Component {
    constructor(props) {
        super(props)
        this.groupData = initData.getGroupData();
        this.groupId = this.props.match.params.groupId;
        this.tabs = initData.getTabs(props.page);
        this.state = {
            groupData: null,
            loading: true,
            currentMode: this.groupData.modes.profileDisplay,
            isOptionsDropdownOpened: false,
            isUserOptionsDropdownOpened: false,
            isBrandDropdownOpened: false,
            currentUser: null,
            currentBrand: null,
            storedGroupFields: null,
            isDeleteModalOpened: false,
            isDeleteUserModalOpened: false,
            isSearchUserOpened: false,
            searchUser: '',
            userSuggestions: [],
            selectedTab: 0,
            statusData: []
        }
    }

    getListData = () => {
        this.setState({loading: true});
        ApiData.getGroupDetailed(this.groupId).then(
            (result) => {
                this.setState({
                    groupData: result,
                    loading: false,
                    storedGroupFields: this.getGroupFormData(result),
                });
            }
        ).catch(
            (err) => {
                console.log("Get group data error", err);
            }
        );
    }

    createNewContractClick = () => {
        const { history } = this.props;
        history.push(`/${this.groupId}/addcontract`);
    }

    onContractClick = (contractId) => {
        const { history } = this.props;
        history.push(`/${this.groupId}/contract/${contractId}`);
    }

    onTabClick = (tab) => {
        this.setState({ selectedTab: tab });
    }

    deleteUserFromGroup = (userId) => {
        this.closeUserOptionsDropdown();
        this.setState({ loading: true });
        
        ApiData.removeUserfromGroup(this.groupId, userId)
            .then(result => {
                this.setState({ loading: false }, this.getListData);
                this.closeUserDeleteModal();
            })
            .catch(
                (err) => {
                    console.log("Deleting user from group error" + err);
                    this.setState({ loading: false });
                }
            );
    }

    deleteGroup = () => {
        const { types } = initData.getStatusMessages();
        const { history, showMessage } = this.props;
        this.setState({ loading: true });

        ApiData.removeGroup(this.groupId)
            .then(result => {
                history.push('/groups');
                typeof showMessage === 'function' && showMessage(types.removeGroup, '');
            })
            .catch(error => {
                console.log('Deleting group error', error);
                this.setState({ loading: false });
            })
    }

    addUserToGroup = (userId) => {
        this.setState({ loading: true });
        ApiData.addUsertoGroup(this.groupId, userId)
            .then(result => {
                this.setState({ loading: false, userSuggestions: [], searchUser: '' }, this.getListData);
            })
            .catch(error => {
                console.log('Add user to group error', error);
                this.setState({ loading: false, userSuggestions: [], searchUser: '' });
            })
    }

    findUpdateDiff = (groupData, storedGroupFields) => {
        if(groupData && storedGroupFields) {
            const { form } = this.groupData;
            const groupFields = groupData;
            const diff = [];
            delete groupFields[form.internalOwners.key];
            Object.keys(groupFields).forEach(key => {
                if(groupFields[key] !== storedGroupFields[key] && !(!groupFields[key] && !storedGroupFields[key])) {
                    diff.push(key)
                }
            });
            if(storedGroupFields[form.internalOwners.key] && 
                !groupData.selectedInternalOwners.every((internalOwners, index) => {
                return !!internalOwners && !!storedGroupFields[form.internalOwners.key][index] && internalOwners.id === storedGroupFields[form.internalOwners.key][index];
            })){
                diff.push(form.internalOwners.key)
            }
            const diffLabels = Object.values(form).filter(value => diff.some(key => value.key === key)).map(value => value.label);
            return {
                keys: diff,
                labels: diffLabels,
                string: diffLabels.length ? diffLabels.join(', ') : '',
            };
        }
    }

    setPrimaryBrand = (groupId, brandId) => {
        const { types } = initData.getStatusMessages();
        const { showMessage } = this.props;
        const { groupData } = this.state;
        let brands = [];
        groupData.contracts.forEach(contract => brands = [...brands, ...contract.brands]);
        const brand = brands.find(brand => brand.id === brandId);
        return ApiData.setPrimaryBrand(groupId, brandId)
            .then((result) => {
                typeof showMessage === 'function' && showMessage(types.setPrimaryBrand, brand.name);
                this.setState({ currentBrand: null }, this.getListData);
            })
            .catch((error) => {
                console.log("Setting primary brand error" + error);
                this.setState({ currentBrand: null });
            });
    }

    onBrandClick = () => {
        const { currentBrand, groupData } = this.state;
        this.closeBrandOptionsDropdown();
        if (groupData.mainBrandId && groupData.mainBrandId > 0) {
            ApiData.removePrimaryBrand(this.groupId)
            .then((result) => {
                if (currentBrand && currentBrand !== groupData.mainBrandId) {
                    this.setPrimaryBrand(this.groupId, currentBrand);
                }    
            })
            .catch((error) => {
                console.log("error=" + error);
                this.setState({ currentBrand: null });
            }); 
        } else if (currentBrand > 0) {
            this.setPrimaryBrand(this.groupId, currentBrand);
        }
    }

    updateGroup = (group, rawData) => {
        const { showMessage } = this.props;
        const { storedGroupFields } = this.state;
        const { types } = initData.getStatusMessages();
        const updateDiff = this.findUpdateDiff(rawData, storedGroupFields);

        this.setState({ loading: true });

        ApiData.updateGroupPartial(this.groupId, group)
            .then(response => {
                typeof showMessage === 'function' && showMessage(types.updateGroup, updateDiff.string);
                this.setState({ currentMode: this.groupData.modes.profileDisplay, loading: false }, this.getListData);
            })
            .catch(error => {
                this.setState({ loading: false });
                console.log('Updating group error', error);
            });
        
    }

    cancelUpdatingGroup = () => {
        this.setState({ currentMode: this.groupData.modes.profileDisplay });
    }

    getGroupFormData = (groupData) => {
        const { form: { groupName, company,internalOwners }} = this.groupData;
        return {
            id: groupData.id,
            [groupName.key]: groupData.displayName,
            [company.key]: groupData.company,
            [internalOwners.key]: groupData.internalOwners
        }
    }

    editGroupAction = () => {
        this.setState({ currentMode: this.groupData.modes.profileUpdate, isOptionsDropdownOpened: false });
    }

    cancelEditAction = () => {
        this.setState({ currentMode: this.groupData.modes.profileDisplay });
    }

    openDeleteGroupModal = () => {
        this.setState({ isDeleteModalOpened: true });
    }

    closeDeleteGroupModal = () => {
        this.setState({ isDeleteModalOpened: false });
    }

    deleteGroupAction = () => {
        this.openDeleteGroupModal();
        this.closeOptionsDropdown();
    }

    openUserDeleteModal = () => {
        this.setState({ isDeleteUserModalOpened: true });
    }

    closeUserDeleteModal = () => {
        this.setState({ isDeleteUserModalOpened: false, currentUser: null });
    }

    deleteUserAction = () => {
        this.openUserDeleteModal();
        this.setState({ isUserOptionsDropdownOpened: false });
    }

    createDropdownOptions = () => {
        const groupOptions = {...initData.getGroupDropdownOptions().group};
        groupOptions.options[0].action = this.editGroupAction;
        groupOptions.separateOptions[0].action = this.deleteGroupAction;

        return groupOptions;
    }

    createUserDropdownOptions = () => {
        const userOptions = {...initData.getGroupDropdownOptions().user};
        userOptions.separateOptions[0].action = this.deleteUserAction;

        return userOptions;
    }

    createBrandDropdownOptions = (isPrimaryBrand) => {
        const brandOptions = [...initData.getContractBrandDropdownOptions(isPrimaryBrand)];
        brandOptions[0].action = () => this.onBrandClick();

        return brandOptions;
    }

    closeOptionsDropdown = () => {
        this.setState({ isOptionsDropdownOpened: false });
    }

    openOptionsDropdown = () => {
        this.setState({ isOptionsDropdownOpened: true });
    }

    openUserOptionsDropdown = (currentUser) => {
        this.setState({ isUserOptionsDropdownOpened: true, currentUser });
    }

    closeUserOptionsDropdown = () => {
        this.setState({ isUserOptionsDropdownOpened: false, currentUser: null });
    }

    openBrandOptionsDropdown = (currentBrand) => {
        this.setState({ isBrandDropdownOpened: true, currentBrand });
    }

    closeBrandOptionsDropdown = () => {
        this.setState({ isBrandDropdownOpened: false, currentBrand: null });
    }

    onOptionsBtnClick = () => {
        const { isOptionsDropdownOpened } = this.state;
        if(isOptionsDropdownOpened) this.closeOptionsDropdown();
        else this.openOptionsDropdown();
    }

    onUserOptionsBtnClick = (userId) => {
        const { currentUser } = this.state;
        if(userId !== currentUser) this.openUserOptionsDropdown(userId);
        else this.closeUserOptionsDropdown();
    }
    
    onBrandOptionsBtnClick = (brandId) => {
        const { currentBrand } = this.state;
        if(brandId !== currentBrand) this.openBrandOptionsDropdown(brandId);
        else this.closeBrandOptionsDropdown();
    }

    getDeleteModalButtons = () => {
        const { modals: { removeGroup: { buttons }}} = this.groupData;

        buttons[0].action = this.closeDeleteGroupModal;
        buttons[1].action = this.deleteGroup;
        return buttons;
    }

    getDeleteUserModalButtons = () => {
        const { modals: { removeUser: { buttons }}} = this.groupData;
        const { currentUser } = this.state;

        buttons[0].action = this.closeUserDeleteModal;
        buttons[1].action = () => this.deleteUserFromGroup(currentUser);
        return buttons;
    }

    toggleSearchUserField = () => {
        const { isSearchUserOpened } = this.state;
        if(isSearchUserOpened) this.setState({ isSearchUserOpened: false });
        else this.setState({ isSearchUserOpened: true });
    }

    onUserClick = (id) => {
        const { history } = this.props;
        history.push(`/user/${id}`);
    }

    onBrandRowClick = (id) => {
        const { history } = this.props;
        history.push(`/brand/${id}`);
    }

    searchUserChanged = (event) => {
        const name = event.target.value;
        if (name.trim() === '') {
            this.setState({
                searchUser: '',
                userSuggestions: []
            });
        } else {
            this.setState({
                searchUser: name,  
            });
            ApiData.searchUserByname(encodeURI(name)).then(
                (result) => {
                this.setState({
                    userSuggestions: result,
                })
            }
            ).catch(
                (err) => {
                    console.log("error=" + err )
                    this.setState({
                        userSuggestions: []
                    });
                }
            );
        }
    }
    getFilterData = async () => {
        const filtersData = await ApiData.getFiltersCounts(this.groupId)
        if(filtersData?.length > 0){
            filtersData.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState({
            statusData: filtersData
        });
        this.getActiveFilterCount(filtersData);
    }
    getActiveFilterCount = (filterData) => {
        const activeFilter = filterData.filter(data => {
            return data.name === ACTIVE;
        });
        if(activeFilter?.length > 0 && activeFilter[0]) {
            this.tabs.map(tab => {
                if(tab.name === FILTERS) {
                    tab.count = activeFilter[0].count;
                }
                return tab
            });
        }else{
            this.tabs.map(tab => {
                if(tab.name === FILTERS) {
                    delete tab['count'];
                }
                return tab
            });
        }
    }

    componentDidMount() {
        this.getListData();
        this.getFilterData();
    };

    render() {
        const { 
            groupData,
            loading,
            currentMode,
            isOptionsDropdownOpened,
            isUserOptionsDropdownOpened,
            currentUser,
            isDeleteModalOpened,
            isSearchUserOpened,
            searchUser,
            userSuggestions,
            currentBrand,
            isBrandDropdownOpened,
            isDeleteUserModalOpened,
            selectedTab, } = this.state;
        const primaryTitle = groupData ? `${this.groupData.title(currentMode)}${groupData.displayName}` : ' ';
        const secondaryTitle = groupData ? `${this.groupData.subtitle(currentMode)}${groupData.id}` : `${this.groupData.subtitle(currentMode)}`;
        const formFieldValues = groupData ? this.getGroupFormData(groupData) : null;
        const { modals } = this.groupData;
        const modalGroup = {
            displayName: groupData ? groupData.displayName : '',
            id: groupData ? groupData.id : '',
        }
        const users = groupData ? groupData.users : [];
        const currentUserData = users?.find(user => user.id === currentUser);
        const modalUser = {
            firstName: currentUserData ? currentUserData.firstName : '',
            lastName: currentUserData ? currentUserData.lastName : '',
            email: currentUserData ? currentUserData.email : '',
        }
        const listData = users?.length ? users.map(user => ({ 
            id: user.id,
            email: user.email ? user.email : '',
            firstname: user.firstName ? user.firstName : '',
            lastname: user.lastName ?  user.lastName : '',
            added: user.dateCreated ? moment(user.dateCreated).format('MMMM D, YYYY') : 'No Date',
        })) : [];
        const dropdownButton = {
            action: this.onUserOptionsBtnClick,
            component: (<img className={'groupOptionsButton'} src={moreIcon} alt={'Option icon'} />)
        }
        let brandList = [];
        const contracts = [];

        

        if (groupData) {
            groupData.contracts.forEach(item => {
                contracts.push({
                    id: item.id,
                    name: item.name ? item.name : 'Name not provided',
                    salesForceId: item.salesforceId,
                    amount: item.amount,
                    startDate: item.startDate ? moment(item.startDate).format('M/D/YY') : 'No Expiry',
                    endDate: item.endDate ? moment(item.endDate).format('M/D/YY') : 'No Expiry',
                    brands: item.brands,
                    campaigns: item.campaigns,
                    markets:item.markets,
                    status: (!!item.startDate && moment(item.startDate).isAfter(moment())) ?
                        'future' : (!!item.endDate && moment(item.endDate).isSameOrAfter(moment().format('YYYY-MM-DD'))) ?
                        'active' :  (!!item.endDate && moment(item.endDate).isBefore(moment())) ?
                        'expired' : 'expired',
                });
                if(!!item.endDate && !!item.startDate && moment(item.endDate).isSameOrAfter(moment().format('YYYY-MM-DD')) && moment(item.startDate).isBefore(moment())) {
                    item.brands.forEach((b)=>{
                        const brandIndex = brandList.findIndex(brand => brand.id === b.id);
                        if(brandIndex < 0){
                            brandList.push({id: b.id, name: b.name, displayName: b.displayName, logo: b.logo, startDate: item.startDate ? moment(item.startDate).format('MMMM D, YYYY'): 'Inactive', endDate: item.endDate ? moment(item.endDate).format('MMMM D, YYYY') : 'No Expiry', campaigns: b.campaigns});
                        } else {
                            b.campaigns.forEach(campaign => {
                                if (!brandList[brandIndex].campaigns.find(c => c.id === campaign.id)) {
                                    brandList[brandIndex].campaigns.push(campaign);
                                }
                            })
                        }
                    });
                    const primaryBrandIndex = (brandList && brandList.length) && brandList.findIndex(brand => groupData.mainBrandId === brand.id);
                    if(primaryBrandIndex) {
                        const primaryBrand = brandList.splice(primaryBrandIndex, 1)[0];
                        brandList = [primaryBrand, ...brandList]
                    }
                }
            });
        }
        
        return (
            <div className={'profile'}>
                <CardHeader
                    primaryTitle={primaryTitle}
                    secondaryTitle={secondaryTitle}
                    button={{type: 'options', onClick: this.onOptionsBtnClick}}
                    isOptionsDropdownOpened={isOptionsDropdownOpened}
                    dropdownOptions={this.createDropdownOptions()}
                    closeDropdown={this.closeOptionsDropdown}
                />
                <Tabs
                    tabs={this.tabs}
                    isBrand={false}
                    isAdminPanel = {true}
                    currentTab={selectedTab}
                    onClickTabItem={this.onTabClick}
                    countEle = {selectedTab === 2 ? <ShowTabCount data={this.state.statusData}/>: null}
                    count = {selectedTab === 2 ? 145: null}
                />
                {
                    selectedTab === 0 &&
                    <div className={'profileContent'} style={{display: "flex"}}>
                    <GroupForm
                        fieldValues={formFieldValues}
                        cancelForm={this.cancelEditAction}
                        editMode={currentMode}
                        createGroup={this.updateGroup}
                        groupData={groupData}
                        isBrandDropdownOpened={isBrandDropdownOpened}
                        closeBrandDropdown={this.closeBrandOptionsDropdown}
                        brandItemClick={this.onBrandOptionsBtnClick}
                        dropdownClassname={'brandGroupDropdown'}
                        currentBrandDropdownId={currentBrand}
                        getDropdownOptions={this.createBrandDropdownOptions}
                        brandList={brandList}
                        brandClick={this.onBrandRowClick}
                    />
                    <div className={'profileTable groupProfile'}>
                        <div className={'profileTableHeader'}>
                            <div className={'profileTableTitle'}>{this.groupData.table.title}</div>
                            <div className={'searchGroupPanel'}>
                                {isSearchUserOpened && !(currentMode === this.groupData.modes.profileUpdate) &&
                                    <div className={'inputBlock'}>
                                        <div className={'selectInputContainer'}>
                                            <div className={'searchIconContainer'}>
                                                {!! searchUser ?
                                                    <img src={searchIconBlue} className={'prefixIcon'} alt={'search icon'} /> :
                                                    <img src={searchIcon} className={'prefixIcon'} alt={'search icon'} />
                                                }
                                            </div>
                                            <input
                                                placeholder={this.groupData.form.users.placeholder}
                                                onChange={this.searchUserChanged}
                                                value={searchUser}
                                                autoComplete={"off"}
                                            />
                                        </div>
                                        {userSuggestions && userSuggestions.length > 0 && 
                                            <ul className={'profileSuggestionsContainer'}>
                                            {userSuggestions.map((item) => {
                                                return (
                                                    <li key={`user${item.id}`} onClick={()=>this.addUserToGroup(item.id)}>
                                                        <span className={'suggestTitle'}>{`${item.firstName} ${item.lastName}`}</span>
                                                        <span>{item.email}</span>
                                                    </li>
                                                );
                                            })}
                                            </ul>    
                                        }
                                    </div>
                                }
                                <div 
                                    className={cx("button whiteButton addFormButton", {"disabled": currentMode === this.groupData.modes.profileUpdate})}
                                    onClick={this.toggleSearchUserField}
                                >
                                    {isSearchUserOpened ? this.groupData.buttons.cancel.label : this.groupData.buttons.addToGroup.label}
                                </div>
                            </div>
                        </div>
                        {listData.length ? 
                            <ListTable
                                listData={listData} 
                                listColumns={this.groupData.table.columns}
                                onItemClick={this.onUserClick}
                                withPagination={false}
                                isDropdownOpened={isUserOptionsDropdownOpened}
                                closeDropdown={this.closeUserOptionsDropdown}
                                dropdownButton={dropdownButton}
                                currentDropdownId={currentUser}
                                dropdownOptions={this.createUserDropdownOptions()}
                                isRowClickable={false}
                                dropdownClassname={'optionGroupDropdown'}
                                isSecondaryTable={true}
                            /> :
                            <div>
                                <div className={'zeroImgContainer'}><img className={'zeroImg'} src={noGroupImg} alt={'No groups'} /></div>
                                <div className={'zeroTitle'}>{this.groupData.table.noGroupsTitle}</div>
                                <div className={'zeroMessage'}><span>{this.groupData.table.noGroupMessage}</span></div>
                            </div>
                        }
                    </div>
                </div>
                }
                {
                    selectedTab === 1 &&
                    <div className={'contractList'}>

                        {contracts.map(contract => {
                            return (
                                <div key={contract.id} className={'contractItem'} onClick={() => this.onContractClick(contract.id)}>
                                    <div className={'header'}>
                                        <div className={'titleBlock'}>
                                            <div title={contract.name} className={'title'}>
                                                <span>{contract.name}</span>
                                            </div>
                                            <div className={'datesBlock'}>
                                                <img className={'dateIcon'} src={dateIcon} alt={'Date icon'} />
                                                <div className={'dates'}>{`${contract.startDate} - ${contract.endDate}`}</div>
                                            </div>
                                        </div>
                                        <StatusLabel status={contract.status} customClassname={'statusBlock'} />
                                    </div>
                                    {contract.brands &&
                                    <div className={'geoBlock'}>
                                                <img className={'geoIcon'} src={geoIcon} alt={'Geo icon'} />
                                                <div className={'dates'}>{(contract.markets && contract.markets.length)? contract.markets.length : 0} Markets</div>
                                    </div>}
                                    <div className={'contractBrandList'}>
                                        {contract.brands && contract.brands.filter((brand, index) => index < 5).map(brand => {
                                            return (
                                                <div key={brand.id} className={'contractBrandItem'}>
                                                    {brand.logo ?
                                                    <img className={'brandLogo'} src={brand.logo} alt={'Brand logo'} /> :
                                                    <div className="brandDefault">
                                                        <div>{(brand.displayName || brand.name).slice(0, 1).toUpperCase()}</div>
                                                    </div>}
                                                    <div className={'brandName'}>{brand.displayName || brand.name}</div>
                                                </div>
                                            )
                                        })}
                                        {contract.brands.length > 5 &&
                                        <div className={'contractBrandItem'}>
                                            <div className={'brandNumberIcon'}>{`+${contract.brands.length - 5}`}</div>
                                            <div className={'brandName brandNumber'}>{'Other Brands'}</div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        <div className={'addContractContaier'}>
                            <div className={'addContractBlock'}>
                                <div className={'iconContainer'} onClick={this.createNewContractClick}>
                                    <img className={'plusIcon'} src={plusIcon} alt={'Plus icon'} />
                                </div>
                                <div className={'createLabel'}>Add New Contract</div>
                            </div>
                        </div>
                    </div>
                }
                {
                    selectedTab === 2 &&
                        <Filters groupId={this.groupId}
                            adminPanelStatusMessage={this.props.adminPanelStatusMessage}
                            showAdminPanelMessage={this.props.showAdminPanelMessage}
                            hideAdminPanelMessage={this.props.hideAdminPanelMessage}
                            updateFilterCount={this.getFilterData}
                            errorMessage={this.props.errorMessage}
                        />
                }
                {loading && <LoadingLayer />}
                {isDeleteModalOpened && 
                <InfoModal
                    title={modals.removeGroup.title}
                    message={modals.removeGroup.message}
                    extraMessage={modals.removeGroup.extraMessage}
                    subjectInfo={{
                        title: `${modalGroup.displayName}`,
                        subtitle: `ID: ${modalGroup.id}`,
                    }}
                    buttons={this.getDeleteModalButtons()}
                />
                }
                {isDeleteUserModalOpened && 
                <InfoModal
                    title={modals.removeUser.title}
                    message={modals.removeUser.message}
                    extraMessage={modals.removeUser.extraMessage}
                    subjectInfo={{
                        title: `${modalUser.firstName} ${modalUser.lastName}`,
                        subtitle: modalUser.email,
                    }}
                    buttons={this.getDeleteUserModalButtons()}
                />
                }
            </div>
        )
    }
}
