import React, { Component } from "react";
import PropTypes from "prop-types";
import DateInput from "react-date-picker";
import moment from "moment";
import cx from "classnames";
import initData from "../../../utils/initData";
import ApiData from "../../../utils/Api";
import dateIcon from "../../../assets/icons/icon_calendarpicker.svg";
import geoIcon from "../../../assets/icons/icon_geo.svg";
import detailedIcon from "../../../assets/icons/icon_nav_detailed.svg";
import RequiredIndicator from "../UsersManagement/RequiredIndicator";
import ClearIcon from "../../../assets/icons/icon_clear.svg";
import noGroupImg from "../../../assets/images/zero-illustration.png";
import "../UsersManagement/ProfileForm.scss";
import SimpleSelect from '../../widgets/Shared/SimpleDropDownSelect';
import hasPermission from '../../../utils/Permissions';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
const dateFormat = "YYYY-MM-DD";
const dateDisplayFormat = "M/d/yyyy";

export default class ContractForm extends Component {
  static propTypes = {
    cancelForm: PropTypes.func.isRequired,
    editQuota: PropTypes.func.isRequired,
    createContract: PropTypes.func.isRequired,
    editMode: PropTypes.string.isRequired,
    fieldValues: PropTypes.object,
    contractData: PropTypes.object,
  
    groupId: PropTypes.string.isRequired,
    brandClick: PropTypes.func,
    campaignList: PropTypes.array,
    contractStatus: PropTypes.string.isRequired,
    triggerLoader: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const addContractData = initData.getContractData();

    const {form: {contractName,salesforceId,purpose,amount,cost,startDate,endDate,brands,campaigns}} = addContractData;
    const fieldValues = this.props.fieldValues;
    this.state = {
      [contractName.key]: fieldValues ? fieldValues[contractName.key] : "",
      [salesforceId.key]: fieldValues ? fieldValues[salesforceId.key] : "",
      [purpose.key]: fieldValues ? fieldValues[purpose.key] : purpose.options[0].id,
      [amount.key]: fieldValues ? fieldValues[amount.key] : "",
      [cost.key]: fieldValues ? fieldValues[cost.key] : '',
      [startDate.key]: fieldValues? fieldValues[startDate.key]: moment().format(dateFormat),
      [endDate.key]: fieldValues? fieldValues[endDate.key]: moment().add(30, "days").format(dateFormat),
      selectedBrands: fieldValues && fieldValues[brands.key] ? fieldValues[brands.key] : [],
      selectedCampaigns:fieldValues && fieldValues[campaigns.key]? fieldValues[campaigns.key]: [],
      originalContract: {
        [contractName.key]: fieldValues ? fieldValues[contractName.key] : '',
        [purpose.key]: fieldValues ? fieldValues[purpose.key] :  purpose.options[0].id,
        [salesforceId.key]: fieldValues ? fieldValues[salesforceId.key] : '',
        [amount.key]: fieldValues ? fieldValues[amount.key] : '',
        [cost.key]: fieldValues ? fieldValues[cost.key] : '',
        [startDate.key]: fieldValues ? fieldValues[startDate.key] : moment().format(dateFormat),
        [endDate.key]: fieldValues ? fieldValues[endDate.key] : moment().add(30, 'days').format(dateFormat),
    },
      searchBrand: "",
      searchCampaign: "",
      brandSuggestions: [],
      campaignSuggestions: [],
      addContractData,
      showMenu: false,
      showClear: false,
      purposeSelectOpened: false,
      brandsResult:[],
      costMessage: '',
      // removeBrands: [],
      alertMessages: {
        activation: "Including this brand may cause the brand to start fielding - Contact Ops for support",
        reactivation: "Including this brand may cause the brand to start fielding - Contact Ops for support",
        deactivation: "Removing this brand may cause the brand to stop fielding - Contact Ops for support",
        extension: "The brand cannot be extended beyond its current end date - Contact Ops for support",
        samplesize: "The brand sample size cannot be increased above its current max sample size – Contact Ops for support"
      },
      warnings: {
        date: false,
        brands: false
      },
    };
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    
    const { fieldValues } = props;
    const {form: {contractName,salesforceId,purpose,amount,cost,startDate,endDate,brands,campaigns}} = state.addContractData;
    let calCount = 0;
    let temp = 0, daysDifference = moment(state[endDate.key] ? state[endDate.key] : fieldValues && fieldValues[endDate.key]).diff((state[startDate.key] ? state[startDate.key] : fieldValues && fieldValues[startDate.key]), 'days') + 1;
    let filteredBrandList = state.selectedBrands.length === 0 ? fieldValues?.brands : state.selectedBrands
    if (props.geoList.length > 0) {
      props.geoList.forEach(obj => {
        let filteredQuotas = props.quotas.filter(quotaObj => quotaObj.marketId === obj.id);
        let count = 0;
        filteredQuotas.forEach(value => {if(value.quota !== null)count += Number(value.quota)});
        calCount += (daysDifference / 7) * count * (3 / 40);
      })
    }
    if (props.editMode === 'profile_display') {
      daysDifference = moment(fieldValues && fieldValues[endDate.key] ? fieldValues && fieldValues[endDate.key] : state[endDate.key]).diff((fieldValues && fieldValues[startDate.key] ? fieldValues && fieldValues[startDate.key] : state[startDate.key]), 'days') + 1
    } else {
      daysDifference = moment(state[endDate.key] ? state[endDate.key] : fieldValues && fieldValues[endDate.key]).diff((state[startDate.key] ? state[startDate.key] : fieldValues && fieldValues[startDate.key]), 'days') + 1
    }
    let dynamicCost = filteredBrandList?.length > 0 ? ((daysDifference / 7) * (temp * (3 / 40))).toFixed(2) === 0.00 ? 0 : ((daysDifference / 7) * (temp * (3 / 40))).toFixed(2) : null
    if (props.editMode === state.addContractData.modes.profileDisplay && fieldValues && fieldValues[contractName.key] !== state[contractName.key]) {
      return {
        [contractName.key]: fieldValues && fieldValues[contractName.key] ? fieldValues[contractName.key] : "",
        [purpose.key]: fieldValues ? fieldValues[purpose.key] : purpose.options[0].id,
        [salesforceId.key]: fieldValues && fieldValues[salesforceId.key] ? fieldValues[salesforceId.key] : "",
        [amount.key]: fieldValues && fieldValues[amount.key] ? fieldValues[amount.key] : "",
        [cost.key]: calCount.toFixed(3) !== null ? '$' + calCount.toFixed(3) : '',
        [startDate.key]: fieldValues && fieldValues[startDate.key] ? fieldValues[startDate.key] : moment().format(dateFormat),
        [endDate.key]: fieldValues && fieldValues[endDate.key] ? fieldValues[endDate.key] : moment().add(30, "days").format(dateFormat),
        selectedBrands: fieldValues && fieldValues[brands.key] ? fieldValues[brands.key] : [],
        originalBrands: fieldValues && fieldValues[brands.key] ? fieldValues[brands.key] : [],
        selectedCampaigns: fieldValues && fieldValues[campaigns.key] ? fieldValues[campaigns.key] : [],
        originalContract: {
          [contractName.key]: fieldValues ? fieldValues[contractName.key] : '',
          [purpose.key]: fieldValues ? fieldValues[purpose.key] : purpose.options[0].id,
          [salesforceId.key]: fieldValues ? fieldValues[salesforceId.key] : '',
          [amount.key]: fieldValues ? fieldValues[amount.key] : '',
          [cost.key]: dynamicCost !== null ? '$' + dynamicCost : '',
          [startDate.key]: fieldValues ? fieldValues[startDate.key] : moment().format(dateFormat),
          [endDate.key]: fieldValues ? fieldValues[endDate.key] : moment().add(30, 'days').format(dateFormat),
        },
      };
    } else if (state[cost.key] !== calCount.toFixed(3) || (fieldValues && fieldValues[endDate.key]) !== state[endDate.key] || (fieldValues && fieldValues[startDate.key]) !== state[startDate.key]) {
      return {
        [cost.key]: calCount.toFixed(3) !== null ? '$' + calCount.toFixed(3) : '',
      }
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.editMode !== this.props.editMode) {
      const { addContractData: { form: { startDate }, modes } } = this.state;

      // Reset/Initialize the Contract Start Date to the current date.
      if (this.props.editMode === modes.create || this.props.editMode === modes.profileCopy) {
        this.setState({ [startDate.key]: moment().format(dateFormat) });
      }
      
      this.clearFieldWarnings();
      this.clearBrandWarnings();
    }
  }

  clearFieldWarnings = () => {
    const { warnings } = this.state;

    Object.keys(warnings).forEach( function (key) {
        warnings[key] = false;
    });

    this.setState({ warnings });
}

clearBrandWarnings = () => {
  const { brandsResult} = this.state;

  brandsResult.forEach(selectedBrand => {
      delete selectedBrand.action;
  });

  this.setState({ brandsResult });
}

  createDropdownOptions = (campaignStatus) => {
    const campaignOptionsAll = { ...initData.getUserContractOptions() };
    campaignOptionsAll.options[0].title = this.editCampaignAction;
    campaignOptionsAll.options[1].title = this.disableCampaign;
    campaignOptionsAll.options[2].action = this.enableCampaign;

    const options = []
      .concat(campaignOptionsAll.options[0])
      .concat(campaignOptionsAll.options[campaignStatus === "ACTIVE" ? 1 : 2]);
    return { options };
  };

  inputChange = (e, inputKey) => {
    this.setState({ [inputKey]: e.target.value });
  };

  dateChange = (dateObj, inputKey) => {
    if (!moment(dateObj).isValid()) return;
    this.setState({ [inputKey]: moment(dateObj).format(dateFormat) });
  };
  onFocus() {
    this.setState({
      showClear: true,
    });
  }

  amountChange = (e, inputKey) => {
    let value = e.target.value;
    let dollarValue = value.indexOf("$") ? "$" : "";
    if (value) value = `${dollarValue}${value}`;
    value = value.replace(/[^$0-9]/g, '');
    let x = value.split(".");
    let x1 = x[0];
    let x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    value = x1 + x2;
    value = value.startsWith("$") ? value : value === '' ? '' : '$' + value;
    this.setState({ [inputKey]: value });
  }

  validateDates = () => {
    const { addContractData: { form: { startDate, endDate }, modes }, warnings, originalContract } = this.state;
    const { editMode } = this.props;
    const start = this.state[startDate.key];
    const end = this.state[endDate.key];

    const dateFourWeeksFromOriginalEndDate = moment(originalContract[endDate.key]).add(4, 'weeks');
    warnings.date = false;

    if (editMode === modes.create || editMode === modes.profileCopy) {
      if (moment(end).diff(moment(start), 'weeks') > 8) {
        warnings.date = endDate?.warnings?.duration ?? false;
      }
    }
    else if (editMode === modes.profileUpdate) {
      if (moment(end).isSameOrAfter(dateFourWeeksFromOriginalEndDate)) {
        warnings.date = endDate?.warnings?.extension ?? false;
      }
    }
    this.setState({ warnings });

    return warnings.date;
  }

  validateBrands = () => {
    const { addContractData: { form: { startDate, endDate }, modes}, warnings } = this.state;
    const contract = {
        startDate: this.state[startDate.key],
        endDate: this.state[endDate.key],
    };
    
    let { brandList, geoList, quotas, removedBrands, editMode, removedQuotas, contractID, originalBrands  } = this.props;
    const QuotaValues = editMode === modes.create? quotas :[...new Set([...quotas,...removedQuotas])]
    const allBrandsList = editMode === modes.create? brandList : [...new Set([...brandList,...removedBrands])];
    let allBrands =[]
    warnings.brands = false;
    let body = {
      brandIds: allBrandsList.map(brand => brand.id),
      geoIds: geoList.map(geo => geo.id),
      contractId: editMode === 'profile_update'? Number(contractID):0
    }
    return new Promise((resolve, reject) => {
        ApiData.validateBrandById(body).then(
            (result) => {
              geoList.forEach(gl=>{
                result.forEach(re=>{
                  if(gl.id === re.id)
                  {
                    re.brands.map(rs=>rs.marketId= re.id)
                    allBrands.push(...re.brands)
                  }
                })
              })
              QuotaValues.forEach(q=>{
                  allBrands.forEach(al=>{
                    if(al.brandId === q.brandId && al.marketId === q.marketId)
                    {
                      al.quota = q.quota
                    }
                  })
                })
                const results = allBrands.filter(obj => !removedBrands?.some(ob => obj.brandId===ob.id));
                result.map(brand => {
                  if(geoList.filter(sc=>sc.id === brand.id).length>0){
                    if (brand.brands.some(b => allBrands.filter(ob=>ob.brandId == b.brandId && ob.marketId === b.marketId))) {
                      allBrands.map(selectedBrand => {
                        let brndsValidation = brand.brands.filter(ob=>ob.brandId === selectedBrand.brandId && ob.marketId === selectedBrand.marketId );
                            if (brndsValidation?.length > 0) {
                                if (String(brndsValidation[0]?.status) !== "ACTIVE_DECIPHER" && brndsValidation[0].quota > 0 ) {
                                        selectedBrand.action = 'activation';
                                        warnings.brands = true;
                                }
                                else if (String(brndsValidation[0]?.status) == "ACTIVE_DECIPHER"  ) {
                                    if (brndsValidation[0]?.numActiveContracts === 0  && brndsValidation[0]?.quota > 0) {
                                        selectedBrand.action = 'activation';
                                        warnings.brands = true;
                                    }
                                    else if ( brndsValidation[0]?.quota > brndsValidation[0]?.highestSampleSize ) {
                                      selectedBrand.action = 'samplesize';
                                      warnings.brands = true;
                                  }
                                    else if (brndsValidation[0]?.endDate && moment(contract.endDate).isAfter(moment(brndsValidation[0]?.endDate)) && brndsValidation[0]?.quota > 0) {
                                        selectedBrand.action = 'extension';
                                        warnings.brands = true;
                                    }
                                  else if (brndsValidation[0]?.numActiveContracts <= 1 ){
                                      if((Number(contractID) === brndsValidation[0]?.activeContractId)? brndsValidation[0]?.quota <= 0:''
                                      && moment(contract.endDate).isSameOrBefore(moment(brndsValidation[0].endDate).format('YYYY-MM-DD')))
                                         {
                                        warnings.brands = true;
                                        selectedBrand.action = 'deactivation';
                                        }
                                     }
                                    else {
                                        delete selectedBrand.action;
                                    }
                                }
                                else {
                                    delete selectedBrand.action;
                                }
                            }

                            return selectedBrand;
                        });
                    }
                    if (this.props.removeAction === true && brand.brands.some(b => removedBrands?.filter(ob=>ob.brandId == b.brandId && ob.marketId === b.marketId))) {
                      let removedBrand = allBrands.filter(obj => removedBrands?.some(ob => obj.brandId ===ob.id));
                      const removedValue = []
                      removedBrand.map(selectedBrand => {
                          let brndsValidation = brand.brands.filter(ob=>ob.brandId === selectedBrand.brandId  && ob.marketId === selectedBrand.marketId );
                        if (brndsValidation?.length > 0 && String(brndsValidation[0]?.status) !== "ACTIVE_DECIPHER" && brndsValidation[0]?.quota > 0) {
                          selectedBrand.action = 'activation';
                          warnings.brands = true;
                          let removeId = allBrands.findIndex(al => selectedBrand.brandId === al.brandId && selectedBrand.marketId == al.marketId);
                          allBrands[removeId] = selectedBrand
                          removedValue.push(selectedBrand.brandId)
                        } else if (String(brndsValidation[0]?.status) === "ACTIVE_DECIPHER" ) {
                          if (brndsValidation[0]?.numActiveContracts <= 1  && QuotaValues.filter(obj=>obj.brandId === brndsValidation[0]?.brandId && obj.marketId === brndsValidation[0]?.marketId)[0]?.quota>0) {
                            selectedBrand.action = 'deactivation';
                            warnings.brands = true;
                            let removeId = allBrands.findIndex(al => selectedBrand.brandId === al.brandId && selectedBrand.marketId == al.marketId);
                            allBrands[removeId] = selectedBrand
                            removedValue.push(selectedBrand.brandId)
                          }
                          else if (brndsValidation[0]?.quota > brndsValidation[0]?.highestSampleSize) {
                            selectedBrand.action = 'samplesize';
                            warnings.brands = true;
                            let removeId = allBrands.findIndex(al => selectedBrand.brandId === al.brandId && selectedBrand.marketId == al.marketId);
                            allBrands[removeId] = selectedBrand
                            removedValue.push(selectedBrand.brandId)
                          }
                        }
                      })
                      if ((!warnings.brands && originalBrands.filter(o1 => !brandList.some(o2 => o1.id === o2.id))?.length > 0)) {
                        removedBrands = removedBrands.filter(obj => removedValue?.some(ob => obj.id === ob.brandId));
                        removedQuotas = removedQuotas.filter(obj => removedValue?.some(ob => obj.id === ob.brandId));
                      }
                      else if ((warnings.brands && originalBrands.filter(o1 => !brandList.some(o2 => o1.id === o2.id))?.length) <= 0) {
                        removedBrands = removedBrands.filter(obj => removedValue?.some(ob => obj.id === ob.brandId));
                        removedQuotas = removedQuotas?.filter(obj => removedValue?.some(ob => obj.id === ob));
                        warnings.brands = false
                      }
                  }
                  }
                });
                // brandList.sort((b1, b2) => (b1.name > b2.name) ? 1 : ((b2.name > b1.name) ? -1 : 0));
                if(editMode !== modes.create && this.props.removeAction === true){
                  this.props.validateBrandsList(brandList,removedBrands,quotas,removedQuotas)
                }
                const unique = (arr, props = []) => [...new Map(arr.map(entry => [props.map(k => entry[k]).join('|'), entry])).values()];
                this.setState({
                    searchBrand: '',
                    brandsResult:unique(allBrands,['brandId','marketId']),
                    brandSuggestions: [],
                    warnings,
                });

                return resolve(warnings.brands);
            }
        ).catch(
            (error) => {
                console.log("Unable to validate brands: ", error)
                this.setState({
                    brandSuggestions: [],
                    warnings
                });
                if(error.message == "Network Error")this.setState({sessionTimeoutErr:true});
                return reject(Error(warnings.brands));
            }
        );
    });
}

  validateContract = () => {
    return new Promise((resolve, reject) => {
      // Validate Contract for standard users who are not Super Admins.
      if (!hasPermission()) {
        this.props.triggerLoader(true);
        this.validateBrands()
          .then(brandWarning => {
            const dateWarning = this.validateDates();

            this.props.triggerLoader(false);
            return resolve((!brandWarning && !dateWarning));
          })
          .catch(err => {
            this.props.triggerLoader(false);
            return reject(Error(err));
          });
      }
      else {
        return resolve(true);
      }
    });
  }

  onBlur() {
    this.setState({
      showClear: false,
    });
  }

  createContract = () => {
    const { addContractData: { form: { contractName, salesforceId, purpose, amount, cost, startDate, endDate }}} = this.state;
    const { groupId } = this.props;
    const contract = {
      name: this.state[contractName.key],
      contractPurposeId: this.state[purpose.key],
      salesforceId: this.state[salesforceId.key],
      amount: this.state[amount.key],
      cost: parseFloat(this.state[cost.key].replace(/\,/g, "").replace('$', "")),
      startDate: this.state[startDate.key],
      endDate: this.state[endDate.key],
      brands: this.state.selectedBrands.map((brand) => brand.id),
      campaigns: this.state.selectedCampaigns.map((campaign) => campaign.id),
      groupId: groupId,
      link: "this_is_link_to_contract",
    };

    this.validateContract().then(validContract => {
      if (validContract) {
        const { createContract } = this.props;
        if (this.validateFields() && typeof createContract === 'function') createContract(contract, this.state);
      }
    });
  }
  updateContract = () => {
    const {
      addContractData: {
        form: { contractName, salesforceId, purpose, amount, cost, startDate, endDate },
      },
    } = this.state;
    const { groupId } = this.props;
    const contract = {
      name: this.state[contractName.key],
      contractPurposeId: this.state[purpose.key],
      salesforceId: this.state[salesforceId.key],
      amount: this.state[amount.key],
      cost: parseFloat(this.state[cost.key].replace(/\,/g, "").replace('$', "")),
      startDate: this.state[startDate.key],
      endDate: this.state[endDate.key],
      brandMarketContracts: this.props.quotas,
      groupId: groupId,
      // brands: this.props.brandList.map((m) => {
      //   return { ...m, campaigns: [] };
      // }),
      //campaigns: this.props.geoList,
      //suspended: false,
      link: "This link",
    };

    this.validateContract().then(validContract => {
      if (validContract) {
        const { createContract } = this.props;
        if (this.validateFields() && typeof createContract === 'function') createContract(contract, this.state);
      }
    });
  };
  validateFields = () => {
    const {
      addContractData: {
        form: {
          contractName,
          purpose,
          startDate,
          endDate,
        },
      },
    } = this.state;
    return (
      !!this.state[contractName.key] &&
      !!this.state[startDate.key] &&
      !!this.state[endDate.key] &&
      !!this.state[purpose.key] &&
      !!(this.props.quotas && this.props.quotas.length && this.props.quotas.filter(quota => quota.quota !== null).length > 0) 
    );
  };

  removeBrand = (id) => {
    const selectedBrands = [...this.state.selectedBrands];
    const removeIndex = selectedBrands.findIndex((brand) => brand.id === id);
    if (removeIndex > -1) {
      selectedBrands.splice(removeIndex, 1);
    }
    this.setState({
      searchBrand: "",
      selectedBrands,
      brandSuggestions: [],
    });
  };

  removeCampaign = (id) => {
    const selectedCampaigns = [...this.state.selectedCampaigns];
    const removeIndex = selectedCampaigns.findIndex(
      (campaign) => campaign.id === id
    );
    if (removeIndex > -1) {
      selectedCampaigns.splice(removeIndex, 1);
    }
    this.setState({
      searchCampaign: "",
      selectedCampaigns,
      campaignSuggestions: [],
    });
  };
  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  }

  closeMenu(event) {
    if (!this.dropdownMenu?.contains(event.target)) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener("click", this.closeMenu);
      });
    }
  }
  onSelectBrand = (id) => {
    const { brandSuggestions } = this.state;
    const item = brandSuggestions.find((suggestion) => suggestion.id === id);
    let { selectedBrands } = this.state;
    let brands = [...selectedBrands];

    if (!brands.find((brand) => brand.id === id)) {
      brands = [...brands, item];
    }
    this.setState({
      searchBrand: "",
      selectedBrands: brands,
      brandSuggestions: [],
    });
  };

  onPurposeSelectClick = () => {
    this.setState((prevState => ({ purposeSelectOpened: !prevState.purposeSelectOpened })));
  }

  purposeHandleSelect = (id) => {
    const { form: { purpose } } = this.state.addContractData;
    this.setState({ [purpose.key]: id });
  }

  onSelectCampaign = (id) => {
    const { campaignSuggestions } = this.state;
    const item = campaignSuggestions.find((suggestion) => suggestion.id === id);

    let { selectedCampaigns } = this.state;
    let campaigns = [...selectedCampaigns];

    if (!campaigns.find((campaign) => campaign.id === id)) {
      campaigns = [...campaigns, item];
    }
    this.setState({
      searchCampaign: "",
      selectedCampaigns: campaigns,
      campaignSuggestions: [],
    });
  };

  searchBrandChanged = (event) => {
    const name = event.target.value;
    if (name.trim() === "") {
      this.setState({
        searchBrand: "",
        brandSuggestions: [],
      });
    } else {
      this.setState({
        searchBrand: name,
      });
      ApiData.searchBrandByname(encodeURI(name))
        .then((result) => {
          this.setState({
            brandSuggestions: result,
          });
        })
        .catch((error) => {
          console.log("Search brand error", error);
          this.setState({
            brandSuggestions: [],
          });
        });
    }
  };

  searchCampaignChange = (event) => {
    const searchValue = event.target.value;
    if (searchValue.trim() === "") {
      this.setState({
        searchCampaign: "",
        campaignSuggestions: [],
      });
    } else {
      this.setState({
        searchCampaign: searchValue,
      });
      ApiData.searchCampaignByNameOrRegion(encodeURI(searchValue))
        .then((result) => {
          this.setState({
            campaignSuggestions: result,
          });
        })
        .catch((error) => {
          console.log("Search campaign error", error);
          this.setState({
            campaignSuggestions: [],
          });
        });
    }
  };

  render() {
    const {
      // brandSuggestions,
      // selectedBrands,
      // searchBrand,
      // searchCampaign,
      addContractData,
      purposeSelectOpened,
      alertMessages,
      // selectedCampaigns,
      // campaignSuggestions,
      warnings,
      brandsResult
    } = this.state;
    const { form: {contractName, salesforceId, purpose, amount, cost, startDate, endDate  }, buttons, modes } = addContractData;
    const unique = (arr, props = []) => [...new Map(arr.map(entry => [props.map(k => entry[k]).join('|'), entry])).values()];
    const {
      cancelForm,
      editMode,
      brandList,
      brandClick,
    } = this.props;
    const calendarIcon = <img src={dateIcon} className="calendarIcon" alt="Date icon" />
    const isSubmitBtnDisabled = !this.validateFields();
    const brandIds = brandList.map((brand) => brand.id);
    const minDateForStartDate = moment(this.state[startDate.key]).isAfter(moment().startOf('day')) ? moment().startOf('day').toDate() : moment(this.state[startDate.key]).toDate();
    return (
      <div className={"profileForm"} style={{ width: "auto" }}>
        <div className={"detailsLabel"}>DETAILS</div>
        <div style={{ display: "flex", width: "100%" }}>
          <div className={"textInputBlock"}>
            <div className={"inputBlock"}>
              <label htmlFor={contractName.key} className={"inputLabel"}>
                {contractName.label}
                <RequiredIndicator />
              </label>
              <div className={"inputBox formInput"}>
                <input
                  id={contractName.key}
                  placeholder={contractName.placeholder}
                  value={this.state[contractName.key]}
                  onChange={(e) => this.inputChange(e, contractName.key)}
                  disabled={editMode === modes.profileDisplay}
                />
              </div>
            </div>
            <div className={'inputBlock'}>
              <label className={'inputLabel'}>{purpose.label}<RequiredIndicator /></label>
              <SimpleSelect
                customClass={cx('formSelect', { 'formSelect--disabled': editMode === modes.profileDisplay })}
                data={purpose.options}
                open={purposeSelectOpened}
                selected={this.state[purpose.key]}
                placeholder={purpose.placeholder}
                skey="ur_"
                onOpenSelect={this.onPurposeSelectClick}
                onSelectFunction={this.purposeHandleSelect}
                disabled={editMode === modes.profileDisplay}
              />
            </div>
            <div className={"inputBlock"}>
              <label htmlFor={salesforceId.key} className={"inputLabel"}>
                {salesforceId.label}
              </label>
              <div className={"inputBox formInput"}>
                <input
                  id={salesforceId.key}
                  placeholder={salesforceId.placeholder}
                  value={this.state[salesforceId.key]}
                  onChange={(e) => this.inputChange(e, salesforceId.key)}
                  disabled={editMode === modes.profileDisplay}
                />
              </div>
            </div>
            <div className={"inputBlock"}>
              <label htmlFor={amount.key} className={"inputLabel"}>
                {amount.label}
              </label>
              <div className={"inputBox formInput"}>
                <input
                  id={amount.key}
                  placeholder={amount.placeholder}
                  value={this.state[amount.key]}
                  data-symbol="$"
                  onChange={(e) => this.amountChange(e, amount.key)}
                  disabled={editMode === modes.profileDisplay}
                />
              </div>
            </div>
            <div className={'inputBlock'}>
              <label htmlFor={cost.key} className={'inputLabel'}>{cost.label}</label>
              <div className={'inputBox formInput'}>
                <input
                  id={cost.key}
                  placeholder={cost.placeholder}
                  value={this.state[cost.key].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  data-symbol="$"
                  disabled={true}
                />
              </div>
            </div>
            {this.state.costMessage ? <div className={'inputBlock'}>
              <label></label>
              <div className={'inputBox formInput'}> <label style={{ color: '#E51E00' }}>{this.state.costMessage}</label></div>
            </div> : ''}
          </div>
          {/* <div className={"divider"} /> */}
          <div className={"textInputBlock"}>
            <div className={"inputBlock"} >
              <label htmlFor={startDate.key} className={"inputLabel"}> {startDate.label} <RequiredIndicator /></label>
              <div className={"datesContainer"}>
                <div className={"formInput"}>
                  <div className={cx("inputBox", "dateInput", "contractDateInput", { "contractDateInput--disabled": editMode === modes.profileDisplay })} >
                    <DateInput
                      className="dateInput_picker"
                      format={dateDisplayFormat}
                      calendarIcon={calendarIcon}
                      clearIcon={null}
                      value={this.state[startDate.key] ? moment(this.state[startDate.key]).toDate() : moment().toDate()}
                      minDate={this.state[startDate.key] ? editMode === "profile_copy" ? '' : minDateForStartDate : moment().toDate()}
                      maxDate={this.state[startDate.key] ? moment(this.state[endDate.key]).toDate() : null }
                      onChange={(date) => this.dateChange(date, startDate.key)}
                      disabled={editMode === modes.profileDisplay}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={"inputBlock"}>
              <label htmlFor={endDate.key} className={"inputLabel"}>{endDate.label}<RequiredIndicator /></label>
              <div className={"datesContainer"}>
                <div className={"formInput"}>
                  <div className={cx("inputBox", "dateInput", "contractDateInput", { "contractDateInput--disabled": editMode === modes.profileDisplay })}>
                    <DateInput
                      className="dateInput_picker"
                      format={dateDisplayFormat}
                      calendarIcon={calendarIcon}
                      clearIcon={null}
                      value={this.state[endDate.key] ? moment(this.state[endDate.key]).toDate() : moment().toDate()}
                      minDate={this.state[startDate.key] ? moment(this.state[startDate.key]).toDate() : moment().toDate()}
                      onChange={(date) => this.dateChange(date, endDate.key)}
                      disabled={editMode === modes.profileDisplay}
                    />
                  </div>
                </div>
              </div>
            </div>
            {(editMode !== modes?.profileDisplay && warnings?.date) &&
              <div className={'warningBlock'}>
                <div className={'inputBox warning widthAuto'}> <label style={{ color: '#E51E00' }}>{warnings?.date}</label></div>
              </div>
            }

          </div>
        </div>

        {/* {editMode === modes.profileDisplay && !!geoList.length &&
                <>
                    <div className={'divider'} />
                    <div className={'inputBlock geographyBlock'}>
                        <label className={'inputLabel geographyLabel'}>{campaigns.label}</label>
                        <div className={'geographyList'}>
                            {campaignList.map(campaign => {
                                return (
                                    <div key={campaign.id} className={'geographyItem'}>
                                        <label className={'geographyName'}>{campaign.name}</label>
                                        <label className={'geographyRegion'}>{campaign.region}</label>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </>
                } */}
        {
          <>
            <div className={"divider"} />
            <div className={"detailsLabel"}>BRANDS/MARKETS</div>
            <div
              className={"inputBlock brandBlock"}
              style={{ justifyContent: "normal" }}
            >
              <div className={"brandList"}>
                <div
                  className={"addButton"}
                  style={{
                    border:
                      editMode === modes.profileDisplay
                        ? "1px solid rgb(201 201 201)"
                        : "1px solid #0062FF",
                    cursor:
                      editMode === modes.profileDisplay ? "auto" : "pointer",
                  }}
                  onClick={
                    editMode !== modes.profileDisplay ? this.showMenu : null
                  }
                >
                  <span
                    className={"addButtonLabel"}
                    style={{
                      color:
                        editMode === modes.profileDisplay
                          ? "rgb(201 201 201)"
                          : "#0062FF",
                    }}
                  >
                    Add
                  </span>
                </div>
                {this.state.showMenu ? (
                  <div
                    className="menu"
                    ref={(element) => {
                      this.dropdownMenu = element;
                    }}
                  >
                    <div className={"menuItem"}>
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={() =>
                          this.props.openModalHandler("Add Brands")
                        }
                      >
                        <img
                          src={detailedIcon}
                          alt=""
                          style={{ height: 16, width: 16, marginRight: 6 }}
                        />
                        <span>Brand</span>
                      </div>
                    </div>
                    {brandList && !!brandList.length && (
                      <div className={"menuItem"}>
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          onClick={() =>
                            this.props.openModalHandler("Add Markets")
                          }
                        >
                          <img
                            src={geoIcon}
                            alt=""
                            style={{ height: 15, width: 15, marginRight: 6 }}
                          />
                          <span>Markets</span>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
                {brandList &&
                  !!brandList.length &&
                  brandList.map((brand, idx) => {
                    //row=this.props.brand.map((quota)=>quota.brandId===brand.id? quota.quota: null);
                    return (
                      <div key={brand.id} className={"brandItem contractBrand"}  >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {brand.logo ? (
                            <div className={"logoContainer"}>
                              <img
                                src={brand.logo}
                                className={"logo"}
                                alt={"Brand logo"}
                              />
                            </div>
                          ) : (
                            <div className="brandDefault brandLogoDefault">
                              <div>
                                {(brand.displayName || brand.name)
                                  .slice(0, 1)
                                  .toUpperCase()}
                              </div>
                            </div>
                          )}
                          <div className={"titleContainer contactForm"}>
                            <div
                              className={"brandName brandNameTooltip tooltipBlock"}
                              onClick={() => brandClick(brand.id || brand.brandId)}
                            >
                              {brand.name.length > 20 ? <span className={'tooltiptext'}>{`${brand.name}`}</span> : ""}
                              {brand.displayName || brand.name}
                            </div>
                          </div>
                        </div>
                        {(editMode === modes.profileUpdate || editMode === modes.create || editMode === modes.profileCopy) && (
                          <img
                            src={ClearIcon}
                            alt=""
                            className="brandClearIcon"
                            onClick={() =>
                              this.props.removeMyBrandItem(brand.id)
                            }
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
              {this.props.quotas &&
                this.props.geoList.map((geo, idx) => {
                  return (
                    <div
                      className={"brandList"}
                      key={geo.id}
                      style={{
                        backgroundColor: idx % 2 === 0 ? "white" : "#F4F5F7",
                      }}
                    >
                      <div className={"columnTitle"}>
                        <div>
                          <div className={"columnTitleText"}>{geo.name}</div>
                          <span
                            style={{
                              color: "#71767D",
                              fontFamily: "azo-sans-web",
                            }}
                          >
                            {geo.region}
                          </span>
                        </div>
                        {(editMode === modes.profileUpdate || editMode === modes.create || editMode === modes.profileCopy) && (
                          <img
                            src={ClearIcon}
                            alt=""
                            className="brandClearIcon"
                            onClick={() => {
                              this.props.removeGeoColumn(geo.id);
                            }}
                          />
                        )}
                      </div>

                      {this.props.quotas
                        .filter((quota, idx) => quota.marketId === geo.id)
                        .sort(
                          (a, b) =>
                            brandIds.indexOf(a.brandId + "") -
                            brandIds.indexOf(b.brandId + "")
                        )
                        .map((quota) => (
                          <div
                            className={"selectInputQuotaContainer"}
                            key={quota.marketId + quota.brandId}
                          >
                            <input
                              id={quota.brandId + quota.marketId}
                              onChange={(e) => {
                                this.props.editQuota(
                                  quota.brandId,
                                  quota.marketId,
                                  e.target.value ? (e.target.value < 0 ? 0 : e.target.value?.replace(/[^0-9.]/g).match(/^[0-9][0-9]{0,3}/g).toString()) : null
                                )
                              }
                              }
                              type='number'
                              style={{ color: quota.quota === 0 ? "#B5B9BF" : "#71767D", width: "100%" }}
                              defaultValue={quota.quota !== null ? quota.quota : ''}
                              value={quota.quota !== null ? quota.quota : ''}
                              autoComplete={"off"}
                              disabled={editMode === modes.profileDisplay}
                            />
                            {brandsResult.map(br=>{
                              if(br.brandId === quota.brandId && br.marketId === quota.marketId && br.action) {
                            return (
                               <Tippy
                                            allowHTML={true}
                                            content={<span>{alertMessages[br?.action]}</span>}>
                                            <img style={{width:'25px',paddingRight:"10px"}} className={'alertIcon'} src="https://cdn-icons-png.flaticon.com/512/3020/3020000.png" alt="Warning" />
                                </Tippy>
                            
                             )}
                             })}
                          </div>
                        ))}
                        
                    </div>
                  );
                })}
            </div>
          </>
        }
        {!(brandList && !!brandList.length) ? (
          <>
            <div className={"zeroImgContainer"}>
              <img
                className={"zeroImg"}
                src={noGroupImg}
                alt={"No groups image"}
              />
            </div>
            <div className={"zeroTitle"}>Uh Oh, No Brands.</div>
            <div className={"zeroMessage"}>
              <span>There are no active brands or geos for this contract</span>
            </div>
          </>
        ) : null}
        {/* {(editMode === modes.create || editMode === modes.profileUpdate) &&
                <>
                    <>
                        <div className={'divider'} />
                        <div className={'inputBlock'}>
                            <label htmlFor={campaigns.key} className={'inputLabel'}>{campaigns.label}<RequiredIndicator /></label>
                            <div className={'selectInputContainer'}>
                                <div className={'searchIconContainer'}>
                                    <img src={searchIcon} className={'prefixIcon'} alt={'search icon'} />
                                </div>
                                <input
                                    id={campaigns.key}
                                    placeholder={campaigns.placeholder}
                                    onChange={this.searchCampaignChange}
                                    value={searchCampaign}
                                    autoComplete={"off"}
                                />
                            </div>
                            {campaignSuggestions && campaignSuggestions.length > 0 &&
                            <ul className={'profileSuggestionsContainer formSuggestionsContainer'}>
                                {campaignSuggestions.map((item) => {
                                    return (
                                        <li key={`campaign${item.id}`} onClick={()=>this.onSelectCampaign(item.id)}>
                                            <span className={'suggestTitle'}>{`${item.name}`}</span>
                                            <span className={'suggestTitle region'}>{`${item.region}`}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                            }
                        </div>
                        <div className={'selectedList'}>
                            {selectedCampaigns.length > 0 && selectedCampaigns.map((campaign) => {
                                return (
                                    <div className={'campaignItem'} key={campaign.id}>
                                        <div className={'titleBlock'}>
                                            <label className={'campaignName'}>{campaign.name}</label>
                                            <label className={'campaignRegion'}>{campaign.region}</label>
                                        </div>
                                        <img src={delGroupIcon} alt="remove" className="removeIcon" onClick={()=>this.removeCampaign(campaign.id)}/>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                    <>
                        <div className={'divider'} />
                        <div className={'inputBlock'}>
                            <label htmlFor={brands.key} className={'inputLabel'}>{brands.label}<RequiredIndicator /></label>
                            <div className={'selectInputContainer'}>
                                <div className={'searchIconContainer'}>
                                    <img src={searchIcon} className={'prefixIcon'} alt={'search icon'} />
                                </div>
                                <input
                                    id={brands.key}
                                    placeholder={brands.placeholder}
                                    onChange={this.searchBrandChanged}
                                    value={searchBrand}
                                    autoComplete={"off"}
                                />
                            </div>
                            {brandSuggestions && brandSuggestions.length > 0 &&
                                <ul className={'profileSuggestionsContainer formSuggestionsContainer'}>
                                {brandSuggestions.map((item) => {
                                    return (
                                        <li key={`brand${item.id}`} onClick={()=>this.onSelectBrand(item.id)}>
                                            <span className={'suggestTitle'}>{`${item.displayName || item.name}`}</span>
                                            <span>{`Brand ID: ${item.id}`}</span>
                                        </li>
                                    );
                                })}
                                </ul>
                            }
                        </div>
                        <div className={'selectedList'}>
                            {selectedBrands.length > 0 && selectedBrands.map((brand) => {
                                return (
                                    <div className={'brandItem'} key={brand.id}>
                                        {brand.logo ?
                                        <img className={'brandLogo'} src={brand.logo} alt={'Brand logo'} /> :
                                        <div className="brandDefaultLogo">
                                            <div>{brand.name.slice(0, 1).toUpperCase()}</div>
                                        </div>}
                                        <div className={'titleBlock'}>
                                            <div className={'title'}>{`${brand.name}`}</div>
                                            <div className={'subtitle'}>{`Brand ID: ${brand.id}`}</div>
                                        </div>
                                        <img src={delGroupIcon} alt="remove" className="removeIcon" onClick={()=>this.removeBrand(brand.id)}/>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                </>
                } */}
        {(editMode === modes.create || editMode === modes.profileUpdate || editMode === modes.profileCopy) && (
          <div className={"buttonsBlock"} style={{ paddingRight: 150 }}>
            <div
              className={"button whiteButton addFormButton"}
              onClick={cancelForm}
            >
              {buttons.cancel.label}
            </div>
            <div
              className={cx("button", "blueButton", "addFormButton", {
                "addFormButton--disabled": isSubmitBtnDisabled,
              })}
              onClick={this.updateContract}
            >
              {buttons.add.label(editMode)}
            </div>
          </div>
        )}
      </div>
    );
  }
}