import React, { useState, useEffect, useRef } from 'react';
import './Header.scss'
import hpLogo from '../../assets/icons/hqb-logo.svg'
import settings from '../../assets/icons/icon_settings_2.svg'
import { logOut } from '../LoginComponent/Login.helpers';
import { getLocalStorage } from '../../utils/storageHelpers';
import Switch from '../widgets/Shared/Switch';
import Avatar from '@material-ui/core/Box';


const Header = (props) => {
    let [ settingsModalOpened, setSettingsModalOpened ]  = useState(false);
    let settingsIconRef  = useRef();
    let settingsModalRef = useRef();
    useEffect(()=>{
        document.addEventListener('mousedown', handleClickOutside);
        return(()=>{
            document.removeEventListener('mousedown', handleClickOutside);
        })
    })
    
    const onLogout = () => {
        logOut();
    }

    const handleClickOutside = (event) => {
        if (settingsIconRef.current && settingsIconRef.current.contains(event.target)) {
            return null
        } else if (settingsModalRef.current && !settingsModalRef.current.contains(event.target)) {
            closeSettingsDropdown();
        }
    }

    const onClickSettings = () => {
        settingsModalOpened ? closeSettingsDropdown() : openSettingsDropdown()
    }

    const openSettingsDropdown = () => {
        setSettingsModalOpened(true);
    }

    const closeSettingsDropdown = () => {
        setSettingsModalOpened(false);
    }

    const switchSite = () => {
        props.SwitchSite();
        closeSettingsDropdown();
    }

    const renderSettings = () => {
        const { authorizedType, SwitchSite } = props
        const userInfo = JSON.parse(getLocalStorage('userInfo'));

        return settingsModalOpened && (
            <div className='settingsModal' style={ userInfo.userType === 'ADMIN'?{height:'130px'}:{height:'90px'}} ref={settingsModalRef}>
                <div className='header'>
                    {`${userInfo.firstName} ${userInfo.lastName}`}
                </div>
                <div className='main' style={ userInfo.userType === 'ADMIN'?{height:'60px'}:{height:'5px'}}>
                    {typeof SwitchSite === 'function' &&
                        <div onClick={switchSite}>
                            {authorizedType === 'ADMIN' ? 'Dashboard' : 'Admin'}
                        </div>
                    }
                    <div className="signOut" style={userInfo.userType === 'ADMIN'?{borderTop: '#DFE2EA solid 1px'}:{borderTop:'none',paddingTop:'8px'}} onClick={onLogout}>Sign Out</div>
                </div>
            </div>
        )
    }

    const { authorizedType, statTestingEnabled, switchStatTesting, title } = props
    return (
        <div className="headerContainer">
            <div className="leftPart" >
                <div>
                    <img src={hpLogo} className="hpLogo" alt="HPLogo" />
                </div>
            </div>            
            <div className="title">
                {title}
            </div>
            <div className="siteOperation">
                {authorizedType === 'ADMIN' ? '' :   <div className='statTesting'>
                <Switch checked={statTestingEnabled} onChange={switchStatTesting}  />
                    Stat Testing
                </div>}
                <div className="settings">
                    <img src={settings} alt="HP settingsLogo" height={25} onClick={onClickSettings} ref={settingsIconRef} />
                    {renderSettings()}
                </div>
                
            </div>
        </div>
    )
}

export default Header;