import moment from 'moment';

/*
    It is mostly generic function for stat testing message creation which is oriented for DonutChart and BarChart.

    entity - could be Brand or Segment (mostly depends on where the message is to be shown). For example,
    DonutChart at Competitive Intelligence uses Brand, while DonutChart at Segments - Segment.
    toExclude - could be "brands"/"period"/null
    higherThan - Array<string> - brands (and/or period) that entity is statistically significantly higher
    lowerThan - Array<string> - brands (and/or period) that entity is statistically significantly lower
    period - string. String representation for period entity is higher/lower
    asComponent - boolean. d3 can render string with tags inside. But React can't by default. So we are going to set it dangerously
 */

const createStatTestingMessage = ({
    entity,
    toExclude,
    higherThan,
    lowerThan,
    period,
    asComponent = false,
}) => {
    const higher = parseStatTestingData(higherThan, toExclude, period);
    const lower = parseStatTestingData(lowerThan, toExclude, period);

    const higherThanComparisonComposedEntity = getComparisonComposedEntity(higher, period);
    const lowerThanComparisonComposedEntity = getComparisonComposedEntity(lower, period);

    const higherThanMessage = higherThanComparisonComposedEntity ? `<b>${entity}</b> is statistically higher than <b>${higherThanComparisonComposedEntity}</b>` : '';
    const lowerThanMessage = lowerThanComparisonComposedEntity ? `<b>${entity}</b> is statistically lower than <b>${lowerThanComparisonComposedEntity}</b>` : '';
    const breakLine = `<br/>`;
    const message = higherThanMessage && lowerThanMessage ? `${higherThanMessage}${breakLine}${lowerThanMessage}` : `${higherThanMessage}${lowerThanMessage}`;

    return asComponent && message
        ? () => { return { __html: message }}
        : !asComponent && message
            ? message
            : null;
};


export const prevPeriod = '<prevPeriod>';
const excludeBrands = 'brands';
const excludePeriod = 'period';
const periods = {
    '7 days': 'Last 7 days',
    '30 days': 'Last 30 days',
    '90 days': 'Last 90 days',
    'year to date': 'Year to Date',
    '180 days': 'Last 180 days',
    'custom': /^\d{4}-\d{2}-\d{2} to \d{4}-\d{2}-\d{2}$/,
};

/*
    Below are enlisted 6 types of charts. But, as for now, it works only for DonutChart and BarChart.
    Other charts are here to clarify which data should be shown.
 */
const excludeBrandsCharts = new Set(['DonutChart', 'FunnelChart', 'BubbleChart', 'LineChart']);
const excludePeriodCharts = new Set(['BarChart', 'StackedBarChart']);

export const replacePrevPeriod = (values, period) => values.map((value) => value !== prevPeriod ? value : period);

export const excludeData = (values, toExclude) => values.reduce((acc, value) => {
    if (toExclude === excludeBrands && value === prevPeriod) {
        acc.push(value);
    }

    if (toExclude === excludePeriod && value !== prevPeriod) {
        acc.push(value);
    }

    if (!toExclude) {
        acc.push(value);
    }

    return acc;
}, []);

export const parseStatTestingData = (data, toExclude, period) => {
    const notExcludedData = excludeData(data, toExclude);
    const notExcludedDataWithReplacedPeriod = replacePrevPeriod(notExcludedData, period);

    return notExcludedDataWithReplacedPeriod;
};

export const getToExclude = (chart) => {
    switch (true) {
        case excludePeriodCharts.has(chart): return excludePeriod;
        case excludeBrandsCharts.has(chart): return excludeBrands;
        default: return null;
    }
};

export const getStatTestingIcon = (higherThan, lowerThan, toExclude, icons) => {
    const [positiveIcon, negativeIcon] = icons;
    const higher = excludeData(higherThan, toExclude);
    const lower = excludeData(lowerThan, toExclude);

    return higher.length
        ? positiveIcon : lower.length
        ? negativeIcon : null;
};

export const convertPeriodToMessage = (period) => {
    let periodEnd = moment(); // today
    let periodStart, prevPeriodEnd, prevPeriodStart, daysDifference;
    switch(true) {
        case period === periods['7 days']:
            periodStart = moment(periodEnd).subtract(7, 'days');
            prevPeriodEnd = moment(periodStart).subtract(1, 'days');
            prevPeriodStart = moment(prevPeriodEnd).subtract(7, 'days').add(1, 'days');

            return `${prevPeriodStart.format('MM/DD/YYYY')} to ${prevPeriodEnd.format('MM/DD/YYYY')}`;
        case period === periods['30 days']:
            periodStart = moment(periodEnd).subtract(30, 'days');
            prevPeriodEnd = moment(periodStart).subtract(1, 'days');
            prevPeriodStart = moment(prevPeriodEnd).subtract(30, 'days').add(1, 'days');

            return `${prevPeriodStart.format('MM/DD/YYYY')} to ${prevPeriodEnd.format('MM/DD/YYYY')}`;
        case period === periods['90 days']:
            periodStart = moment(periodEnd).subtract(90, 'days');
            prevPeriodEnd = moment(periodStart).subtract(1, 'days');
            prevPeriodStart = moment(prevPeriodEnd).subtract(90, 'days').add(1, 'days');

            return `${prevPeriodStart.format('MM/DD/YYYY')} to ${prevPeriodEnd.format('MM/DD/YYYY')}`;
        case period === periods['180 days']:
            periodStart = moment(periodEnd).subtract(180, 'days');
            prevPeriodEnd = moment(periodStart).subtract(1, 'days');
            prevPeriodStart = moment(prevPeriodEnd).subtract(180, 'days').add(1, 'days');

            return `${prevPeriodStart.format('MM/DD/YYYY')} to ${prevPeriodEnd.format('MM/DD/YYYY')}`;
        case period === periods['year to date']:
            periodStart = `${moment().year()}-01-01`;
            daysDifference = moment().diff(periodStart, 'days');
            prevPeriodEnd = moment(periodStart).subtract(1, 'days');
            prevPeriodStart = moment(prevPeriodEnd).subtract(daysDifference, 'days').add(1, 'days');

            return `${prevPeriodStart.format('MM/DD/YYYY')} to ${prevPeriodEnd.format('MM/DD/YYYY')}`;
        default:
            // custom range;
            [periodStart, periodEnd] = period.split(' to ');
            daysDifference = moment(periodEnd).diff(periodStart, 'days');
            prevPeriodEnd = moment(periodStart).subtract(1, 'days');
            prevPeriodStart = moment(prevPeriodEnd).subtract(daysDifference, 'days');

            return `${prevPeriodStart.format('MM/DD/YYYY')} to ${prevPeriodEnd.format('MM/DD/YYYY')}`;
    }
};

const getComparisonComposedEntity = (data, period) => {
    // the only case when in could be a date period
    if (data.length === 1) {
        const periodValues = Object.values(periods);
        const isPeriod = periodValues.some((periodValue) => new RegExp(periodValue).test(data[0]));

        if (isPeriod) {
            // return converted date period
            return convertPeriodToMessage(period);
        }
    }

    // join brands/segments
    return data.length ? data.join(', ') : '';
};

export default createStatTestingMessage;
