import React from 'react'
import doubleArrowLeftIcon from '../../../assets/icons/icon_double_arrow_l.svg';
import arrowLeftIcon from '../../../assets/icons/icon_arrow_l.svg';
import doubleArrowRightIcon from '../../../assets/icons/icon_double_arrow_r.svg';
import arrowRightIcon from '../../../assets/icons/icon_arrow_r.svg';

import './Pagination.scss'

export default class Pagination extends React.Component {

    constructor(props, context) {
        super(props, context);
        
        this.state = {
          spreadIsClicked: false,
        }
        this.pageNumbers=[]
    }
   
    handlePageChange(e,currentPageValue) {
        if (e.target.className.indexOf("disabled") < 0  && currentPageValue <= this.pageNumbers.length && currentPageValue !== 0) {
            let currentPage = Number(currentPageValue);
            let f = this.props.handlePageChange;
            if (typeof f === "function") {
                f(currentPage);
            }
            /*if (this.props.parentContainer) {
                this.props.parentContainer.scrollTo(0, 0);
            }*/
        }    
    }

    onClickSpreadButton = () => {
      this.setState({ spreadIsClicked: true });
    }

    render() {
        const {total, limit, current} = this.props;
        const { spreadIsClicked } = this.state;
        
        let pageNumbers = [];
        if (total > 0) {
            for (let i = 1; i <= Math.ceil(total / limit); i++) {
                pageNumbers.push(i);
            }
        }
        this.pageNumbers = pageNumbers
        return (pageNumbers.length > 1 ?
          <div className="pageNav">
            <ul> 
              <li key="prev" className={"pageNumber" + (current === 1 ?" disabled":"")} onClick={(e)=>this.handlePageChange(e,1)}> 
                <img src={doubleArrowLeftIcon} alt={'double left icon'} />
              </li>
              <li key="prev1" className={"pageNumber" + (current === 1 ?" disabled":"")} onClick={(e)=>this.handlePageChange(e,current-1)}> 
                <img src={arrowLeftIcon} alt={'left icon'} />
              </li>
              {(pageNumbers.length <= 6 || spreadIsClicked) && pageNumbers.map(number => {
                return (
                  <li
                    key={number}
                    id = {number}
                    className={(number===current)?"current":"pageNumber"}
                    onClick={(e)=>this.handlePageChange(e,number)}
                  >
                    {number}
                  </li>
                );
              })}
              {pageNumbers.length > 6 && !spreadIsClicked && 
              <>
                {pageNumbers.slice(0, 5).map(number => {
                  return (
                    <li
                      key={number}
                      id = {number}
                      className={(number===current)?"current":"pageNumber"}
                      onClick={(e)=>this.handlePageChange(e,number)}
                    >
                      {number}
                    </li>
                  );
                })}
                <li key="spread" className={"pageNumber"} onClick={this.onClickSpreadButton}>...</li>
                <li
                  key={pageNumbers[pageNumbers.length - 1]}
                  id = {pageNumbers[pageNumbers.length - 1]}
                  className={(pageNumbers[pageNumbers.length - 1]===current)?"current":"pageNumber"}
                  onClick={(e)=>this.handlePageChange(e,pageNumbers[pageNumbers.length - 1])}
                >
                  {pageNumbers[pageNumbers.length - 1]}
                </li>
              </>}
              <li key="next" className={"pageNumber" + (current === pageNumbers.length ?" disabled":"")} onClick={(e)=>this.handlePageChange(e,current+1)}>
                <img src={arrowRightIcon} alt={'right icon'} />
              </li>
              <li key="next1" className={"pageNumber" + (current === pageNumbers.length ?" disabled":"")} onClick={(e)=>this.handlePageChange(e,pageNumbers.length)}>
                <img src={doubleArrowRightIcon} alt={'double right icon'} />
              </li>
            </ul>
          </div>: null);
    }
}
