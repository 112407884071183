import {cloneDeep} from "lodash";
import moment from "moment";

const dateTransform = (lineData, interval) => {
    if (!interval) return lineData;
    const clonedLineData = cloneDeep(lineData);
    clonedLineData.forEach(d => {
        const {data} = d;
        const clonedData = cloneDeep(data);
        if(clonedData?.length > 0){
            const updatedData = clonedData?.map(el => {
                switch (interval) {
                    case 'DAYS': {
                        el.formatedDate = moment(el.date).format(`MM/D/YY`);
                        el.dateForCompare = moment(el.date).format('YYYY-MM-DD')
                        break;
                    }
                    case 'WEEKS':{
                        const dateArr = el.date?.split('--');
                        if(dateArr){
                            const endFormat = (moment(dateArr[0]).format('M') === moment(dateArr[1]).format('M')) ? 'D' : 'MMM D';
                            const sepChar = (moment(dateArr[0]).format('M') === moment(dateArr[1]).format('M')) ? '-' : ' - ';  
                            const dateFrom = moment(dateArr[0]).format('MMM D');
                            const dateEnd = moment(dateArr[1]).format(endFormat);
                            el.formatedDate = `${dateFrom}${sepChar}${dateEnd}`;
                            el.dateForCompare = moment(dateArr[0]).format('YYYY-MM-DD')
                        }
                        break;
                    }
                    case 'MONTHS':{
                        el.formatedDate = moment(el.date).format(`MMM YYYY`);
                        el.dateForCompare = moment(el.date).format('YYYY-MM-DD')
                        break;
                    }
                    case 'QUARTERS':{
                        const quarter = Math.floor(moment(el?.date).month() / 3) + 1;
                        el.formatedDate = `Q${quarter} ` + moment(el.date).format(`YYYY`);
                        el.dateForCompare = moment(el.date).format('YYYY-MM-DD')
                        break;
                    }
                    default:
                        el.formatedDate = moment(el.date).format(`MM/D/YY`);
                        el.dateForCompare = moment(el.date).format('YYYY-MM-DD')
                }
                return el;
            });
            d.data = updatedData;
        }
    });
    return clonedLineData
};


export default dateTransform;
