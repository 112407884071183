import React, { Component } from 'react';
import cx from 'classnames';
import toggleBarIcon from '../../../assets/icons/icon_toggle_bar.svg'
import toggleBarBlueIcon from '../../../assets/icons/icon_toggle_bar_blue.svg'
import toggleColumnIcon from '../../../assets/icons/icon_toggle_column.svg'
import toggleColumnBlueIcon from '../../../assets/icons/icon_toggle_column_blue.svg'

import './MiniBar.scss';

export default class MiniBar extends Component {
    render() {
        const {menuExpand, horizontalMenu, onClickHorizontalMenuItem} = this.props;
        return (
            <div className={cx("miniBar", {"menuExpand": menuExpand})} >
                <div className="toggleContainer" >
                    <div onClick={horizontalMenu === 'primary'? null : ()=>onClickHorizontalMenuItem('primary')} className="toggleIconContainer rightBorder" >
                        <img src={horizontalMenu === 'primary' ? toggleColumnBlueIcon : toggleColumnIcon} className="horizontalMenuIcon" alt="column"/>
                    </div>
                    <div onClick={horizontalMenu === 'secondary'? null : ()=>onClickHorizontalMenuItem('secondary')} className="toggleIconContainer" >
                        <img src={horizontalMenu === 'secondary' ? toggleBarBlueIcon : toggleBarIcon} className="horizontalMenuIcon" alt="bar"/>
                    </div>
                </div>
            </div>
        )
    }
}            