import React, { Component } from 'react';
import moment from 'moment';
import LoadingLayer from '../../widgets/Shared/LoadingLayer';
import ListTable from '../../widgets/Shared/ListTable';
import initData from '../../../utils/initData';
import { phoneConvert } from '../../../utils/common';
import ApiData from '../../../utils/Api';
import SearchComponent from '../../widgets/Shared/SearchComponent';
import  { ADMINSEARCH_TERM } from '../../../reducers/AdminSearch/SearchReducer'
import { connect } from 'react-redux';
import Toaster from '../../widgets/Toaster';

import './UsersManagementComponent.scss'
class UsersManagementComponent extends Component {
    constructor(props) {
        super(props)
        this.userColumns = initData.getColumns(0);
        this.sortingTypes = initData.getSortingTypes();
        const userActiveSortColumn = this.userColumns.find(column => column.sorting === 'active');

        this.state = {
            listData:[],
            loading: true,
            addNew: false,
            page: 1,
            pageLimit: 150,
            offsetHeight: 0,
            userSorting: JSON.parse(localStorage.getItem('userSorting')) || {
                key: userActiveSortColumn.field,
                type: this.sortingTypes.asc,
            },
            toasts: [],
            toastConfig: {
                autoDismiss: false,
                dismissTime: 2000 // 2s
            },
        }
    }

    addNew = (option) => {
        const route = option === 'user' ? 'adduser' : 'addgroup';
        this.props.history.push(route);
    }
    
    getListData = () => {
        this.setState({loading: true});
        const { searchKey } = this.props;
        const {offsetHeight, page, pageLimit, userSorting} = this.state;
        const listData = [];
        const userSort = userSorting.key;
        const userSortType = this.sortingTypes.asc === userSorting.type;
            ApiData.getUsers(page, pageLimit, userSort, userSortType, searchKey).then(
                (result) => {
                    result.data.forEach(item=>{
                        const group = item && item.groups ? item.groups.filter((group, index) => index < 2).map(group => group.displayName ? group.displayName : group.name).join(', ') : '';
                        const groupTrail = item.groups.length > 2 ? ` (+${item.groups.length - 2} more)` : '';
                        const total = item && item.groups ? item.groups.filter((group, index) => index > 1).map(group => group.displayName ? group.displayName : group.name).join(', ') : '';
                        listData.push({
                            id: item.id,
                            firstName: item.firstName,
                            lastName: item.lastName,
                            userGroups: {
                                main: group,
                                trail: groupTrail,
                                total:total
                            },
                            email: item.email,
                            verified: item.verified ? 'verified' : 'notVerified',
                            phone: phoneConvert(item.phoneNumber),
                            company: item.company,
                            position: item.position,
                            dateCreated: moment(item.dateCreated).format('MM/DD/YYYY'),
                            status:item.status,
                            lastLoginDate: item.lastLoginDate === null? '': moment(item.lastLoginDate).format('MM/DD/YYYY')
                        });
                    });

                    this.setState({
                        selectedTab: 0,
                        listData,
                        loading: false,
                        page: page,
                        pageSize: result.pageInfo.pageSize,
                        totalCount: result.pageInfo.totalCount,
                    }, () => {
                        window.scrollTo(0, offsetHeight);
                        // this.sortUsers(this.userColumns[0]);
                    });
                }
            ).catch(
                (err) => {
                    console.log("error=" + err );
                }
            );
         }

    sortUsers = (column) => {
        const { userSorting} = this.state;
        const type = userSorting.type === this.sortingTypes.asc ? this.sortingTypes.desc : this.sortingTypes.asc;

        localStorage.setItem('userSorting', JSON.stringify({ key: column.field, type }))
        this.setState({
            userSorting: { key: column.field, type },
        }, this.getListData);
    } 

    showToast = (params) => {
        let toastId = Math.floor((Math.random() * 101) + 1);

        let toastProperties = {
            id: toastId,
            title: params.title ?? "Notification",
            content: params.content,
            action: params.action ?? null,
            preventAutoDismiss: params.preventAutoDismiss ?? false
        };

        this.setState({ toasts: [toastProperties]  });
    }
    exportData = (type) => {
        const exportDate = moment().format("MMDDYYYY");

        switch (type) {
            case "user":
                this.showToast({
                    title: "Data Export",
                    content: "Please Wait! Data is being exported.",
                    action: { type: "text", content: "Processing" }
                });
                ApiData.getUserExportDocument()
                .then(response => {
                    const downloadLink = window.URL.createObjectURL(new Blob([response.data]));

                    this.showToast({
                        title: "Data Export",
                        content: "Users export is ready for download.",
                        action: {
                            type: "download",
                            fileName: `QuestBrand-users-export-${exportDate}.xlsx`,
                            link: downloadLink
                        },
                        preventAutoDismiss: true
                    });
                })
                .catch(error => {
                    console.log('Getting export file error', error);
                    this.showToast({
                        title: "Data Export",
                        content: "We are having some trouble exporting the data.",
                        action: { type: "text", content: "Error" }
                    });
                })
                break;

            default:
                break;
        }
       
    }


    async componentDidMount() {
        await this.props.searchUser('');
        this.getListData();
        //window.addEventListener('scroll', this.lazyLoad);
    }
    componentDidUpdate(nextProps){
        const {searchKey } = this.props;
        if(searchKey !==nextProps.searchKey && searchKey !==''){
            clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(() => this.getListData(true),1500);
            }
    } 
    //componentWillUnmount() {
        //window.removeEventListener('scoll', this.lazyLoad);
    //}
    // componentDidUpdate(prevProps) {
    //     if (prevProps.history?.location?.pathname !== this.props.history?.location?.pathname) {
    //         this.setState({page: 1})
    //     }
    // }
/*
    lazyLoad = () => {
        const {pageSize, totalCount, page} = this.state;
        
        if ( (totalCount/pageSize > page) && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
           this.setState({offsetHeight: document.body.offsetHeight, page: page+1}, this.getListData);
        }
    }
*/    
    render() {
        const { listData, loading, totalCount, pageSize, page, userSorting } = this.state;
        const pathname = this.props.history?.location?.pathname;
        const dataItemClickCallback =  (userId) => this.props.dataItemClick(0, `/user/${userId}/`)  
          ;
        let dataItemsContainerCls = "dataItemsContainer" + (loading ? " loading":"");
        return (
            <div>
            <Toaster 
                toastList={this.state.toasts}
                position="bottom-left"
                autoDismiss={this.state.toastConfig.autoDismiss}
                dismissTime={this.state.toastConfig.dismissTime}
            />
                {pathname === '/users' && 
                <div className="actionableButtons">
                <SearchComponent />
                <div className="button orangeButton" onClick={() => this.exportData('user')}>
                            Export Users
                        </div>
                    <div className="button blueButton" onClick={() => this.addNew('user')}>
                        Add New User
                    </div>
                </div>
                }
                {/* </div> */}
                {/* {addNew && 
                <div className="modalLayer">
                    {selectedTab===0 && <CreateUserComponent closeFunction={()=>this.setState({addNew: false}, this.getListData(0))} />}
                    {selectedTab===1 && <CreateGroupComponent closeFunction={()=>this.setState({addNew: false}, this.getListData(1))} />}
                </div>} */}
                <div className={dataItemsContainerCls} ref={e=>this.dataListEl=e} style={{overflowX:"auto"}}>
                    {loading && <LoadingLayer />}
                    {listData.length>0 && 
                        <ListTable 
                            listData={listData}
                            total={totalCount}
                            limit={pageSize}
                            current={page}
                            listColumns={initData.getColumns(0)}
                            handlePageChange={(current)=>this.setState({page: current}, this.getListData) }
                            onItemClick={dataItemClickCallback}
                            sortedColumn={userSorting}
                            sortTable={ this.sortUsers }
                        />}
                </div>
            </div>    
        )
    }
}

const mapStateToProps = state => {
    return {
      searchKey: state.SearchReducer.adminsearch_term,
    };
  };
  const mapDispatchToProps = (dispatch) => ({
    searchUser: (value) => dispatch({ type: ADMINSEARCH_TERM, payload: value }),  
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(UsersManagementComponent);


