export const ADMINSEARCH_TERM="ADMINSEARCH_TERM";
export const ADMINSEARCH_TERM_FAIL="ADMINSEARCH_TERM_FAIL";


const initialState = {
	adminsearch_term:'',
};
const SearchReducer = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }  
  switch (action.type) {
    case ADMINSEARCH_TERM:
      return { ...state, adminsearch_term  : action.payload };  
    case ADMINSEARCH_TERM_FAIL:    
      return {...state, adminsearch_term : action.payload};
    default:
      return state;
  }
};

export default SearchReducer;


