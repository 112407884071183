import React, { Component } from 'react';
import * as d3 from "d3";
import initData from '../../../utils/initData';
import './BubbleChart.scss';
import PropTypes from 'prop-types';
export default class BubbleChart extends Component {
    constructor(props) {
        super(props);
       const cardWidth=window.innerWidth>812? 370: window.innerWidth;
        this.state = {
            width: cardWidth,
            height: cardWidth,
            data: []
        };

        this.renderChart = this.renderChart.bind(this);
        this.renderBubbles = this.renderBubbles.bind(this);
        this.onResize = this.onResize.bind(this);
    }

    componentDidMount() {
        this.resetData(this.props);
        window.addEventListener('resize', this.onResize)
    }

    componentWillUnmount() {
         window.removeEventListener('resize', this.onResize);
    }
 
    componentWillReceiveProps(nextProps) {
        this.resetData(nextProps);
    }

    resetData = (props) => {
        const { data, colors } = props;
        let bubbleData = [];
        if (data && colors) {
            const linearScale = d3.scaleLinear().domain([100, 0]).range(colors);

            data.forEach(item => {
                item.color = linearScale(item.value);
                bubbleData.push(
                    item
                )
            })
       }
       this.setState({data: bubbleData}, this.renderChart);
    }

    onResize() {
        const cardWidth=window.innerWidth>812? 370: window.innerWidth;
        this.setState({
            width: cardWidth,
            height: cardWidth,
        }, this.resetData(this.props));
        
    }
    
    render() {
        const {
            width,
            height,
        } = this.state;
        return (
            <svg width={width} height={height} ref={e=>this.svg=e} >
            </svg>
        )
    }

    renderChart(w) {
        const { overflow, graph, padding } = this.props;
        const width = w? w : this.state.width;
        const { data } = this.state;
        // Reset the svg element to a empty state.
        this.svg.innerHTML = '';
        // Allow bubbles overflowing its SVG container in visual aspect if props(overflow) is true.
        if (overflow)
            this.svg.style.overflow = "visible";

        const pack = d3.pack()
            .size([width * graph.zoom, width * graph.zoom])
            .padding(padding);

        // Process the data to have a hierarchy structure;
        const root = d3.hierarchy({ children: data })
            .sum(function (d) { return d.value; })
            .sort(function (a, b) { return b.value - a.value; })
            .each((d) => {
                if (d.data.label) {
                    d.label = d.data.label;
                    d.id = d.data.label.toLowerCase().replace(/ |\//g, "-");
                }
            });

        // Pass the data to the pack layout to calculate the distribution.
        const nodes = pack(root).leaves();
        // Call to the function that draw the bubbles.
        this.renderBubbles(width, nodes);
    }

    renderBubbles(width, nodes) {
        const {
            graph,
            data,
            brand,
            labelFont,
        } = this.props;

        const bubbleChart = d3.select(this.svg).append("g")
            .attr("class", "bubble-chart")
            .attr("transform", function (d) { return "translate(" + (width * graph.offsetX) + "," + (width * graph.offsetY) + ")"; });;

        const node = bubbleChart.selectAll(".node")
            .data(nodes)
            .enter().append("g")
            .attr("class", "node")
            .attr("transform", function (d) { return "translate(" + (isNaN(d.x)?0:d.x) + "," + (isNaN(d.y)?0:d.y) + ")"; });

        const tooltip = d3.select("body")
            .append("div")
            .attr("class", "tooltip")
            .style("display", "none");

        tooltip.append("div")
            .attr("class", "brand")
            .text(brand);

        const label = tooltip.append("div")
            .attr("class", "label");

        const value = tooltip.append("div")
            .attr("class", "value");

        node.append("circle")
            .attr("id", function (d) { return d.id; })
            .attr("r", function (d) { return (isNaN(d.r)?0:d.r*1.04); })
            .style("fill", function (d) { return d.data.color })
            .style("z-index", 1)
            .on('mouseover', function (d) {
                value.text(d.value.toFixed(1) + '%');
                label.text(initData.getLabelText(d.label));
            })
            .on("mousemove", function () {
                tooltip.style("display", "flex");
                return tooltip.style("top", (d3.event.pageY - 10) + "px").style("left", (d3.event.pageX + 10) + "px");
            })
            .on("mouseout", function () { return tooltip.style("display", "none"); });


        node.append("text")
            .attr("class", "label-text")
            .style("font-size", function(d){
                let fsize = parseInt(d.r/4);
                if (fsize>18) {
                    fsize = 18;
                }
                if (fsize<6) {
                    fsize = 6;
                }
                return fsize;
            })
            .attr("clip-path", function (d) { return "url(#clip-" + d.id + ")"; })
            .style("font-weight", (d) => {
                return labelFont.weight ? labelFont.weight : 600;
            })
            .style("font-family", '"azo-sans-web", sans-serif')
            .style("fill", () => {
                return labelFont.color ? labelFont.color : '#000';
            })
            .style("stroke", () => {
                return labelFont.lineColor ? labelFont.lineColor : '#000';
            })
            .style("stroke-width", () => {
                return labelFont.lineWeight ? labelFont.lineWeight : 0;
            })
            .text(function (d) {
                if(data.length === 14){
                    return  initData.getAdvertisingAndEmotionalLabelText(d.label);
                }else{
                    return initData.getLabelText(d.label);
                }            
            })
            .on('mouseover', function (d) {
                value.text(d.value + '%');
                label.text(initData.getLabelText(d.label));
            })
            .on("mousemove", function () {
                tooltip.style("display", "flex");
                return tooltip.style("top", (d3.event.pageY - 10) + "px").style("left", (d3.event.pageX + 10) + "px");
            })
            .on("mouseout", function () { return tooltip.style("display", "none"); });



        // Center the texts inside the circles.
        d3.selectAll(".label-text").attr("x", function (d) {
            const self = d3.select(this);
            const width = self.node().getBBox().width;
            return -(width / 2);
        })

            .style("opacity", function (d) {
                const self = d3.select(this);
                const width = self.node().getBBox().width;
                d.hideLabel = width * 1.05 > (d.r * 2);

                return d.hideLabel ? 0 : 1;
            })
            .attr("y", function (d) {
                var fontSize = isNaN(d.r)? 6 : (d.r / 3 < 6 ? 6 : d.r / 3);
                return fontSize / 2;
            })

    }
}

BubbleChart.propTypes = {
    overflow: PropTypes.bool,
    graph: PropTypes.shape({
        zoom: PropTypes.number,
        offsetX: PropTypes.number,
        offsetY: PropTypes.number,
    }),
    width: PropTypes.number,
    height: PropTypes.number,
    padding: PropTypes.number,
    legendFont: PropTypes.shape({
        family: PropTypes.string,
        size: PropTypes.number,
        color: PropTypes.string,
        weight: PropTypes.string,
    }),
    valueFont: PropTypes.shape({
        family: PropTypes.string,
        size: PropTypes.number,
        color: PropTypes.string,
        weight: PropTypes.string,
    }),
    labelFont: PropTypes.shape({
        family: PropTypes.string,
        size: PropTypes.number,
        color: PropTypes.string,
        weight: PropTypes.string,
    }),
}
BubbleChart.defaultProps = {
    overflow: false,
    graph: {
        zoom: 1.1,
        offsetX: -0.05,
        offsetY: -0.01,
    },
    width: 1000,
    height: 800,
    padding: 0,
    legendFont: {
        family: 'Arial',
        size: 12,
        color: '#000',
        weight: 'bold',
    },
    valueFont: {
        family: 'Arial',
        size: 16,
        color: '#fff',
        weight: 'bold',
    },
    labelFont: {
        family: 'Arial',
        size: 11,
        color: '#fff',
        weight: 'normal',
    },
    bubbleClickFun: (label) => { console.log(`Bubble ${label} is clicked ...`) },
    legendClickFun: (label) => { console.log(`Legend ${label} is clicked ...`) }
}