import React, { Component } from 'react';
import CardHeader from '../UsersManagement/CardHeader';
import BrandForm from './BrandForm';
import ListTable from '../../widgets/Shared/ListTable';
import initData from '../../../utils/initData';
import ApiData from '../../../utils/Api';
import InfoModal from '../InfoModal';
import noContractImg from '../../../assets/images/zero-illustration.png';

import '../UsersManagement/Profile.scss';
import { setLocalStorage } from '../../../utils/storageHelpers';

export default class BrandProfile extends Component {
    constructor(props) {
        super(props)
        this.brandData = initData.getBrandData();
        this.brandId = this.props.match.params.brandId;
        this.sortingTypes = initData.getSortingTypes();
        const contractSortColumn = this?.brandData?.table[0]?.columns.find(column => column.sorting === 'active');
        const marketsSortColumn = this?.brandData?.table[1]?.columns.find(column => column.sorting === 'active');

        this.state = {
            brandData: null,
            brandCompletesData: null,
            loading: true,
            currentMode: this.brandData.modes.profileDisplay,
            isOptionsDropdownOpened: false,
            isContractOptionsDropdownOpened: false,
            currentContract: null,
            storedBrandFields: null,
            isDeleteModalOpened: false,
            contractSorting: {
                key: contractSortColumn.field,
                type: this.sortingTypes.asc,
            },
            marketsSorting: {
                key: marketsSortColumn.field,
                type: this.sortingTypes.asc,
            }
        }
    }

    getListData = () => {
        this.setState({loading: true});
        const contractSort = this.state.contractSorting.key;
        const contractSortType = this.sortingTypes.asc === this.state.contractSorting.type;
        ApiData.getBrandDetailed(this.brandId, contractSort, contractSortType).then(
            (result) => {
                this.setState({
                    brandData: result,
                    loading: false,
                    storedBrandFields: this.getBrandFormData(result),
                });
                this.getCompletesListData();
            }
        ).catch(
            (err) => {
                this.setState({
                    loading: false,
                });
                console.log("Get brand data error", err);
            }
        );
    }

    getCompletesListData = () => {
        const marketsSort = this.state.marketsSorting.key;
        const marketsSortType = this.sortingTypes.asc === this.state.marketsSorting.type;
        this.setState({loading: true});
        ApiData.getBrandCampaigns(this.brandId, marketsSort, marketsSortType).then((result) => {
            this.setState({
                brandCompletesData: result,
                loading: false,
            });
        }).catch((err) => {
            console.log("Get brand campaigns error: ", err);
        });
    }

    createNewBrandClick = () => {
        const { history } = this.props;
        history.push(`/addbrand`);
    }

    deleteBrand = () => {
        const { types } = initData.getStatusMessages();
        const { history, showMessage } = this.props;
        this.setState({ loading: true });

        ApiData.deleteBrand(this.brandId)
            .then(result => {
                history.push('/brands');
                typeof showMessage === 'function' && showMessage(types.removeBrand, '');
            })
            .catch(error => {
                console.log('Deleting brand error', error);
                this.setState({ loading: false });
            })
    }

    findUpdateDiff = (brandData, storedBrandFields) => {
        if(brandData && storedBrandFields) {
            const { form } = this.brandData;
            const brandFields = {...brandData};
            delete brandFields[form.logo.key];
            const diff = [];
            Object.keys(brandFields).forEach(key => {
                if(brandFields[key] !== storedBrandFields[key] && !(!brandFields[key] && !storedBrandFields[key])) {
                    diff.push(key)
                }
            });
            if(brandData.newLogo && brandData.logo !== brandData.newLogo) {
                diff.push(form.logo.key);
            }
            const diffLabels = Object.values(form).filter(value => diff.some(key => value.key === key)).map(value => value.label);
            return {
                keys: diff,
                labels: diffLabels,
                string: diffLabels.length ? diffLabels.join(', ') : '',
            };
        }
    }

    updateBrand = (brand, rawData) => {
        const { showMessage } = this.props;
        const { storedBrandFields } = this.state;
        const { types } = initData.getStatusMessages();
        const updateDiff = this.findUpdateDiff(rawData, storedBrandFields);

        this.setState({ loading: true });

        ApiData.updateBrand(this.brandId, brand)
            .then(response => {
                typeof showMessage === 'function' && showMessage(types.updateBrand, updateDiff.string);
                this.setState({ currentMode: this.brandData.modes.profileDisplay, loading: false }, this.getListData);
            })
            .catch(error => {
                this.setState({ loading: false });
                console.log('Updating brand error', error);
            })
            .then(() => {
                // brandInfo was updated, but the data in localStorage was not. Let's request the updated one
                return ApiData.getUserBrands();
            })
            .then((brands) => {
                if (brands.length) {
                    setLocalStorage('myBrands', JSON.stringify(brands));
                }
            });

    }

    cancelUpdatingBrand = () => {
        this.setState({ currentMode: this.brandData.modes.profileDisplay });
    }

    getBrandFormData = (brandData) => {
        const { form: { decipherName, displayName, decipherId, logo, industry }} = this.brandData;
        return {
            id: brandData.id,
            [decipherName.key]: brandData.name,
            [decipherId.key]: `${brandData.externalId}-${brandData.id}`,
            [displayName.key]: brandData.displayName,
            [logo.key]: brandData.logo,
            [industry.key]: this.transformIndustryForSelect(brandData?.industry),
        }
    }

    transformIndustryForSelect = (industry) => {
        if (!industry) return null;

        return {
            label: industry.name,
            value: industry.id,
        }
    }

    editBrandAction = () => {
        this.setState({ currentMode: this.brandData.modes.profileUpdate, isOptionsDropdownOpened: false });
    }

    cancelEditAction = () => {
        this.setState({ currentMode: this.brandData.modes.profileDisplay });
    }

    openDeleteBrandModal = () => {
        this.setState({ isDeleteModalOpened: true });
    }

    closeDeleteBrandModal = () => {
        this.setState({ isDeleteModalOpened: false });
    }

    deleteBrandAction = () => {
        this.closeOptionsDropdown();
        this.openDeleteBrandModal();
    }

    createDropdownOptions = () => {
        const brandOptions = {...initData.getBrandDropdownOptions()};
        brandOptions.options[0].action = this.editBrandAction;
        brandOptions.separateOptions[0].action = this.deleteBrandAction;

        return brandOptions;
    }

    closeOptionsDropdown = () => {
        this.setState({ isOptionsDropdownOpened: false });
    }

    openOptionsDropdown = () => {
        this.setState({ isOptionsDropdownOpened: true });
    }

    onOptionsBtnClick = () => {
        const { isOptionsDropdownOpened } = this.state;
        if(isOptionsDropdownOpened) this.closeOptionsDropdown();
        else this.openOptionsDropdown();
    }

    getDeleteModalButtons = () => {
        const { modals: { removeBrand: { buttons }}} = this.brandData;

        buttons[0].action = this.closeDeleteBrandModal;
        buttons[1].action = this.deleteBrand;
        return buttons;
    }

    componentDidMount() {
        this.getListData();
    }

    contractNameClick = (id) => {
        const { history } = this.props;
        const { brandData } = this.state;
        const contract = brandData.contracts && brandData.contracts.length && brandData.contracts.find(contract => contract.id === id);
        const groupId = contract && contract.group && contract.group.id ? contract.group.id : null;
        history.push(`/${groupId}/contract/${id}`);
    }

    groupNameClick = (id) => {
        const { history } = this.props;
        const { brandData } = this.state;
        const contract = brandData.contracts && brandData.contracts.length && brandData.contracts.find(contract => contract.id === id);
        const groupId = contract && contract.group && contract.group.id ? contract.group.id : null;
        if(!groupId) return;
        else history.push(`/group/${groupId}`);
    }

    sampleSizeClick=(obj)=>{
        const { history } = this.props;
        const { contractId, groupId } = obj;
        if(!groupId && !contractId) return;
        else history.push(`/${groupId}/contract/${contractId}`);    }

    sortContracts = (column) => {
        const { contractSorting } = this.state;
        const type = contractSorting.type === this.sortingTypes.asc ? this.sortingTypes.desc : this.sortingTypes.asc;
        this.setState({
            contractSorting: { key: column.field, type },
        }, this.getListData);
    }

    sortMarkets = (column) => {
        const { marketsSorting } = this.state;
        const type = marketsSorting.type === this.sortingTypes.asc ? this.sortingTypes.desc : this.sortingTypes.asc;
        this.setState({
            marketsSorting: { key: column.field, type },
        }, this.getCompletesListData);
    }

    render() {
        const {
            brandData,
            currentMode,
            isOptionsDropdownOpened,
            brandCompletesData,
            isDeleteModalOpened } = this.state;
        const primaryTitle = brandData ? `${this.brandData.title(currentMode)}${brandData.displayName ? brandData.displayName : brandData.name}` : ' ';
        const secondaryTitle = brandData ? `${this.brandData.subtitle(currentMode)}${brandData.id}` : `${this.brandData.subtitle(currentMode)}`;
        const formFieldValues = brandData ? this.getBrandFormData(brandData) : null;
        const { modals } = this.brandData;
        const modalBrand = {
            displayName: brandData ? brandData.displayName : '',
            id: brandData ? brandData.id : '',
        }
        const contracts = brandData && brandData.contracts ? brandData.contracts : [];
    
        const listData = contracts.length ? contracts.map(contract => ({
            id: contract.id,
            brandContractName: {
                text: contract.displayName ? contract.displayName : contract.name ? contract.name : '',
                action: this.contractNameClick,
            },
            brandGroupName: {
                text: contract.group && contract.group.displayName ? contract.group.displayName : '',
                action: this.groupNameClick,
            },
            campaignGroups: {
                main: contract && contract.markets ? contract.markets.filter((market, index) => index < 2).map(market => market.code).join(', ') : '',
                trail: contract.markets && contract.markets.length > 2 ? ` (+${contract.markets.length - 2} more)` : '',
                total: contract && contract.markets ? contract.markets.filter((market, index) => index > 1).map(market => market.code).join(', ') : '',
            },
            startDate: contract.startDate ?  contract.startDate : '',
            endDate: contract.endDate ?  contract.endDate : '',
            quota: contract?.quota
        })) : [];

        let completesListData = brandData && brandCompletesData ? brandCompletesData?.map(brandCompletes => ({
            campaignCountry: brandCompletes?.country,
            campaignRegion: brandCompletes?.region,
            completes: brandCompletes?.completes,
            sampleSize: {
                text: brandCompletes?.sampleSize > 0 ? brandCompletes?.sampleSize : 0,
                action: this.sampleSizeClick,
            },
            contractId: brandCompletes.contractId,
            groupId: brandCompletes.groupId,
        })) : [];

        completesListData = [...completesListData, {id: -1, campaignCountry: 'Total Completes', completes: sumCompletes(completesListData)}];

        function sumCompletes(completesList) {
            let completesSum = 0;
            completesList.forEach(complete => {
                completesSum = completesSum + complete?.completes
            });

            return completesSum;
        }

        return (
            <div className={'profile'}>
                <CardHeader
                    primaryTitle={primaryTitle}
                    secondaryTitle={secondaryTitle}
                    button={{type: 'options', onClick: this.onOptionsBtnClick}}
                    isOptionsDropdownOpened={isOptionsDropdownOpened}
                    dropdownOptions={this.createDropdownOptions()}
                    closeDropdown={this.closeOptionsDropdown}
                />
                <div className={'profileContent'} style={{display:"flex"}}>
                    <BrandForm
                        fieldValues={formFieldValues}
                        cancelForm={this.cancelEditAction}
                        editMode={currentMode}
                        createBrand={this.updateBrand}
                    />
                    <div className={'profileContainer'}>
                        <div className={'profileTable'}>
                            <div className={'profileTableHeader'}>
                                <div className={'profileTableTitle'}>{this.brandData.table[0].title}</div>
                            </div>
                            {listData.length ?
                                <div className={'profileTableData'}>
                                    <ListTable
                                        listData={listData}
                                        listColumns={this.brandData.table[0].columns}
                                        onItemClick={() => {}}
                                        withPagination={false}
                                        isRowClickable={false}
                                        page={'brand'}
                                        sortedColumn={this.state.contractSorting}
                                        sortTable={this.sortContracts}
                                        selectedPage={'brands'}
                                    />
                                </div> :
                                <div>
                                    <div className={'zeroImgContainer'}><img className={'zeroImg'} src={noContractImg} alt={'No groups '} /></div>
                                    <div className={'zeroTitle'}>{this.brandData.table[0].noGroupsTitle}</div>
                                    <div className={'zeroMessage'}><span>{this.brandData.table[0].noGroupMessage}</span></div>
                                </div>
                            }
                        </div>
                        <div className={'profileTable'}>
                            <div className={'profileTableHeader'}>
                                <div className={'profileTableTitle'}>{this.brandData.table[1].title}</div>
                            </div>
                            {listData.length ?
                                <div className={'profileTableData'}>
                                    <ListTable
                                        listData={completesListData}
                                        listColumns={this.brandData.table[1].columns}
                                        onItemClick={() => {}}
                                        withPagination={false}
                                        isRowClickable={false}
                                        sortedColumn={this.state.marketsSorting}
                                        sortTable={this.sortMarkets}
                                        selectedPage={'brands'}
                                    />
                                </div>:
                                <div>
                                    <div className={'zeroImgContainer'}><img className={'zeroImg'} src={noContractImg} alt={'No groups'} /></div>
                                    <div className={'zeroTitle'}>{this.brandData.table[1].noGroupsTitle}</div>
                                    <div className={'zeroMessage'}><span>{this.brandData.table[1].noGroupMessage}</span></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {isDeleteModalOpened &&
                <InfoModal
                    title={modals.removeBrand.title}
                    message={modals.removeBrand.message}
                    extraMessage={modals.removeBrand.extraMessage}
                    subjectInfo={{
                        title: `Brand Name: ${modalBrand.displayName}`,
                        subtitle: `Brand ID: ${modalBrand.id}`,
                    }}
                    buttons={this.getDeleteModalButtons()}
                />
                }
            </div>
        )
    }
}
