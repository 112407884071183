import React, { Component } from 'react';
import './LoadingLayer.scss';

export default class LoadingLayer extends Component {
   render() {
        return (
            <div className="loadingLayer">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }
}