import React, { Component } from 'react';
import cx from 'classnames';
import initData from '../../../utils/initData';
import './ButterflyMap.scss';

export default class ButterflyMap extends Component {
  render() {
    const { mapData, colors, logo, brandName, statTestingEnabled } = this.props;
    const betterData=mapData.filter((md=>md.diff>0)).sort((a,b)=>{ return b.diff - a.diff});
    const worseData=mapData.filter((md=>md.diff<=0)).sort((a,b)=>{ return b.diff - a.diff});
    let endValue = parseInt((Math.max(betterData.length>0?betterData[0].diff:0, worseData.length>0? Math.abs(worseData[worseData.length-1].diff):0)+10)/10)*10;
    if (endValue>100) {
      endValue = 100;
    }
    const xAxis = [];
    for (let i=-(endValue/10); i<(endValue/10+1); i++) {
      xAxis.push(i);
    }

    return (
      <div className="ButterflyMapContainer" >
        <div className="map">
    <div className="title better" style={{padding: "40px 40px 20px"}}>{logo} <div><span>{brandName[0]}</span> is better</div></div>
        {betterData.map((data, index)=>{
           const itemStyle = {top: (100+ index*50) + 'px'};
           const ttStyle =  {left: (50*data.diff/endValue) + '%'};
           const barStyle= {width: (100*data.diff/endValue)+'%', backgroundColor: colors[0]};
           const higher = statTestingEnabled && data.higherThan;

           return <div key={index} className="butterflyItem better" style={itemStyle}>
                    <div
                        className={cx(
                            "itemTitle",
                            { "itemTitle--bold": higher },
                        )}
                    >
                        {initData.getLabelText(data.name)}
                    </div>
                    <div
                        style={barStyle}
                        className={cx(
                            "itemBar",
                            { "itemBar--bold": higher },
                        )}
                    >
                        <span>
                            {data.diff.toFixed(1)}%
                        </span>
                    </div>
                    <div className="tooltip" style={ttStyle}>
                      <div className="brand">{initData.getLabelText(data.name)}</div>
                      <div className="label">{brandName[0]}</div>
                      <div className="value">{data.brandValue.toFixed(1)}%</div>
                      <div className="label">{brandName[1]}</div>
                      <div className="value">{data.opponentValue.toFixed(1)}%</div>
                      <div className="label">Difference</div>
                      <div className="value">{data.diff.toFixed(1)}%</div>
                        {higher &&
                            <div className="statTooltip">
                                <span>{brandName[0]}</span> is statistically higher than <span>{brandName[1]}</span>
                            </div>
                        }
                    </div>
                  </div>
        })}
          <div className="title worse" style={{padding: "10px 40px 20px", top: (92+betterData.length*50) + 'px' }}>{logo} <div><span>{brandName[0]}</span> is worse</div></div>
          {worseData.map((data, index)=>{
           const itemStyle =  {top: (154+(betterData.length+index)*50) + 'px'};
           const ttStyle =  {right: (Math.abs(50*data.diff/endValue)) + '%'};
           const barStyle= {width: Math.abs(100*data.diff/endValue)+'%', backgroundColor: colors[1]};
           const lower = statTestingEnabled && data.lowerThan;

           return <div key={index} className="butterflyItem worse" style={itemStyle}>
                    <div
                        className={cx(
                            "itemTitle",
                            { "itemTitle--bold": lower },
                        )}
                    >
                        {initData.getLabelText(data.name)}
                    </div>
                   <div
                       style={barStyle}
                       className={cx(
                           "itemBar",
                           { "itemBar--bold": lower },
                       )}
                   >
                            <span>
                                {data.diff.toFixed(1)}%
                            </span>
                   </div>
                    <div className="tooltip" style={ttStyle}>
                      <div className="brand">{initData.getLabelText(data.name)}</div>
                      <div className="label">{brandName[0]}</div>
                      <div className="value">{data.brandValue.toFixed(1)}%</div>
                      <div className="label">{brandName[1]}</div>
                      <div className="value">{data.opponentValue.toFixed(1)}%</div>
                      <div className="label">Difference</div>
                      <div className="value">{data.diff.toFixed(1)}%</div>
                        {lower &&
                        <div className="statTooltip">
                            <span>{brandName[0]}</span> is statistically lower than <span>{brandName[1]}</span>
                        </div>
                        }
                    </div>
                  </div>
        })}
        </div>
        <div className="axis xAxis">
          {xAxis.map(x=>{
             return <span key={x}>{x*10}{x!==0 && '%'}</span>
          })}
        </div>
      </div>
    );
  }
}
