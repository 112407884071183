import {getLocalStorage} from "./storageHelpers";
import {STORAGE_KEY} from "../constants/app/storage";
import {GlobalAppEventType} from "../constants/enums/GlobalAppEventType";

const _ABLY_CLIENT_ID_PREFIX = process.env.REACT_APP_ABLY_CLIENT_ID_PREFIX ?? 'hpoll-user';
const _ABLY_PRIVATE_CHANNEL_PREFIX = process.env.REACT_APP_ABLY_CHANNEL_PRIVATE_NAMESPACE ?? 'hpoll-quest-socket-private';

export const generateWSClientId = (identifier) => {
    return identifier ? (_ABLY_CLIENT_ID_PREFIX + '--' + identifier) : null;
}

export const generateWSPrivateChannelId = (identifier) => {
    return identifier ? (_ABLY_PRIVATE_CHANNEL_PREFIX + ':' + identifier) : null;
}

export const getAuthUserId = () => {
    const user = getLocalStorage(STORAGE_KEY.USER); // TODO: Need to secure this.
    if (!user) {
        return null;
    }

    return JSON.parse(user).id;
}

export const getUserClientId = () => {
    const userId = getAuthUserId();

    return generateWSClientId(userId);
}

export const getUserWSClientId = () => {
    return getLocalStorage(STORAGE_KEY.WS_CLIENT_ID);
}

export const triggerWSClientIdUpdateEvent = (clientId) => {
    const event = new CustomEvent(GlobalAppEventType.WS_CLIENT_ID_UPDATED, { detail: clientId });
    window.dispatchEvent(event);
}
