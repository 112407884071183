import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { RiDeleteBinLine } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
import { RxQuestionMarkCircled } from "react-icons/rx";
import { IoWarningOutline } from "react-icons/io5";
import { CgDuplicate } from "react-icons/cg";
import './FilterCommonItem.scss';
import filtersTransformations from '../../../utils/filterTransformations';

const SegmentCommonItem = (props) => {

    const onClickSelf = () => {
        const {categoryObj, filterObj, optionObj, active, filterType} = props;
        if (props.onToggle) {
            props.onToggle(categoryObj, filterObj, optionObj, !active, filterType)
        }
    }

    const handleEditDeleteCopy = (action, id) => {
        props.handleSegmentsActions(action, id)
    }

    const escapeRegExp = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); 
    };

    const {title, active, filterObj, searchKey, searchFound, selectedMarket, selectedGlobalBrand, page, categoryObj} = props;
    const {filterId, isExpired, filterMarket, module, expiredFilters} = filterObj;
    const matchOption = title?.toLowerCase()?.includes(searchKey?.toLowerCase());
    const filtersPresentInMarket = filtersTransformations.checkFilterPresentInMarket(page, filterMarket, selectedMarket?.marketName, selectedGlobalBrand);
    const detachedFilters = filterObj.detachedFilters?.length > 0;
    const unFieldingFilters = filterObj.unFieldingFilters?.length > 0;
    return (
        <div className='segmentContainer'>
            <div className={cx("filterIconsListContainer")}>
                <div className={cx("filterCommonItemContainer segmentsCommonConatiner", {"active": active},{"isExpired":(!filtersPresentInMarket || detachedFilters || unFieldingFilters)})}
                    onClick={(!filtersPresentInMarket || detachedFilters || unFieldingFilters)?null:onClickSelf}>
                    {(searchFound && matchOption) ?
                        <div className="filterOptionTitle segmentOption" dangerouslySetInnerHTML={{ __html: title.toLowerCase()?.replace(new RegExp(escapeRegExp(searchKey)), match => `<span class='match'>${match}</span>`) }} />:
                        <div className="filterOptionTitle segmentOption">{title}</div>
                    }
                    {title?.length>15 && <span className='tooltiptext'>{title}</span>}
                </div>
                <div className='iconsContainer'>
                    <div className='showOrHideIconContainer'>
                        {(((page === 'global' && module === 'GLOBAL') || (page !== 'global' && module !== 'GLOBAL')) && (filtersPresentInMarket || detachedFilters || unFieldingFilters) && filterObj.filterGroup !== 'Shared') &&
                            <div className='showOrHideIconContainerEdit'>
                                <FiEdit2 alt="" className="showOrHideIcon" onClick = {()=>handleEditDeleteCopy('edit',filterId)}/>
                                <span className={'tooltipEdit'}>Edit</span>
                            </div>
                        }
                        {((page === 'global' && module === 'GLOBAL') || (page !== 'global' && module !== 'GLOBAL')) && (filtersPresentInMarket || detachedFilters || unFieldingFilters) &&
                            <div className=' showOrHideIconContainerEdit'>
                                <CgDuplicate  alt="" className="showOrHideIcon" onClick={() => handleEditDeleteCopy('copy', filterId)} />
                                <span className={'tooltipEdit'}>Duplicate</span>
                            </div>
                        }
                        <div className='showOrHideIconContainerDelete'>
                        <RiDeleteBinLine alt="" className="showOrHideIcon"  onClick = {()=>handleEditDeleteCopy('delete',filterId)}/>
                        <span className={'tooltipDelete'}>Delete</span>
                        </div>
                    </div>
                    <div className='warningIconsConatiner'>
                        {(page !== 'global' && module == 'GLOBAL') && <div className="segmentWarning">
                            <RxQuestionMarkCircled className="WarningIcons" />
                            <span className={'tooltipWarning'}>Filters for this segment are related to global markets.</span>
                        </div>}   
                        {!filtersPresentInMarket && <div className="segmentWarning">
                            <IoWarningOutline className="WarningIcons"/>
                            <span className={'tooltipWarning'}>Filters for this segment are not applicable for this market.</span>
                        </div>}
                        {filtersPresentInMarket && (isExpired || detachedFilters || unFieldingFilters) && <div className="segmentWarning">
                            <IoWarningOutline className="WarningIcons"/>
                            <div className={'tooltipWarning'}>
                            {isExpired && <span style={{paddingBottom: "5px"}}>The following filter(s) have expired:</span>}
                            {detachedFilters && <span style={{paddingBottom: "5px"}}>The following filter(s) are not available in this portal view.</span>}
                            {unFieldingFilters && <span style={{paddingBottom: "5px"}}>The following filter(s) are no longer available for use.</span>}
                              <br></br>
                                <div style={{padding:"10px 0px 10px 0px"}}>
                                    {expiredFilters?.length>0 && expiredFilters.map(ele=><span><li style={{fontSize:'10px'}}>{ele}</li></span>)}
                                    {detachedFilters && filterObj.detachedFilters?.map(ele=><span><li style={{fontSize:'10px'}}>{ele}</li></span>)}
                                    {unFieldingFilters && filterObj.unFieldingFilters?.map(ele=><span><li style={{fontSize:'10px'}}>{ele}</li></span>)}
                                </div>
                                {expiredFilters?.length>0? <span style={{paddingTop: "5px"}}>Reach out to your client service contact regarding reactivation.</span>:<span style={{paddingTop: "5px"}}>Reach out to your client service contact for more details.</span>}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

SegmentCommonItem.propTypes = {
    oId: PropTypes.number,
    title: PropTypes.string,
    active: PropTypes.bool,
    onToggle: PropTypes.func,
};

SegmentCommonItem.defaultProps = {
    oId: 0,
    title: 'Default',
    active: false,
};

export default SegmentCommonItem;

