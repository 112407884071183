import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ddIcon from '../../../assets/icons/icon_arrow_down.svg';
import cx from 'classnames';


export default class AddCampaignComponent extends Component {
    static propTypes = {
        items: PropTypes.array.isRequired,
        selectedItem: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedItem: props.items.filter(item => item.id === this.props.selectedItem)[0],
            dropdownOpen: false,
        }

        this.outsideClickListener = this.outsideClickListener.bind(this);
    }

    openDropdown() {
        const { disabled } = this.props;
        const { dropdownOpen } = this.state;

        if (!disabled) {
            if (!dropdownOpen) {
                document.addEventListener('click', this.outsideClickListener)
                this.setState({
                    dropdownOpen: true
                });
            } else {
                document.removeEventListener('click', this.outsideClickListener)
                this.setState({
                    dropdownOpen: false
                });
            }
        }
    }

    outsideClickListener() {
        const { dropdownOpen } = this.state;

        if (dropdownOpen) {
            this.openDropdown();
        }
        document.removeEventListener('click', this.outsideClickListener);
    }

    renderDropdownItems() {
        const { items } = this.props;

        return (
            <div className={'surveyDropdownItems'}>
                {items.map(item =>
                   <div key={item.id} className={'surveyDropdownItem'} onClick={() => this.selectItem(item)}>
                       <div>{item.surveyName}</div>
                       <div className={'subPath'}>{item.surveyPath}</div>
                   </div>
               )}
            </div>
        )
    }

    selectItem(item) {
        this.setState({
            selectedItem: item,
            dropdownOpen: false
        });

        this.props.onChange(item);
    }

    render() {
        const { disabled } = this.props;
        const { selectedItem, dropdownOpen } = this.state;

        return (
            <div className={cx('surveyDropdown', { 'open': dropdownOpen, 'disabled': disabled })}>
                <div className={'surveyDropdownInput'} onClick={() => this.openDropdown()}>
                    <span>{selectedItem.surveyName}</span>
                    <img className="ddArrow" src={ddIcon} alt="" />
                </div>
                {dropdownOpen && this.renderDropdownItems()}
            </div>
        )
    }
}