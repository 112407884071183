import React from 'react';
import styled from 'styled-components';
import noFilterImg from '../../../../assets/icons/icon_filter_empty.svg';


const Container = styled.div({
    display: 'flex',
    gap: '1.2rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'calc( 100vh - 340px)', //header + group name + tab + container padding-y+gap
    '& .zeroImg': {
        width: '182px',
        height: '150px',
        marginTop: 'auto',
    }
});
const Img = styled.img({
    width: '182px',
    height: '150px',
    marginTop: 'auto',
});
const Title = styled.div`
    text-transform: capitalize;
    font-size: 1.3rem;
    font-weight: 500;
`;
const SubText = styled.div`
    font-size: 1rem;
    font-weight: 300;
`;
const AutoHeightContainer = styled.div({
    display: 'flex',
    gap: '1.2rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    width: '100%',
    height: 'calc( 100vh - 340px)',
    '& .text-container': {
        textAlign: 'center'
    },
    '& .zeroImg': {
        width: '182px',
        height: '150px',
    }
});
const NoData = (({ children, src = noFilterImg, title = 'No Filters configured.', subText = 'Click on the button to add filters.', autoCalHeight = false }) => {
    let content = (<>
        <img className={'zeroImg'} src={src} alt={title} />
        <div className="text-container">
            <Title>{title}</Title>
            <SubText>{subText}</SubText>
        </div>
        {children}
    </>)
    if (autoCalHeight) {
        return (<AutoHeightContainer>
            {content}
        </AutoHeightContainer>)
    } else {
        return (
            <Container>
                <Img className={'zeroImg'} src={src} alt={title} />
                <div className="text-container">
                    <Title>{title}</Title>
                    <SubText>{subText}</SubText>
                </div>
                {children}
            </Container>
        );
    }

});
export default NoData;