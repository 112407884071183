import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

const Title = styled.div`
    color: black;
    font-size: 12px;
    font-family: "azo-sans-web", sans-serif;
    display: flex;
    align-items: center;
    column-gap: 10px;
    & .roundedIcon{
        height: 42px;
        width: 42px;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        text-align: center;
        display: flex;
        background-color: #cc9c96 ;
        opacity: 0.5;
    }
`;
const RemoveBtn = styled(Button)`
&.MuiButton-root {
    height: 50px;
    width: 100px;
    border: 1px solid #dfe2ea;
    background-color: #d9402c;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
    &:hover {
        background-color: #d9402c;
    }
}
`;
const CancelBtn = styled(Button)`
&.MuiButton-root {
    height: 50px;
    width: 100px;
    border: 1px solid #dfe2ea;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #71767D;
}
`;

const RemoveFilter = (({ open, removeHandle, closeHandle, filterCount }) => {
    const handleClose = () => {
        closeHandle();
    }
    const removeFilters = () => {
        removeHandle();
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Title>
                    <div className="roundedIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="35" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                        </svg>
                    </div>
                    <Typography variant="h6">Remove Filters</Typography>
                </Title>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div>Removing the filters will cause the filters to
                        be no longer available for the users belonging to the group
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div>{`${filterCount} filter${filterCount > 1 ? 's': ''} selected`}</div>
                <CancelBtn onClick={handleClose}>
                    Cancel
                </CancelBtn>
                <RemoveBtn onClick={removeFilters}>
                    Remove
                </RemoveBtn>
            </DialogActions>
        </Dialog>
    );
});
export default RemoveFilter;