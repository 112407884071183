import React, { Component } from 'react';
import searchIcon from "../../../assets/icons/icon_search.svg";
import { connect } from 'react-redux';
import  { ADMINSEARCH_TERM } from '../../../reducers/AdminSearch/SearchReducer'


 class SearchComponent extends Component {
    constructor(props) {
        super(props)
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    handleOnChange(e){
      let {placeholder, searchData, searchUser} = this.props;
      if(placeholder === "Category"){
        searchData(e.target.value,"category")
      }else if(placeholder === "Filter"){
        searchData(e.target.value,"filter")
      }else{
        searchUser(e.target.value);
      }
    }
    render() {
        const { searchKey, selectedTab, searchValue, placeholder} = this.props;
        const pathname = window.location.pathname;
        return (
              <div className="search-input" style={{marginRight:'7px'}}>
                            <div className="search-icon">
                                <img src={searchIcon} className="prefixIcon" alt='search'/>
                            </div>
                           <input
                                  type="text"
                                  value={placeholder ? searchValue : searchKey}
                                  placeholder={placeholder?`Search for ${placeholder}`:selectedTab === 1? 'Search for Groups': `Search for ${pathname.slice(1)}`}
                                  className="textarea"
                                  onChange={this.handleOnChange}
                                  style={{width: '100%'}}
                                  // onChange={searchValue?(e)=>handleChange(e,searchValue):this.handleOnChange}
                           />
                    </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      searchKey: state.SearchReducer.adminsearch_term,
    };
  };

const mapDispatchToProps = (dispatch) => ({
    searchUser: (value) => dispatch({ type: ADMINSEARCH_TERM , payload: value }),  
});


export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent);
