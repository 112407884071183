import React, { Component } from "react";
import PropTypes from "prop-types";
import initData from "../../../utils/initData";
import "../UsersManagement/ProfileForm.scss";
import cx from "classnames";
import ApiData from '../../../utils/Api';
import DecipherSurveyDropdown from './DecipherSurveyDropdown';
import RequiredIndicator from '../UsersManagement/RequiredIndicator';

export default class CampaignForm extends Component {
  static propTypes = {
    fieldValues: PropTypes.object,
    editMode: PropTypes.string.isRequired,
    cancelForm: PropTypes.func.isRequired,
    updateCampaign: PropTypes.func.isRequired,
    apiError: PropTypes.bool
  };

  constructor(props) {
    super(props);
    const campaignDataFieldsAttributes = initData.getCampaignFieldsAttributes();

    const {
      form: { name, regionState, totalCompletes, decipherSurvey, sampleId, code, displayName, campaignStatus, qcampaign, weightQuota, lastWeightedAt, ingestedCount, weightDifference },
    } = campaignDataFieldsAttributes;
    const fieldValues = this.props.fieldValues;
 
    this.state = {
      id: fieldValues ? fieldValues["id"] : "",
      [name.key]: fieldValues ? fieldValues[name.key] : "",
      [regionState.key]: fieldValues ? fieldValues[regionState.key] : "",
      // [consumerQuota.key]: fieldValues ? fieldValues[consumerQuota.key] : "",
      // [vendorQuota.key]: fieldValues ? fieldValues[vendorQuota.key] : "",
      [totalCompletes.key]: fieldValues ? fieldValues[totalCompletes.key] : 0,
      [decipherSurvey.key]: fieldValues ? fieldValues[decipherSurvey.key] : "",
      [sampleId.key]: fieldValues ? fieldValues[sampleId.key] : "",
      [code.key]: fieldValues ? fieldValues[code.key] : "",
      [qcampaign.key]: fieldValues ? fieldValues[qcampaign.key] : "",
      [displayName.key]: fieldValues ? fieldValues[displayName.key] : "",
      // [identity.key]: fieldValues ? fieldValues[identity.key] : "",
      [campaignStatus.key]: fieldValues ? fieldValues[campaignStatus.key] : "",
      [weightQuota.key]: fieldValues ? fieldValues[weightQuota.key] : "",
      [lastWeightedAt.key]: fieldValues ? fieldValues[lastWeightedAt.key] : "",
      [ingestedCount.key]: fieldValues ? fieldValues[ingestedCount.key] : "",
      [weightDifference.key]: fieldValues ? fieldValues[weightDifference.key] : "",
      summaryQuota: fieldValues ? fieldValues["summaryQuota"] : null,
      campaignDataFieldsAttributes: campaignDataFieldsAttributes,
      decipherSurveyList: fieldValues ? fieldValues['decipherSurveyList'] : "",
      sampleIdErr: false,
      qcampaignErr: false,
      weightQuotaErr: false
    };

    this.initializeSurveyState();
  }

  //a trick to update state as constructor is called only once. Using props as source of initial state
  //seems to be an antipattern/risky based on SO: https://stackoverflow.com/a/28785276/5069226
  //doing this nevertheless as other forms are done the same way
  static getDerivedStateFromProps(props, state) {
    const { fieldValues } = props;
    const {
      form: { name, regionState, totalCompletes, decipherSurvey, sampleId, code, displayName, campaignStatus, qcampaign, weightQuota, lastWeightedAt, ingestedCount, weightDifference },
    } = state.campaignDataFieldsAttributes;

    if (props.editMode === state.campaignDataFieldsAttributes.modes.profileDisplay) {
      return {
        id: fieldValues && fieldValues["id"] ? fieldValues["id"] : "",
        [name.key]:
          fieldValues && fieldValues[name.key] ? fieldValues[name.key] : "",
        [regionState.key]:
          fieldValues && fieldValues[regionState.key]
            ? fieldValues[regionState.key]
            : "",
        // [consumerQuota.key]:
        //   fieldValues && fieldValues[consumerQuota.key]
        //     ? fieldValues[consumerQuota.key]
        //     : "",
        // [vendorQuota.key]:
        //   fieldValues && fieldValues[vendorQuota.key]
        //     ? fieldValues[vendorQuota.key]
        //     : "",
        [totalCompletes.key]:
          fieldValues && fieldValues[totalCompletes.key]
            ? fieldValues[totalCompletes.key]
            : 0,
        [decipherSurvey.key]:
            fieldValues && fieldValues[decipherSurvey.key]
                ? fieldValues[decipherSurvey.key]
                : "",
        [sampleId.key]:
            fieldValues && fieldValues[sampleId.key]
                ? fieldValues[sampleId.key]
                : "",
        // [identity.key]:
        //     fieldValues && fieldValues[identity.key]
        //         ? fieldValues[identity.key]
        //         : "",
        [code.key]:
            fieldValues && fieldValues[code.key]
                ? fieldValues[code.key]
                : "",
        [qcampaign.key]:
            fieldValues && fieldValues[qcampaign.key]
                ? fieldValues[qcampaign.key]
                : "",
         [weightQuota.key]:
            fieldValues && fieldValues[weightQuota.key]
                ? fieldValues[weightQuota.key]
                : "",
        [lastWeightedAt.key]:
            fieldValues && fieldValues[lastWeightedAt.key]
                ? fieldValues[lastWeightedAt.key]
                : "",
        [ingestedCount.key]:
            fieldValues && fieldValues[ingestedCount.key]
                ? fieldValues[ingestedCount.key]
                : "",
        [weightDifference.key]:
            fieldValues && fieldValues[weightDifference.key]
                ? fieldValues[weightDifference.key]
                : "",
        [displayName.key]:
            fieldValues && fieldValues[displayName.key]
                ? fieldValues[displayName.key]
                : "",
        [campaignStatus.key]:
          fieldValues && fieldValues[campaignStatus.key]
            ? fieldValues[campaignStatus.key]
            : "",
        summaryQuota:
          fieldValues && fieldValues["summaryQuota"]
            ? fieldValues["summaryQuota"]
            : null,
        decipherSurveyList:
          state && state["decipherSurveyList"]
            ? state["decipherSurveyList"]
              : null
      };
    } else return null;
  }

  initializeSurveyState() {
    let surveyPaths = initData.getSurveyPaths();
    ApiData.getDecipherSurveys().then((result) => {
      this.setState({
        decipherSurvey: result[0].id,
        decipherSurveyList: result
      });
    }).catch((error) => {
      console.log(error)
    });
  }

  validation = (value, key) => {
    let sampleId = value?.split('_')
    let regex = /^\d+$/;
    if(key === 'sampleId'){
      if(!value.toLowerCase().includes('sample_') || !sampleId[1].match(regex)) {
        this.setState({ sampleIdErr: true });
      }else {
        this.setState({ sampleIdErr: false });
      }
    }else if(key === 'qcampaign'){
      if(value.match(regex)){
        this.setState({ qcampaignErr: false });
      }else{
        this.setState({ qcampaignErr: true });
      }
    }else if(key === 'weightQuota'){
      if(value.match(regex)){
        this.setState({ weightQuotaErr: false });
      }else{
        this.setState({ weightQuotaErr: true });
      }
    }
  }

  inputChange = (e, inputKey, id) => {
    const value = inputKey === 'decipherSurvey' ? e.id 
    : inputKey === 'code'?e.target.value.replace(/[^a-z-]+/i, ''):e.target.value;
    if(inputKey === 'sampleId' || inputKey === 'qcampaign' || inputKey === 'weightQuota'){
      this.validation(e.target.value, inputKey);
    }
    this.setState({ [inputKey]: value });
  };
  vendorQuotaChange = (e, id) => {
    const {
      form: { vendorQuota },
    } = this.state.campaignDataFieldsAttributes;
    const currentQuotaValue = this.state[vendorQuota.key].find(
      (quota) => quota.vendorId === id
    ).quotaValue;
    this.setState({
      summaryQuota:
        Number(this.state.summaryQuota) -
        Number(currentQuotaValue) +
        Number(e.target.value),
    });
    this.setState({
      [vendorQuota.key]: this.state[vendorQuota.key].map((quota) =>
        quota.vendorId === id
          ? {
              ...quota,
              quotaValue: e.target.value==="" ? 0 : Number(e.target.value),
              quotaPercent:(Number(e.target.value) / (this.state.summaryQuota - Number(currentQuotaValue) + Number(e.target.value)) * 100)
            }
          : {
              ...quota,
              quotaPercent:
                (Number(quota.quotaValue) / (this.state.summaryQuota - Number(currentQuotaValue) + Number(e.target.value)) * 100),
            }
      ),
    });
  };

  generateVendorQuotaBlock = (vendorsQuotasData) => {
    const { campaignDataFieldsAttributes } = this.state;
    const {
      form: { vendorQuota },
      modes,
    } = campaignDataFieldsAttributes;
    const vendorsQuotas = [];
    const { editMode } = this.props;
    let i = 0;

    if (vendorsQuotasData)
      vendorsQuotasData.sort((a, b) => (a.vendorId > b.vendorId) ? 1 : -1).forEach((quota, key) => {
        vendorsQuotas.push(
          <div className={"inputBlock"} key={key}>
            {/*Display label only for the first input*/}
            {
              <label htmlFor={vendorQuota.key} className={"inputLabel"}>
                {i === 0 && vendorQuota.label}
              </label>
            }
            <div>
              <div className={"vendorQuotaVendorName"}>{quota.vendorName}</div>
              <div className={"inputBox formInput"} style={{ display: "flex" }}>
                <input
                  id={vendorQuota.key + quota.vendorId}
                  type="number"
                  min="0"
                  style={{ marginRight: 10, width: 320 }}
                  placeholder={vendorQuota.placeholder}
                  pattern="^[0-9]*(?:\.[0-9]*)?$"
                  defaultValue={quota.quotaValue}
                  onChange={(e) => this.vendorQuotaChange(e, quota.vendorId)} //TODO need to replace general vendorQuota.key with actual component
                  disabled={editMode === modes.profileDisplay}
                />
                <input
                  id={vendorQuota.key + quota.vendorId + "%"}
                  placeholder={vendorQuota.placeholder}
                  style={{ width: 100 }}
                  value={Math.floor(quota.quotaPercent * 100) / 100 + "%"}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        );
        i++;
      });

    return <div className={"vendorQuotaBlock"}>{vendorsQuotas}</div>;
  };

  onCancelClick = () => {
    const { cancelForm } = this.props;
    cancelForm && cancelForm();
  };

  updateCampaign = () => {
    const { campaignDataFieldsAttributes } = this.state;
    const {
      form: { name, regionState, decipherSurvey, sampleId, code, displayName, qcampaign, weightQuota }
    } = campaignDataFieldsAttributes;

    const campaign = {
      id: this.state["id"],
      [name.key]: this.state[name.key],
      [regionState.key]: this.state[regionState.key],
      // [totalCompletes.key]: this.state[totalCompletes.key],
      [decipherSurvey.key]: this.state[decipherSurvey.key],
      [sampleId.key]: this.state[sampleId.key],
      [code.key]: this.state[code.key],
      [qcampaign.key]: this.state[qcampaign.key],
      [weightQuota.key]: this.state[weightQuota.key],
      [displayName.key]: this.state[displayName.key],
      // [identity.key]: this.state[identity.key],
      // [consumerQuota.key]: this.state[consumerQuota.key],
      // vendorsQuotas: this.state[vendorQuota.key],
      summaryQuota: this.state.summaryQuota
    };

    const { updateCampaign } = this.props;

    if (this.validateFields() && typeof updateCampaign === "function") {
      updateCampaign(campaign);
    }
  };

  validateFields = () => {
    const { campaignDataFieldsAttributes, sampleIdErr, qcampaignErr, weightQuotaErr} = this.state;
    const {
      form: { name, regionState, sampleId, code, displayName, qcampaign, weightQuota }
    } = campaignDataFieldsAttributes;
   
    return (
      !!this.state[name.key] &&
      !!this.state[regionState.key] &&
      !!this.state[sampleId.key] &&
      !!this.state[code.key] &&
      !!this.state[displayName.key] &&
      !!this.state[qcampaign.key] &&
      !!this.state[weightQuota.key] &&
      !sampleIdErr &&
      !qcampaignErr &&
      !weightQuotaErr
    )
  };

  render() {
    const { campaignDataFieldsAttributes, sampleIdErr, qcampaignErr, weightQuotaErr} = this.state;
    const {
      form: { name, regionState, totalCompletes, decipherSurvey, sampleId, code, displayName, qcampaign, weightQuota, lastWeightedAt, ingestedCount, weightDifference },
      modes,
      buttons,
    } = campaignDataFieldsAttributes;
    const { editMode } = this.props;

    let emptyFields = [];
    let invalidPatternFields = [];

    const isSubmitBtnDisabled = !this.validateFields();
    return (
        <div className={"profileForm"} style={{width: "50%"}}>
          <div className={"inputBlock"}>
            <label htmlFor={decipherSurvey.key} className={"inputLabel"}>
              {decipherSurvey.label}
            </label>
            <div className={"inputBox formInput formInput--heightAuto"}>
              {this.state[decipherSurvey.key] && this.state['decipherSurveyList'] &&
                  <DecipherSurveyDropdown
                      items={this.state['decipherSurveyList']}
                      selectedItem={this.state[decipherSurvey.key]}
                      onChange={item => this.inputChange(item, decipherSurvey.key)}
                      disabled={editMode === modes.profileDisplay}
                  />
              }
            </div>
          </div>
          <div className={"inputBlock"}>
            <label htmlFor={name.key} className={"inputLabel"}>
              {name.label}<RequiredIndicator/>
            </label>
            <div
                className={cx("inputBox formInput formInput--heightAuto", {'formError': emptyFields.indexOf(name.key) > -1})}>
              <input
                  id={name.key}
                  placeholder={name.placeholder}
                  value={this.state[name.key]}
                  onChange={(e) => this.inputChange(e, name.key)}
                  required={true}
                  disabled={editMode === modes.profileDisplay}
              />
            </div>
          </div>
          <div className={"inputBlock"}>
            <label htmlFor={regionState.key} className={"inputLabel"}>
              {regionState.label}<RequiredIndicator/>
            </label>
            <div
                className={cx("inputBox formInput formInput--heightAuto", {'formError': emptyFields.indexOf(regionState.key) > -1})}>
              <input
                  id={regionState.key}
                  placeholder={regionState.placeholder}
                  value={this.state[regionState.key]}
                  onChange={(e) => this.inputChange(e, regionState.key)}
                  required={true}
                  disabled={editMode === modes.profileDisplay}
              />
            </div>
          </div>
          <div className={"inputBlock"}>
            <label htmlFor={displayName.key} className={"inputLabel"}>
              {displayName.label}<RequiredIndicator/>
            </label>
            <div
                className={cx("inputBox formInput formInput--heightAuto", {'formError': emptyFields.indexOf(displayName.key) > -1})}>
              <input
                  id={displayName.key}
                  placeholder={displayName.placeholder}
                  value={this.state[displayName.key]}
                  onChange={(e) => this.inputChange(e, displayName.key)}
                  required={true}
                  disabled={editMode === modes.profileDisplay}
              />
            </div>
          </div>
          <div className={"inputBlock"}>
            <label htmlFor={sampleId.key} className={"inputLabel"}>
              {sampleId.label}<RequiredIndicator/>
            </label>
            <div
                className={cx("inputBox formInput formInput--heightAuto", {'formError': emptyFields.indexOf(sampleId.key) > -1 || invalidPatternFields.length > 0})}>
              <input
                  id={sampleId.key}
                  placeholder={sampleId.placeholder}
                  value={this.state[sampleId.key]}
                  onChange={(e) => this.inputChange(e, sampleId.key)}
                  required={true}
                  disabled={editMode === modes.profileDisplay}
              />
            </div>
          </div>
          {sampleIdErr && <div className={'inputBlock'}>
            <label></label>
            <div className={'inputBox formInput'}><label
                style={{color: '#E51E00'}}>{'Sample ID - valid pattern: sample_{id}'}</label></div>
          </div>}
          <div className={"inputBlock"}>
            <label htmlFor={code.key} className={"inputLabel"}>
              {code.label}<RequiredIndicator/>
            </label>
            <div
                className={cx("inputBox formInput formInput--heightAuto", {'formError': emptyFields.indexOf(code.key) > -1})}>
              <input
                  id={code.key}
                  placeholder={code.placeholder}
                  value={this.state[code.key]}
                  onChange={(e) => this.inputChange(e, code.key)}
                  required={true}
                  disabled={editMode === modes.profileDisplay}
              />
            </div>
          </div>
          <div className={"inputBlock"}>
            <label htmlFor={qcampaign.key} className={"inputLabel"}>
              {qcampaign.label}<RequiredIndicator/>
            </label>
            <div
                className={cx("inputBox formInput formInput--heightAuto", {'formError': emptyFields.indexOf(qcampaign.key) > -1})}>
              <input
                  id={qcampaign.key}
                  placeholder={qcampaign.placeholder}
                  value={this.state[qcampaign.key]}
                  onChange={(e) => this.inputChange(e, qcampaign.key)}
                  required={true}
                  disabled={editMode === modes.profileDisplay}
              />
            </div>
          </div>
          {qcampaignErr && <div className={'inputBlock'}>
            <label></label>
            <div className={'inputBox formInput'}><label
                style={{color: '#E51E00'}}>{'Qcampaign must be a number'}</label></div>
          </div>}
          <div className={"inputBlock"}>
            <label htmlFor={weightQuota.key} className={"inputLabel"}>
              {weightQuota.label}<RequiredIndicator/>
            </label>
            <div
                className={cx("inputBox formInput formInput--heightAuto", {'formError': emptyFields.indexOf(weightQuota.key) > -1})}>
              <input
                  id={weightQuota.key}
                  placeholder={weightQuota.placeholder}
                  value={this.state[weightQuota.key]}
                  onChange={(e) => this.inputChange(e, weightQuota.key)}
                  required={true}
                  disabled={editMode === modes.profileDisplay}
              />
            </div>
          </div>

          {/* Last Weighted At */}
          {editMode === modes.profileDisplay && (
            <div className={"inputBlock"}>
              <label htmlFor={lastWeightedAt.key} className={"inputLabel"}>
                {lastWeightedAt.label}
              </label>
              <div
                  className={cx("inputBox formInput formInput--heightAuto", {'formError': emptyFields.includes(lastWeightedAt.key)})}>
                <input
                    id={lastWeightedAt.key}
                    placeholder={lastWeightedAt.placeholder}
                    value={this.state[lastWeightedAt.key]}
                    required={false}
                    disabled={editMode === modes.profileDisplay}
                />
              </div>
            </div>
          )}

          {weightQuotaErr && <div className={'inputBlock'}>
            <label></label>
            <div className={'inputBox formInput'}><label
                style={{color: '#E51E00'}}>{'Weight quota must be a number'}</label></div>
          </div>}
          {/* <div className={"inputBlock"}>
          <label htmlFor={identity.key} className={"inputLabel"}>
            {identity.label}
          </label>
          <div className={cx("inputBox formInput formInput--heightAuto", {'formError': emptyFields.indexOf(identity.key) > -1})}>
            <input
                id={identity.key}
                placeholder={identity.placeholder}
                value={this.state[identity.key]}
                onChange={(e) => this.inputChange(e, identity.key)}
                // required={true}
                maxLength={5}
                disabled={editMode === modes.profileDisplay}
            />
          </div>
        </div> */}

          {/*{this.generateVendorQuotaBlock(this.state[vendorQuota.key])}*/}

          {editMode !== modes.profileCreate && (
              <div className={"inputBlock"}>
                <label htmlFor={totalCompletes.key} className={"inputLabel"}>
                  {totalCompletes.label}
                </label>
                <div className={"inputBox formInput"}>
                  <input
                      id={totalCompletes.key}
                      placeholder={totalCompletes.placeholder}
                      value={this.state[totalCompletes.key]}
                      onChange={(e) => this.inputChange(e, totalCompletes.key)}
                      disabled={true}
                  />
                </div>
              </div>
          )}

          {/* Total Ingested */}
          {editMode === modes.profileDisplay && (
              <div className={"inputBlock"}>
                <label htmlFor={ingestedCount.key} className={"inputLabel"}>
                  {ingestedCount.label}
                </label>
                <div
                    className={cx("inputBox formInput formInput--heightAuto", {'formError': emptyFields.includes(ingestedCount.key)})}>
                  <input
                      id={ingestedCount.key}
                      placeholder={ingestedCount.placeholder}
                      value={this.state[ingestedCount.key]}
                      required={false}
                      disabled={editMode === modes.profileDisplay}
                  />
                </div>
              </div>
          )}

          {/* Weight Difference */}
          {editMode === modes.profileDisplay && (
              <div className={"inputBlock"}>
                <label htmlFor={weightDifference.key} className={"inputLabel"}>
                  {weightDifference.label}
                </label>
                <div
                    className={cx("inputBox formInput formInput--heightAuto", {'formError': emptyFields.includes(weightDifference.key)})}>
                  <input
                      id={weightDifference.key}
                      placeholder={weightDifference.placeholder}
                      value={this.state[weightDifference.key]}
                      required={false}
                      disabled={editMode === modes.profileDisplay}
                  />
                </div>
              </div>
          )}

          {/*In DEV it is handy to have this to test if status was updated properly. Also I suspect this field will sooner or later be required in UI*/}
          {/*<div className={'inputBlock'}>*/}
          {/*    <label htmlFor={campaignStatus.key} className={'inputLabel'}>{campaignStatus.label}</label>*/}
          {/*    <div className={'inputBox formInput'}>*/}
          {/*        <input*/}
          {/*            id={campaignStatus.key}*/}
          {/*            placeholder={campaignStatus.placeholder}*/}
          {/*            value={this.state[campaignStatus.key]}*/}
          {/*            disabled={true}*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*</div>*/}
          {(editMode === modes.profileUpdate || editMode === modes.profileCreate) && (
              <div className={"buttonsBlock"}>
                <div
                    className={"button whiteButton addFormButton"}
                    onClick={this.onCancelClick}
                >
                  {buttons.cancel.label}
                </div>
                <div
                    className={cx("button", "blueButton", "addFormButton", {"addFormButton--disabled": isSubmitBtnDisabled})}
                    onClick={this.updateCampaign}
                >
                  {editMode === modes.profileCreate ? buttons.create.label : buttons.update.label}
                </div>
              </div>
          )}
        </div>
    );
  }
}
