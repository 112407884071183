import React, { Component } from 'react';
import FiltersList from './FiltersList';
import './SegmentTitle.scss';

export default class SegmentTitle extends Component {

    render() {
        const { segment, pool, page, selectedTab } = this.props;
        let currentFilters = [];
        if (segment.segmentId>-1) {
            segment.filters.forEach(cf=>{
                if (cf.filterId===8) {
                    currentFilters.push({filterId:cf?.filterId,filterName:'Age Range', filterOptions:[ cf?.filterOptions[0]?.filterOptionId, cf?.filterOptions[1]?.filterOptionId],labels:[`Age : ${ cf?.filterOptions[0]?.filterOptionId}-${ cf?.filterOptions[1]?.filterOptionId}`]});
                } else {
                    let filterOptions = [], labels = [];
                    cf.filterOptions.forEach(foption=>{
                            if (filterOptions.indexOf(foption?.filterOptionId)>-1) {
                                filterOptions.push(foption?.filterOptionId);
                                labels.push(foption?.filterOptionName)
                            }else{
                                filterOptions.push(foption?.filterOptionId)
                                labels.push(foption?.filterOptionName)

                            }
                    });
                    currentFilters.push({filterId:cf?.filterId,filterName:cf?.filterName, filterOptions: filterOptions, labels: labels});
                }
            });
        }
        return (
            <div className="segmentHeader">
                <div className="SegmentTitle">
                    <div className="segmentName">{segment.segmentName}</div>
                    {Number(pool?pool:0)>0 &&<div className="segmentCount">n={Math.round(pool).toLocaleString('en-En')}</div>}
                </div>
                <FiltersList
                    currentSelectedFilters={segment.segmentId > -1 ? currentFilters : null}
                    mbClickFunction={this.props.onClickSegmentFilter}
                    page={page}
                    selectedTab={selectedTab}
                />
            </div>
        )
    }
}
