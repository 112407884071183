import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CardHeader from './CardHeader';
import UserForm from './UserForm';
import initData from '../../../utils/initData';
import LoadingLayer from '../../widgets/Shared/LoadingLayer';
import ApiData from '../../../utils/Api';

export default class AddUserComponent extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        showMessage: PropTypes.func.isRequired,
    }

    state = {
        inProgress: false,
        error: null,
        errMessage: false,
    }

    addUser = (user) => {
        const { types } = initData.getStatusMessages();
        const { history, showMessage, errorMessage } = this.props;
        this.setState({ inProgress: true });
        const userData = {
            ...user,
            registrationType: 'SYSTEM',
        }

        ApiData.createUser(userData)
        .then(response => {
            this.setState({errMessage:false});
            typeof showMessage === 'function' && showMessage(types.addUser, user.email);
            typeof errorMessage === 'function' && errorMessage(this.state.errMessage)
            history.push('/users');
        })
        .catch(error => {
            this.setState({errMessage:true});
            if(error?.message === "Network Error"){
                this.setState({sessionTimeoutErr:true});
            }else{
                typeof showMessage === 'function' && showMessage(null, error.data.error);
            }
            this.setState({
                inProgress: false,
                error: error.statusCode === 409 ? error.data.error : error.error,
            },typeof errorMessage === 'function' && errorMessage(this.state.errMessage));
        })
    }

    cancelAddingUser = () => {
        this.props.history.push('/users');
    }

    render() {
        const addUserData = initData.getUserData();
        const { inProgress, error } = this.state;

        return (
            <div>
                <CardHeader 
                    primaryTitle={addUserData.title(addUserData.modes.create)}
                    secondaryTitle={addUserData.subtitle(addUserData.modes.create)} 
                />
                <UserForm cancelForm={this.cancelAddingUser} createUser={this.addUser} editMode={addUserData.modes.create} error={error} />
                {inProgress && <LoadingLayer />}
            </div>
        )
    }
}
