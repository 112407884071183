import {
	SET_CHART_DATA,
	DISABLE_BRAND,
	ENABLE_BRAND,
	DISABLE_SEGMENT,
	ENABLE_SEGMENT,
	CLEAR_ALL_DATA,
} from './actions';


export const setChartData = (chartData) => ({
	type: SET_CHART_DATA,
	chartData,
});

export const disableBrand = (id) => ({
	type: DISABLE_BRAND,
	id,
});

export const enableBrand = (id) => ({
	type: ENABLE_BRAND,
	id,
});

export const disableSegment = (id) => ({
	type: DISABLE_SEGMENT,
	id,
});

export const enableSegment = (id) => ({
	type: ENABLE_SEGMENT,
	id,
});

export const clearAllData = () => ({
	type: CLEAR_ALL_DATA,
});
