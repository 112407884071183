import React, { Component } from 'react';
import cx from 'classnames';
import SimpleSelect from '../Shared/SimpleDropDownSelect';
import { get } from 'lodash';
import './FilterDropdown.scss';

const optionTypes = {
    category: 'category',
    subcategory: 'subcategory',
}

export default class FilterDropdown extends Component {
    state = {
        selectedOptionType: '',
        selectedCategory: this.props.selectedOptions.category,
        selectedSubcategory: get(this.props, 'selectedOptions.subcategory.id', -1),
    }

    onSelectCategory = (index) => {
        const { subcategoriesList, categoriesList } = this.props;

        this.setState({
            selectedCategory: categoriesList[index].id,
            selectedSubcategory: get(subcategoriesList, `[${index}][0].id`, -1),
            selectedOptionType: '',
        });
    }

    onSelectSubcategory = (index) => {
      const { selectedCategory } = this.state;
      const { subcategoriesList } = this.props;

      this.setState({
        selectedSubcategory: subcategoriesList[selectedCategory].find(el => el.id === index).id,
        selectedOptionType: '',
      });
    }

    onButtonSubmit = (category, subcategory) =>{
        if (typeof this.props.onChangeLineOption === 'function') {
            this.props.onChangeLineOption({
                category,
                subcategory
            });
        }
    }

    onOpenSelect = (selectType) => (e) => {
        e.stopPropagation();
        const { selectedOptionType } = this.state;
        this.setState({
            selectedOptionType: selectType === selectedOptionType ? '' : selectType,
        })
    }

    render() {
        const { title, currentAxis, subcategoriesList, categoriesList, zAxisDisabled, toggleZAxisData } = this.props;
        const { selectedOptionType, selectedCategory, selectedSubcategory } = this.state;
        const categoriesSelectOpened = selectedOptionType === optionTypes.category;
        const subcategoriesSelectOpened = selectedOptionType === optionTypes.subcategory;

        return (
            <div className={cx('filter-dropdown', {
              [`filter-dropdown--${currentAxis}-axis`]: !!currentAxis,
            })}>
                <div className={'titleContainer'}>
                    <div className={'filter-dropdown-title'}>{title}</div>
                    {currentAxis === 'z' &&
                    <div className={'filter-dropdown_disable-option'} onClick={toggleZAxisData}>
                        {zAxisDisabled ? 'Enable' : 'Disable'}
                    </div>}
                </div>
                <div className={'selectContainer'}>
                    <SimpleSelect
                        customClass="twobytwo-axis-select"
                        data={categoriesList}
                        open={categoriesSelectOpened}
                        selected={selectedCategory}
                        skey="cat_"
                        onOpenSelect={this.onOpenSelect(optionTypes.category)}
                        onSelectFunction={this.onSelectCategory}
                    />
                    {selectedCategory !== 4 &&
                    <SimpleSelect
                        customClass="twobytwo-axis-select"
                        data={subcategoriesList[selectedCategory]}
                        open={subcategoriesSelectOpened}
                        selected={selectedSubcategory}
                        skey="subcat_"
                        onOpenSelect={this.onOpenSelect(optionTypes.subcategory)}
                        onSelectFunction={this.onSelectSubcategory}
                    />
                    }
                <div className="blueButton" style={{width: 88, height: 50, padding: "15px 0px", fontSize: 14}} onClick={()=>this.onButtonSubmit(this.state.selectedCategory, this.state.selectedSubcategory)}>
                    Apply
                </div>
                </div>
            </div>
        )
    }
}
