import moment from 'moment';

class LineChartStatTestingDateService {
    #intervals;

    constructor() {
        this.#intervals = {
            days: 'DAYS',
            weeks: 'WEEKS',
            months: 'MONTHS',
            quarters: 'QUARTERS'
        };
    }

    #getDateForValidation = (date, interval, isComparisonDate = false) => {
        switch (interval) {
            case this.#intervals.months:
            case this.#intervals.quarters:
            case this.#intervals.days:
                return date;
            case this.#intervals.weeks:
            default:
                return isComparisonDate ? date.split('--')[1] : date.split('--')[0];
        }
    };

    validateStatTestingDate = (date, comparisonDate, interval) => {
        const dateStart = this.#getDateForValidation(date, interval);
        const comparisonDateEnd = this.#getDateForValidation(comparisonDate, interval, true);

        // only prior dates are valid
        const isValid = moment(dateStart).isAfter(moment(comparisonDateEnd));

        return isValid;
    };

    formatStatTestingDate = (date, interval) => {
        switch (interval) {
            case this.#intervals.months:
                return moment(date).format('MMM YYYY');
            case this.#intervals.days:
                return moment(date).format('MM/DD/YY');
            case this.#intervals.quarters:
                const quarter = Math.floor(moment(date).month() / 3) + 1;
                return `Q${quarter} ` + moment(date).format(`YYYY`);
            case this.#intervals.weeks:
            default:
                const [date1, date2] = date.split('--');
                const formattedMonth1 = moment(date1).format('MMM');
                const formattedMonth2 = moment(date2).format('MMM');

                const statTestingDate = formattedMonth1 === formattedMonth2
                    ? `${moment(date1).format('MMM D')}-${moment(date2).format('D')}`
                    : `${moment(date1).format('MMM D')}-${moment(date2).format('MMM D')}`;

                return statTestingDate;
        }
    };
}

const lineChartStatTestingDateService = new LineChartStatTestingDateService();

export default lineChartStatTestingDateService;
