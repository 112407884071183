import React, { useState } from "react";
import "./FilterCommonItem.scss";
import '../FilterOptionComponent.scss';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SelectedFiltersList from "../Shared/SelectedFiltersList";

const DeleteSegment = (props) => {
    let [deleteSegmentsSelected, setDeleteSegmentsSelected] = useState(false);
   
    const onSelectedSegments = () =>{
        setDeleteSegmentsSelected(deleteSegmentsSelected = deleteSegmentsSelected === false? !deleteSegmentsSelected: false)
        props.onDeleteAllSegmentsInCategory(deleteSegmentsSelected)
    }

    let { currentFilters, categoryCheck, selectedSegment } = props;
    return (
        <>
        { !categoryCheck?
        <div className="segmentOptionContainer">
            <div className='deleteContentContainer'>
                <div className = 'roundedIcon'>
                    <ErrorOutlineIcon htmlColor={'#D92D20'} sx={{ color: '#D92D20' }}/>
                </div>  
                <div className="deleteSegmentTitleConatiner" >
                    <div className='deleteTitle'>Delete</div> 
                    <div className='segmentHeader'>'{selectedSegment?.segmentName}'?</div>
                </div>
                <div className='segmentTitle'>Are you sure you want to delete this segment?</div>
            </div>
            {currentFilters.length > 0 && <div className='deleteTitle'>Selected Filters</div>}
            {/* <div className='segmentTitle'>All filters Present in segment are expired or not applicable to this market</div>} */}
            <SelectedFiltersList currentSelectedFilters={currentFilters} changeCurrentFilters={props.changeCurrentFilters}/>
        </div>
        :
            <div className="segmentOptionContainer">
                <div className='deleteContentContainer' style={{height:'63vh'}}>
                    <div className='roundedIcon'>
                        <ErrorOutlineIcon htmlColor={'#D92D20'} sx={{ color: '#D92D20' }} />
                    </div>
                    <div className="deleteSegmentTitleConatiner" >
                        <div className='deleteTitle'>Delete</div>
                        <div className='segmentHeader'>'{props.deleteCategoryName}'?</div>
                    </div>
                        <div className='segmentTitle'>Are you sure you want to delete this category?</div>
                        <div className='segmentTitleCategory'>
                        <input type="checkbox" checked={deleteSegmentsSelected} className="categoryCheckBox" onClick={onSelectedSegments} />
                        <div className="categoryText">Delete Segments within '{props.deleteCategoryName}'</div>
                        </div>
                </div>
            </div>
}
        </>
    )
}

export default DeleteSegment;