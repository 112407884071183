import React, { Component } from "react";
import searchIcon from "../../../../assets/icons/icon_search.svg";
import searchIconBlue from "../../../../assets/icons/icon_search_blue.svg";
import BrandItemList from "./BrandItemList";
import BrandItemCard from "./BrandItemCard";
import ApiData from '../../../../utils/Api';
import "./AddBrandComponent.scss";

export default class AddBrandComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: undefined,
      mybrands: [],
      searchBrand: "",
      cost: "0.00",
      showCreateScreen: false,
      saveBrands: false,
      saveSuccess: 0
    };
    
    this.onInputChanged = this.onInputChanged.bind(this);
    this.searchBrandChanged = this.searchBrandChanged.bind(this);
    this.onSelectBrand = this.onSelectBrand.bind(this);
    this.onClickCreate = this.onClickCreate.bind(this);
    this.removeMyBrandItem = this.removeMyBrandItem.bind(this);
    this.onCreateFinish = this.onCreateFinish.bind(this);
  }
  
  renderMyBrandItems() {
    const { mybrands } = this.state;
    let cells = [];
    mybrands.forEach((item, i) => {
      cells.push(
        <div key={item.name} className="mybrandCardContainer">
          <BrandItemCard
            index={i}
            id={item.id}
            name={item.name}
            icon={item.logo}
            onRemove={this.removeMyBrandItem}
          />
        </div>
      );
    });

    return <div>{cells}</div>;
  }

  onClickCreate() {
    this.setState({
      showCreateScreen: true,
      suggestions: undefined
    });
  }

  onInputChanged(name) {
    const { selectedBrandList } = this.props;

    let currentBrandsIds =  this.props.currentBrands? this.props.currentBrands.map((brand)=>brand.id) : [];
    const selectedBrandsIds = selectedBrandList ? selectedBrandList.map(brand => brand.id) : [];

    ApiData.searchBrandByname(encodeURI(name)).then(
      (result) => {
        this.setState({
          suggestions: result.filter(function(item) {
            return currentBrandsIds.indexOf(item.id) === -1 && selectedBrandsIds.indexOf(item.id) === -1
          }),
        })
      }
    ).catch(
        (err) => {
          console.log("error=" + err )
        }
    );
  }

  /**
   * triggerred by user selected from the suggestion list
   * @param {*} id
   */
  onSelectBrand(id) {
    const { suggestions, mybrands } = this.state;

    let item = suggestions.find(s=>{return s.id===id});
    let selectedBrands = [];
    if (mybrands) {
      var found = mybrands.find(function(element) {
        return element.name === item.name;
      });
      if (found) {
        // already in mybrands, cannot add again
        return;
      }
      selectedBrands = mybrands;
    }
    selectedBrands.push(item);

    this.setState({
      searchBrand: "",
      suggestions: undefined,
      mybrands: selectedBrands
    });
    this.props.setBrands(this.state.mybrands);
    let field = document.getElementById("search");
    field.focus();
    field.value = "";
  }

  /**
   * triggerred by user manually input
   * @param {*} brand
   */
  searchBrandChanged(e) {
    const brand = e.target.value;
    this.onInputChanged(brand);
    this.setState({
      searchBrand: brand
    });
  }

  removeMyBrandItem(i) {
    var { mybrands } = this.state;

    if (mybrands && mybrands.length > i) {
      let temp = mybrands;
      temp.splice(i, 1);
      this.setState({
        mybrands: temp
      });
    }
  }

  onCreateFinish() {
    this.setState({
      showCreateScreen: false,
    });
  }


  renderMyBrands() {
    const { mybrands } = this.state;
    let currentBrandsIds = this.props.currentBrands? this.props.currentBrands.map((brand)=>brand.id) : []
    const filteredBrands = mybrands.filter(({id}) => !currentBrandsIds.includes(id));
    if (!filteredBrands || filteredBrands.length === 0) {
      return;
    }

    return (
      <div className="mybrandsContainer" ref="brands">
        <div className="brands-list">{this.renderMyBrandItems()}</div>
      </div>
    );
  }

  render() {
    const {  searchBrand, suggestions, mybrands } = this.state;
    let icon = searchBrand !== "" ? searchIconBlue : searchIcon;
    let available_suggestions =[];

    if (suggestions && suggestions.length > 0 ) {
        suggestions.forEach(s => {
          if (mybrands && mybrands.length > 0) {
            if (mybrands.findIndex( mb => { return mb.name === s.name})<0) {
              available_suggestions.push(s);  
            }
          } else {
            available_suggestions.push(s);
          }
        });      
    }
    let currentBrandsIds =  this.props.currentBrands? this.props.currentBrands.map((brand)=>brand.id) : []
    available_suggestions.filter(function(item) {
      return currentBrandsIds.indexOf(item.id) === -1
    });
    return (
        <>
        <div className="selectAddBrandContainer">
          <div className="selectBrandContent">
            <div className="selectBrandSelectionContainer">
              <div className="selectBrandInputContainer">
                <div className="searchIconContainer">
                  <img src={icon} className="prefixIcon" alt="" />
                </div>
                <input
                  id="search"
                  placeholder={"Search for brands to add…"}
                  label="Search for brand…"
                  type="text"
                  autoFocus={true}
                  onChange={this.searchBrandChanged}
                />
              </div>
              {available_suggestions && available_suggestions.length > 0 && (
                <BrandItemList
                  onSelect={this.onSelectBrand}
                  data={available_suggestions}
                />
              )}
            </div>
          </div>
        </div>
          {this.renderMyBrands()}
        </>
    );
  }
}
