import React, { Component } from 'react';
import cx from 'classnames';
import initData from '../../../utils/initData';
import './DifferentiationMap.scss';

export default class DifferentiationMap extends Component {

  render() {
    const { mapData, showXY, zoomlevel, brands } = this.props;
    const zoom=(zoomlevel!==0)?Number('1e'+zoomlevel) : 1;
    let xMax = 1;
    let yMax = 1;
    if (zoom>0) {
      xMax=parseInt(Math.max(...mapData.map(data=>{ return Math.abs(data.coords?.x*zoom)}))*10+1)/10;
      yMax=parseInt(Math.max(...mapData.map(data=>{ return Math.abs(data.coords?.y*zoom)}))*10+1)/10;
    }
    return (
      <div className={cx("differentiationMapContainer", {"noXY": !showXY})} >
      {showXY &&
        <div className="axis yAxis">
          <span>1.0</span>
          <span>0.5</span>
          <span></span>
        </div>
      }
        <div className="map">
        {mapData.map((data, index)=>{
          if (!data.coords)
            return;

          let x = (zoom>0) ? (data.coords.x*zoom/xMax+1)*50:data.coords.x;
          let y = (zoom>0) ? (data.coords.y*zoom/yMax+1)*50:data.coords.y;
            
          let itemStyle = {};
          let tooltipStyle = {} 
          if (x < 51) {
            itemStyle.left = x + '%';
            tooltipStyle.left = x+ '%';
          } else {
            itemStyle.right = (100-x)+ '%';
            tooltipStyle.right = (100-x) + '%';
          }
          if (y < 51) {
            itemStyle.bottom = y + '%';
            tooltipStyle.bottom = 'calc(' + y + '% + 20px)';
          } else {
            itemStyle.bottom = 'calc(' + y+ '% - 20px)';
            tooltipStyle.top = 'calc(' + (100 - y) + '% + 20px)';
          }
          const brand = brands && data.brand? brands.find(b=>b.name===data.name):null;
          const brandName = brand && brand.displayName ? brand.displayName : brand && brand.name ? brand.name : '';
          const label = brand?(brand.logo ? <img src={brand.logo} className="icon" alt={"logo" + index} /> : <div>{brandName.slice(0, 1).toUpperCase()}</div>): initData.getLabelText(data.name);
          return <div key={index}>
                    <div className={cx("differentiationItem", {"logoImg": brand&&brand.logo, "logoText": brand&&!brand.logo })} style={itemStyle}>{label}</div>
                    <div className="tooltip" style={tooltipStyle}>
                      <div className={cx("brand", {"single": !showXY})}>{data.brand && brandName ? brandName : initData.getLabelText(data.name)}</div>
                      {showXY && <div className="percentage">x: {Number(data.coords.x).toFixed(1)}%</div>}
                      {showXY && <div className="percentage">y: {Number(data.coords.y).toFixed(1)}%</div>}
                    </div>
                  </div>
        })}
        </div>
      {showXY &&
        <div className="axis xAxis">
          <span>0.0</span>
          <span>0.5</span>
          <span>1.0</span>
        </div>
      }  
      </div>
    );
  }
}
