import React, { Component } from 'react'
import { logOut } from './Login.helpers';

export default class LogoutComponent extends Component {
    
    componentDidMount(){
        let path = window.location.pathname
        if( path === '/logout'){
          logOut()
        }
       }
    render() {
        return (
           <div></div>
        )
    }
}
