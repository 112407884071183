import React, { Component } from 'react';
import './BrandItemList.scss';
import BrandItem from './BrandItem';

export default class BrandItemList extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
        this.onClickItem = this.onClickItem.bind(this);
    }

    onClickItem(index) {
        if (this.props.onSelect) {
            this.props.onSelect(index);
        }
    }

    renderItems() {
        const {data} = this.props;

        if (!data) {
            return (<li />);
        }

        return (data.map((item, i) => {
            return (
                <BrandItem
                    key={item.name}
                    index={i}
                    onClick={() => this.onClickItem(item.id)}
                    name={item.name}
                    id={item.id}
                    icon={item.logo}
                    {...item}
                />
            );
        }));
    }

    render() {
        return (
            <ul className="brandItemListContainer">
                {this.renderItems()}
            </ul> 
        );
    }
}