import React, { useState, useRef } from "react";
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import dateIcon from "../../../../assets/icons/icon_calendarpicker.svg";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { START_DATE, EXPIRY_DATE } from './Filters.jsx';
import AddFilter from '../../../../assets/icons/building-05.svg';
import DateInput from 'react-date-picker';
import moment from "moment";

const dateFormat = "YYYY-MM-DD";
const dateDisplayFormat = "M/d/yyyy";

const DateContainer = styled.div`
  display: flex;
  column-gap: 20px;
`;
const BtnContainer = styled(DateContainer)`
  margin-top: 20px;
  & button {
      box-sizing: border-box;
      border: 1px solid #dfe2ea;
      border-radius: 4px;
      background-color: #fff;
      flex: 1;
      cursor: pointer;
      color: #71767D;
      &.confirmButton {
        background-color: green;
        color: #fff;
      }
  }
`;


// const CONTRACT_EXT_DATE = 45;

const EditFilter = ({ isOpen, closeFilter, filterCount, editFilterHandler, action, minMaxDates }) => {
  let [startDate, setStartDate] = useState();
  let [endDate, setEndDate] = useState(moment().toDate());
  const modalRef = useRef(null);
  let text = 'End Date';
  let aObj = {};

  if(action === START_DATE) {
    text = 'Start Date';
    aObj.minDate = moment().toDate();
    aObj.maxDate = moment(minMaxDates.maxDate).toDate();
  } else if (action === EXPIRY_DATE) {
    let minDate = moment(minMaxDates.minDate).toDate() >= moment().toDate()?moment(minMaxDates.minDate).toDate():moment().toDate()
    aObj.minDate = moment(minDate).toDate();
    aObj.maxDate = '';//.add(CONTRACT_EXT_DATE, 'days').toDate();
  }


  const dateChange = (dateObj) => {
    if (!moment(dateObj).isValid()) return;
    setStartDate(moment(dateObj).format(dateFormat))
    
  };

  const handleSubmit = () => {
    let aObj = {};
    if(action === START_DATE) {
      aObj.startDate = moment(startDate).format(dateFormat);
    } else if(action === EXPIRY_DATE) {
      aObj.expiryDate = moment(startDate).format(dateFormat);
    }
    editFilterHandler(aObj);
    closeFilter();
  }

  const calendarIcon = (
    <img src={dateIcon} className="calendarIcon" alt="Date icon" />
  );

  return (
    <Modal ref={modalRef} open={isOpen} onClose={closeFilter} initialFocusRef={modalRef} center
      classNames={{
        modal: 'edit-filter-modal',
      }}>
      <div>
        <div className="modalHeader">
          <div className="rounded"><img src={AddFilter} /></div>
          <div className="headingText">
            <span>Edit Filter configuration</span> ({filterCount} selected)
            <div>Update the configuration of the filters selected</div>
          </div>
        </div>
        <div className="datesContainerRoot">
          <DateContainer>
            <div className="inputContainer">
              <div className="inputBlock">
                <label className={"inputLabel"}>{text}</label>
                <div className={'datesContainer'}>
                  <div className={'formInput'}>
                    <div>
                      <DateInput
                        className="dateInput_picker"
                        format={dateDisplayFormat}
                        calendarIcon={null}
                        clearIcon={null}
                        value={startDate? moment(startDate).toDate() : null}
                        
                        onChange={(date) => dateChange(date)}
                        isOpen={false}
                        {...aObj}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DateContainer>
          <span>Note: Updating the dates may affect the filters displayed for the users for the group</span>
        </div>
      </div>
      <BtnContainer>
        <Button className={"cancelButton"} onClick={closeFilter}>Cancel</Button>
        <Button className={"confirmButton"} onClick={handleSubmit}>Confirm</Button>
      </BtnContainer>
    </Modal>
  )
}

export default EditFilter;


