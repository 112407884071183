import React, { Component } from "react";
import LoadingLayer from "../../widgets/Shared/LoadingLayer";
import ListTable from "../../widgets/Shared/ListTable";
import initData from "../../../utils/initData";
import ApiData from "../../../utils/Api";
// import moment from "moment";
// import ApproveBrandComponent from "./ApproveBrand";
import "./CampaignManagementComponent.scss";
// import CardHeader from "../UsersManagement/CardHeader";

export default class CampaignBrands extends Component {
  constructor(props) {
    super(props);
    this.approvedBrandsColumns = initData.getColumns(7);
    this.pendingBrandsColumns = initData.getColumns(0);
    this.sortingTypes = initData.getSortingTypes();
    const approvedActiveSortColumn = this.approvedBrandsColumns.find(
      (column) => column.sorting === "active"
    );
    const pendingActiveSortColumn = this.pendingBrandsColumns.find(
      (column) => column.sorting === "active"
    );

    this.state = {
      listData: [],
      campaignData: null,
      loading: true,
      page: 1,
      pageLimit: 150,
      offsetHeight: 0,
      approveId: 0,
      approvedBrandsSorting: {
        key: approvedActiveSortColumn.field,
        type: this.sortingTypes.asc,
      },
      pendingBrandsSorting: {
        key: pendingActiveSortColumn.field,
        type: this.sortingTypes.asc,
      },
    };
  }

  getListData = () => {
    this.setState({ loading: true, listData: [] });
    const {
      offsetHeight,
      page,
      pageLimit,
      approvedBrandsSorting,
      // pendingBrandsSorting,
    } = this.state;
    const listData = [];
    const approvedBrandSort = approvedBrandsSorting.key;
    const approvedBrandSortType =
      this.sortingTypes.asc === approvedBrandsSorting.type;

    ApiData.getCampaignBrands(
      this.props.campaignId,
      "all",
      page,
      pageLimit,
      approvedBrandSort,
      approvedBrandSortType
    )
      .then((result) => {
        result.data.forEach((item) => {
          const group =
            item && item.groups
              ? item.groups
                  .filter((group, index) => index < 2)
                  .map((group) =>
                    group.displayName ? group.displayName : group.name
                  )
                  .join(", ")
              : "";
          const groupTrail =
            item.groups && item.groups.length > 2
              ? ` (+${item.groups.length - 2} more)`
              : "";

          listData.push({
            id: item.id,
            brand: {
              name: item.displayName,
              decipherName: item.name,
              logo: item.logo,
            },
            displayName: item.displayName,
            brandId: item.externalId + "-" + item.id,
            tag: item.tag,
            brandGroups: {
              main: group,
              trail: groupTrail,
              total: item && item.groups? item.groups.filter((group, index) => index > 1).map((group) =>group.displayName ? group.displayName : group.name).join(", "): ""
            },
            contracts: item.anyContracts? item.activeContracts : "No contracts",
            expired: item.expired,
            status: item.statusDecipher,
          });
        });

        this.setState(
          {
            selectedTab: 0,
            listData,
            loading: false,
            page: page,
            pageSize: result.pageInfo.pageSize,
            totalCount: result.pageInfo.totalCount,
          },
          () => {
            window.scrollTo(0, offsetHeight);
          }
        );
      })
      .catch((err) => {
        console.log("error=" + err);
        this.setState({ loading: false });
      });
  };

  sortApprovedBrands = (column) => {
    const { approvedBrandsSorting} = this.state;
    const type =
      approvedBrandsSorting.type === this.sortingTypes.asc
        ? this.sortingTypes.desc
        : this.sortingTypes.asc;

    this.setState(
      {
        approvedBrandsSorting: { key: column.field, type },
      },
      this.getListData
    );
  };

  sortPendingBrands = (column) => {
    const { pendingBrandsSorting } = this.state;
    const type =
      pendingBrandsSorting.type === this.sortingTypes.asc
        ? this.sortingTypes.desc
        : this.sortingTypes.asc;

    this.setState(
      {
        pendingBrandsSorting: { key: column.field, type },
      },
      this.getListData
    );
  };

  openBrandProfile = (id) => {
    const { history } = this.props;
    history.push(`/brand/${id}`);
  };

  componentDidMount() {
    this.getListData();
    // window.addEventListener('scroll', this.lazyLoad);
  }

  render() {
    const {
      listData,
      loading,
      pageSize,
      totalCount,
      page,
      approvedBrandsSorting,
      // pendingBrandsSorting,
    } = this.state;
    let dataItemsContainerCls =
      "dataItemsContainer" + (loading ? " loading" : "");
    // const { history, selectedTab } = this.props;
    // const formFieldsValues = this.campaignData ? this.extractDataForTheForm(this.campaignData) : null;
    // const title = formFieldsValues ? formFieldsValues[name.key] : '';
    // const subtitle = formFieldsValues ? formFieldsValues["code"] : '';
    // console.log(history);
    // if(selectedTab===0){
    //     history.push(`${history.location.pathname.substring(0, history.location.pathname.lastIndexOf('/'))}`)
    // } else if(selectedTab===2){
    //     history.push(`${history.location.pathname.substring(0, history.location.pathname.lastIndexOf('/'))}/weighting`)
    // }
    return (
      <div>
        <div className={dataItemsContainerCls} style={{overflowX:"auto"}}>
          {loading && <LoadingLayer />}
          {listData.length > 0 && (
            <ListTable
              listData={listData}
              total={totalCount}
              limit={pageSize}
              current={page}
              listColumns={initData.getColumns(7)}
              handlePageChange={(current) =>
                this.setState({ page: current }, this.getListData)
              }
              onItemClick={this.openBrandProfile}
              sortedColumn={approvedBrandsSorting}
              sortTable={this.sortApprovedBrands}
            />
          )}
        </div>
      </div>
    );
  }
}
