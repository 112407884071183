import React, { Component } from 'react';
import GeoTitle from './GeoTitle';
import './BrandItem.scss';

export default class GeoItem extends Component {
    /* constructor(props) {
        super(props);

    }*/

    render() {
        const { index, region, name, id } = this.props;
        return (
            <li id={index} className="brandItemContainer" onClick={this.props.onClick}>
                <GeoTitle geo={{ region: region, name: name, id: id}} />
            </li>
        );
    }
}