import styled from 'styled-components'

const ProductContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    flex-direction: row;
    align-items: flex-start;
    gap: 15px;
`;

const ProductCard = styled.div`
    display: flex;
    padding: 5px 15px 20px 15px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 300px;
    min-height: 180px;
    width: 100%;
    height: auto;

    border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 1.96774px 3.93548px 0px rgba(0, 0, 0, 0.10), 0px 0px 3.93548px 0px rgba(0, 0, 0, 0.10);
`;

const ProductImage = styled.img`
    max-width: 230px;
    width: 100%;
    margin-left: -14px;
`;

const ProductContent = styled.div`
    display: flex;
    padding: 0px 0px 40px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    font-size: 14px;
`;

const ProductActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const ProductActionButton = styled.button`
    display: flex;
    color: #1AB76C;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 13.992px;
    text-decoration: none;
    background: transparent;
    border: none;
    padding: 0;
    font-family: "Silka-SemiBold", sans-serif;
`;

export { ProductContainer, ProductCard, ProductImage, ProductContent, ProductActions, ProductActionButton };
