import { DateTime } from 'luxon';

export const _DEFAULT_DATE_FORMAT = { month: '2-digit', day: '2-digit', year: 'numeric' };
export const _DEFAULT_DATE_TIME_FORMAT = { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };

/**
 * Converts a UTC date string to a human-readable format.
 *
 * @param {string} input - The UTC date string to convert.
 * @param {string} [timeZone='local'] - The time zone to use for the conversion.
 * @param {object} [format=_DEFAULT_DATE_TIME_FORMAT] - The format of the human-readable date string.
 * @return {string} The converted human-readable date string.
 */
export function convertUTCToReadableFormat(input, timeZone = 'local', format = _DEFAULT_DATE_TIME_FORMAT) {
    if (input === null) {
        return '--';
    }

    const dateTime = new Date(input);
    const localDate = DateTime.fromJSDate(dateTime).setZone(timeZone);

    return localDate.toLocaleString(format);
}

/**
 * Takes a date string and formats it.
 *
 * @param {string} input - The date string to format.
 * @param {object} format - The format to apply.
 *
 * @return {string} The formatted date string.
 */
export function formatDateString(input, format = _DEFAULT_DATE_FORMAT) {
    if (input === null) {
        return '--';
    }

    const dateTime = DateTime.fromISO(input);
    if (!dateTime.isValid) {
        console.error('[formatDateString] Invalid date string:', input);
        return '--';
    }

    return dateTime.toLocaleString(format);
}

/**
 * Formats a date string as UTC using the specified format.
 * If the input is null, returns '--'.
 *
 * @param {string} input - The date string to format.
 * @param {object} [format=_DEFAULT_DATE_FORMAT] - The format to use for formatting the date string.
 * @returns {string} - The formatted date string in UTC.
 */
export function formatDateStringAsUTC(input, format = _DEFAULT_DATE_FORMAT) {
    if (input === null) {
        return '--';
    }

    const dateTime = DateTime.fromISO(input).setZone('utc');

    return dateTime.toLocaleString(format);
}

export const DateTimeHelper = {
    convertUTCToReadableFormat,
    formatDateString,
    formatDateStringAsUTC
};
