import React, { Component } from 'react';
import cx from 'classnames';
import Pagination from './Pagination'
import BrandTitle from './BrandTitle';
import StatusLabel from './StatusLabel';
import OptionsDropdown from './OptionsDropdown';
import sortingIconGrey from '../../../assets/icons/sort-grey.svg';
import sortingIconBlack from '../../../assets/icons/sort-black.svg';
import './ListTable.scss';
import InitData from '../../../utils/initData';
import { IoWarning } from "react-icons/io5";

export default class listTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settingId: 0 ,
            totalCompletesRow: false,
            scrollWrapperHeight: null,
        };
    }

    componentDidMount() {
        this.initializeAndUseScrollWrapperService();
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.onResize);
    }

    settingClick(id) {
        this.setState({
            settingId: 0
        }, this.props.onItemClick(id));
    }

    initializeAndUseScrollWrapperService = () => {
        const scrollWrapperHeight =  window.innerHeight;

        this.setState({
            scrollWrapperHeight,
        });
    };

    onDropdownButtonClick = (event, id) => {
        const { dropdownButton } = this.props;
        event.preventDefault();
        event.stopPropagation();

        typeof dropdownButton.action === 'function' && dropdownButton.action(id)
    }
    getTableCells = (colData, rowCount) => {
        const { listColumns, cells } = this.props;
        const jsxEle = listColumns.map((col, index) => {
            const data = colData[`${col.value}`];
            return (<>
                <div className={cx("rowCell", `cell-${index}`)}>
                    {cells(data, col.value, index, rowCount, colData)}
                </div>
            </>);

        });
        return jsxEle;
    }

    onResize = () => {
        setTimeout(() => {
            const scrollWrapperHeight = window.innerHeight
            this.setState({ scrollWrapperHeight });
        }, 500);
    };

    /**
     * Renders the market weight difference for a market.
     *
     * @param {Object} item - The item to render the market weight difference for.
     * @param {string} columnKey - The key of the column containing the weight difference value.
     * @param {string} conditionKey - The key of the column containing the weight sample size value.
     * @returns {JSX.Element} - The rendered market weight difference element.
     */
    renderMarketWeightDifference = (item, columnKey, conditionKey) => {
        const weightDifference = Number(item[columnKey]);
        const weightSampleSize = Number(item[conditionKey]);

        return (
            <div
                style={{
                    "display": "flex",
                    "alignItems": "center",
                }}
            >
                { Number.isFinite(weightDifference) ? weightDifference : '--' }
                { (Number.isFinite(weightDifference) && Number.isFinite(weightSampleSize) && weightDifference > weightSampleSize)
                    && <IoWarning style={{ color: 'red', fontSize: '1.3em', marginLeft: '0.2em' }} />
                }
            </div>
        );
    };

    render() {
        const { listData, listColumns, onItemClick, hoverIcon, hoverClickIcon, total, limit, current, handlePageChange, setId, activeField, withPagination = true, dropdownButton, closeDropdown, isDropdownOpened, currentDropdownId, dropdownOptions, isRowClickable = true, dropdownClassname = true, sortTable, sortedColumn, isSecondaryTable, page, tableItem, selectedPage } = this.props;
        const { settingId } = this.state;
        const clickOnRow = typeof onItemClick === 'function' && !!!hoverIcon && !!!hoverClickIcon;
        const sortingTypes = InitData.getSortingTypes();

        return (
            <div className="listTable" style={(isSecondaryTable || selectedPage === 'brands') ? {minWidth: 'initial'} : {height:this.state.scrollWrapperHeight-172}}>
                <div className="tableHeader">
                    {listColumns.map((column)=>{
                        return (
                            <div className="headerCell" style={{"width": column.width}} key={column.key}>
                                {column.jsx? column.jsx : null}
                                {column.title}
                                {!!column.sorting && !!sortedColumn && sortedColumn.key === column.field ?
                                <img className={cx('sortingIcon', {'sortingIcon--rotated': (sortedColumn.type === sortingTypes.asc || sortedColumn.asc === true)})}
                                        src={sortingIconBlack}
                                        alt={'sorting icon'}
                                        onClick={!!sortTable ? () => sortTable(column) : null}
                                    /> : !!column.sorting && !!sortedColumn && sortedColumn.key !== column.field ?
                                        <img className={'sortingIcon'}
                                            src={sortingIconGrey}
                                            alt={'sorting icon'}
                                            onClick={!!sortTable ? () => sortTable(column) : null}
                                        /> : null}
                            </div>
                        )
                    })}
                </div>
                {listData?.map((item, index) => {
                    const settingLabel = item.id===setId? 'Remove as Primary' : 'Set as Primary Brand';
                    const settingActive = !activeField || item[activeField]!=='Inactive';
                    return (
                        <div className={cx("tableRow", { "rowClickable": isRowClickable, "primarySetting": typeof setId !== 'undefined', "setted": item.id === setId, "totalCompletesRow": item.completes && item.campaignCountry === 'Total Completes' })} key={'row' + index} onClick={(clickOnRow && settingActive) ? () => { onItemClick(item.id) } : null} style={!!item.brandContractName || !!item.brandGroupName || !!item?.sampleSize ? { cursor: 'initial' } : null}>
                            {!tableItem && listColumns?.map((column) => {
                                return (
                                    <div className={cx("rowCell", {
                                        "rowCellClickable": column.key==='brandContractName' || column.key==='brandGroupName'|| column.key==='sampleSize',
                                            "cellClickable": column.clickable,
                                        })}
                                        style={{"width": column.width}}
                                        key={'row'+index+'_'+column.key}
                                            onClick={
                                                (item.brandContractName && item.brandGroupName && !page) ?
                                            (column.key==='brandContractName' || column.key==='brandGroupName'||column.key==='sampleSize') ? () => item.action(item['brandGroupName']?.id, item[column.key]?.id) : null :
                                            (column.key==='brandContractName' || column.key==='brandGroupName') ? () => item[column.key]?.action(item?.id) :(column.key==='sampleSize')?() => item[column.key]?.action(item): null
                                            }
                                        >
                                        {
                                            column.key === 'weightDifference'
                                                ? this.renderMarketWeightDifference(item, column.key, "weightQuota")
                                                : column.key==='brand'
                                                    ? <BrandTitle isBrandTable={true} brand={item[column.key]} primaryBrand={item.id===setId} />
                                                    : column.key==='userGroups'
                                                        ?  <>{!!item[column.key] && item[column.key].main}<span className={'cellTrail'}>{!!item[column.key] && item[column.key].trail}<div className="alLlist">{!!item[column.key] && item[column.key].total}</div></span></>
                                                        : column.key==='brandGroups'
                                                            ? <>{!!item[column.key] && item[column.key].main}<span className={'cellTrail'}>{!!item[column.key] && item[column.key].trail}<div className="alLlist">{!!item[column.key] && item[column.key].total}</div></span></>
                                                            : column.key==='campaignGroups'
                                                                ? <>{!!item[column.key] && item[column.key].main}<span className={'cellTrail'}>{!!item[column.key] && item[column.key].trail}<div className="alLlist">{!!item[column.key] && item[column.key].total}</div></span></>
                                                                : column.key==='brandContractName' || column.key==='brandGroupName'||column.key==='sampleSize'
                                                                    ? item[column.key]?.text
                                                                    : (column.key==='status' || column.key === 'contractsStatus' || column.key === 'verified')
                                                                        ? <StatusLabel status={item[column.key]} />
                                                                        : item[column.key]
                                        }
                                    </div>
                                )
                            })
                            }
                            {settingActive && !!hoverIcon &&
                                <div className="hoverIcon" onClick={typeof onItemClick === 'function' ? () => { onItemClick(item.id) } : null}>{hoverIcon}</div>}
                            {settingActive && !!hoverClickIcon &&
                                <div className="hoverIcon primarySetting">
                                    <div onClick={() => this.setState({ settingId: item.id })}>{hoverClickIcon}</div>
                                    <div className={cx("settingAction", { show: settingId === item.id })} onClick={this.settingClick.bind(this, item.id)} >{settingLabel}</div>
                                </div>}
                            {dropdownButton &&
                                <div
                                    id={`groupOptionsButton${item.id}`}
                                    className="hoverIcon primarySetting"
                                    onClick={(event) => this.onDropdownButtonClick(event, item.id)}
                                >
                                    {dropdownButton.component}
                                </div>
                            }
                            {dropdownButton && isDropdownOpened && currentDropdownId === item.id &&
                                <OptionsDropdown
                                    customClassname={dropdownClassname}
                                    closeDropdown={closeDropdown}
                                    options={dropdownOptions.options}
                                    separateOptions={dropdownOptions.separateOptions}
                                    buttonId={`groupOptionsButton${item.id}`}
                                />
                            }
                            {tableItem && this.getTableCells(item, index)}
                        </div>
                    )
                })
                }
                {withPagination && <Pagination total={total} limit={limit} current={current} handlePageChange={handlePageChange} />}
            </div>
        )
    }
} 
