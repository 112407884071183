import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CardHeader from '../UsersManagement/CardHeader';
import LoadingLayer from '../../widgets/Shared/LoadingLayer';
import CampaignForm from './CampaignForm';
import initData from '../../../utils/initData';
import ApiData from '../../../utils/Api';


export default class AddCampaignComponent extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        showMessage: PropTypes.func.isRequired,
        errorMessage: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            apiError: false,
            decipherSurveys: []
        }
    }

    addGeo(geo) {
        const { types } = initData.getStatusMessages();
        const { showMessage, errorMessage} = this.props;

        this.setState({ inProgress: true, apiError: false });
        ApiData.createCampaign(geo).then((result) => {
            this.setState({errMessage:false});
            if(result?.id){
                typeof showMessage === 'function' && showMessage(types.campaignCreate, result.name + '/' + result.region);
                this.cancelAddingGeo()
            }else{
                this.setState({errMessage:true});
                typeof showMessage === 'function' && showMessage(types.existingCampaign);
                typeof errorMessage === 'function' && errorMessage(this.state.errMessage)
            }
            this.setState({ inProgress: false });
        }).catch(error => {
            this.setState({ inProgress: false, apiError: true });
            console.log(error);
        });
    }

    cancelAddingGeo = () => {
        this.props.history.push('/markets');
    }

    addDecipherSurvey = () => {
        this.props.history.push('/addsurvey')
    }

    render() {
        const geoDataFields = initData.getCampaignFieldsAttributes();
        const { inProgress, apiError } = this.state;

        return (
            <div>
                <CardHeader
                    primaryTitle={'Add New Market'}
                    secondaryTitle={'Create a new Market for Harris Poll QuestBrand'}
                    decipherSurveyButton={true}
                    addDecipherSurvey={this.addDecipherSurvey}
                />
                <CampaignForm
                    editMode={geoDataFields.modes.profileCreate}
                    cancelForm={this.cancelAddingGeo}
                    updateCampaign={(geo) => this.addGeo(geo)}
                    apiError={apiError}
                />
                {inProgress && <LoadingLayer />}
            </div>
        )
    }
}