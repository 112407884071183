import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import './CircleProgressBar.scss';

const CirlceProgressBar = ({ progress, progressMax = 100, pathColor, trailColor, strokeWidth = 1 }) => {
    return (
        <CircularProgressbar 
            value={progress} 
            maxValue={progressMax} 
            strokeWidth={strokeWidth} 
            styles={buildStyles({
                pathColor,
                trailColor,
            })}
        />
    )
}

CirlceProgressBar.propTypes = {
    progress: PropTypes.number.isRequired,
    progressMax: PropTypes.number,
    pathColor: PropTypes.string,
    trailColor: PropTypes.string,
    strokeWidth: PropTypes.number,
}

export default CirlceProgressBar;
