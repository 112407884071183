import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './Toaster.scss';

const Toaster = props => {
    const { toastList, position, autoDismiss, dismissTime } = props;
    const [list, setList] = useState(toastList);

    useEffect(() => {
        setList([...toastList]);
    }, [toastList]);

    useEffect(() => {
        const delibleItemIndex = list.findIndex(e => e.preventAutoDismiss !== true);
        if (delibleItemIndex > -1 && typeof delibleItemIndex != "undefined") {
            const interval = setInterval(() => {
                if (autoDismiss && toastList.length && list.length) {
                    deleteToast(toastList[delibleItemIndex].id);
                }
            }, dismissTime);
            
            return () => {
                clearInterval(interval);
            }
        }
    }, [toastList, autoDismiss, dismissTime, list]);

    const deleteToast = (id, forceDelete = false) => {
        const listItemIndex = list.findIndex(e => e.id === id);
        const toastListItem = toastList.findIndex(e => e.id === id);

        if (!list[toastListItem].preventAutoDismiss || forceDelete) {
            list.splice(listItemIndex, 1);
            toastList.splice(toastListItem, 1);
            setList([...list]);
        }
    }

    return (
        <>
            <div className={`toaster-container ${position}`}>
                {
                    list.map((toast, i) =>     
                        <div 
                            key={i}
                            className={`toaster ${position}`}
                        >
                            <div className="toast-header">
                                <strong className="header-title">{ toast.title ?? "Notification" } </strong>

                                {(toast.action && toast.action.type.toString() === 'download') && 
                                    <small>
                                        <a href={toast.action.link} onClick={() => deleteToast(toast.id, true)} download={ toast.action.fileName ?? "export.xlsx"}>Download</a>
                                    </small>
                                }
                                {(toast.action && toast.action.type.toString() === 'text') && 
                                    <small>
                                        { toast.action.content ?? '' }
                                    </small>
                                }
                            </div>
                            <div className="toast-body">
                                {toast.content}
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
}

Toaster.propTypes = {
    toastList: PropTypes.array.isRequired,
    position: PropTypes.string,
    autoDismiss: PropTypes.bool,
    dismissTime: PropTypes.number
}

export default Toaster;
