import React, { Component } from 'react';
import cx from 'classnames';
import initData from '../../../utils/initData';
import BrandTitle from '../Shared/BrandTitle';
import SegmentTitle from '../Shared/SegmentTitle';
import NoDataBox from '../Shared/NoDataBox';
import './FunnelChartCard.scss';
import * as d3 from "d3";
import upIcon from '../../../assets/icons/icon_positive.svg';
import downIcon from '../../../assets/icons/icon_negative.svg';
import { isEmpty } from 'lodash'

export default class FunnelChartCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: 'solid',
            width: 0,
            height: 0,
            ratio: 0.8,
            id: this.props.id,
            data: this.data
        }
        this.onResize = this.onResize.bind(this);
    }

    componentDidMount() {
        this.onResize();
        window.addEventListener('resize', this.onResize)
    }

    componentWillUnmount() {
         window.removeEventListener('resize', this.onResize);
    }

    componentWillReceiveProps(nextProps) {
        this.drawChart(nextProps);
    }

    onResize() {
        const cardWidth=window.innerWidth>812? 500: window.innerWidth;
        this.chartWidth = Math.floor(cardWidth * 0.68);
        this.descriptionWidth = Math.floor(cardWidth * 0.2);
        //this.bottomWidth = Math.floor(cardWidth / 5);
        this.setState({
            width: cardWidth,
            height: cardWidth * this.props.hightRatio,
            ratio: cardWidth / 500,
        }, this.drawChart);
    }

    drawChart=(props)=>{
        const { data, brandValue} = props?props:this.props;
        // let ref = "#fc_chartGroup_key_" + id;
        // d3.select(ref).selectAll("svg").remove();
        d3.select(this.svg).selectAll("g").remove();

        if (brandValue>0) {
            this.blocks = this.standardizeData(data);
            this.drawOntoDom();
        }
    }

    /**
     * Convert the raw data into a standardized format.
     *
     * @param {Array}  data
     * @param {Number} totalCount
     *
     * @return {Array}
     */
    standardizeData(data) {
        const {height} = this.state;
        const {colors} = this.props;
        const totalCount = data.reduce((a, b) => a + this.getRawBlockCount(b), 0);
        if (Array.isArray(data) && data.length>0) {
            const linearscale = d3.scaleLinear().domain([data[0].value, data[data.length - 1].value]).range(colors);
            return data.map((block, index) => {
                const ratio = totalCount > 0 ? (block.value / totalCount || 0) : 1 / data.length;
                return {
                    index,
                    ratio,
                    value: block.value,
                    height: height * ratio,
                    fill: linearscale(block.value),
                    tooltip: {
                        enabled: block.enabled,
                    },
                };
            });
        } else {
            return [];
        }
    }

    makePaths() {

        let paths = [];
        let straightPaths = [];
        let divideLinePaths = [];
        let blockDivideLinePaths = [];

        // Calculate change in x, y direction
        //this.dx = bottomLeftX / this.blocks.length;
        let dy = this.state.height / this.blocks.length;

        // Initialize starting positions
        let prevLeftX = this.descriptionWidth;
        let prevRightX = this.chartWidth + this.descriptionWidth;
        let prevHeight = 0;

        // Initialize next positions
        let nextLeftX = 0;
        let nextRightX = 0;
        let nextHeight = 0;
        // Remember to loop back to the beginning point for a closed path

        let firstBlockValue = this.blocks[0].value;

        this.blocks.forEach((block, i) => {
            if (firstBlockValue > 0) {
                if (i + 1 < this.blocks.length) {
                    let nextBlock = this.blocks[i + 1];
                    let v = Math.floor((nextBlock.value / firstBlockValue) * this.chartWidth);
                    let x = (prevRightX - prevLeftX - v) / 2;
                    nextLeftX = prevLeftX + x;
                    nextRightX = prevRightX - x;
                    // console.log('next item: ' + nextLeftX + ', ' + nextRightX + ', actual width: ' + (nextRightX - nextLeftX));
                } else {
                    let temp = Math.min(prevRightX - prevLeftX, Math.floor(this.chartWidth / 5));

                    nextLeftX = (this.chartWidth - temp) / 2 + this.descriptionWidth;
                    nextRightX = nextLeftX + temp;
                    // console.log('last item: ' + nextLeftX + ', ' + nextRightX + ', actual width: ' + (nextRightX - nextLeftX));
                }
            } else {
                prevLeftX = 0;
                prevRightX = 0;
                nextLeftX = 0;
                nextRightX = 0;
            }
            nextHeight = prevHeight + dy;

            straightPaths = [
                // Start position
                [prevLeftX, prevHeight + 5, 'M'],
                // Move to right
                [prevRightX, prevHeight + 5, 'L'],
                // Move down
                [nextRightX, nextHeight, 'L'],
                // Move to left
                [nextLeftX, nextHeight, 'L'],
                // Wrap back to top
                [prevLeftX, prevHeight + 5, 'L']
            ]
            divideLinePaths = [
                // Move to bottom
                [nextLeftX - 10, nextHeight, 'M'],
                // Write a line
                [8, 'H']
            ]
            paths = [...paths, straightPaths];
            blockDivideLinePaths = [...blockDivideLinePaths, divideLinePaths];
            // Set the next block's previous position
            prevLeftX = nextLeftX;
            prevRightX = nextRightX;
            prevHeight = nextHeight;
        });

        this.blockPaths = paths;
        this.blockDivideLinePaths = blockDivideLinePaths;
    }

    /**
     * Given a raw data block, return its count.
     *
     * @param {Array} block
     *
     * @return {Number}
     */
    getRawBlockCount(block) {
        if (Array.isArray(block)) {
            return Array.isArray(block[1]) ? block[1][0] : block[1];
        }

        return block.value;
    }

    drawOntoDom() {

        this.makePaths();

        // Add each block
        this.blocks.forEach((block, index)=>{
            this.drawBlock(block, index);
        })
    }

    drawBlock(block, index) {
        const {id, tag} = this.props;

        // Create a group just for this block
        const group = d3.select(this.svg).append('g');

        // Fetch path element
        let path = group.append('path');

        // Attach data to the element
        this.attachData(path, block);

        // let overlayPath = null;
        let pathColor = block.fill;

        path.attr('fill', pathColor)
            .attr('id', 'block_id_' + id + '_' + index)
            .attr('d', this.getPathDefinition(this.blockPaths[index]));

        let path1 = group.append('path');

        // Attach data to the element
        this.attachData(path1, block);

        path1.attr('stroke', '#DFE2EA')
             .attr('d', this.getPathDefinition(this.blockDivideLinePaths[index]));

        group
        .on('mouseover', function (d) {
            d3.select(this)
            .style("opacity", 0.5)

            let tooltip = document.getElementById(tag + index);
            if(tooltip) tooltip.style.display = 'flex';

        })
        .on("mousemove", function () {
        })
        .on("mouseout", function () {
            d3.select(this)
            .style("opacity", 1);
            let tooltip = document.getElementById(tag + index);
            if(tooltip){
                tooltip.style.display = 'none';
            }
        });
    }

    attachData(element, data) {
        const nodeData = {
            ...data,
            node: element.node(),
        };

        element.data([nodeData]);
    }

    getPathDefinition(path) {
        const commands = [];

        path.forEach((command) => {
            if (command[2]) {
                commands.push([command[2], command[0], command[1]]);
            } else {
                commands.push([command[1], command[0]]);
            }
        });

        return this.plot(commands);
    }

    plot(commands) {
        let path = '';

        commands.forEach((command) => {
            if (command.length === 3) {
                path += `${command[0]}${command[1]},${command[2]} `;
            } else {
                path += `${command[0]}${command[1]}`;
            }
        });


        let newPath = path.replace(/ +/g, ' ').trim();

        return newPath;
    }

    renderStatTestingArrow = (key) => {
        const { statTestingEnabled, lowerThan, higherThan } = this.props

        if (statTestingEnabled) {
            if (!isEmpty(lowerThan) && lowerThan[key] && lowerThan[key].includes('<prevPeriod>')) {
                return <img src={downIcon} className="icon statArrow" alt="" height={14}/>
            }
            if (!isEmpty(higherThan) && higherThan[key] && higherThan[key].includes('<prevPeriod>')) {
                return <img src={upIcon} className="icon statArrow" alt="" height={14}/>
            }
        }
    }

    render() {
        const { id, data, logo, brand, brandValue, tag, segment, colors, name, regionState, page, countryCode, selectedMarket } = this.props;
        const { height, width, ratio } = this.state;
        let chartGroup_key = data.length>0 ? "fc_chartGroup_key_" + id : "fc_noData_key_" + id;

        return (
            <div id="funnelChart" className={cx("cardContainer", "funnelChartContainer", {"segmentHidden": segment&&!segment.isActive})}>
                <div className="cardHeaderContainer" id={"cardHeaderContainer"}>
                    {segment ? <SegmentTitle segment={segment} page={page} pool={brandValue} onClickSegmentFilter={this.props.onClickSegment} />: name? <BrandTitle brand={{ name: name}} regionState={regionState} customClass={'equityCard'} conversion="conversion" noLogo/> : <BrandTitle brand={{name:brand, logo:logo, countryCode: countryCode}} conversion="conversion"/>}
                    {!segment && brandValue > 0 && <div className="value">n={Math.round(brandValue).toLocaleString('en-En')}</div>}
                </div>
                <div className="scrollContainer" style={{ height, width, marginTop: 80 * ratio }} id={chartGroup_key}>
                <div className="peopleValue" style={{height: 50 * ratio, marginTop: -50 * ratio}}>Out of 100 People <span style={{color: colors[0]}}>(% from step above)</span></div>
                {brandValue>0 ?
                    <div className="descriptionOverlapContainer">
                    {data.length>0 && width > 0 && data.map((item, index) => {
                    return (
                        <div className="itemDescriptionContainer" key={index}>
                            <div className="valueText">
                                {item.value.toFixed(1)}
                                {this.renderStatTestingArrow(item.key)}
                            </div>
                            <div className="categoryText withQText">{initData.getLabelText(item.label)}<span>{initData.getQText(item.label.toLowerCase(), selectedMarket)}</span></div>
                            <div className="percentageText" style={{color: colors[0]}}>
                                {index !== 0?(item.percentage.toFixed(1))+'%':''}
                                {/* {this.renderStatTestingArrow(`${item.key}Percent`)} */}
                            </div>
                            <div className="tooltip" id={tag + index}>
                                <div className="brand">{segment?segment.segmentName:brand}</div>
                                <div className="label">{initData.getLabelText(item.label)}</div>
                                <div className="value">{item.value.toFixed(1)}</div>
                                <div className="percentage" style={{color: colors[0]}}>{item.percentage.toFixed(1)}%</div>
                            </div>
                        </div> );
                    })
                }
                    </div> :
                    <NoDataBox />
                }
                <svg width={width} height={height} ref={e=>this.svg=e} />
                </div>
          </div>
        )
    }
}
