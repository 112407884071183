import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { SliderRail, Handle, Track } from "./RangeSliderComponent";

import './RangeSlider.scss';

const sliderStyle = {
    position: "relative",
    width: "100%"
};

const domain = [18, 120];
const defaultValues = [18, 120];


export default class RangeSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: !!this.props.active,
            range: Array.isArray(this.props.rangeData) && this.props.rangeData.length===2 ? this.props.rangeData : defaultValues,
            updateClick: false
        }
    }

    onClickSelf() {
        const{updateClick, active, range} = this.state;
        const rangerActive = updateClick ? true : !active;
        this.setState({
            active: rangerActive,
            range:  rangerActive?range:defaultValues,
            updateClick: false
        }, () => {
            if (this.props.onToggle) {
                this.props.onToggle(rangerActive, rangerActive?range:defaultValues)
            }
        })
    }

    onUpdate(update) {
        this.setState({
            active: true,
            updateClick: true,
            range: update
        }, () => {
            if (this.props.onToggle) {
                this.props.onToggle(true, update)
            }
        })
    }

    onChange(change) {
        //console.log("change=" + change)
    }

    render() {
        const { active } = this.state;
        const { disabled } = this.props;

        const range = Array.isArray(this.state.range) && this.state.range.length===2 ? this.state.range : defaultValues;
        return (
            <div className={cx("rangeSliderContainer", { "inactive": !active || disabled || !this.props.active, disabled: disabled })}
                onClick={!disabled?this.onClickSelf.bind(this):null}>
                <div className="top">
                    <div className="rangeTitle">RANGE</div>
                    <div className="value">{range.join(' - ')}</div>
                </div>
                <div className="ranger">
                    <Slider
                        mode={2}
                        step={1}
                        domain={domain}
                        rootStyle={sliderStyle}
                        onUpdate={this.onUpdate.bind(this)}
                        onChange={this.onChange.bind(this)}
                        values={range}
                        disabled={disabled}
                    >
                        <Rail>
                            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                        </Rail>
                        <Handles>
                            {({ handles, getHandleProps }) => (
                                <div className="slider-handles">
                                    {handles.map(handle => (
                                        <Handle
                                            key={handle.id}
                                            handle={handle}
                                            domain={domain}
                                            getHandleProps={getHandleProps}
                                        />
                                    ))}
                                </div>
                            )}
                        </Handles>
                        <Tracks left={false} right={false}>
                            {({ tracks, getTrackProps }) => (
                                <div className="slider-tracks">
                                    {tracks.map(({ id, source, target }) => (
                                        <Track
                                            key={id}
                                            source={source}
                                            target={target}
                                            getTrackProps={getTrackProps}
                                        />
                                    ))}
                                </div>
                            )}
                        </Tracks>
                    </Slider>
                </div>
            </div>
        )
    }
}

RangeSlider.propTypes = {
    title: PropTypes.string,
    active: PropTypes.bool,
    onToggle: PropTypes.func,
};

RangeSlider.defaultProps = {
    title: 'Segment',
    active: false,
};
