import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CardHeader from '../UsersManagement/CardHeader';
import GroupForm from './GroupForm'
import initData from '../../../utils/initData';
import LoadingLayer from '../../widgets/Shared/LoadingLayer';
import ApiData from '../../../utils/Api';

export default class AddGroupComponent extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        showMessage: PropTypes.func.isRequired,
    }

    state = {
        inProgress: false,
        error: null,
    }

    addGroup = (group) => {
        const { types } = initData.getStatusMessages();
        const { history, showMessage, errorMessage } = this.props;
        this.setState({ inProgress: true });

        ApiData.createGroupPartial(group)
            .then(result => {
                this.setState({errMessage:false});
                typeof showMessage === 'function' && showMessage(types.addGroup, group.displayName);
                typeof errorMessage === 'function' && errorMessage(this.state.errMessage)
                history.push('/groups');
            })
            .catch(error => {
                this.setState({errMessage:true});
                if(error.message === "Network Error"){
                    this.setState({sessionTimeoutErr:true});
                }else{
                    typeof showMessage === 'function' && showMessage(types.existingGroup, group.displayName);
                }
                this.setState({
                    inProgress: false,
                    error: error.error,
                },typeof errorMessage === 'function' && errorMessage(this.state.errMessage));
            })
    }

    cancelAddingGroup = () => {
        this.props.history.push('/groups');
    }

    render() {
        const addGroupData = initData.getGroupData();
        const { inProgress, error } = this.state;

        return (
            <div>
                <CardHeader
                    primaryTitle={addGroupData.title(addGroupData.modes.create)}
                    secondaryTitle={addGroupData.subtitle(addGroupData.modes.create)} 
                />
                <GroupForm cancelForm={this.cancelAddingGroup} createGroup={this.addGroup} editMode={addGroupData.modes.create} error={error} />
                {inProgress && <LoadingLayer />}
            </div>
        )
    }
}
