import * as Ably from 'ably';
import ApiData from "../utils/Api";

export const createAblyClient = (clientId) => {
	return new Ably.Realtime({
		authCallback: async (tokenParams, callback) => {
			try {
				const response = await ApiData.getSocketAuthorizationToken(clientId);

				const token = response.data.token;
				callback(null, token);
			} catch (error) {
				callback(error, null);
			}
		},
		clientId: clientId,
		closeOnUnload: false,
	});
};

export const closeAblyConnection = (client) => {
	if (client) {
		client.close();
	}
};
