import React from 'react';
import PropTypes from 'prop-types';

import './SquareRadioButton.scss';

const SquareRadioButton = ({ option: { name, id, label }, checked, onChange, disabled }) => {
    return (
        <div className={'squareRadioButtonContainer'}>
            <input 
                className={'squareRadioButton'} 
                type='radio' 
                id={id} 
                name={name} 
                checked={checked} 
                disabled={disabled}
            />
            <label htmlFor={id} onClick={disabled ? null : onChange}>{label}</label>
        </div>
    )
}

SquareRadioButton.propTypes = {
    option: PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
    }),
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
}

export default SquareRadioButton;
