import React, {Component} from 'react';
import CardHeader from '../UsersManagement/CardHeader';
import LoadingLayer from '../../widgets/Shared/LoadingLayer';
import ListTable from '../../widgets/Shared/ListTable';
import initData from '../../../utils/initData';
import ApiData from '../../../utils/Api';
import noContractImg from '../../../assets/images/zero-illustration.png';
import Tabs from '../../../components/LayoutComponent/Tabs';
import '../UsersManagement/Profile.scss';
import CampaignForm from "./CampaignForm";
import CampaignBrands from './CampaignBrandsTab';
import InfoModal from "../InfoModal";
import {DateTime} from "luxon";
import {DateTimeHelper} from "../../../utils/dateTimeHelper";
import {MarketHelper} from "./helpers/marketHelper";

export default class CampaignProfile extends Component {
    constructor(props) {
        super(props)
        this.campaignDataFieldsAttributes = initData.getCampaignFieldsAttributes();
        this.sortingTypes = initData.getSortingTypes();
        const contractSortColumn = this?.campaignDataFieldsAttributes?.table?.columns.find(column => column.sorting === 'active');
        this.campaignId = this.props.match.params.marketId;
        this.state = {
            campaignData: null,
            loading: true,
            currentMode: this.campaignDataFieldsAttributes.modes.profileDisplay,
            isOptionsDropdownOpened: false,
            formFieldsValuesSnapshot: null,
            selectedTab: 0,
            isEnableModalOpened: false,
            isDisableModalOpened: false,
            contractSorting: {
                key: contractSortColumn?.field,
                type: this.sortingTypes.asc,
            },
        }
    }
    onTabClick = (tab) => {
        this.setState({ selectedTab: tab });
    }
    createDropdownOptions = (campaignStatus) => {
        const campaignOptionsAll = {...initData.getCampaignDropdownOptions()};
        campaignOptionsAll.options[0].action = this.editCampaignAction;
        campaignOptionsAll.options[1].action = this.disableMarketAction;
        campaignOptionsAll.options[2].action = this.enableMarketAction;
        
        

        const options = [].concat(campaignOptionsAll.options[0]).concat(campaignOptionsAll.options[(campaignStatus === 'active') ? 1 : 2]);
        return {options};
    }

    editCampaignAction = () => {
        this.props.onCampaignEdit();
        this.setState({ currentMode: this.campaignDataFieldsAttributes.modes.profileUpdate, isOptionsDropdownOpened: false, selectedTab: 0 });
    }

    cancelEditCampaignAction = () => {
        this.setState({ currentMode: this.campaignDataFieldsAttributes.modes.profileDisplay });
    }

    updateCampaignAction = (campaign) => {
        this.setState({ currentMode: this.campaignDataFieldsAttributes.modes.profileDisplay, loading: true });
        const { showMessage } = this.props;
        const { types } = initData.getStatusMessages();
        const { formFieldsValuesSnapshot } = this.state;

        const updateDiff = this.findUpdateDiff(campaign, formFieldsValuesSnapshot);

        ApiData.updateCampaign(this.campaignId, campaign).then(
            (result) => {
                this.getListData();
                typeof showMessage === 'function' && showMessage(types.campaignUpdated, updateDiff.string);
            }
        ).catch(
            (err) => {
                console.log("Update campaign error", err);
            }
        );
    }

    findUpdateDiff = (formFieldsValuesCurrent, formFieldsValuesSnapshot) => {
        if(formFieldsValuesCurrent && formFieldsValuesSnapshot) {
            const { form } = this.campaignDataFieldsAttributes;
            const campaignFields = {...formFieldsValuesCurrent};
            const diff = [];
            Object.keys(campaignFields).forEach(key => {
                if(campaignFields[key] !== formFieldsValuesSnapshot[key] && !(!campaignFields[key] && !formFieldsValuesSnapshot[key])) {
                    diff.push(key)
                }
            });
            const diffLabels = Object.values(form).filter(value => diff.some(key => value.key === key)).map(value => value.label);
            return {
                keys: diff,
                labels: diffLabels,
                string: diffLabels.length ? diffLabels.join(', ') : '',
            };
        }
    }

    flipCampaignState(messageType) {
        this.setState({loading: true});
        const { showMessage } = this.props;

        ApiData.flipCampaignStatus(this.campaignId).then(
            (result) => {
                this.getListData();
                typeof showMessage === 'function' && showMessage(messageType,this.state.campaignData.name);
            }
        ).catch(
            (err) => {
                console.log("Flip campaign state error", err);
            }
        );
    }

    enableMarketAction = () => {
        this.closeOptionsDropdown();
        this.openEnableCampaignModal();
    }
    disableMarketAction = () => {
        this.closeOptionsDropdown();
        this.openDisableCampaignModal();
    }
    disableCampaign = () => {
        const { types } = initData.getStatusMessages();
        this.flipCampaignState(types.campaignDisabled);
        this.closeDisableCampaignModal()
    }
    enableCampaign = () => {
        const { types } = initData.getStatusMessages();
        this.flipCampaignState(types.campaignEnabled);
        this.closeEnableCampaignModal();
    }

    openEnableCampaignModal = () => {
        this.setState({ isEnableModalOpened: true });
    }
    closeEnableCampaignModal = () => {
        this.setState({ isEnableModalOpened: false });
    }
    openDisableCampaignModal = () => {
        this.setState({ isDisableModalOpened: true });
    }
    closeDisableCampaignModal = () => {
        this.setState({ isDisableModalOpened: false });
    }

    getEnableModalButtons = () => {
        const { modals: { enableMarket: { buttons }}} = this.campaignDataFieldsAttributes;
        buttons[0].action = this.closeEnableCampaignModal;
        buttons[1].action = this.enableCampaign;
        return buttons;
    }
    getDisableModalButtons = () => {
        const { modals: { disableMarket: { buttons }}} = this.campaignDataFieldsAttributes;
        buttons[0].action = this.closeDisableCampaignModal;
        buttons[1].action = this.disableCampaign;
        return buttons;
    }

    closeOptionsDropdown = () => {
        this.setState({ isOptionsDropdownOpened: false });
    }

    openOptionsDropdown = () => {
        this.setState({ isOptionsDropdownOpened: true });
    }

    onOptionsBtnClick = () => {
        const { isOptionsDropdownOpened } = this.state;
        if(isOptionsDropdownOpened) this.closeOptionsDropdown();
        else this.openOptionsDropdown();
    }

    getListData = () => {
        this.setState({loading: false});
        const contractSort = this.state.contractSorting.key;
        const contractSortType = this.sortingTypes.asc === this.state.contractSorting.type;
        ApiData.getCampaignDetailed(this.campaignId,contractSort, contractSortType).then(
            (result) => {
                this.setState({
                    campaignData: result,
                    loading: false,
                    formFieldsValuesSnapshot: this.extractDataForTheForm(result),
                });
            }
        ).catch(
            (err) => {
                console.log("Get campaign data error", err);
            }
        );
    }

    sortContracts = (column) => {
        const { contractSorting } = this.state;
        const type = contractSorting.type === this.sortingTypes.asc ? this.sortingTypes.desc : this.sortingTypes.asc;
        this.setState({
            contractSorting: { key: column.field, type },
        }, this.getListData);
    }

    extractDataForTheForm = (campaignData) => {
        const { form: { name, regionState, totalCompletes, sampleId, displayName, decipherSurvey, campaignStatus, identity, qcampaign, weightQuota, lastWeightedAt, ingestedCount, weightDifference }} = this.campaignDataFieldsAttributes;
        return {
            id: campaignData.id,
            code: campaignData.code,
            [qcampaign.key]: campaignData.qcampaign,
            [campaignStatus.key]: campaignData.status,
            [name.key]: campaignData.name,
            [regionState.key]: campaignData.regionState,
            [totalCompletes.key]: campaignData.totalCompletes,
            [sampleId.key]: campaignData.sampleId,
            [displayName.key]: campaignData.displayName,
            [decipherSurvey.key]: campaignData.decipherSurvey,
            [identity.key]: campaignData.identity,
            [weightQuota.key]: campaignData.weightQuota,
            [weightQuota.key]: campaignData.weightQuota,
            [lastWeightedAt.key]: DateTimeHelper.convertUTCToReadableFormat(campaignData.lastWeightedAt, 'utc'),
            [ingestedCount.key]: campaignData.ingestedCount,
            [weightDifference.key]: MarketHelper.calculateIngestionWeighedDifference(campaignData.ingestedCount, campaignData.totalCompletes),
            // [consumerQuota.key]: campaignData.consumerQuota,
            // [vendorQuota.key]: campaignData.vendorsQuotas,
            summaryQuota: campaignData.vendorQuota
        }
    }

    extractDataForContractsTable(campaignData) {
        const contracts = campaignData && campaignData.contracts ? campaignData.contracts : [];

        return contracts.length ? contracts.map(contract => ({
            brandContractName: {
                text: contract.displayName ? contract.displayName : contract.name ? contract.name : '',
                id: contract.id
            },
            brandGroupName: {
                text: contract.group && contract.group.displayName ? contract.group.displayName : '',
                id: contract.group.id
            },
            startDate: contract.startDate ? contract.startDate : '',
            endDate: contract.endDate ? contract.endDate : '',
            action: this.onHyperLinkClick
        })) : [];
    }

    componentDidMount() {
        this.getListData();
    }

    doNothing() {}

    onHyperLinkClick = (groupId, clickedItemId) => {
        const { history } = this.props;
        if (groupId === clickedItemId) {
            history.push(`/group/${clickedItemId}`);
        } else {
            history.push(`/${groupId}/contract/${clickedItemId}`);
        }
    }

    addDecipherSurvey = () => {
        this.props.history.push('/addsurvey')
    }

    render() {
        const { form: { name }} = this.campaignDataFieldsAttributes;
        const { campaignData, loading, isOptionsDropdownOpened, currentMode, isEnableModalOpened,isDisableModalOpened } = this.state;
        const { page, history } = this.props;
        const formFieldsValues = campaignData ? this.extractDataForTheForm(campaignData) : null;
        const title = formFieldsValues ? formFieldsValues[name.key] : '';
        const subtitle = formFieldsValues ? formFieldsValues["code"] : '';
        const contractsTableData = this.extractDataForContractsTable(campaignData);
        const tabs = initData.getTabs(page);
        const { modals } = this.campaignDataFieldsAttributes;
        const modalMarket = {
            name: campaignData ? campaignData?.displayName : campaignData?.name,
            id: campaignData ? campaignData.id : "",
        };
        const renderFunction = () =>{
        if(this.state.selectedTab===0){
            return(
            <div className={'profileContent'} style={{display: "flex"}}>
            <CampaignForm
                fieldValues={formFieldsValues}
                cancelForm={this.cancelEditCampaignAction}
                updateCampaign={this.updateCampaignAction}
                editMode={currentMode}
            />
            <div className={'profileTable'}>
                <div className={'profileTableHeader'}>
                    <div className={'profileTableTitle'}>{this.campaignDataFieldsAttributes.table.title}</div>
                </div>
                {contractsTableData.length ?
                    <ListTable
                        listData={contractsTableData}
                        listColumns={this.campaignDataFieldsAttributes.table.columns}
                        onItemClick={() => this.onHyperLinkClick}
                        withPagination={false}
                        isRowClickable={false}
                        sortTable={this.sortContracts}
                        sortedColumn={this.state.contractSorting}
                    /> :
                    <div>
                        <div className={'zeroImgContainer'}><img className={'zeroImg'} src={noContractImg} alt={'No groups'} /></div>
                        <div className={'zeroTitle'}>{this.campaignDataFieldsAttributes.table.noGroupsTitle}</div>
                        <div className={'zeroMessage'}><span>{this.campaignDataFieldsAttributes.table.noGroupMessage}</span></div>
                    </div>
                }
            </div>
        </div>)
        } else if (this.state.selectedTab===1){
            return <CampaignBrands campaignId= {this.campaignId} history={history}/>
        }}
        return (
            <div className={'profile'}>
                <CardHeader
                    primaryTitle={title}
                    secondaryTitle={subtitle}
                    button={{type: 'options', onClick: this.onOptionsBtnClick}}
                    isOptionsDropdownOpened={isOptionsDropdownOpened}
                    dropdownOptions={this.createDropdownOptions(formFieldsValues ? formFieldsValues["status"] : 'ACTIVE')}
                    closeDropdown={this.closeOptionsDropdown}
                    decipherSurveyButton={true}
                    addDecipherSurvey={this.addDecipherSurvey}
                /> 
                <Tabs
                    tabs={tabs}
                    isBrand={false}
                    isAdminPanel = {true}
                    currentTab={this.state.selectedTab}
                    onClickTabItem={this.onTabClick}
                />
                {renderFunction()}
                {loading && <LoadingLayer />}
                {isEnableModalOpened && 
                <InfoModal
                    title={modals.enableMarket.title}
                    message={modals.enableMarket.message}
                    extraMessage={modals.enableMarket.extraMessage}
                    subjectInfo={{
                        title: `${modalMarket.name}`,
                        subtitle: `ID: ${modalMarket.id}`,
                    }}
                    buttons={this.getEnableModalButtons()}
                />
                }
                 {isDisableModalOpened && 
                <InfoModal
                    title={modals.disableMarket.title}
                    message={modals.disableMarket.message}
                    extraMessage={modals.disableMarket.extraMessage}
                    subjectInfo={{
                        title: `${modalMarket.name}`,
                        subtitle: `ID: ${modalMarket.id}`,
                    }}
                    buttons={this.getDisableModalButtons()}
                />
                }
            </div>
        )
    }


}
