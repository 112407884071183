import React, { Component } from "react";
import BrandTitle from './BrandTitle';
import "./BrandItemCard.scss";
import ClearIcon from "../../../../assets/icons/icon_clear.svg";

export default class BrandItemCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showClear: false,
    };
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onClickClear = this.onClickClear.bind(this);
  }

  onFocus() {
    this.setState({
      showClear: true,
    }) 
  }

  onBlur() {
    this.setState({
      showClear: false,
    })
  }

  onClickClear() {
    //console.log('onClickClear');

    if (this.props.onRemove) {
      this.props.onRemove(this.props.index);
    }
  }

  render() {
    const { index, name, icon, id } = this.props;
    //const {showClear} = this.state;
    
    return (
        <div tabIndex={index.toString()} className="brandItemCardBackground" onFocus={this.onFocus} onBlur={this.onBlur} >
          <BrandTitle brand={{logo: icon, name: name, id: id}} />
          <img src={ClearIcon} alt="" className="brandClearIcon" onClick={this.onClickClear} />
        </div>
    );
  }
}
