import React, { Component } from 'react';
import initData from '../../utils/initData';
import './AdvancedIntelligenceLanding.scss'

export default class AdvancedIntelligenceLanding extends Component {
    render() {
        return (
            <div className = "advancedLanding">
                {initData.getTabs('advanced').map((tab, index)=>{
                    return <div key={tab.name} className="tabIndex" onClick={()=>this.props.onPageClick(index)}>
                                <div className={tab.name.replace('/','')}>
                                    <div className="img1"></div>
                                    <div className="img2"></div>
                                </div>
                                <h2>{tab.title} Map</h2>
                                <p>{tab.desc}</p>       
                            </div>
                })}
            </div>    
        )
    }
}