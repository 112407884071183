import React, { Component } from 'react';
import './BrandTitle.scss';
import PropTypes from 'prop-types';


export default class geoTitle extends Component {
    render() {
        const {
          afterIcon,
          geo,
          customClass,
          noData,
          ssize,
          onClickItemFunction,
        } = this.props;

        const geoClass= "geoTitle" + (customClass? " "+customClass:"");
        return (
            <div className={geoClass} onClick={(typeof onClickItemFunction)==='function'?()=>onClickItemFunction(geo.id):null}>
                <div className="brandName">
                    {/* <div className={cx({
                        "brandNameDataContainer": true,
                        "brandNameDataContainer--competitive": page === 'competitive',
                        "brandNameDataContainer--segments": page === 'segments',
                      })
                    }> */}
                    <div className={'titleBlock'}>
                        <div className={'title'}>{`${geo.name}`}</div>
                        <div className={'subtitle'}>{`${geo.region}`}</div>
                    </div>
                        {/* {brandName}
                        {brand.id} */}
                        {Number(ssize)>0 && <div className="sampleSize">n={Number(ssize.toFixed(0)).toLocaleString('en-En')}</div>}
                        {noData && <div className="noData">No data available</div>}
                    {/* </div> */}
                </div>
                {afterIcon}
            </div>
        )
    }
}
geoTitle.propTypes = {
    noData: PropTypes.bool,
}
geoTitle.defaultProps = {
    noData: false,
}
