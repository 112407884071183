import React, { useEffect, useImperativeHandle, useState, useRef } from 'react';
import '../FilterOptionComponent.scss';
import ApiData from '../../../utils/Api';
import { ChromePicker } from "react-color";
import { IoIosSearch } from "react-icons/io";
import initData from '../../../utils/initData';
import { useMutation, useQuery } from "react-query";
import SelectedFiltersList from '../Shared/SelectedFiltersList';
import filtersTransformations from '../../../utils/filterTransformations';
import { FiCheck } from "react-icons/fi";
import { RxCrossCircled } from "react-icons/rx";
import { getSessionStorage } from '../../../utils/storageHelpers';

const CreateSegment = React.forwardRef((props, ref) => {
    
    let {selectedSegment, categoryList, currentFilters, propsData:{page, setSegmentsData}, onClickPageChange, handleSaveButtonEnabled, changeCurrentFilters, setLoading, selectedAction, selectedMarketIds} = props;
    
    let [ segmentName, setSegmentName ] = useState('');
    let [ selectedColor, setSelectedColor ] = useState();
    let [ selectedCategory, setSelectedCategory ] = useState('');
    let [ showColorPicker, setShowColorPicker ] = useState(false);
    let [ categorySuggestions, setCategorySuggestions ] = useState([]);
    let suggestionModalRef = useRef();

    let getCategories = useQuery('categories',ApiData.getUserCategories);

    let createSegmentMutation = useMutation(ApiData.createSegment,{
         onSuccess: async (data) => {
            await filtersTransformations.applyFilters(data[0], page);
            await setSegmentsData(data[0]);
            await onClickPageChange(0);
            setLoading(false);
        },
        onError: async (error) => {
            setLoading(false);
            console.log(error);
        } 
    });

    let updateSegmentMutation = useMutation(ApiData.updateUserSegment,{
        onSuccess: async (data) => {
            await filtersTransformations.applyFilters(data[0], page, 'edit');
            await setSegmentsData(data[0]);
            await onClickPageChange(0);
            setLoading(false);
        },
        onError: async (error) => {
            setLoading(false);
            console.log(error);
        } 
    });

    useEffect(()=>{
        let defaultColor =  initData.getColors().map(color => color[0])[getSegmentsList()?.length%10];
        selectedAction !== 'Copy' && setSelectedCategory(selectedSegment?.categoryName);
        (selectedAction === 'Copy' && (!selectedSegment?.segmentName?.includes('(Copy)')) && selectedSegment?.segmentName) ? setSegmentName(selectedSegment?.segmentName + ' (Copy)') : setSegmentName(selectedSegment?.segmentName);
        !selectedColor && setSelectedColor(selectedSegment?.color?selectedSegment?.color:defaultColor);
        selectedSegment?.segmentName?.length>0 && handleSaveButtonEnabled(true)
    },[selectedSegment])

    useImperativeHandle(ref, () => ({
        createOrEditSegment() {
            createOrEditSegment();
        }
    }))

    const segmentNameChange = (e) => {
        setSegmentName(e.target.value)
        handleSaveButtonEnabled(e.target.value?.length>0 ? true :false)
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return (() => {
            document.removeEventListener('mousedown', handleClickOutside);
        })
    })

    const handleClickOutside = (event) => {
        if (suggestionModalRef.current && suggestionModalRef.current.contains(event.target)) {
            return null;
        } else if (suggestionModalRef.current && !suggestionModalRef.current.contains(event.target)) {
            setCategorySuggestions([]);
        }
    }

    const onColorChange = (e) => {
        let color = e.hex.toUpperCase();
        setSelectedColor(color);
    }

    const onChangeCategorySearchKey = (e) => {
        let {value} = e.target;
        let categories = getCategories?.data;
        if(selectedAction === "Copy") categories = categories?.filter(({categoryName, categoryType})=> categoryName !== "Shared"); 
        if(categories?.length > 0) setCategorySuggestions(categories);
        let findCategory = categories?.filter(({categoryName}) => categoryName.toLowerCase().includes(value?.toLowerCase()));
        if(value.trim() === ''){
            setCategorySuggestions([]);
        }else if(findCategory.length > 0) {
           setCategorySuggestions(findCategory); 
        }
        else if((findCategory.length) === 0){
            findCategory.push({categoryName: value})
            setCategorySuggestions(findCategory); 
        }
        setSelectedCategory(value);
    }
    const handleClick = () =>{
        if(getCategories?.data?.length > 0) {
            let categories = getCategories?.data;
            if(selectedAction === "Copy") categories = categories?.filter(({categoryName, categoryType})=> categoryName !== "Shared"); 
            setCategorySuggestions(categories);
        }
    }

    const onSelectCategory = (categoryName) => {
        setSelectedCategory(categoryName);
        setCategorySuggestions([]);
    }

    const clearCategory = () =>{
        setSelectedCategory([])
        setCategorySuggestions([])
    }

    const getSegmentsList = () =>{
        return categoryList.reduce((acc, segmntObj) => {
                acc.push(...segmntObj.segments);
                return acc;
        }, [])
    }

    const createOrEditSegment = async() => {
        let selectedCategoryId = getCategories?.data?.find(({categoryName})=>categoryName === selectedCategory)?.categoryId;
        let segmentIsActive = getSegmentsList()?.filter(ele=>ele.isActive && ele.segmentMarkets.includes(props.selectedMarket?.marketName) === true)?.length>19?false:true;
        let order;
        let segmentsList = JSON.parse(getSessionStorage('segmentsList'));
        segmentsList?.length>0 && segmentsList.forEach(categoryObj => {
            if(selectedCategoryId === categoryObj.categoryId){
                order = categoryObj?.segments?.length;
            }else if(!selectedCategoryId){
                let segmentsListResult = segmentsList.reduce((acc, sr) => {
                    acc.push(...sr.segments);
                    return acc;
                }, [])
                order = segmentsListResult?.length;
            }
        })
        let payload = {
            categoryId:selectedCategoryId?selectedCategoryId:null,
            categoryName: selectedCategory,
            segmentName: segmentName,
            segmentColor: selectedColor,
            isActive:(selectedSegment && selectedAction === 'Edit')?selectedSegment.isActive:segmentIsActive,
            filters: filtersTransformations.formatSelectedFilters(currentFilters),
            module: initData.getModuleType(page),
            geoIds: selectedMarketIds
        } 
        if(selectedAction === 'Edit'){
            await updateSegmentMutation.mutate({segmentId:selectedSegment?.segmentId,payload}); 
        }else{ 
            payload.position = order;
            await createSegmentMutation.mutate(payload); 
        } 
        handleSaveButtonEnabled(false);
    }
    return(
        <div className='segmentOptionContainer'>
            <div className='inputGroup'>
                <div className="inputBox">
                    <input placeholder="Input Segment Name" value={segmentName} maxLength="30" onChange={(e)=>segmentNameChange(e)}/>
                </div>
                <div className='searchCategoryContainer' ref={suggestionModalRef}>
                    <div className={'selectFilterContainer'} >
                        <div className={'searchIconContainer'}>
                            <IoIosSearch className="prefixIcon"/>
                        </div>
                        <input type="text"
                            placeholder="Input Category Name"
                            value={selectedCategory}
                            className="textarea"
                            onChange={(e)=> onChangeCategorySearchKey(e)}
                            onClick={handleClick}
                        />
                        { selectedCategory?.length > 0 &&<RxCrossCircled className="prefixIcon" onClick={clearCategory} />}
                        {categorySuggestions?.length > 0 &&
                            <ul className={'suggestionContainer formSuggestionsContainer'}>
                                {categorySuggestions?.map(({categoryName, categoryId},ind) => {
                                    return (
                                        <li  style={{flexDirection:'row', justifyContent:'normal'}} key={ind} onClick={()=>onSelectCategory(categoryName)}>
                                            {categoryId === undefined?  <span className={'suggestTitle'}>Add New Option: {`"${selectedCategory}"`}</span>: <span className={'suggestTitle'}>{`${categoryName}`}</span>}
                                            {(selectedCategory === categoryName) &&categoryId !== undefined &&  < FiCheck  style={{marginLeft:'auto', color:'blueviolet', marginTop:'14px'}}/> }
                                        </li>
                                    );
                                })}
                            </ul>  
                        }
                    </div>
                </div>
                <div className='colorPickerContainer' onMouseLeave={()=>setShowColorPicker(false)}>
                    <div className={'show-color-picker'} onMouseLeave={()=>setShowColorPicker(false)}>
                            {showColorPicker &&  
                                <div className='color-picker colorPicker' >
                                    <ChromePicker className="arrow-right"
                                        color={selectedColor}
                                        id="chrome-picker"
                                        onChangeComplete={onColorChange}
                                        disableAlpha={true}
                                        width="50%"
                                        renderers={false}
                                    />
                                </div> 
                            } 
                    </div>
                    <div className='colorPickerInput'>
                        <div className='colorPickerIcon' style={{ backgroundColor: selectedColor}} onMouseOver={()=>setShowColorPicker(!showColorPicker)}/>
                        <div className='inputText'>{selectedColor}</div>
                    </div>
                </div>
            </div>
            <SelectedFiltersList currentSelectedFilters={currentFilters} changeCurrentFilters={changeCurrentFilters} iscreateOrEditPage = {true}/>
        </div>
    )
})

export default CreateSegment;