import React, { Component } from 'react';
import cx from 'classnames';
import moment from 'moment';

import LoadingLayer from '../../widgets/Shared/LoadingLayer';
import ListTable from '../../widgets/Shared/ListTable';
import CardHeader from './CardHeader';
import UserForm from './UserForm';
import Tabs from '../../../components/LayoutComponent/Tabs';
import initData from '../../../utils/initData';
import ApiData from '../../../utils/Api';
import InfoModal from '../InfoModal';
import searchIcon from "../../../assets/icons/icon_search.svg";
import searchIconBlue from "../../../assets/icons/icon_search_blue.svg";
import moreIcon from '../../../assets/icons/icon_more.svg';
import noGroupImg from '../../../assets/images/zero-illustration.png';
import { setLocalStorage } from '../../../utils/storageHelpers';
import './Profile.scss';

export default class UserProfile extends Component {
    constructor(props) {
        super(props)
        this.userId = this.props.match.params.userId;
        this.userData = initData.getUserData();
        this.state = {
            userData: null,
            loading: true,
            currentMode: this.userData.modes.profileDisplay,
            isOptionsDropdownOpened: false,
            isGroupOptionsDropdownOpened: false,
            currentGroup: null,
            storedUserFields: null,
            isDeleteModalOpened: false,
            isSearchGroupOpened: false,
            searchGroup: '',
            groupSuggestions: [],
        }
    }

    getListData = async() => {
        this.setState({loading: true});

        await Promise.allSettled([ApiData.getUserDetailed(this.userId),ApiData.getUserInfo()]).then(
            ([result, userInfo]) => {
                if(result?.status === 'fulfilled') {
                    this.setState({ userData: result?.value, loading: false, storedUserFields: this.getUserFormData(result?.value) });
                }
                if(userInfo?.status === 'fulfilled') {
                    setLocalStorage('userInfo', JSON.stringify(userInfo?.value?.user));
                    if(Number(userInfo?.value?.user?.activeContracts) === 0){
                        setLocalStorage('authorizedType', userInfo?.value?.user?.userType);
                    }
                }
            }
        ).catch(
            (error) => {
                this.setState({ loading: false });
                console.log("Getting user error", error);
            }
        );
    }

    deleteUserFromGroup = () => {
        const { currentGroup } = this.state;
        this.closeGroupOptionsDropdown();
        this.setState({ loading: true });
        
        ApiData.removeUserfromGroup(currentGroup, this.userId)
            .then(result => {
                this.setState({ loading: false }, this.getListData);
            })
            .catch(
                (err) => {
                console.log("Deleting user from group error" + err);
                this.setState({ loading: false });
                }
            );
    }

    deleteUser = () => {
        const { types } = initData.getStatusMessages();
        const { history, showMessage } = this.props;
        this.setState({ loading: true });

        ApiData.removeUser(this.userId)
            .then(result => {
                history.push('/users');
                typeof showMessage === 'function' && showMessage(types.removeUser, '');
            })
            .catch(error => {
                console.log('Deleting user error', error);
                this.setState({ loading: false });
            })
    }

    addUserToGroup = (groupId) => {
        this.setState({ loading: true });
        ApiData.addUsertoGroup(groupId, this.userId)
            .then(result => {
                this.setState({ loading: false, groupSuggestions: [], searchGroup: '' }, this.getListData);
            })
            .catch(error => {
                console.log('Deleting user error', error);
                this.setState({ loading: false, groupSuggestions: [], searchGroup: '' });
            })
    }

    findUpdateDiff = (userData, storedUserFields) => {
        if(userData && storedUserFields) {
            const { form } = this.userData;
            const userFields = userData;
            const diff = [];
            Object.keys(userFields).forEach(key => {
                if(userFields[key] !== storedUserFields[key] && !(!userFields[key] && !storedUserFields[key])) {
                    diff.push(key)
                }
            });
            const diffLabels = Object.values(form).filter(value => diff.some(key => value.key === key)).map(value => value.label);
            return {
                keys: diff,
                labels: diffLabels,
                string: diffLabels.length ? diffLabels.join(', ') : '',
            };
        }
    }

    updateUser = (user, rawData) => {
        const { showMessage } = this.props;
        const { storedUserFields } = this.state;
        const { types } = initData.getStatusMessages();
        const { form } = this.userData;
        const updateDiff = this.findUpdateDiff(rawData, storedUserFields);

        if(updateDiff.keys.find(key => key === form.userRoles.key)) {
            const role = form.userRoles.options.find(userRole => userRole.id === user.userRole).type;
            ApiData.updateUserRole(this.userId, role)
                .then(response => {
                    // typeof showMessage === 'function' && showMessage(types.updateUser, updateDiff.string);
                })
                .catch(error => {
                    // this.setState({ loading: false });
                    console.log('Updating user role error', error);
                });
        }

        this.setState({ loading: true });
        const updatedFieldsWithoutRole = updateDiff.keys.filter(key => key !== form.userRoles.key);

        if(updatedFieldsWithoutRole.length) {
            ApiData.updateUser(this.userId, user)
            .then(response => {
                typeof showMessage === 'function' && showMessage(types.updateUser, updateDiff.string);
                this.setState({ currentMode: this.userData.modes.profileDisplay, loading: false }, this.getListData);
            })
            .catch(error => {
                this.setState({ loading: false });
                console.log('Updating user error', error);
            });
        }
    }

    getUserFormData = (userData) => {
        const { form: { firstName, lastName, email, phoneNumber, company, position, userRoles, groups }} = this.userData;
        return {
            id: userData.id,
            [firstName.key]: userData.firstName,
            [lastName.key]: userData.lastName,
            [email.key]: userData.email,
            [company.key]: userData.company,
            [position.key]: userData.position,
            [phoneNumber.key]: userData.phoneNumber,
            [userRoles.key]: userRoles.options.find(role => role.type === userData.userType).id,
            [groups.key]: userData.groups,
        }
    }

    componentDidMount() {
        this.getListData();
    }

    editUserAction = () => {
        this.setState({ currentMode: this.userData.modes.profileUpdate, isOptionsDropdownOpened: false });
    }

    cancelEditAction = () => {
        this.setState({ currentMode: this.userData.modes.profileDisplay });
    }

    resetPasswordAction = () => {
        const { showMessage } = this.props;
        const { userData } = this.state;
        const { types } = initData.getStatusMessages();

        ApiData.resetPassword(this.userId)
            .then(result => {
                typeof showMessage === 'function' && showMessage(types.resetPassword, userData.email);
            })
            .catch(error => {
                console.log('Reset pass error', error);
            });

        this.closeOptionsDropdown();
    }

    toggleUserStatus = () => {
        const { showMessage } = this.props;
        const { userData } = this.state;
        const { types } = initData.getStatusMessages();

        ApiData.enableUser(this.userId, !userData.active)
            .then(result => {
                typeof showMessage === 'function' && showMessage(types.changeUserStatus, '');
                this.getListData();
            })
            .catch(error => {
                console.log('Enable user error', error);
            });

        this.closeOptionsDropdown();
    }

    openDeleteUserModal = () => {
        this.setState({ isDeleteModalOpened: true });
    }

    closeDeleteUserModal = () => {
        this.setState({ isDeleteModalOpened: false });
    }

    deleteUserAction = () => {
        this.closeOptionsDropdown();
        this.openDeleteUserModal();
    }

    createDropdownOptions = (userData) => {
        const userOptions = {...initData.getUserDropdownOptions(userData).user};
        userOptions.options[0].action = this.editUserAction;
        userOptions.options[1].action = this.resetPasswordAction;
        userOptions.separateOptions[0].action = this.deleteUserAction;

        return userOptions;
    }

    createGroupDropdownOptions = () => {
        const groupOptions = {...initData.getUserDropdownOptions().group};
        groupOptions.separateOptions[0].action = this.deleteUserFromGroup;

        return groupOptions;
    }

    closeOptionsDropdown = () => {
        this.setState({ isOptionsDropdownOpened: false });
    }

    openOptionsDropdown = () => {
        this.setState({ isOptionsDropdownOpened: true });
    }

    openGroupOptionsDropdown = (currentGroup) => {
        this.setState({ isGroupOptionsDropdownOpened: true, currentGroup });
    }

    closeGroupOptionsDropdown = () => {
        this.setState({ isGroupOptionsDropdownOpened: false, currentGroup: null });
    }

    onOptionsBtnClick = () => {
        const { isOptionsDropdownOpened } = this.state;
        if(isOptionsDropdownOpened) this.closeOptionsDropdown();
        else this.openOptionsDropdown();
    }

    onGroupOptionsBtnClick = (groupId) => {
        const { currentGroup } = this.state;
        if(groupId !== currentGroup) this.openGroupOptionsDropdown(groupId);
        else this.closeGroupOptionsDropdown();
    }

    getDeleteModalButtons = () => {
        const { modals: { removeUser: { buttons }}} = this.userData;

        buttons[0].action = this.closeDeleteUserModal;
        buttons[1].action = this.deleteUser;
        return buttons;
    }

    toggleSearchGroupField = () => {
        const { isSearchGroupOpened } = this.state;
        if(isSearchGroupOpened) this.setState({ isSearchGroupOpened: false });
        else this.setState({ isSearchGroupOpened: true });
    }

    onGroupClick = (groupId) => {
        const { history } = this.props;
        history.push(`/group/${groupId}`);
    }

    searchGroupChanged = (event) => {
        const name = event.target.value;
        if (name.trim() === '') {
            this.setState({
                searchGroup: '',
                groupSuggestions: []
            });
        } else {
            this.setState({
                searchGroup: name,  
            });
            ApiData.searchGroupByName(encodeURI(name)).then(
                (result) => {
                this.setState({
                    groupSuggestions: result,
                })
            }
            ).catch(
                (err) => {
                    console.log("error=" + err )
                    this.setState({
                        groupSuggestions: []
                    });
                }
            );
        }
    }
    
    render() {
        const { userData, loading, currentMode, isOptionsDropdownOpened, isDeleteModalOpened, isGroupOptionsDropdownOpened, currentGroup, isSearchGroupOpened, searchGroup, groupSuggestions } = this.state;
        const { page } = this.props;
        const primaryTitle = userData ? `${this.userData.title(currentMode)}${userData.firstName} ${userData.lastName}` : ' ';
        const secondaryTitle = userData ? `${this.userData.subtitle(currentMode)}${userData.id}` : `${this.userData.subtitle(currentMode)}`;
        const formFieldValues = userData ? this.getUserFormData(userData) : null;
        const tabs = initData.getTabs(page);
        const { modals } = this.userData;
        const modalUser = {
            firstName: userData ? userData.firstName : '',
            lastName: userData ? userData.lastName : '',
            email: userData ? userData.email : '',
        }
        const groups = userData ? userData.groups : [];
        const listData = groups?.length ? groups.map(group => ({ 
            id: group.id,
            displayName: group.displayName,
            usersAmount: group.users ? group.users.length : 3,
            brandsAmount: group.brandsAmount,
            added: group.createdAt ? moment(group.createdAt.split('T')[0]).format('MMMM D, YYYY') : 'No Date',
        })) : [];
        const dropdownButton = {
            action: this.onGroupOptionsBtnClick,
            component: (<img className={'groupOptionsButton'} src={moreIcon} alt={'Option icon'} />)
        }

        return (
            <div className={'profile'}>
                <CardHeader
                    primaryTitle={primaryTitle}
                    secondaryTitle={secondaryTitle}
                    button={{type: 'options', onClick: this.onOptionsBtnClick}}
                    isOptionsDropdownOpened={isOptionsDropdownOpened}
                    dropdownOptions={this.createDropdownOptions(userData)}
                    closeDropdown={this.closeOptionsDropdown}
                />
                <Tabs
                    tabs={tabs}
                    isBrand={false}
                    isAdminPanel = {true}
                    currentTab={0}
                />
                <div className={'profileContent'} style={{display: "flex"}}>
                    <UserForm
                        fieldValues={formFieldValues}
                        cancelForm={this.cancelEditAction}
                        editMode={currentMode}
                        createUser={this.updateUser}
                    />
                        <div className={'profileTable'}>
                            <div className={'profileTableHeader'}>
                                <div className={'profileTableTitle'}>{this.userData.table.title}</div>
                                <div className={'searchGroupPanel'}>
                                    {isSearchGroupOpened && !(currentMode === this.userData.modes.profileUpdate) &&
                                        <div className={'inputBlock'}>
                                            <div className={'selectInputContainer'}>
                                                <div className={'searchIconContainer'}>
                                                {!! searchGroup ?
                                                    <img src={searchIconBlue} className={'prefixIcon'} alt={'search icon'} /> :
                                                    <img src={searchIcon} className={'prefixIcon'} alt={'search icon'} />
                                                }
                                                </div>
                                                <input
                                                    placeholder={this.userData.form.groups.placeholder}
                                                    onChange={this.searchGroupChanged}
                                                    value={searchGroup}
                                                    autoComplete={"off"}
                                                />
                                            </div>
                                            {groupSuggestions && groupSuggestions.length > 0 && 
                                                <ul className={'profileSuggestionsContainer'}>
                                                {groupSuggestions.map((item) => {
                                                    return (
                                                        <li key={`user${item.id}`} onClick={()=>this.addUserToGroup(item.id)}>
                                                            <span className={'suggestTitle'}>{`${item.displayName}`}</span>
                                                            <span>{`Group ID: ${item.id}`}</span>
                                                        </li>
                                                    );
                                                })}
                                                </ul>    
                                            }
                                        </div>
                                    }
                                    <div 
                                        className={cx("button whiteButton addFormButton", {"disabled": currentMode === this.userData.modes.profileUpdate})}
                                        onClick={this.toggleSearchGroupField}
                                    >
                                        {isSearchGroupOpened ? this.userData.buttons.cancel.label : this.userData.buttons.addToGroup.label}
                                    </div>
                                </div>
                            </div>
                            {listData.length ? 
                                <ListTable
                                    listData={listData} 
                                    listColumns={this.userData.table.columns}
                                    onItemClick={this.onGroupClick}
                                    withPagination={false}
                                    isDropdownOpened={isGroupOptionsDropdownOpened}
                                    closeDropdown={this.closeGroupOptionsDropdown}
                                    dropdownButton={dropdownButton}
                                    currentDropdownId={currentGroup}
                                    dropdownOptions={this.createGroupDropdownOptions()}
                                    isRowClickable={false}
                                    dropdownClassname={'optionGroupDropdown'}
                                    isSecondaryTable={true}
                                /> :
                                <div>
                                    <div className={'zeroImgContainer'}><img className={'zeroImg'} src={noGroupImg} alt={'No groups '} /></div>
                                    <div className={'zeroTitle'}>{this.userData.table.noGroupsTitle}</div>
                                    <div className={'zeroMessage'}><span>{this.userData.table.noGroupMessage}</span></div>
                                </div>
                            }
                        </div>
                    
                </div>
                {loading && <LoadingLayer />}
                {isDeleteModalOpened && 
                    <InfoModal
                        title={modals.removeUser.title}
                        message={modals.removeUser.message}
                        extraMessage={modals.removeUser.extraMessage}
                        subjectInfo={{
                            title: `${modalUser.firstName} ${modalUser.lastName}`,
                            subtitle: modalUser.email,
                        }}
                        buttons={this.getDeleteModalButtons()}
                        user={modalUser}
                    />
                }
            </div>
        )

    }
}
