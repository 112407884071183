import {SocketChannelNamespaceType} from "../constants/enums/SocketChannelNamespaceType";

const ablyChannelCoreNamespace = process.env.REACT_APP_ABLY_CHANNEL_CORE_NAMESPACE ?? 'hpoll-quest-socket-core';
const ablyChannelSyncNamespace = process.env.REACT_APP_ABLY_CHANNEL_SYNC_NAMESPACE ?? 'hpoll-quest-socket-sync';
const ablyChannelPrivateNamespace = process.env.REACT_APP_ABLY_CHANNEL_PRIVATE_NAMESPACE ?? 'hpoll-quest-socket-private';

export const getChannelName = (clientId, namespaceType) => {
    let channelNameSpaceType;
    switch (namespaceType) {
        case SocketChannelNamespaceType.CORE:
            channelNameSpaceType = ablyChannelCoreNamespace;
            break;
        case SocketChannelNamespaceType.SYNC:
            channelNameSpaceType = ablyChannelSyncNamespace;
            break;
        case SocketChannelNamespaceType.PRIVATE:
            channelNameSpaceType = ablyChannelPrivateNamespace;
            break;
        default:
            channelNameSpaceType = ablyChannelPrivateNamespace
            break;
    }

    return `${channelNameSpaceType}:${clientId}`;
};

export const getChannel = (ablyClient, clientId, namespaceType) => {
    if (!ablyClient) return null;

    const channelName = getChannelName(clientId, namespaceType);
    return ablyClient.channels.get(channelName);
};

export const subscribeToCoreChannel = (ablyClient, clientId, eventName, callback) => {
    const channel = getChannel(ablyClient, clientId, SocketChannelNamespaceType.CORE);
    if (channel) {
        channel.subscribe(eventName, callback);
    }
};

export const subscribeToSyncChannel = (ablyClient, clientId, eventName, callback) => {
    const channel = getChannel(ablyClient, clientId, SocketChannelNamespaceType.SYNC);
    if (channel) {
        channel.subscribe(eventName, callback);
    }
};

export const subscribeToPrivateChannel = (ablyClient, clientId, eventName, callback) => {
    const channel = getChannel(ablyClient, clientId, SocketChannelNamespaceType.PRIVATE);
    if (channel) {
        channel.subscribe(eventName, callback);
    }
};

export const unsubscribeFromPrivateChannel = (ablyClient, clientId, eventName, callback) => {
    const channel = getChannel(ablyClient, clientId, SocketChannelNamespaceType.PRIVATE);
    if (channel) {
        channel.unsubscribe(eventName, callback);
    }
};

export const parseMessage = (message) => {
    try {
        return JSON.parse(message);
    } catch (error) {
        return null;
    }
};