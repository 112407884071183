import React, { Component } from "react";
import ImageUploader from 'react-images-upload';
import ApiData from '../../../utils/Api';
import initData from '../../../utils/initData';
import BrandTitle from '../../widgets/Shared/BrandTitle'
import "./ApproveBrand.scss";

const buttonStyles = {
  alignItems: "center",
  width: "180px",
  height: "40px",
  backgroundColor: "transparent",
  color: "#0062FF",
  boxShadow: "none",
  padding: 0,
};

const containerStyle = {
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: "transparent",
  padding: 0,
};

export default class ApproveBrand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saveSuccess: -1,
      logo: this.props.approveBrand.brand.logo,
      newlogo: undefined,
      bname: this.props.approveBrand.brand.name || this.props.approveBrand.brand.decipherName,
      tag: this.props.approveBrand.tag?this.props.approveBrand.tag:this.props.approveBrand.brand.name,
      act: this.props.act
    };

    this.onPressApproveButton = this.onPressApproveButton.bind(this);
    this.onPressDelete = this.onPressDelete.bind(this);
  }

  onChangeName(e) {
    //e.preventDefault();
    let bname = e.target.value;
    this.setState({bname});
  }

  onChangeTag(e) {
    //e.preventDefault();
    let tag = e.target.value;
    this.setState({tag});
  }

  onPressDelete() {
    const { approveBrand, showMessage, closeFunction } = this.props;
    const { types } = initData.getStatusMessages();
    const { bname } = this.state;

    ApiData.deleteBrand(approveBrand.id).then(
      (result) => {
        closeFunction();
        typeof showMessage === 'function' && showMessage(types.declineBrand, bname);
      }
    ).catch(
        (error) => {
          console.log("error=" + error);
          this.setState({saveSuccess: 0});
        }
    );
  }

  handleUpload = (e) => {
    try {
      let reader = new FileReader();
      let file = e[e.length - 1];
      reader.onloadend = () => {
        this.setState({
          newlogo: reader.result,
        })
      };
      reader.readAsDataURL(file);
    } catch (err) {

    }

  }

  onPressApproveButton() {
    const { approveBrand, showMessage, closeFunction } = this.props;
    const { bname } = this.state;
    const { types } = initData.getStatusMessages();

    ApiData.approveBrand(approveBrand.id).then(
      (result) => {
        typeof showMessage === 'function' && showMessage(types.approveBrand, bname);
        closeFunction();
      }
    ).catch(
        (error) => {
          console.log("error=" + error);
          this.setState({saveSuccess: 0});
        }
    );
  }

  handleKeyUp = (e) => {
    let bname = e.target.value.replace(/[^0-9a-zA-Z`!@#$%^&*\-';\s]/g,'');
    this.setState({ bname });
 }

  render() {
    const { saveSuccess, act, bname, logo, newlogo} = this.state;
    const {approveBrand} = this.props;
    const title= 'Approved Brand';
    const desc = 'Review brand details and approve or decline. Declining a brand will permanently delete it from HQB database.';
    const customStyle = newlogo?{backgroundImage : `url("${newlogo}")`}:{};

    return (
      <div className="brandContent">
        <h1 className={'modalTitle'}>{title}</h1>
        <p className={'modalDescription'}>{desc}</p>

        {act==='Edit' && saveSuccess===-1?
          <div className="brandEdit">
            <div className='uploadContainer'>
            {!!newlogo ?
              <div className="brandLogoIcon" style={customStyle}></div>:
              (!!logo ? <img src={logo} className="brandLogoIcon" alt="" /> :
                    <div className="brandLogoIcon uploadImg" style={customStyle}>{bname.slice(0, 1).toUpperCase()}</div>)
            }
                <ImageUploader
                  // className='uploadButton'
                  withIcon={false}
                  withLabel={false}
                  buttonText='Upload New Logo'
                  buttonStyles={buttonStyles}
                  maxFileSize={5242880}
                  singleImage={true}
                  imgExtension={[".jpg", ".gif", ".png"]}
                  fileContainerStyle={containerStyle}
                  onChange={this.handleUpload}>
                </ImageUploader>
              </div>
            <input placeholder="Brand Name" maxLength="255" onKeyUp={this.handleKeyUp} onChange={this.onChangeName.bind(this)} value={bname} />
          </div> :
          <BrandTitle brand={approveBrand.brand} />
        }
        <div className="addedBy">{approveBrand.createdBy}</div>
        <div className="bottomButtons">
            <div className="button whiteButton" onClick={this.props.closeFunction}>Cancel</div>
            <div className={"button blueButton"} onClick={this.onPressApproveButton}>
              {act==='Edit'?'Save': act}
            </div>
            <div className="textButton" onClick={this.onPressDelete}>Decline Brand</div>
        </div>
      </div>
    )
  }
}
