import React, { useState, useEffect, useRef, useCallback } from 'react';
import cx from 'classnames';
import './CustomSegments.scss';
import { LiaBrailleSolid } from "react-icons/lia";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
import { RxQuestionMarkCircled } from "react-icons/rx";
import { IoWarningOutline } from "react-icons/io5";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ChromePicker } from "react-color";
import { useMutation } from 'react-query';
import ApiData from '../../../utils/Api';
import { IoIosArrowUp } from "react-icons/io";
import { CgDuplicate } from "react-icons/cg";

const CustomSegments = (props) => {
    let { selectedMarket, selectedSegementsCount } = props;
    let [expanded, setExpanded] = useState(false);
    let [selectedSegementCount, setselectedSegementCount] = useState(0);
    let [checkedCheckBox, setCheckedCheckBox] = useState(false)
    let [categoryNameValue, setCategoryNameValue] = useState(props.title)
    let [showColorPicker, setShowColorPicker] = useState(false);
    let [selectedColor, setSelectedColor] = useState('');
    let [customSegmentId, setCustomSegmentId] = useState();
    let [colorChange, setColorChange] = useState(false)
    let [ isLoading, setIsLoading ] = useState(false);
    let ref = useRef(null);

    let updateSegmentColor = useMutation(ApiData.updateSegmentColor, {
        onSuccess: async () => {
            setIsLoading(false);
        },
        onError: async (error) => {
            console.log(error);
            setIsLoading(false)
        }
    });

    useEffect(() => {
        setExpanded(expanded)
        calcSelectedSegmentsCount(0)
        setCheckedCheckBox(checkedCheckBox)
        if(selectedMarket?.countryCode === 'HSS' && categoryNameValue === 'Age 18+'){
            setCategoryNameValue('Age 35+');
        }else if(selectedMarket?.countryCode !== 'HSS' && (categoryNameValue === 'Age 18+' || categoryNameValue === 'Age 35+')){
            setCategoryNameValue('Age 18+');
        }
    }, [props.page, selectedMarket, selectedSegementsCount])

    useEffect(() => {
        let categoryTest = categoryData[0]?.segments?.every((obj, index) => obj.isActive)
        if (categoryTest) {
            categoryData[0].isCheck = true
        }
        else {
            categoryData[0].isCheck = false
        }
    }, [props.categoryData, selectedSegementsCount])

    const onExpandClick = () => {
        setExpanded(!expanded)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return (() => {
            document.removeEventListener('mousedown', handleClickOutside);
        })
    })

    let updateSegmentOrder = useMutation(ApiData.updateSegmentsOrder, {
        onSuccess: async () => {
            setIsLoading(false)
        },
        onError: async (error) => {
            console.log(error);
            setIsLoading(false)
        }
    });

    const calcSelectedSegmentsCount = () => {
        let { categoryList, categoryData } = props
        let checkData = categoryData.map(obj => obj)
        let findCategory = props?.categoryList?.filter(ele => checkData[0].categoryId === ele.categoryId);
        let count;
        if (categoryList?.categoryId === null) {
            count = categoryList?.reduce((accu, categoryList) => accu + categoryList.segments.length, 0)
        } else {
            count = findCategory && findCategory[0]?.segments?.reduce((acc, obj) => acc + (obj?.isActive === true &&  (obj.segmentMarkets.includes(selectedMarket?.marketName) === true)), 0);
        }
        setselectedSegementCount(count);
        return count;
    }

    const onSelectedCategory = async (categoryData) => {
        let { categoryList } = props;
        let { categoryId, categoryType } = categoryData;


        const categoryIndex = categoryType === 'DEFAULT' ?
            categoryList.findIndex(cf => { return cf.categoryName === categoryType }) :
            categoryList.findIndex(cf => { return cf.categoryId === categoryId });
        if (categoryIndex > -1) {
            categoryList[categoryIndex].isCheck = !categoryList[categoryIndex].isCheck
        }
       
        let selectedSegments = categoryList[categoryIndex]?.segments && categoryList[categoryIndex]?.segments.filter(obj=>obj.segmentMarkets.includes(selectedMarket.marketName) === true);
        let lastIndex;
        let testValue = 20 - props.selectedSegementsCount + (selectedSegments?.filter(obj=>obj.isActive)?.length)
        lastIndex = selectedSegments?.length < testValue? selectedSegments?.length -1 : testValue - 1
        let categoryStatus = props.categoryData[0]?.segments?.every((obj, index) => obj.isActive)
        if (categoryType !== 'DEFAULT' && (props.selectedSegementsCount <= 20 && (categoryStatus === false ||categoryStatus === true)) ) {
            if (selectedSegments?.length > 0) {
                selectedSegments.map((ele, index) => {
                    if (index <= lastIndex && categoryStatus === false) {
                        ele.isActive = true
                    }else{
                        ele.isActive = false
                    }
                })
            }
            calcSelectedSegmentsCount()
            let segments = categoryList.reduce((acc, sr) => {
                acc.push(...sr.segments);
                return acc;
            }, [])
            let segmentData = [];
            segments.forEach((item) => {
                segmentData.push({
                    segmentId: item?.segmentId,
                    isActive: item.isActive,
                })
            })
            let count = await props.calcSegmentsCategoryCount(categoryList)
            
           let segmentsInactive = selectedSegments.findIndex(obj=>obj.isActive === false)
            if(count === 20 && (segmentsInactive === -1)){
                props.handleSegmentsLimited(false)
                activeDeactiveSegments.mutate(segmentData)
                await props.onSegments(categoryList, selectedSegementCount);
                
            }else if( count === 20 && (segmentsInactive < selectedSegments.length)){
                props.handleSegmentsLimited(true)
                    activeDeactiveSegments.mutate(segmentData)
                    await props.onSegments(categoryList, selectedSegementCount);
            }
            else{
                activeDeactiveSegments.mutate(segmentData)
                await props.onSegments(categoryList, selectedSegementCount);
                props.handleSegmentsLimited(false)
            }
        }
        await props.setSegmentsData(categoryList);
    }
    const activeDeactiveSegments = useMutation(ApiData.enableDisableSegment, {
        onSuccess: async () => {
        },
        onError: async (error) => {
            console.log(error);
        }
    });


    const onSelectedSegments = async (categoryData, segmentResponse) => {
        let { categoryList, selectedMarket } = props;
        let { categoryId, categoryType } = categoryData;

        const categoryIndex = categoryType === 'DEFAULT' ?
            categoryList.findIndex(cf => { return cf.categoryName === categoryType }) :
            categoryList.findIndex(cf => { return cf.categoryId === categoryId });

        const segmentsIndex = categoryList[categoryIndex]?.segments?.findIndex(cf => { return cf.segmentId === segmentResponse.segmentId });
        let selectedSegementObj = categoryList[categoryIndex]?.segments[segmentsIndex];
        let filtersPresentInMarket = selectedSegementObj?.segmentMarkets?.includes(selectedMarket?.marketName)

        if (segmentsIndex >= 0 && filtersPresentInMarket === true) {
            selectedSegementObj.isActive = props.selectedSegementsCount <= 19 && filtersPresentInMarket? !(selectedSegementObj?.isActive ? selectedSegementObj?.isActive : false) : false;
        }
        
        await calcSelectedSegmentsCount()

        let segments = categoryList.reduce((acc, sr) => {
            acc.push(...sr.segments);
            return acc;
        }, [])
        let segmentData = [];
        segments.forEach((item) => {
            segmentData.push({
                segmentId: item?.segmentId,
                isActive: item?.isActive,
            })
        })
    let payload =[{
        segmentId: selectedSegementObj?.segmentId,
        isActive: selectedSegementObj?.isActive
    }]
        let count = await props.calcSegmentsCategoryCount(categoryList)
        if(count === 20 && selectedSegementObj?.isActive === false){
            props.handleSegmentsLimited(true)
        }
        else if(categoryType !== 'DEFAULT' && filtersPresentInMarket === true ){
            activeDeactiveSegments.mutate(payload)
            await props.onSegments(categoryList);
            await props.setSegmentsData(categoryList);
            props.handleSegmentsLimited(false)
        }
    }


    const handleEditDeleteCopySegment = (action, id) => {
        props.handleSegmentsActions(action, id)
    }

    const handleCategoryEdit = () => {
        ref.current.focus()
    }
    const handleCategoryKeyUp = async (e, categoryId) => {
        let categoryList = props.categoryList;
        if (e.keyCode === 13) {
            ref.current.blur()
            let data = {
                categoryName: categoryNameValue
            }
            categoryList.forEach((ele)=>ele.categoryId === categoryId && (ele.categoryName = categoryNameValue));
            await props.onSegments(categoryList);
            await ApiData.updateCategory(categoryId, data);
            await props.setSegmentsData(categoryList);
        }
    }
    const handleClickOutside = async (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
           let categoryResult = props.categoryData.filter(ele=>ele.categoryName !== categoryNameValue && ele.categoryType !== 'DEFAULT');
           let data = {
            categoryName: categoryNameValue
           }
        if(categoryResult?.length > 0){
            await ApiData.updateCategory(categoryResult[0]?.categoryId, data);
            await props.getSegments(selectedMarket?.marketId);
        }
    }
}
    const handleCategoryDelete = (action, id, name) => {
        props.handleDeleteCategoryActions(action, id, name)
    }

    const onCategoryChange = useCallback((e) => {
        setCategoryNameValue(e.target.value);
    },[categoryNameValue])

    const reorder = (segmentResponse, startIndex, endIndex) => {
        const result = Array.from(segmentResponse);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = async (result) => {
        if (!result.destination) {
            return;
        }
        const activearr = reorder(
            props.segmentResponse,
            result.source.index,
            result.destination.index
        );

        const data = await activearr.map((b, i) => {
            b.position = i + 0;
            return b;
        });

        let finalArry = props.categoryList.filter(ele => (ele.segments.sort((a, b) => a.position - b.position).map(sr => data.map(da => sr.segmentId === da.segmentId))))
        let segments = finalArry.reduce((acc, sr) => {
            acc.push(...sr.segments);
            return acc;
        }, [])
        let segmentData = [];
        segments.forEach((item, index) => {
            segmentData.push({
                segmentId: item?.segmentId,
                position: index,
            })
        })
        await updateSegmentOrder.mutate(segmentData);
        await props.onSegments(finalArry);
        await props.setSegmentsData(finalArry);
    }

   const getListStyle = (isDraggingOver, itemsLength) => ({
        background: isDraggingOver? "white" : '',
        flexGrow: 1,
        marginBottom : isDraggingOver ? "32px" : "4px"
    });

    const onClose = async (categoryData, segmentResponse) => {
        let { categoryList } = props;
        let { categoryId, categoryType } = categoryData;

        const categoryIndex = categoryType === 'DEFAULT' ?
            categoryList.findIndex(cf => { return cf.categoryType === 'DEFAULT' }) :
            categoryList.findIndex(cf => { return cf.categoryId === categoryId });

        const segmentsIndex = categoryList[categoryIndex]?.segments?.findIndex(cf => { return cf.segmentId === segmentResponse.segmentId });
        let selectedSegementObj = categoryList[categoryIndex]?.segments[segmentsIndex];
        if (segmentsIndex >= 0) {
            selectedSegementObj.color = selectedColor
        }
        let payload = { color: selectedColor }
        await updateSegmentColor.mutate({ segmentId: selectedSegementObj?.segmentId, payload })
        await props.onSegments(categoryList);
        await props.setSegmentsData(categoryList);
        setShowColorPicker(false)
        setColorChange(false)
    }

    const handleColourChange = async (e) => {
        setColorChange(true);
        let color = e.hex.toUpperCase();
        setSelectedColor(color);
    };

    const onShowColourPicker = (item, segmentResponse) => {
        let value = segmentResponse.filter(ele => ele.segmentId === item)
        setCustomSegmentId(value[0].segmentId)
        setShowColorPicker(!showColorPicker)
    }

    let { title, categoryData, searchFound, searchKey, handleDrag } = props;
    return (
        <div className={cx("CustomCardContainer", { "collapse": (!expanded && !searchFound) }, { "active": selectedSegementCount > 0 })} >
            {categoryData?.map((obj, index) => {
                let { categoryId, categoryName , categoryType } = obj;
                const matchTitle = title?.toLowerCase()?.includes(searchKey?.toLowerCase());
               const categoryLimit = categoryNameValue?.length > 15;
                return (
                    <>
                        <div className={cx("CustomCardHeader ", { "active": selectedSegementCount > 0 }, { "expanded": (selectedSegementCount > 0 && expanded) })} style={{ justifyContent: 'flex-start', display: 'flex', flexDirection: 'row' }} onClick={onExpandClick}  >
                            <div style={{ display: 'flex' }}>
                                <div {...handleDrag}>
                                    <LiaBrailleSolid className='LiaBrailleSolidIcon' />
                                </div>
                             <input type="checkbox" checked={(categoryType === "DEFAULT") ? true : obj.isCheck} className='categoryCheckBox'  onClick={() => onSelectedCategory(obj)} />
                                <div className={cx('CustomSegmentTooltip', { "expanded": !expanded})}>
                                    <div className="categoryTitle">
                                        <div className='customTooltip'>
                                        <input ref={ref} type='type' style={{width: categoryLimit? '14ch':categoryNameValue?.length+'ch'}} className={cx(categoryLimit? "title caret-hidden categoryTitleInput ":"title caret-hidden categoryTitleInputLimit", { "match": searchFound && matchTitle })} value={categoryNameValue}  onChange={onCategoryChange} onKeyUp={(e) => handleCategoryKeyUp(e, categoryId)} ></input>
                                      {categoryLimit? <span className='tooltiptext' style={{ left:'65px',marginTop:'-31px'}}>{categoryNameValue}</span>:''}
                                        </div>
                                        {selectedSegementCount > 0 &&
                                            <div className='countContainer'>
                                                <span>{selectedSegementCount}</span>
                                            </div>
                                        }
                                    </div>
                                    {(categoryType === "DEFAULT")||(categoryType === "SYSTEM")||(categoryName === "Uncategorized Segments")||(categoryName === "Uncategorized")? '' : <div className='showOrHideIconContainer segmentWarning'>
                                    <div className=' showOrHideIconContainerEdit'>
                                        <FiEdit2 alt="" className="showOrHideIcon" onClick={handleCategoryEdit} />
                                        <span className={'tooltipEdit'}>Edit</span>
                                    </div>
                                    <div className='showOrHideIconContainerDelete'>
                                       <RiDeleteBinLine alt="" className="showOrHideIcon" onClick={() => handleCategoryDelete('delete', categoryId, categoryName)} />
                                       <span className={'tooltipDelete'}>Delete</span>
                                       </div>
                                    </div>}
                                </div>
                            </div>
                            <IoIosArrowUp className="arrowIcon" alt="" />
                        </div>
                        {(expanded || searchFound) && <div className="CustomListContainer">
                            <div>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable" direction="vertical">
                                        {(provided, snapshot) => (
                                            <div ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver, obj?.segments.length)}
                                            {...provided.droppableProps}>
                                                {obj.segments?.map((ele, index) => {
                                                    let filtersPresentInMarket = ele.segmentMarkets.includes(selectedMarket?.marketName)
                                                    let expiredFilters = ele.expiredFilters?.length > 0
                                                    let detachedFilters = ele.detachedFilters?.length > 0
                                                    let unFieldingFilters = ele.unFieldingFilters?.length > 0
                                                    let active = ele.isActive === true
                                                    let segmentTooltip = ele.segmentName?.length > 15
                                                    const matchSegmentName = ele.segmentName?.toLowerCase()?.includes(searchKey?.toLowerCase());
                                                    return (
                                                        <>
                                                            <Draggable
                                                                key={ele.segmentId}
                                                                index={index}
                                                                draggableId={`${ele.segmentId}`}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div className='drag'
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                    >
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                            <div {...provided.dragHandleProps}>
                                                                                {ele.segmentName === null ? '' : <LiaBrailleSolid className='LiaBrailleSolid' />}
                                                                            </div>
                                                                            <div onMouseLeave={() => setShowColorPicker(false)}>
                                                                                {customSegmentId === ele.segmentId && showColorPicker ? <div className='show-color-picker' onMouseLeave={() => setShowColorPicker(false)}>
                                                                                    <div className='color-picker' style={{ position: 'absolute', zIndex: 9999 }}>
                                                                                        <ChromePicker className="arrow-right"
                                                                                            color={colorChange ? selectedColor : ele.color}
                                                                                            id="chrome-picker"
                                                                                            onChangeComplete={(e) => handleColourChange(e)}
                                                                                            disableAlpha={true}
                                                                                            width="50%"
                                                                                            renderers={false}
                                                                                        />
                                                                                        <button
                                                                                            style={{ position: 'absolute', bottom: '12px', height: '33px', background: 'rgb(36, 31, 31)', borderRadius: '5px', zIndex: 9, left: '47%', width: '77px' }} onClick={() => onClose(obj, ele)}
                                                                                        ><span style={{ color: 'white', fontSize: '10px', fontFamily: 'sans-serif' }}>Save Color</span></button>
                                                                                    </div>
                                                                                </div> : ''}
                                                                                <div className={"legend"} style={{ backgroundColor: ele.color, height: '10px', width: '10px', marginTop: '8px', marginRight: '6px' }} onMouseOver={() => onShowColourPicker(ele.segmentId, props.segmentResponse)} />
                                                                            </div>
                                                                            <div className='segmentContainer' >
                                                                                <div className={cx("filterIconsListContainer")}>
                                                                                    <div key={"stitle" + ele.segmentId} className={cx("filterCommonItemContainer", { "active": active && filtersPresentInMarket},{"isExpired":!filtersPresentInMarket})} onClick={() => onSelectedSegments(obj, ele)}>
                                                                                    <div className='customTooltip' >
                                                                                        {(searchFound && matchSegmentName) ?
                                                                                            <div className="segmentOptionTitle" dangerouslySetInnerHTML={{ __html: ele.segmentName.replace(new RegExp(`(${searchKey})`, 'gi'), match => `<span class='match'>${match}</span>`) }} /> :
                                                                                            <span  className= {segmentTooltip &&'segmenttooltiptext'}>{ele?.segmentName}</span>
                                                                                        }
                                                                                         {segmentTooltip && <span className='tooltiptext' style={{marginLeft:'25px',marginTop:'-34px'}}>{ele?.segmentName}</span>}
                                                                                     </div>
                                                                                    </div>
                                                                                    <div className='iconsContainer'>
                                                                                      {categoryType === "DEFAULT"?'': <div className='showOrHideIconContainer'>
                                                                                            {(filtersPresentInMarket && ele?.module !== "GLOBAL" && categoryName !== 'Shared') &&
                                                                                                <div className=' showOrHideIconContainerEdit'>
                                                                                                    <FiEdit2 alt="" className="showOrHideIcon" onClick={() => handleEditDeleteCopySegment('edit', ele.segmentId)} />
                                                                                                    <span className={'tooltipEdit'}>Edit</span>
                                                                                                </div>
                                                                                            }
                                                                                            {filtersPresentInMarket && ele?.module !== "GLOBAL" &&
                                                                                                <div className=' showOrHideIconContainerEdit'>
                                                                                                    <CgDuplicate  alt="" className="showOrHideIcon" onClick={() => handleEditDeleteCopySegment('copy', ele.segmentId)} />
                                                                                                    <span className={'tooltipEdit'}>Duplicate</span>
                                                                                                </div>
                                                                                            }
                                                                                            <div className='showOrHideIconContainerDelete'>
                                                                                                <RiDeleteBinLine alt="" className="showOrHideIcon" onClick={() => handleEditDeleteCopySegment('delete', ele.segmentId)} />
                                                                                                <span className={'tooltipDelete'}>Delete</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        }
                                                                                        {(ele?.module == 'GLOBAL') && 
                                                                                        <div className='warningIconsConatiner'>
                                                                                        <div className="segmentWarning">
                                                                                            <RxQuestionMarkCircled className="WarningIcons" />
                                                                                            <span className={'tooltipWarning'}>Filters for this segment are related to global markets.</span>
                                                                                        </div>
                                                                                        </div>}   
                                                                                        <div className='warningIconsConatiner'>
                                                                                            {!filtersPresentInMarket && <div className="segmentWarning">
                                                                                            <IoWarningOutline className="WarningIcons"/>
                                                                                                <span className={'tooltipWarning'}>Filters for this segment are not applicable for this market.</span>
                                                                                            </div>}
                                                                                            {(expiredFilters || detachedFilters || unFieldingFilters)  && filtersPresentInMarket && <div className="segmentWarning">
                                                                                                <IoWarningOutline className="WarningIcons"/>
                                                                                                <div className={'tooltipWarning'}>
                                                                                                   {expiredFilters && <span style={{paddingBottom: "5px"}}>The following filter(s) have expired:</span>}
                                                                                                   {detachedFilters && <span style={{paddingBottom: "5px"}}>The following filter(s) are not available in this portal view.</span>}
                                                                                                   {unFieldingFilters && <span style={{paddingBottom: "5px"}}>The following filter(s) are no longer available for use.</span>}
                                                                                                    <br></br>
                                                                                                   <div style={{padding:"10px 0px 10px 0px"}}>
                                                                                                        { expiredFilters && ele.expiredFilters?.map(obj=><span><li style={{fontSize:'10px'}}>{obj}</li></span>)}
                                                                                                        { detachedFilters && ele.detachedFilters?.map(obj=><span><li style={{fontSize:'10px'}}>{obj}</li></span>)}
                                                                                                        { unFieldingFilters && ele.unFieldingFilters?.map(obj=><span><li style={{fontSize:'10px'}}>{obj}</li></span>)}
                                                                                                    </div>
                                                                                                    {expiredFilters? <span style={{paddingTop: "5px"}}>Reach out to your client service contact regarding reactivation.</span>:<span style={{paddingTop: "5px"}}>Reach out to your client service contact for more details.</span>}
                                                                                                </div>
                                                                                            </div>}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}</Draggable>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>
                        }
                    </>
                )
            })}
        </div>
    )
}

export default CustomSegments;