import React, { Component } from "react";
import BrandLogo from "../../assets/icons/harris-logo.png";
import { logOut } from '../LoginComponent/Login.helpers';
import { setLocalStorage, getLocalStorage } from '../../utils/storageHelpers';
import "./SelectBrandComponent.scss";

export default class SelectBrandComponent extends Component {
  constructor(props) {
    super(props);

    const myUserInfo = getLocalStorage('userInfo');
    this.userType = JSON.parse(myUserInfo)?.userType;
    this.authorizedType = getLocalStorage('authorizedType');
    this.groups = JSON.parse(myUserInfo).groups;
  }

  backToLogin=()=>{
    localStorage.clear();
    logOut();
   }

  switchSite() {
    setLocalStorage('authorizedType', 'ADMIN');
    this.props.history.push('/admin');
  }
  render() {
    return (
      <div className="selectBrandBackground">
        <div className="selectBrandContainer" style={{textAlign:'center'}}>
          <div className="selectBrandHeader">
            <img src={BrandLogo} className="iconLogo" alt="" height={50} width={251}/>
          </div>
          <div className="selectCampaignContent">
          </div>
          <div className="contractDataContainer">
          </div>
          <div className="contractDates">
          </div>
          <div className="content">
          <div key="emptyMyBrandDesc" className="selectBrandDescription">
            {Number(this.groups) === 0? '“Your user account has not been mapped to any group. Please reach out to your Harris Client Service Representative to have brands activated for your account”':'“Your user account is no longer associated with active brands. Please reach out to your Harris Client Service Representative to have brands activated for your account.”'}
          </div>  
          <div className="buttonContainer">
            <div className="blueButton"
              onClick={this.backToLogin}>
                Back to Log In
            </div>
            {this.userType==='ADMIN' && <div className="blueButton" onClick={() => this.switchSite()}>Go to Admin</div>}
          </div>
          </div>
        </div>
      </div>
    );
  }
}
