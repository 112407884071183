import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SimpleSelect from '../../widgets/Shared/SimpleDropDownSelect';
import initData from '../../../utils/initData';
import ApiData from '../../../utils/Api';
import { phoneConvert } from '../../../utils/common';
import searchIcon from "../../../assets/icons/icon_search.svg";
import delGroupIcon from "../../../assets/icons/icon_x.svg";
import RequiredIndicator from './RequiredIndicator';
import eyeopen from "../../../assets/icons/eyeopen.svg";
import eyeclose from "../../../assets/icons/eyeclose.svg";
import blockListDomainsData from './EmailsList.json';
import './ProfileForm.scss';
import hasPermission from '../../../utils/Permissions'

export default class UserForm extends Component {
    static propTypes = {
        cancelForm: PropTypes.func.isRequired,
        createUser: PropTypes.func.isRequired,
        editMode: PropTypes.string.isRequired,
        fieldValues: PropTypes.object,
    }

    constructor(props) {
        super(props);

        const addUserData = initData.getUserData();
        const { form: { firstName, lastName, email, phoneNumber, company, position, userRoles, groups, password } } = addUserData;
        const fieldValues = this.props.fieldValues;

        this.state = {
            [firstName.key]: fieldValues && fieldValues[firstName.key] ? fieldValues[firstName.key] : '',
            [lastName.key]: fieldValues && fieldValues[lastName.key] ? fieldValues[lastName.key] : '',
            [email.key]: fieldValues && fieldValues[email.key] ? fieldValues[email.key] : '',
            [phoneNumber.key]: fieldValues && fieldValues[phoneNumber.key] ? fieldValues[phoneNumber.key] : '',
            [company.key]: fieldValues && fieldValues[company.key] ? fieldValues[company.key] : '',
            [position.key]: fieldValues && fieldValues[position.key] ? fieldValues[position.key] : '',
            [userRoles.key]: fieldValues && fieldValues[userRoles.key] ? fieldValues[userRoles.key] : userRoles.options[0].id,
            [password.key]: fieldValues && fieldValues[password.key] ? fieldValues[password.key] : '',
            rolesSelectOpened: false,
            emailMessage:'',
            blocklistMessage:'',
            passwordMessage:'',
            searchGroup: '',
            selectedGroups: fieldValues ? fieldValues[groups.key] : [],
            companySuggestions:[],
            groupSuggestions: [],
            addUserData,
            passwordState: false,
            editPassword: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { fieldValues } = props;
        const { form: { firstName, lastName, email, phoneNumber, company, position, userRoles, groups } } = state.addUserData;

        if (props.editMode === state.addUserData.modes.profileDisplay && fieldValues && fieldValues[firstName.key] !== state[firstName.key]) {
            return {
                [firstName.key]: fieldValues && fieldValues[firstName.key] ? fieldValues[firstName.key] : '',
                [lastName.key]: fieldValues && fieldValues[lastName.key] ? fieldValues[lastName.key] : '',
                [email.key]: fieldValues && fieldValues[email.key] ? fieldValues[email.key] : '',
                [phoneNumber.key]: fieldValues && fieldValues[phoneNumber.key] ? fieldValues[phoneNumber.key] : '',
                [company.key]: fieldValues && fieldValues[company.key] ? fieldValues[company.key] : '',
                [position.key]: fieldValues && fieldValues[position.key] ? fieldValues[position.key] : '',
                [userRoles.key]: fieldValues && fieldValues[userRoles.key] ? fieldValues[userRoles.key] : userRoles.options[0].id,
                selectedGroups: fieldValues && fieldValues[groups.key] ? fieldValues[groups.key] : [],
            }
        } else return null;
    }


    passwordChange = (password1, inputKey) => {
        const { form: { password } } = this.state.addUserData;
        var passw = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        if (password1.match(passw)) {
            this.setState({ passwordMessage: '' })
        }
        else {
            this.setState({ passwordMessage: password.inputMessages.validPassword })
        }
        this.setState({ [inputKey]: password1 });
    }

    emailChange = (email1, inputKey) => {
        const { form: { email } } = this.state.addUserData;
        // const regEx = /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?com\.com)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
        const regEx = /^([A-Za-z0-9_\-\.\+])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?com\.com)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
        if (regEx.test(email1)) {
            this.setState({ emailMessage: '' })
        } else {
            this.setState({ emailMessage: email.inputMessages.validEmail })
        }
        this.setState({ [inputKey]: email1 })
        const domainList = blockListDomainsData;
        
        var splitArray = email1.split('@');
               if(domainList.includes(splitArray[1])){
                   this.setState({ blocklistMessage: `This domain ${splitArray[1]} is not allowed`})
               }else{
                   this.setState({blocklistMessage:''})
               }  
 
    }

    inputChange = (e, inputKey) => {
        const { form: { phoneNumber, password, email } } = this.state.addUserData;
        if (inputKey === phoneNumber.key) {
            let phone = e.target.value.replace(/[^0-9]/g, '');
            phone = phoneConvert(phone);
            this.setState({ [inputKey]: phone });
        }
        const email1 = e.target.value;
        if (inputKey === email.key) {
            this.emailChange(email1, inputKey)
        }
        const password1 = e.target.value;
        if (inputKey === password.key) {
            this.setState({ 'editPassword': true });
            this.passwordChange(password1, inputKey)
        }
        else {
            this.setState({ [inputKey]: e.target.value });
        }
    }

    onRolesSelectClick = () => {
        this.setState((prevState => ({ rolesSelectOpened: !prevState.rolesSelectOpened })));
    }

    rolesHandleSelect = (id) => {
        const { form: { userRoles } } = this.state.addUserData;
        this.setState({ [userRoles.key]: id });
    }

    createUser = () => {
        const { form: { firstName, lastName, email, phoneNumber, company, position, userRoles, password } } = this.state.addUserData;
        const user = {
            firstName: this.state[firstName.key],
            lastName: this.state[lastName.key],
            email: this.state[email.key],
            phoneNumber: this.state[phoneNumber.key],
            company: this.state[company.key],
            position: this.state[position.key],
            userRole: this.state[userRoles.key],
            password: this.state[password.key]?this.state[password.key]:null,
            groups: this.state.selectedGroups.map(group => group.id),
        };
        const { createUser } = this.props;
        if (this.validateFields() && typeof createUser === 'function') createUser(user, this.state);
    }

    validateFields = () => {
        const { form: { firstName, lastName, email, password, company } } = this.state.addUserData;
        
        if(this.props.editMode === "profile_update" && this.state[password.key]?.length === 0){
            return !!(this.state[firstName.key] && this.state[lastName.key] && this.state[email.key] && this.state[company.key] && !this.onEditHandler() &&
                this.state.addUserData.modes.create?!(this.state.emailMessage!==''||  this.state.blocklistMessage!=='')
                :false);  
        }else{
            return !!(this.state[firstName.key] && this.state[lastName.key] && this.state[email.key] && this.state[password.key] && this.state[company.key] && 
                this.state.addUserData.modes.create?!(this.state.emailMessage!==''|| this.state.passwordMessage!==''|| this.state.blocklistMessage!=='')
                :false);
        }
    }

    onEditHandler = () =>{
        const { form: { firstName, lastName, phoneNumber, company, position, userRoles } } = this.state.addUserData;
        let {fieldValues} = this.props;
        if(fieldValues && fieldValues[firstName.key]  !== this.state[firstName?.key] ||
            fieldValues && fieldValues[lastName?.key] !== this.state[lastName.key]||
            fieldValues && fieldValues[phoneNumber?.key] !== this.state[phoneNumber.key]  || 
            fieldValues && fieldValues[company?.key] !== this.state[company.key]   ||  
            fieldValues && fieldValues[position?.key] !== this.state[position.key]   ||
            fieldValues && fieldValues[userRoles?.key] !== this.state[userRoles.key] ||
            (this.state.editPassword === true &&  this.state.passwordMessage===''))
        {
            return false;
        } else {
            return true
        }
    }

    removeGroup = (id) => {
        const selectedGroups = [...this.state.selectedGroups];
        const removeIndex = selectedGroups.findIndex(group => group.id === id);
        if (removeIndex > -1) {
            selectedGroups.splice(removeIndex, 1);
        }
        this.setState({
            searchGroup: '',
            selectedGroups,
            groupSuggestions: []
        });
    }

    onSelectGroup(id) {
        const { groupSuggestions } = this.state;
        const item = groupSuggestions.find(suggestion => suggestion.id === id);
        let { selectedGroups } = this.state;
        let groups = [...selectedGroups];

        if (!groups.find(group => group.id === id)) {
            groups = [...groups, item];
        }
        this.setState({
            searchGroup: '',
            selectedGroups: groups,
            groupSuggestions: []
        });
    }

    searchGroupChanged = (event) => {
        const name = event.target.value;
        if (name.trim() === '') {
            this.setState({
                searchGroup: '',
                groupSuggestions: []
            });
        } else {
            this.setState({
                searchGroup: name,
            });
            ApiData.searchGroupByName(encodeURI(name)).then(
                (result) => {
                    this.setState({
                        groupSuggestions: result,
                    })
                }
            ).catch(
                (err) => {
                    console.log("error=" + err)
                    this.setState({
                        groupSuggestions: []
                    });
                }
            );
        }
    }

    changePassState = () => {
        const { passwordState } = this.state;
        this.setState({ passwordState: !passwordState })
    }

    onSelectCompany = (companyName) => {
        const { companySuggestions } = this.state;
        const item = companySuggestions.find(suggestion => suggestion.company === companyName);
        this.setState({
            company: '', 
            company: item.company,
            companySuggestions: []
        });
      }

    searchCompanyChanged = (event) => {
        const companyValue = event.target.value;
        if (companyValue.trim() === ''){
            this.setState({
                company: '',
                companySuggestions: []
            });
        } else {
            this.setState({
                company: companyValue, 
            });
            ApiData.searchCompany(companyValue).then(
                (result) => {
                this.setState({
                    companySuggestions: result,
                })
            }
            ).catch(
                (err) => {
                    console.log("error=" + err )
                    this.setState({
                        companySuggestions: []
                    });
                    if(err.message == "Network Error")this.setState({sessionTimeoutErr:true});
                }
            );
        }
    }

    render() {
        const { rolesSelectOpened, groupSuggestions, selectedGroups, searchGroup, passwordState, companySuggestions,
            addUserData: { form: { firstName, lastName, email, phoneNumber, company, position, groups, userRoles, password }, buttons, modes, inputMessages } } = this.state;
        const { cancelForm, editMode, error } = this.props;
        const isSubmitBtnDisabled = !this.validateFields();
        return (
            <div className={'profileForm'}>
                <div className={'inputBlock'}>
                    <label htmlFor={firstName.key} className={'inputLabel'}>{firstName.label}<RequiredIndicator /></label>
                    <div className={'inputBox formInput'}>
                        <input
                            id={firstName.key}
                            placeholder={firstName.placeholder}
                            value={this.state[firstName.key]}
                            onChange={(e) => this.inputChange(e, firstName.key)}
                            disabled={editMode === modes.profileDisplay}
                        />
                    </div>
                </div>
                <div className={'inputBlock'}>
                    <label htmlFor={lastName.key} className={'inputLabel'}>{lastName.label}<RequiredIndicator /></label>
                    <div className={'inputBox formInput'}>
                        <input
                            id={lastName.key}
                            placeholder={lastName.placeholder}
                            value={this.state[lastName.key]}
                            onChange={(e) => this.inputChange(e, lastName.key)}
                            disabled={editMode === modes.profileDisplay}
                        />
                    </div>
                </div>
                <div className={'inputBlock'}>
                    <label htmlFor={email.key} className={'inputLabel'}>{email.label}<RequiredIndicator /></label>
                    <div className={'inputBox formInput'}>
                        <input
                            id={email.key}
                            placeholder={email.placeholder}
                            value={this.state[email.key]}
                            onChange={(e) => this.inputChange(e, email.key)}
                            disabled={editMode === modes.profileDisplay || editMode === modes.profileUpdate}
                        />
                    </div>
                </div>
                {this.state.emailMessage && this.state.email !== '' ? <div className={'inputBlock'}>
                    <label></label>
                    <div className={'inputBox formInput'}> <label style={{ color: '#E51E00' }}>{this.state.emailMessage}</label></div>
                </div> : ''}
                {this.state.blocklistMessage && this.state.email !== '' ? <div className={'inputBlock'}>
                    <label></label>
                    <div className={'inputBox formInput'}> <label style={{ color: '#E51E00' }}>{this.state.blocklistMessage}</label></div>
                </div> : ''}
                {editMode === modes.profileUpdate && <div className={'inputMessage'}>{inputMessages.emailNotEdited}</div>}
                <div className={'inputBlock'}>
                    <label htmlFor={password.key} className={'inputLabel'}>{password.label}{(editMode !== 'profile_update') && <RequiredIndicator />}</label>
                    <div className={'inputBox formInput'}>
                        <input
                            type={passwordState ? "text" : "password"}
                            id={password.key}
                            placeholder={password.placeholder}
                            value={this.state[password.key]}
                            onChange={(e) => this.inputChange(e, password.key)}
                            disabled={editMode === modes.profileDisplay}
                        />

                        {editMode !== modes.profileDisplay && <img onClick={this.changePassState} className="eyes" src={passwordState ? eyeclose : eyeopen} />}
                    </div>
                </div>
                {this.state.passwordMessage && this.state.password !== '' ? <div className={'inputBlock'}>
                    <label></label>
                    <div className={'inputBox formInput'}> <label style={{ color: '#E51E00' }}>{this.state.passwordMessage}</label></div>
                </div> : ''}
                <div className={'inputBlock '}>
                    <label htmlFor={phoneNumber.key} className={'inputLabel'}>{phoneNumber.label}</label>
                    <div className={'inputBox formInput'}>
                        <input
                            id={phoneNumber.key}
                            placeholder={phoneNumber.placeholder}
                            value={this.state[phoneNumber.key]}
                            onChange={(e) => this.inputChange(e, phoneNumber.key)}
                            disabled={editMode === modes.profileDisplay}
                        />
                    </div>
                </div>
                <div className={'divider'} />
                <div className={'inputBlock'}>
                <label htmlFor={company.key} className={'inputLabel'}>{company.label}<RequiredIndicator /></label>
                <div className={editMode === modes.profileDisplay ?'disabledInputContainer':'selectInputContainer'}>
                    <div className={'searchIconContainer'}>
                        { this.state.company?.length > 0 ? '' : <img src={searchIcon} className={'prefixIcon'} alt={'search icon'} />}
                    </div>
                    <input
                        id={company.key}
                        placeholder={company.placeholder}
                        value={this.state[company.key]}
                        onChange={this.searchCompanyChanged}
                        disabled={editMode === modes.profileDisplay}
                    />
                    </div>
                    {companySuggestions && companySuggestions.length > 0 && 
                            <ul className={'profileSuggestionsContainer formSuggestionsContainer'}>
                            {companySuggestions.map((item) => {
                                return (
                                    <li key={`company${item}`} onClick={()=>this.onSelectCompany(item.company)}>
                                        <span className={'suggestTitle'}>{`${item.company}`}</span>
                                    </li>
                                );
                            })}
                            </ul>    
                        }
                </div>
                <div className={'inputBlock'}>
                    <label htmlFor={position.key} className={'inputLabel'}>{position.label}</label>
                    <div className={'inputBox formInput'}>
                        <input
                            id={position.key}
                            placeholder={position.placeholder}
                            value={this.state[position.key]}
                            onChange={(e) => this.inputChange(e, position.key)}
                            disabled={editMode === modes.profileDisplay}
                        />
                    </div>
                </div>
                <div className={'divider'} />
                <div className={'inputBlock'}>
                    <label className={'inputLabel'}>{userRoles.label}<RequiredIndicator /></label>
                    <SimpleSelect
                        customClass={cx('formSelect', { 'formSelect--disabled': editMode === modes.profileDisplay })}
                        data={userRoles.options}
                        open={rolesSelectOpened}
                        selected={this.state[userRoles.key]}
                        placeholder={userRoles.placeholder}
                        skey="ur_"
                        onOpenSelect={this.onRolesSelectClick}
                        onSelectFunction={this.rolesHandleSelect}
                        disabled={editMode === modes.profileDisplay || ( hasPermission()? '':editMode === modes.profileUpdate) }
                    />
                </div>
                {editMode === modes.create &&
                    <>
                        <div className={'divider'} />
                        <div className={'inputBlock'}>
                            <label htmlFor={groups.key} className={'inputLabel'}>{groups.label}</label>
                            <div className={'selectInputContainer'}>
                                <div className={'searchIconContainer'}>
                                    <img src={searchIcon} className={'prefixIcon'} alt={'search icon'} />
                                </div>
                                <input
                                    id={groups.key}
                                    placeholder={groups.placeholder}
                                    onChange={this.searchGroupChanged}
                                    value={searchGroup}
                                    autoComplete={"off"}
                                />
                            </div>
                            {groupSuggestions && groupSuggestions.length > 0 &&
                                <ul className={'profileSuggestionsContainer formSuggestionsContainer'}>
                                    {groupSuggestions.map((item) => {
                                        return (
                                            <li key={`user${item.id}`} onClick={() => this.onSelectGroup(item.id)}>
                                                <span className={'suggestTitle'}>{`${item.displayName}. `}</span>
                                                <span>{`Group ID: ${item.id}`}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            }
                        </div>
                        <div className={'selectedList'}>
                            {selectedGroups.length > 0 && selectedGroups.map((group) => {
                                return (
                                    <div className={'item'} key={group.id}>
                                        <div className={'titleBlock'}>
                                            <div className={'title'}>{group.displayName}</div>
                                            <div className={'itemId'}>{`Group ID: ${group.id}`}</div>
                                        </div>
                                        <img src={delGroupIcon} alt="remove" className="removeIcon" onClick={() => this.removeGroup(group.id)} />
                                    </div>
                                )
                            })}
                        </div>
                    </>}
                {(editMode === modes.create || editMode === modes.profileUpdate) &&
                    <div className={'buttonsBlock'}>
                        <div className={"button whiteButton addFormButton"} onClick={cancelForm}>
                            {buttons.cancel.label}
                        </div>
                        <div className={cx("button", "blueButton", "addFormButton", { "addFormButton--disabled": isSubmitBtnDisabled })} onClick={this.createUser}>
                            {buttons.add.label(editMode)}
                        </div>
                    </div>
                }
                {error && <span className={'error'}>{error}</span>}
            </div>
        )
    }
}
