import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import styled from 'styled-components';
import filterIcon from '../../../../assets/icons/Featured icon.svg';
import dateIcon from "../../../../assets/icons/icon_calendarpicker.svg";
import noFilterDataImg from '../../../../assets/icons/icon_filter_404.svg';
import "./AddFilters.scss";
import NoData from './NoData';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import AddFilter from '../../../../assets/icons/building-05.svg';
import SearchComponent from '../../../widgets/Shared/SearchComponent';
import RequiredIndicator from "../../UsersManagement/RequiredIndicator";
import ComboBox from '../../../widgets/Shared/ComboBox';
import ApiData from '../../../../utils/Api';
import DateInput from 'react-date-picker';
import moment from "moment";
import { getLocalStorage } from '../../../../utils/storageHelpers';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const dateFormat = "YYYY-MM-DD";
const dateDisplayFormat = "M/d/yyyy";

const AddFilterCombo = styled(ComboBox)`
  display: flex;
`;


const FilterLableCard = styled.div`
  &.checked {
    border: 0.5px solid rgb(65, 51, 181);
    color: rgb(65, 51, 181);
  }
  border: 1px solid #dfe2ea;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  & input[type="checkbox"] {
    margin-left: auto;
  }
`;

const ConfirmBtn = styled.button`
  margin-bottom: 24px;
  height: 50px;
  width: 188px;
  border: 1px solid #dfe2ea;
  border-radius: 4px;
  background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  &[disabled], &:disabled {
    background-color: grey;
  }
`;
const Tooltip = styled(Tippy)`
  background-color: white;
  color: #4c4d4e;
  border: 1px solid #DFE2EA;
`;

const FilterOptionsContainer = styled.div`
    display: flex;
`;

const AddFilters = ({ isOpen, marketData, closeAddFilter, groupId, onCreation, data }) => {
  let [filtersList, setFiltersList] = useState([])
  let [filterSearch, setFilterSearch] = useState('')
  let [categorySearch, setCategorySearch] = useState('')
  let [marketSearch, setMarketSearch] = useState([])
  let [startDate, setStartDate] = useState(moment().toDate())
  let [expiryDate, setExpiryDate] = useState(moment().toDate())
  let [selectedFilters, setSelectedFilters] = useState([])
  let [filterExpand, setFilterExpand] = useState([])
  const selectedFilterRef = useRef({});
  const modalRef = useRef(null);

  const getFilterDataList = async (params = '') => {
    const data = await ApiData.getAllFiltersList(groupId, params);
    
    const filterExpandAry = new Array(data.length).fill(false);
    const selectedFilterAry = data.reduce((accumulator, item) => {
      const sel = item.filters.reduce((acc, { filterId }) => {
        acc[filterId] = selectedFilterRef.current[filterId] || false;
        return acc;
      }, {});
      const aObj = { ...accumulator, ...sel };
      return aObj;
    }, {});
    setFiltersList(data);
    setFilterExpand(filterExpandAry);
    setSelectedFilters(selectedFilterAry);
  }

  const onExpandClick = (position) => {
    const updatedFilterExpand = filterExpand.map((item, index) => ((index === position) ? !item : item));
    setFilterExpand(updatedFilterExpand);
  }

  const createFilter = async () => {
    
    const filterIds = Object.keys(selectedFilterRef.current).reduce((acc, key) => {
      if (selectedFilterRef.current[key]) {
        acc.push(key);
      }
      return acc;
    }, []);
    const payload = {
      filterIds,
      startDate: moment(startDate).format(dateFormat),
      expiryDate: moment(expiryDate) >= moment(startDate) ? moment(expiryDate).format(dateFormat):moment(startDate).format(dateFormat), 
      groupId
    };
    let userID = JSON.parse(getLocalStorage('userInfo'))?.id;
    const resData = await ApiData.createFilters(payload,userID);

    let existFilters  = filterIds?.filter(el=> !resData?.some(val=> Number(val?.filterId) === Number(el)));
    let existingfilters = data?.filter(val => existFilters?.some(el=> Number(el) === Number(val?.filter?.id))).map(el=>el?.filter?.name[0] + el?.filter?.name?.substring(1)?.toLowerCase())
   
    resetFilterData();
    onCreation([...new Set(existingfilters)]);
    closeAddFilter();
  }
  const resetFilterData = () => {
    selectedFilterRef.current = {};
    const unSelectedFilterAry = filtersList.reduce((accumulator, item) => {
      const sel = item.filters.reduce((acc, { filterId }) => {
        acc[filterId] = false;
        return acc;
      }, {});
      const aObj = { ...accumulator, ...sel };
      return aObj;
    }, {});
    setSelectedFilters(unSelectedFilterAry);
  };

  const dateChange = (dateObj, inputKey) => {
    if (!moment(dateObj).isValid()) return;
    if (inputKey === "startDate") {
      setStartDate(dateObj)
    } else {
      setExpiryDate(dateObj)
    }
  };

  const searchData = (value, inputKey) => {
    if (inputKey === 'filter') {
      setFilterSearch(value)
    } else {
      setCategorySearch(value)
    }
  }

  const handleFilterSelection = (idAry, event) => {
    const selFil = { ...selectedFilters };
    idAry.forEach(id => {
      selFil[id] = event.target.checked;
    });
    const current = {...selectedFilterRef.current}
    selectedFilterRef.current = {...current, ...selFil};
    setSelectedFilters(selFil);
  }

  const calendarIcon = (
    <img src={dateIcon} className="calendarIcon" alt="Date icon" />
  );
  const closeModel = () => {
    resetFilterData()
    closeAddFilter();
  }
  
  useEffect(() => {
    getFilterDataList();
  },[]);

  useEffect(() => {
    let params = {
      filterSearch,
      categorySearch,
      marketSearch: marketSearch.join(',')
    }
    getFilterDataList(params);
  }, [filterSearch, categorySearch, marketSearch]);

  return (
    <Modal classNames={{
      modal: 'add-filter-modal',
    }} ref={modalRef} open={isOpen} onClose={closeModel} initialFocusRef={modalRef}>
      <div className="modalHeader">
        <div className="rounded"><img src={AddFilter} alt=""/></div>
        <div className="headingText">
          <span>Add Filter</span> ({`${Object.keys(selectedFilterRef.current).filter(item => selectedFilterRef.current[item]).length}`} selected)
          <div>Choose one or more filters to add to the group</div>
        </div>
      </div>
      <div className="container">
        <div className="inputBlock">
          <label className={"inputLabel"}>Filters<RequiredIndicator /></label>
          <SearchComponent placeholder="Filter" searchValue={filterSearch} searchData={searchData} />
        </div>
        <div style={{ display: 'flex' }}>
          <div className="inputBlock" style={{ flex: '1' }}>
            <label className={"inputLabel"}>Category<RequiredIndicator /></label>
            <SearchComponent placeholder="Category" searchValue={categorySearch} searchData={searchData} />
          </div>
          <div className="inputBlock" style={{ flex: '1', paddingRight: '5px' }}>
            <label className={"inputLabel"}>Market<RequiredIndicator /></label>
            <AddFilterCombo
              data={marketData}
              style={{ display: 'flex', marginTop: '-5px' }}
              value={marketSearch}
              setValue={setMarketSearch}
              icon={<ChevronDownIcon className='icon' />} />
          </div>

        </div>

      </div>
      <div className="filterContainer">
        {filtersList.length !==0 ? filtersList.map((value, index) => {
          return (
            <div className="filterCard" >
              <div className="categoryData" >
                <div onClick={(e) => { onExpandClick(index) }} style={{ display: "flex" }}>
                  {filterExpand[index] ? <ChevronUpIcon style={{width: '25px', color: '#4133b5'}} /> : <ChevronDownIcon style={{width: '25px', color: '#4133b5'}} />}
                  <div style={{ display: "flex", minWidth: '530px' }}>
                    <div className="categoryName" key={index} >{value.categoryName}</div>
                    <div className="selectedFilters"> ({value.filters.filter(({filterId}) => selectedFilters[`${filterId}`]).length}/{value?.filters?.length} Selected)</div>
                  </div>
                </div>
                <input type="checkbox" onChange={(e) => handleFilterSelection(value.filters.map(item => item.filterId), e)} checked={value.filters.every(({filterId}) => selectedFilters[filterId])} />
              </div>
              {
                filterExpand[index] &&
                <div style={{ padding: "0px 5px 10px 5px", borderTop: '0.5px solid #f6f3f3' }}>
                  {value?.filters.map((filter) => {
                    const marketCount = (filter.markets.length > 1) ? `(+ ${(filter.markets.length - 1)})` : '';
                    return (
                      <FilterLableCard className={cx({"checked": selectedFilters[`${filter.filterId}`]})}>
                        <div className="rounded"><img src={filterIcon} alt=""/></div>
                        <label for={filter.filterId} className="headingLabel"><div className="headingText">
                        <span style={{fontWeight: 500}}>{filter.filterName}</span> <span style={{fontSize: '13px'}}>{filter.decipherVariableName}</span>
                          <FilterOptionsContainer >
                          <Tooltip
                              allowHTML={true} placement="top"
                              content={<span style={{fontSize: "14px"}}>{filter.filterOptionsName}</span>}>
                              <div style={{ fontSize: "12px" }}>{filter.optionCount} Options&nbsp;</div> 
                          </Tooltip>
                          <div style={{ fontSize: "12px" }}> | {filter.markets[0].name} {marketCount}</div> 
                          </FilterOptionsContainer>
                        </div></label>
                        <input id={filter.filterId} type="checkbox" checked={selectedFilters[`${filter.filterId}`]} className="filter-checkbox" onChange={(event) => handleFilterSelection([filter.filterId], event)} />
                      </FilterLableCard>
                    )
                  })}
                </div>
              }
            </div>
          )
        }): <NoData autoCalHeight={true} src={noFilterDataImg} title={'No Matching Filter found'} subText={'Verify your search criteria'}/>}
      </div>
      <div className="datesContainerRoot">
        <div className="datesContainer">
          <div className="inputContainer">
            <div className="inputBlock">
              <label className={"inputLabel"}>Start Date</label>
              <div className={'datesContainer'}>
                <div className={'formInput'}>
                  <div>
                    <DateInput
                      className="dateInput_picker"
                      format={dateDisplayFormat}
                      calendarIcon={calendarIcon}
                      clearIcon={null}
                      value={startDate}
                      minDate={moment().toDate()}
                      onChange={(date) => dateChange(date, "startDate")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inputContainer">
            <div className="inputBlock">
              <label className={"inputLabel"}>End Date</label>
              <div className={'datesContainer'}>
                <div className={'formInput'}>
                  <div>
                    <DateInput
                      className="dateInput_picker"
                      format={dateDisplayFormat}
                      calendarIcon={calendarIcon}
                      clearIcon={null}
                      value={expiryDate}
                      minDate={startDate}
                      onChange={(date) => dateChange(date, "expiryDate")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span>Note :  Updating the dates may affect the filters displayed for the users for the group</span>
      </div>
      <div className="buttons">
        <button type="submit" className="cancelButton" onClick={closeModel}>Cancel</button>
        <ConfirmBtn type="submit" className="confirmButton" disabled={!(Object.keys(selectedFilterRef.current).some(key => selectedFilterRef.current[key]))} onClick={createFilter}>Confirm</ConfirmBtn>

      </div>
    </Modal>
  )
}

export default AddFilters;


