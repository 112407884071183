export function getLocalStorage (name) {
  return window.localStorage.getItem(name)
}

export function setLocalStorage (name, value) {
  return window.localStorage.setItem(name, value)
}

export function removeLocalStorage (name) {
  return window.localStorage.removeItem(name)
}

export function setSessionStorage (name, value) {
  return window.sessionStorage.setItem(name, value)
}

export function getSessionStorage (name) {
  return window.sessionStorage.getItem(name)
}

export function removeSessionStorage (name) {
  return window.localStorage.removeItem(name)
}