import React, { Component } from "react";
import moment from "moment";
import CardHeader from "../UsersManagement/CardHeader";
import ContractForm from "./ContractForm";
import LoadingLayer from "../../widgets/Shared/LoadingLayer";
import initData from "../../../utils/initData";
import ApiData from "../../../utils/Api";
import InfoModal from "../InfoModal";
import AddBrandsGeoModal from "./AddBrandsGeoModal";
import "../UsersManagement/Profile.scss";

export default class ContractProfile extends Component {
  constructor(props) {
    super(props);
    this.contractData = initData.getContractData();
    this.contractId = this.props.match.params.contractId;
    this.state = {
      brandList: [],
      originalBrands:[],
      geoList: [],
      quotas: [],
      originalQuotas:[],
      contractData: null,
      loading: true,
      currentMode: this.contractData.modes.profileDisplay,
      isOptionsDropdownOpened: false,
      isBrandDropdownOpened: false,
      storedContractFields: null,
      isSuspendContractModalOpened: false,
      openModal: false,
      title: null,
      copyContract: false,
      scrollWrapperHeight: null,
      removedBrands:[],
      removedQuotas:[],
      removeAction:false,
    };
    this.openModalHandler = this.openModalHandler.bind(this);
    this.setBrandListHandler = this.setBrandListHandler.bind(this);
    this.setGeoListHandler = this.setGeoListHandler.bind(this);
    this.removeMyBrandItem = this.removeMyBrandItem.bind(this);
    this.removeGeoColumn = this.removeGeoColumn.bind(this);
    this.editQuota = this.editQuota.bind(this);
    this.updateUserContract = this.updateUserContract.bind(this);
  }

  getListData = () => {
    this.setState({ loading: true });
    ApiData.getContract(this.contractId)
      .then((result) => {
        this.setState({
          contractData: result,
          loading: false,
          storedContractFields: this.getContractFormData(result),
        });
      })
      .catch((err) => {
        console.log("Get group data error", err);
        this.setState({
          loading: false,
        });
      });
  };

  getQuotas = () => {
    this.setState({ loading: true });
    ApiData.getQuotas(this.contractId)
      .then((result) => {
        const brandResult = [];
        const campaignResult = [];
        const brandMap = new Map();
        for (const item of result.map((res)=>res.brand)) {
          if (!brandMap.has(item.id)) {
            brandMap.set(item.id, true); // set any value to Map
            brandResult.push({
              id: item.id,
              name: item.name,
              logo: item.logo
            });
          }
        }
        const campaignMap = new Map();
        for (const item of result.map((res)=>res.market)) {
          if (!campaignMap.has(item.id)) {
            campaignMap.set(item.id, true); // set any value to Map
            campaignResult.push({
              id: item.id,
              name: item.name,
              region: item.region
            });
          }
        }

        let quotas = [];

        campaignResult.forEach(c => {
          brandResult.forEach(b => {
            const r = result.find(q => (q.market.id === c.id && q.brand.id === b.id));
            if (r === undefined)
              quotas.push({quota: null, marketId: c.id, brandId: b.id});
            else
              quotas.push({quota: r.quota, marketId: r.market.id, brandId: r.brand.id})
          });
        });

        this.setState({
          quotas: quotas,
          geoList: result? campaignResult: [],
          brandList: result? brandResult:[],
          originalBrands: result? brandResult:[],
          loading: false,
        });
      })
      .catch((err) => {
        console.log("Get group data error", err);
        this.setState({
          loading: false,
        });
      });
  };

  updateUserContract = (quotas) => {
    ApiData.updateContractPartial(this.contractId, quotas)
      .then((response) => {
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("Updating user error", error);
      });
  };
  openModalHandler = (childTitle) => {
    this.setState({ title: childTitle });
    this.setState({ openModal: true });
  };
  editQuota(brandId, marketId, insertedQuota) {
    const { quotas } = this.state;
    this.setState({
      quotas: quotas.map((quota) =>
        quota.brandId === brandId && quota.marketId === marketId
          ? {
              brandId: quota.brandId,
              marketId: quota.marketId,
              quota: insertedQuota !== null ? Number(insertedQuota) : null,
            }
          : quota
      ),
    });
  }
  removeMyBrandItem(id) {
    const { brandList, quotas, geoList , removedBrands,removedQuotas,removeAction} = this.state;
    this.setState({ removeAction: true})
    let testQuota =  [...removedQuotas,...quotas.filter((quota) => quota.brandId === id)]
    const unique = (arr, props = []) => [...new Map(arr.map(entry => [props.map(k => entry[k]).join('|'), entry])).values()];
    this.setState({
      brandList: brandList.filter((brand) => brand.id !== id),
      geoList: !brandList.filter((brand) => brand.id !== id).length? [] : geoList,
      quotas: quotas.filter((quota) => quota.brandId !== id),
      removedBrands:[...removedBrands,brandList.filter((brand) => brand.id === id)[0]],
      removedQuotas: unique(testQuota,['brandId','marketId']),
    });
  }

  setBrandListHandler = (newBrands) => {
    const marketIds = this.state.geoList.map((m) => m.id);
    this.setState({ brandList: [...this.state.brandList, ...newBrands] });
    this.setState({
      quotas: [
        ...this.state.quotas,
        ...newBrands.map((brand) =>
        marketIds.map((id) => {
            return { brandId: brand.id, quota: null, marketId: id };
          })
        ),
      ].flat(),
    });
  };
  setGeoListHandler = (newGeos) => {
    const brandIds = this.state.brandList.map((m) => m.id);
    this.setState({ geoList: [...this.state.geoList, ...newGeos] });
    this.setState({
      quotas: [
        ...this.state.quotas,
        ...newGeos.map((geo) =>
          brandIds.map((id) => {
            return { brandId: id, quota: null, marketId: geo.id };
          })
        ),
      ].flat(),
    });
  };
  suspendContract = () => {
    const { types } = initData.getStatusMessages();
    const { showMessage } = this.props;
    const { contractData } = this.state;

    this.setState({ loading: true });

    ApiData.suspendContract(this.contractId, true)
      .then((result) => {
        typeof showMessage === "function" &&
          showMessage(types.suspendContract, contractData.name);
      })
      .catch((error) => {
        console.log("Suspend contract error", error);
        this.setState({ loading: false });
      });
  };

  findUpdateDiff = (contractData, storedContractFields) => {
    if (contractData && storedContractFields) {
      const { form } = this.contractData;
      const contractFields = { ...contractData };
      const diff = [];
      delete contractFields[form.brands.key];
      delete contractFields[form.campaigns.key];
      Object.keys(contractFields).forEach((key) => {
        if (
          contractFields[key] !== storedContractFields[key] &&
          !(!contractFields[key] && !storedContractFields[key])
        ) {
          diff.push(key);
        }
      });
      if (
        storedContractFields[form.brands.key] &&
        !contractData.selectedBrands.every((brand, index) => {
          return (
            !!brand &&
            !!storedContractFields[form.brands.key][index] &&
            brand.id === storedContractFields[form.brands.key][index]
          );
        })
      ) {
        diff.push(form.brands.key);
      }
      if (
        storedContractFields[form.campaigns.key] &&
        !contractData.selectedCampaigns.every((market, index) => {
          return (
            !!market &&
            !!storedContractFields[form.campaigns.key][index] &&
            market.id === storedContractFields[form.campaigns.key][index]
          );
        })
      ) {
        diff.push(form.campaigns.key);
      }
      const diffLabels = Object.values(form)
        .filter((value) => diff.some((key) => value.key === key))
        .map((value) => value.label);
      return {
        keys: diff,
        labels: diffLabels,
        string: diffLabels.length ? diffLabels.join(", ") : "",
      };
    }
  };

  updateContract = (contract, rawData) => {
    const { showMessage } = this.props;
    const { storedContractFields } = this.state;
    const { types } = initData.getStatusMessages();
    const updateDiff = this.findUpdateDiff(rawData, storedContractFields);

    this.setState({ loading: true });
    contract.brandMarketContracts = contract.brandMarketContracts.filter(bcc => bcc.quota !== null);

    ApiData.updateContract(this.contractId, contract)
      .then((response) => {
        typeof showMessage === "function" &&
          showMessage(types.updateContract, updateDiff.string);
        this.setState(
          {
            currentMode: this.contractData.modes.profileDisplay,
            loading: false,
          },
          this.getListData
        );
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("Updating user error", error);
      });
  };

  copyContract = (contract, rawData) => {
    const { showMessage ,history } = this.props;
    const { types } = initData.getStatusMessages();
    this.setState({ loading: true });
       ApiData.createContract(contract)
        .then(result => {
            typeof showMessage === 'function' && showMessage(types.createContract, contract.name);
            history.push('/users');
            this.setState({ currentMode: this.contractData.modes.profileDisplay, loading: false }, this.getListData);
        })
        .catch(error => {
            this.setState({ inProgress: false, loading:false });
            if(error.message === "Network Error")this.setState({sessionTimeoutErr:true});
        })
  }

  cancelUpdatingContract = () => {
    this.setState({ currentMode: this.contractData.modes.profileDisplay });
  };

  getContractFormData = (contractData) => {
    const {
      form: {
        contractName,
        purpose,
        salesforceId,
        amount,
        startDate,
        endDate,
        brands,
        campaigns,
        cost
      },
    } = this.contractData;
    return {
      id: contractData.id,
      [contractName.key]: contractData.name,
      [purpose.key]: contractData.contractPurposeId,
      [salesforceId.key]: contractData.salesforceId,
      [amount.key]: contractData.amount,
      [cost.key]: contractData?.cost,
      [startDate.key]: contractData.startDate,
      [endDate.key]: contractData.endDate,
      [brands.key]: contractData.brands,
      [campaigns.key]: contractData.campaigns,
    };
  };
  removeGeoColumn = (id) => {
    const { geoList, quotas } = this.state;
    this.setState({
      geoList: geoList.filter((geo) => geo.id !== id),
      quotas: quotas.filter((quota) => quota.marketId !== id),
    });
  };
  editContractAction = () => {
    this.setState({
      currentMode: this.contractData.modes.profileUpdate,
      isOptionsDropdownOpened: false,
      copyContract:true
    });
  };

  copyContractAction = () => {
    this.setState({ currentMode: this.contractData.modes.profileCopy, isOptionsDropdownOpened: false, copyContract:false });
    const {match}=this.props;
    window.history.pushState({}, null,`/${match.params.groupId}/addcontract`);
  }

  cancelEditAction = () => {
    this.setState({ currentMode: this.contractData.modes.profileDisplay });
  };

  openSuspendContractModal = () => {
    this.setState({ isSuspendContractModalOpened: true });
  };

  closeOpenModal = () => {
    this.setState({ openModal: false });
  };

  suspendContractAction = () => {
    this.closeOptionsDropdown();
    this.openSuspendContractModal();
  };

  createDropdownOptions = () => {
    const contractOptions = { ...initData.getContractDropdownOptions() };
    contractOptions.options[0].action = this.editContractAction;
    contractOptions.options[1].action = this.copyContractAction;

    return contractOptions;
  };
  closeOptionsDropdown = () => {
    this.setState({ isOptionsDropdownOpened: false });
  };

  openOptionsDropdown = () => {
    this.setState({ isOptionsDropdownOpened: true });
  };

  onOptionsBtnClick = () => {
    const { isOptionsDropdownOpened } = this.state;
    if (isOptionsDropdownOpened) this.closeOptionsDropdown();
    else this.openOptionsDropdown();
  };

  getSuspendContractModalButtons = () => {
    const {
      modals: {
        suspendContract: { buttons },
      },
    } = this.contractData;

    buttons[0].action = this.closeOpenModal;
    buttons[1].action = this.suspendContract;
    return buttons;
  };

  onGroupNameClick = () => {
    const { history } = this.props;
    const { contractData } = this.state;
    const id =
      contractData && contractData.group && contractData.group.id
        ? contractData.group.id
        : "";
    if (id) {
      history.push(`/group/${id}`);
    }
  };

  onBrandClick = (id) => {
    const { history } = this.props;
    history.push(`/brand/${id}`);
  };

  triggerLoader = (show) => {
    this.setState({ loading: show });
}

  generateContractSubtitle = ({id, createdBy, updatedBy, updatedAt}, text) => {
      // If a contract has not been updated then use the created by information.
      if (!updatedBy) {
          updatedBy = createdBy;
      }

      return text.replace("%{id}%", id ?? "N/A")
                  .replace("%{userName}%", (updatedBy ? `${updatedBy?.firstName} ${updatedBy?.lastName}` : "N/A"))
                  .replace("%{updatedDate}%", (updatedAt ? moment.utc(updatedAt).local().format('LLL') : "N/A"));
    }

  onResize = () => {
    setTimeout(() => {
        const scrollWrapperHeight = window.innerHeight
        this.setState({ scrollWrapperHeight });
    }, 500);
  };

  initializeAndUseScrollWrapperService = () => {
    const scrollWrapperHeight =  window.innerHeight;

    this.setState({
        scrollWrapperHeight,
    });
  };

  componentDidMount() {
    this.getListData();
    this.getQuotas();
    this.initializeAndUseScrollWrapperService();
    window.addEventListener('resize', this.onResize);
  }
 
  componentWillUnmount(){
    window.removeEventListener('resize', this.onResize);
  }

  validateBrandsList = (brand, removedBrands,quotas,removedQuotas) => {
    this.setState({brandList:[...new Set([...brand,...removedBrands])],quotas:[...new Set([...quotas,...removedQuotas])], removeAction:false, removedBrands:[],removedQuotas:[]})
  }

  render() {
    const {
      contractData,
      loading,
      currentMode,
      isOptionsDropdownOpened,
      isSuspendContractModalOpened,
    } = this.state;
    const { match } = this.props;
   
    const primaryTitle = (currentMode === 'profile_display'||currentMode === 'profile_update') &&contractData && contractData.name ? `${this.contractData.title(currentMode)}${contractData.name}` : 'Add New Contract';
    const secondaryTitle = (currentMode === 'profile_display'||currentMode === 'profile_update') && contractData ? this.generateContractSubtitle(contractData, this.contractData.subtitle(currentMode)) : `${this.contractData.subtitle(currentMode)}`;
    const formFieldValues = contractData
      ? this.getContractFormData(contractData)
      : null;
    const { modals } = this.contractData;

    const modalContract = {
      name: contractData ? contractData.name : "",
      id: contractData ? contractData.id : "",
    };

    const headerExtraInfo = contractData
      ? {
          status: this.contractData.header.extraInfo.status,
          message: `${this.contractData.header.extraInfo.message} ${
            contractData.group && contractData.group.displayName
              ? contractData.group.displayName
              : contractData.group && contractData.group.name
              ? contractData.group.name
              : ""
          }`,
          onMessageClick: this.onGroupNameClick,
        }
      : null;
      
    return (
      <div className={"profile"} >
        <CardHeader
          primaryTitle={primaryTitle}
          secondaryTitle={secondaryTitle}
          button={{ type: "options", onClick: this.onOptionsBtnClick }}
          isOptionsDropdownOpened={isOptionsDropdownOpened}
          dropdownOptions={this.createDropdownOptions()}
          closeDropdown={this.closeOptionsDropdown}
          extraInfo={headerExtraInfo}
        />
        {this.state.openModal && (
          <AddBrandsGeoModal
            setBrandListHandler={this.setBrandListHandler}
            setGeoListHandler={this.setGeoListHandler}
            title={this.state.title}
            subjectInfo={{
              title: `Contract Name: ${modalContract.name}`,
              subtitle: `Contract ID: ${modalContract.id}`,
            }}
            currentBrands={this.state.brandList}
            currentGeos={this.state.geoList}
            closeOpenModal={this.closeOpenModal}
          />
        )}
        <div className={"profileContent"} style={{height:this.state.scrollWrapperHeight-172}}>
          <ContractForm
            editQuota={this.editQuota}
            removeMyBrandItem={this.removeMyBrandItem}
            removeGeoColumn={this.removeGeoColumn}
            fieldValues={formFieldValues}
            cancelForm={this.cancelEditAction}
            editMode={currentMode}
            createContract={this.state.copyContract? this.updateContract: this.copyContract}
            contractData={contractData}
            brandList={contractData ? this.state.brandList : []}
            originalBrands={contractData ? this.state.originalBrands : []}
            geoList={this.state.geoList}
            quotas={this.state.quotas}
            campaignList={contractData ? contractData.campaigns : []}
            groupId={match.params.groupId}
            brandClick={this.onBrandClick}
            openModalHandler={this.openModalHandler}
            triggerLoader={this.triggerLoader}
            removedBrands= {contractData ? this.state.removedBrands : []}
            validateBrandsList={this.validateBrandsList}
            removedQuotas={contractData ? this.state.removedQuotas:[]}
            removeAction={this.state.removeAction}
            contractID = {this.contractId}
          />
        </div>

        {loading && <LoadingLayer />}
        {isSuspendContractModalOpened && (
          <InfoModal
            title={modals.suspendContract.title}
            message={modals.suspendContract.message}
            extraMessage={modals.suspendContract.extraMessage}
            subjectInfo={{
              title: `Contract Name: ${modalContract.name}`,
              subtitle: `Contract ID: ${modalContract.id}`,
            }}
            buttons={this.getSuspendContractModalButtons()}
          />
        )}
      </div>
    );
  }
}
