import React, { Component } from 'react'
import './Switch.scss'

export default class Switch extends Component {
    render() {
        const { checked, onChange } = this.props

        return (
          <label className='switch' style={{marginRight: '3mm'}}>
            <input type='checkbox' checked={checked} onChange={onChange} />
            <span className='slider round'></span>
          </label>
        )
    }
}
