/*
    The backend related to stat testing (lowerThan and higherThan) data keys are slightly different to
    labels used across other parts of the app.
    This method converts the keys (as they come from backend) to be the same as labels to simplify the data usage.
 */

const transformHigherLowerThan = (data) => {
    if (!data) {
        return {};
    }

    return Object.entries(data)
        .reduce((acc, [key, value]) => {
            switch (true) {
                case key === 'netMomentum':
                    acc['Momentum'] = value;
                    break;
                case key === 'highQuality':
                    acc['Quality'] = value;
                    break;
                default:
                    acc[key.replace(/^./, key[0].toUpperCase())] = value;
            }

            return acc;
        }, {});
}

export default transformHigherLowerThan;
