import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import './FiltersList.scss';

const FiltersList = (props) => {
    const filtersListRef = useRef();

    useEffect(()=>{
        let {page, selectedTab} = props;
        if (page === 'segments' && selectedTab === 0) {
            filtersListRef.current.addEventListener('mouseover', onMouseOver);
            filtersListRef.current.addEventListener('mouseout', onMouseLeave);
        }
        return(()=>{
             if (page === 'segments' && selectedTab === 0) {
                filtersListRef.current.removeEventListener('mouseover', onMouseOver);
                filtersListRef.current.removeEventListener('mouseout', onMouseLeave);
            }
        })
    },[props.currentSelectedFilters])

    const getCertainParentByClass = (targetParentClass, node) => {
        const { parentNode } = node;

        return parentNode.classList.contains(targetParentClass)
            ? parentNode
            : getCertainParentByClass(targetParentClass, parentNode);
    };

    const onMouseOver = () => {
        const parentNode = getCertainParentByClass('cardContainer', filtersListRef.current);
        parentNode.classList.add('increasedZIndex');
    };

    const onMouseLeave = () => {
        const parentNode = getCertainParentByClass('cardContainer', filtersListRef.current);
        parentNode.classList.remove('increasedZIndex');
    };

    const { currentSelectedFilters, showLimit, clearFunction, page } = props;
    const slimit = showLimit ? Number(showLimit):0;
    const filterShow = [];
        if (Array.isArray(currentSelectedFilters)) {
        currentSelectedFilters.forEach((f)=>{
            if (f?.labels && f?.labels?.length !== 0) {
                f.labels.forEach((label)=>{
                    filterShow.push(<div key={'f_'+f.fId+'_'+label} onClick={props.mbClickFunction} className={cx("filterItem",{ 'segmentItem': f.filterType === "SEGMENT"})}>{label}</div>);
                });
            }
        });
    }
    const fnum = filterShow.length;
    return (
            <div
                className={cx(
                    "filterList",
                    { 'empty': fnum === 0 }
                )}
                ref={filtersListRef}
            >
                {slimit>0?filterShow.slice(0,slimit):filterShow }
                {slimit>0 && fnum>slimit && <div className="moreButton" onClick={props.mbClickFunction}>+{fnum-slimit}</div>}
                {slimit>0 && fnum>slimit && <div className="moreFilters">{filterShow.slice(slimit)}</div>}
                {clearFunction && fnum>0 && <div className="clearButton blueTextButton" onClick={clearFunction}>Clear Filters</div>}
            </div>
    )
}

export default FiltersList;
