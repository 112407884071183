import React, { Component } from 'react';
import CardHeader from '../UsersManagement/CardHeader';
import { DecipherSurveyForm } from './DecipherSurveyForm';
import PropTypes from 'prop-types';
import ApiData from '../../../utils/Api';
import initData from '../../../utils/initData';
import LoadingLayer from '../../widgets/Shared/LoadingLayer';


export default class AddDecipherSurveyComponent extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        showMessage: PropTypes.func.isRequired
    }

    state = {
        inProgress: false,
        apiError: false
    }

    cancelForm = () => {
        this.props.history.push('/markets');
    }

    createSurvey(survey) {
        const { types } = initData.getStatusMessages();
        const { showMessage } = this.props;

        this.setState({ inProgress: true, apiError: false });
        ApiData.createDecipherSurvey(survey).then((result) => {
            typeof showMessage === 'function' && showMessage(types.decipherSurveyCreate, result.survey);
            this.setState({ inProgress: false });
            this.cancelForm()
        }).catch(error => {
            this.setState({ inProgress: false, apiError: true });
            console.log(error);
        });
    }

    render() {
        const { inProgress, apiError } = this.state;

        return (
            <div>
                <CardHeader
                    primaryTitle={'Add Decipher Survey'}
                    secondaryTitle={'Create a new Decipher Survey for Geography'}
                />
                <DecipherSurveyForm
                    cancelForm={this.cancelForm}
                    createSurvey={(survey) => this.createSurvey(survey)}
                    apiError={apiError}
                />
                {inProgress && <LoadingLayer />}
            </div>
        );
    }
}