export const selectors = ['.newRow', '.horizontalBarContainer', '.headerContainer'];

/*
    This class accepts as argument the list of selectors other than scrollWrapper to precisely calculate it's height
 */

export default class EquityScrollWrapperHeightService {
    #nodes;

    constructor (selectors) {
        this.#nodes = selectors.map((selector) => this.#getElement(selector));
    }

    calculateScrollWrapperHeight = () => {
        const screenHeight = this.#getScreenHeight();
        const otherNodesHeight = this.#calculateNodesHeight();

        return screenHeight - otherNodesHeight;
    };

    #getElement = (selector) => document.querySelector(selector);

    #getElementHeight = (node) => node?.offsetHeight ?? 0;

    #getScreenHeight = () => window.innerHeight>730? window.innerHeight-27:window.innerHeight-20;

    #calculateNodesHeight = () => this.#nodes.reduce((acc, node) => acc + this.#getElementHeight(node), 0);
}
