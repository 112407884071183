import React, { Component } from 'react';
import PropTypes from 'prop-types';

import OptionsDropdown from '../../widgets/Shared/OptionsDropdown';
import moreIcon from '../../../assets/icons/icon_more_circle.svg';
import moreIconGrey from '../../../assets/icons/icon_more_circle_grey.svg';

import './CardHeader.scss';

export default class CardHeader extends Component {
    static propTypes  = {
        primaryTitle: PropTypes.string.isRequired,
        secondaryTitle: PropTypes.string,
        extraInfo: PropTypes.shape({
            status: PropTypes.string,
            message: PropTypes.string,
            onMessageClick: PropTypes.func,
        }),
        button: PropTypes.shape({
            type: PropTypes.string,
            onClick: PropTypes.func,
        }),
        dropdownOptions: PropTypes.object,
        isOptionsDropdownOpened: PropTypes.bool,
        closeDropdown: PropTypes.func,
        decipherSurveyButton: PropTypes.bool,
        addDecipherSurvey: PropTypes.func
    }

    state = {
        isOptionsBtnHover: false,
    }

    onMouseOverOptionsBtn = () => {
        this.setState({ isOptionsBtnHover: true });
    }

    onMouseLeaveOptionsBtn = () => {
        this.setState({ isOptionsBtnHover: false });
    }

    render() {
        const { primaryTitle, secondaryTitle, dropdownOptions, closeDropdown, isOptionsDropdownOpened, extraInfo, decipherSurveyButton } = this.props;
        const { button } = this.props;
        const { isOptionsBtnHover } = this.state;
        const optionsButtonId = "optionsButton";

        return (
            <div className={'userCardHeader'}>
                <div className={'titleContainer'}>
                    <div className={'primaryTitle'}>{primaryTitle}</div>
                    <div className={'secondaryTitle'}>{secondaryTitle}</div>
                </div>
                <div className={'rightHeaderContainer'}>
                    {decipherSurveyButton &&
                    <div className="button blueButton" onClick={this.props.addDecipherSurvey}>
                        Add Decipher Survey
                    </div>
                    }
                    {!!extraInfo && 
                        <div className={'extraInfo'}>
                            <span className={'status'}>{extraInfo.status}</span>
                            <span className={'message'} onClick={extraInfo.onMessageClick ? extraInfo.onMessageClick : null}>{extraInfo.message}</span>
                        </div>
                    }
                    {!!button && button.type === 'cancel' && 
                        <div className={"button whiteButton cancelButton"} onClick={button.onClick}>
                            Cancel
                        </div>
                    }
                    {!!button && button.type === 'options' && 
                        <div id={optionsButtonId} className={"optionsButton"} onClick={button.onClick} onMouseOver={this.onMouseOverOptionsBtn} onMouseOut={this.onMouseLeaveOptionsBtn}>
                            <img src={isOptionsDropdownOpened || isOptionsBtnHover ? moreIcon : moreIconGrey} alt={'More icon'} />
                        </div>
                    }
                    {!!button && button.type === 'options' && isOptionsDropdownOpened &&
                    <OptionsDropdown
                        closeDropdown={closeDropdown}
                        options={dropdownOptions.options}
                        separateOptions={dropdownOptions.separateOptions}
                        buttonId={optionsButtonId}
                    />}
                </div>
            </div>
        )
    }
}
